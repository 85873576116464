
import { calendarOutline, person, call, mail, expand, resize, scale, remove, medical, calendarClear, bodyOutline } from "ionicons/icons";
import { IonGrid, IonRow, IonCol, IonIcon, useIonViewWillEnter, IonModal, IonAvatar, IonImg, IonButton } from "@ionic/react";
import Cookies from "universal-cookie";
import '../assets/scss/Booking_Confirmation.scss';
import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Preferences } from '@capacitor/preferences';
import { useHistory } from "react-router";
import { postRequest } from "../ApiServices";

interface ChildProps {
  proofView: any;
  ref: any
}

const BookingConfirm: React.FC<ChildProps> = forwardRef((props, ref) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const history = useHistory();
  function dismiss() {
    modal.current?.dismiss();
  }
  const myDate = new Date();

  const cookies = new Cookies();
  let total_distance: any = 0;
  let order_details: any;

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [orderAmount, setOrderAmount] = useState<any>(0);
  const [setting, setSetting] = useState<any>('');
  const [orderDetails, setOrderDetails] = useState<any>('');
  const [activeCustomer, setActiveCustomer] = useState(true);
  const [userDetails, setUserDetails] = useState<any>('');
  // const [totalDistance, setTotalDistance] = useState<any>(0);

  // useEffect(()=>{
  //   setup();
  // });
  useIonViewWillEnter(async () => {
    total_distance = 0;
    setup();
  });

  const handleOneToOneDistance = (order_details: any, setting: any) => {
    var distanceService: any = new google.maps.DistanceMatrixService();
    distanceService.getDistanceMatrix({
      origins: [order_details.pickup_address[order_details.pickup_address.length - 1].latitude + ',' + order_details.pickup_address[order_details.pickup_address.length - 1].longitude],
      destinations: [order_details.dropoff_address[0].latitude + ',' + order_details.dropoff_address[0].longitude],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      durationInTraffic: true,
      avoidHighways: false,
      avoidTolls: false
    },
      (response: any, status: any) => {
        let distance_text = response.rows[0].elements[0].distance.text;
        let unit = distance_text.substring(distance_text.length - 2, distance_text.length);
        let distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
        if (unit === 'ft') {
          distance_value = distance_value / 5280;
        } else if (unit === 'mi') {
          distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
        }
        total_distance = total_distance + distance_value;
        // setTotalDistance(distance_value);

        //determining whether it is inside or outside the working hours
        handleDetermineInOutWorkingHours(order_details, setting);
      });
  }

  const handleCalculateDistance = (order_details: any, num: any = 0, other: any = 1, setting: any, type: any = '') => {
    var distanceService: any = new google.maps.DistanceMatrixService();
    distanceService.getDistanceMatrix({
      origins: [order_details.pickup_address[num].latitude + ',' + order_details.pickup_address[num].longitude],
      destinations: [order_details.pickup_address[other].latitude + ',' + order_details.pickup_address[other].longitude],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      durationInTraffic: true,
      avoidHighways: false,
      avoidTolls: false
    },
      (response: any, status: any) => {
        let distance_text = response.rows[0].elements[0].distance.text;
        let unit = distance_text.substring(distance_text.length - 2, distance_text.length);
        let distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
        if (unit === 'ft') {
          distance_value = distance_value / 5280;
        } else if (unit === 'mi') {
          distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
        }
        total_distance = total_distance + distance_value;

        num = other;
        other = other + 1;
        if (order_details.pickup_address.length > other) {
          if (type === 'many-to-one') {
            handleCalculateDistance(order_details, num, other, setting, 'many-to-one');
          } else {
            handleCalculateDistance(order_details, num, other, setting);
          }
        } else if (type === 'many-to-one') {
          handleOneToOneDistance(order_details, setting);
        } else if (type === '') {
          handleCalculateDistance2(order_details, 0, 1, setting);
        }
      });
  }

  const handleCalculateDistance2 = (order_details: any, num: any = 0, other: any = 1, setting: any) => {
    var distanceService: any = new google.maps.DistanceMatrixService();
    distanceService.getDistanceMatrix({
      origins: [order_details.dropoff_address[num].latitude + ',' + order_details.dropoff_address[num].longitude],
      destinations: [order_details.dropoff_address[other].latitude + ',' + order_details.dropoff_address[other].longitude],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      durationInTraffic: true,
      avoidHighways: false,
      avoidTolls: false
    },
      (response: any, status: any) => {
        let distance_text = response.rows[0].elements[0].distance.text;
        let unit = distance_text.substring(distance_text.length - 2, distance_text.length);
        let distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
        if (unit === 'ft') {
          distance_value = distance_value / 5280;
        } else if (unit === 'mi') {
          distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
        }
        total_distance = total_distance + distance_value;

        num = other;
        other = other + 1;
        if (order_details.dropoff_address.length > other) {
          handleCalculateDistance2(order_details, num, other, setting);
        } else {
          var distanceService: any = new google.maps.DistanceMatrixService();
          distanceService.getDistanceMatrix({
            origins: [order_details.pickup_address[order_details.pickup_address.length - 1].latitude + ',' + order_details.pickup_address[order_details.pickup_address.length - 1].longitude],
            destinations: [order_details.dropoff_address[0].latitude + ',' + order_details.dropoff_address[0].longitude],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.IMPERIAL,
            durationInTraffic: true,
            avoidHighways: false,
            avoidTolls: false
          },
            async (response: any, status: any) => {
              let distance_text = response.rows[0].elements[0].distance.text;
              let unit = distance_text.substring(distance_text.length - 2, distance_text.length);
              let distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
              if (unit === 'ft') {
                distance_value = distance_value / 5280;
              } else if (unit === 'mi') {
                distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
              }
              total_distance = total_distance + distance_value;

              //determining whether it is inside or outside the working hours
              handleDetermineInOutWorkingHours(order_details, setting);
            });
        }
      });
  }

  const handleDetermineInOutWorkingHours = async (order_details: any, setting: any) => {
    let outOfWorkingHours: boolean = false;
    for await (let pickup_address of order_details.pickup_address) {
      for await (let meetup_point of pickup_address.meetup_points) {
        let working_hours_starting = setting.starts_at;
        let working_hours_ending = setting.ends_at;
        working_hours_starting = working_hours_starting.split(':');
        working_hours_ending = working_hours_ending.split(':');
        let working_hours_starting_hours = parseInt(working_hours_starting[0]);
        let working_hours_starting_minutes = parseInt(working_hours_starting[1]);
        let working_hours_ending_hours = parseInt(working_hours_ending[0]);
        let working_hours_ending_minutes = parseInt(working_hours_ending[1]);
        let meetup_at_24h = convertTime12to24(meetup_point.meetup_at);
        if (!(parseInt(meetup_at_24h.hours) > working_hours_starting_hours) && (parseInt(meetup_at_24h.hours) < working_hours_ending_hours)) {
          outOfWorkingHours = true;
        } else if ((parseInt(meetup_at_24h.hours) === working_hours_starting_hours) && (parseInt(meetup_at_24h.minutes) < working_hours_starting_minutes)) {
          outOfWorkingHours = true;
        } else if ((parseInt(meetup_at_24h.hours) === working_hours_ending_hours) && (parseInt(meetup_at_24h.minutes) > working_hours_ending_minutes)) {
          outOfWorkingHours = true;
        }
        if (outOfWorkingHours) {
          break;
        }
      }
      if (outOfWorkingHours) {
        break;
      }
    }

    let amount = 0;
    if (outOfWorkingHours) {
      amount = (parseFloat(setting.non_paid_base_price) + (total_distance * parseFloat(setting.non_paid_per_mile_price))) + (parseFloat(setting.non_paid_base_price) / 2);
    } else {
      amount = parseFloat(setting.non_paid_base_price) + (total_distance * parseFloat(setting.non_paid_per_mile_price));
    }
    setOrderAmount(amount);
    setShowLoading(false);
    
    let orderdetails;
    const { value } = await Preferences.get({ key: 'api-data' });
    if (value) {
      orderdetails = JSON.parse(value);
      orderdetails.total = amount;
      orderdetails.subtotal = amount;
      setOrderDetails(orderdetails);
      await Preferences.set({key: 'api-data', value: JSON.stringify(orderdetails)});
    }else{
      const { value } = await Preferences.get({ key: 'dtlc-order-details' });
      if (value) {
        orderdetails = JSON.parse(value);
        orderdetails.total = amount;
        orderdetails.subtotal = amount;
        setOrderDetails(orderdetails);
        await Preferences.set({key: 'dtlc-order-details', value: JSON.stringify(orderdetails)});
      }
    }
  }

  const convertTime12to24 = (time12h: any) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM' || modifier === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }

    return {
      hours: hours,
      minutes: minutes
    };
  }

  useImperativeHandle(ref, () => ({
    getAlert() {
      setup();
    }

  }));

  const handleGetPricing = () => {
    postRequest('general-setting', {}, '').then(data => {
      setSetting(data.data);

      if (order_details.pickup_address.length === 1 && order_details.dropoff_address.length === 1) {
        handleOneToOneDistance(order_details, data.data);
      } else if (order_details.pickup_address.length === 1 && order_details.dropoff_address.length > 1) {
        handleCalculateDistance2(order_details, 0, 1, data.data);
      } else if (order_details.pickup_address.length > 1 && order_details.dropoff_address.length > 1) {
        handleCalculateDistance(order_details, 0, 1, data.data);
      } else if (order_details.pickup_address.length > 1 && order_details.dropoff_address.length === 1) {
        handleCalculateDistance(order_details, 0, 1, data.data, 'many-to-one');
      }
    }).catch(err => console.log(err));
  }

  const setup = async () => {
    let dtlc = cookies.get('dtlc');
    if (dtlc && dtlc.token) {
      setUserDetails(dtlc);
    }


    postRequest('general-setting', {}, '').then(async (data) => {
      setSetting(data.data);

      let order_details;
      const { value } = await Preferences.get({ key: 'api-data' });
      if (value) {
        order_details = JSON.parse(value);
        setOrderDetails(order_details);
      } else {
        const { value } = await Preferences.get({ key: 'dtlc-order-details' });
        if (value) {
          order_details = JSON.parse(value);
          setOrderDetails(order_details);

        }
      }

      if (order_details.pickup_address.length === 1 && order_details.dropoff_address.length === 1) {
        handleOneToOneDistance(order_details, data.data);
      } else if (order_details.pickup_address.length === 1 && order_details.dropoff_address.length > 1) {
        handleCalculateDistance2(order_details, 0, 1, data.data);
      } else if (order_details.pickup_address.length > 1 && order_details.dropoff_address.length > 1) {
        handleCalculateDistance(order_details, 0, 1, data.data);
      } else if (order_details.pickup_address.length > 1 && order_details.dropoff_address.length === 1) {
        handleCalculateDistance(order_details, 0, 1, data.data, 'many-to-one');
      }
    }).catch(err => console.log(err));
  }

  const handleAddProof = (addressType: any) => {
    props.proofView(addressType);
  }

  const handleViewProof = (addressType: any) => {
    props.proofView(addressType);
  }

  return (
    <div className="booking-wrapper booking-confirm-wrapper">
      <div className="wrapper-inner">
        {Object.keys(orderDetails).length > 0 ?
          <>
            <div className="services-wrapper ion-margin-bottom booking-background-color">
              {/* <h6 className="ion-text-center ion-margin-bottom">Local Services</h6> */}
              <IonGrid className="ion-padding">
                <IonRow>
                  <IonCol>
                    <div className="d-flex"><IonIcon icon={calendarOutline} /><h6>Booking placed</h6></div>
                  </IonCol>
                  <IonCol>
                    <h6 className="ion-text-end">{new Date(orderDetails.booked_at).toDateString()}</h6>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {typeof orderDetails.user === 'object' && orderDetails.user !== null ? <div className="d-flex" id="open-custom-dialog"><IonIcon icon={bodyOutline} /><h6 className="info-heading">Customer Information</h6></div> : ''}
                    <IonModal className="customer-info-modal" ref={modal} trigger="open-custom-dialog">
                      <div className="customer-profile">
                        <div className="ion-text-end ion-margin-bottom"><span onClick={dismiss}>x</span></div>
                        <div className="d-flex">
                          <IonAvatar>
                            <IonImg src={typeof orderDetails.user === 'object' && orderDetails.user !== null ? orderDetails.user.image_url : ''} />
                          </IonAvatar>
                          <div className="ion-padding-start">
                            <h4>{typeof orderDetails.user === 'object' && orderDetails.user !== null ? orderDetails.user.full_name : ''}</h4>
                            <h6>{typeof orderDetails.user === 'object' && orderDetails.user !== null ? orderDetails.user.email : ''}</h6>
                            <p>{typeof orderDetails.user === 'object' && orderDetails.user !== null ? orderDetails.user.phone_no : ''}</p>
                            {typeof orderDetails.user === 'object' && orderDetails.user !== null ? orderDetails.user.is_active ? <IonButton shape='round' size="small" className='active-btn'>Active</IonButton> : <IonButton shape='round' size="small" className='non-active-btn'>Non Active</IonButton> : ''}
                          </div>
                        </div>
                      </div>
                    </IonModal>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>

            <div className="booking-background-color ion-margin-bottom">
              <div className="pickup-wrapper ion-padding">
                <h5 className="ion-no-margin">Pickup Address{orderDetails.pickup_address.length > 1 ? 'es' : ''}</h5>
                {/* <h6>Address:</h6> */}
                {orderDetails.pickup_address.map((item: any, index: any) => {
                  return (
                    <div key={index} className="addresses-wrapper">
                      {orderDetails.pickup_address.length > 1 ?
                        <div className="ion-margin-end">
                          <span className={'previous_input'}>
                            {index + 1}
                          </span>
                        </div>
                        :
                        ''
                      }
                      <div>
                        {/* {userDetails && userDetails.user_data.role_id === 3 && orderDetails.status === 'TRANSFERRED' && (item.order_proof === null || item.order_proof === undefined || !item.order_proof)?
                            <h6 className="proof-btn" onClick={()=> handleAddProof(item.id)}>Add proof</h6>
                            :
                            item.order_proof !== undefined && item.order_proof !== null?
                              <h6 className="proof-btn" onClick={()=> handleViewProof('pickup-'+index)}>View proof</h6>
                            :
                            '' 
                          } */}
                        <h6>
                          {item.address}
                        </h6>
                        <div className="d-flex"><IonIcon className="addr-ico" icon={person} /><p><b>{item.contact.first_name}</b></p></div>
                        <div className="d-flex"><IonIcon className="addr-ico" icon={call} /><p>{item.contact.phone_no}</p></div>
                        <div className="d-flex"><IonIcon className="addr-ico" icon={mail} /><p>{item.contact.email}</p></div>
                        <IonButton fill="outline" className="btn-text ion-margin-top" onClick={() => {
                          history.push({
                            pathname: "view-meetup-points",
                            state: {
                              address: item,
                              address_index: index
                            }
                          });
                        }}>View Meetup Points</IonButton>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* <div className="booking-background-color ion-margin-bottom">
              <div className="pickup-wrapper ion-padding">
                <h5 className="ion-margin-bottom">Delivery Address</h5>
                <h6>Address:</h6>
                <div className="d-flex ion-margin-top">
                  <div className="ion-margin-end">
                    <IonIcon icon={locationOutline} />
                  </div>
                  <h5>{orderDetails.dropoff_address? orderDetails.dropoff_address.address : ''}</h5>
                </div>
                <h6>Receiver Name:</h6>
                <div className="d-flex ion-margin-top">
                  <div className="ion-margin-end">
                    <IonIcon icon={personOutline} />
                  </div>
                  <h5>{orderDetails.dropoff_address? orderDetails.dropoff_address.contact.first_name : ''}</h5>
                </div>
                <h6>Contact Number:</h6>
                <div className="d-flex ion-margin-top">
                  <div className="ion-margin-end">
                    <IonIcon icon={callOutline} />
                  </div>
                  <h5>{orderDetails.dropoff_address? orderDetails.dropoff_address.contact.phone_no : ''}</h5>
                </div>
              </div>
            </div> */}
            <div className="booking-background-color ion-margin-bottom">
              <div className="pickup-wrapper ion-padding">
                <h5 className="ion-no-margin">Dropoff Address{orderDetails.dropoff_address.length > 1 ? 'es' : ''}</h5>
                {orderDetails.dropoff_address.map((item: any, index: any) => {
                  return (
                    <div key={index} className="addresses-wrapper">
                      {orderDetails.dropoff_address.length > 1 ?
                        <div className="ion-margin-end">
                          <span className={'previous_input'}>
                            {index + 1}
                          </span>
                        </div>
                        :
                        ''
                      }
                      <div>
                        {/* {userDetails && userDetails.user_data.role_id === 3 && orderDetails.status === 'TRANSFERRED' && (item.order_proof === null || item.order_proof === undefined || !item.order_proof)?
                            <h6 className="proof-btn" onClick={()=> handleAddProof(item.id)}>Add proof</h6>
                            :
                            item.order_proof !== undefined && item.order_proof !== null?
                              <h6 className="proof-btn" onClick={()=> handleViewProof('dropoff-'+index)}>View proof</h6>
                            :
                            ''
                          } */}
                        <h6>
                          {item.address}
                        </h6>
                        <div className="d-flex"><IonIcon className="addr-ico" icon={person} /><p><b>{item.contact.first_name}</b></p></div>
                        <div className="d-flex"><IonIcon className="addr-ico" icon={call} /><p>{item.contact.phone_no}</p></div>
                        <div className="d-flex"><IonIcon className="addr-ico" icon={mail} /><p>{item.contact.email}</p></div>
                        <IonButton fill="outline" className="btn-text ion-margin-top" onClick={() => {
                          history.push({
                            pathname: "view-meetup-points",
                            state: {
                              address: item,
                              address_index: index
                            }
                          });
                        }}>View Meetup Points</IonButton>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="booking-background-color">
              <div className="amount-wrapper ion-padding">
                <div className="info-bx">
                  <div className="d-flex"><IonIcon className="info-ico" icon={expand} /><p><b>Package Size:</b></p> <p>{orderDetails.package_size ? orderDetails.package_size : ''}</p></div>
                  <div className="d-flex"><IonIcon className="info-ico" icon={resize} /><p><b>Height:</b></p> <p>{orderDetails.height ? orderDetails.height : '0'}</p></div>
                  <div className="d-flex"><IonIcon className="info-ico" icon={remove} /><p><b>Length:</b></p> <p>{orderDetails.length ? orderDetails.length : '0'}</p></div>
                  <div className="d-flex"><IonIcon className="info-ico" icon={expand} /><p><b>Width:</b></p> <p>{orderDetails.width ? orderDetails.width : '0'}</p></div>
                  <div className="d-flex"><IonIcon className="info-ico" icon={scale} /><p><b>Weight:</b></p> <p>{orderDetails.weight ? orderDetails.weight : '0'}</p></div>
                  <div className="d-flex"><IonIcon className="info-ico" icon={medical} /><p><b>Parcel Type:</b></p> <p>{orderDetails.parcel_type ? orderDetails.parcel_type : '0'}</p></div>
                  <div className="d-flex"><IonIcon className="info-ico" icon={calendarClear} /><p><b>Delivery Date:</b></p> <p>{orderDetails.pickup_at ? orderDetails.pickup_at : '0'}</p></div>
                </div>
                <h5 className=""><b>Total Amount</b></h5>
                <h5><b>£ {orderAmount}</b></h5>
              </div>
            </div>
          </>
          :
          ''
        }
      </div>
    </div>
  )
});

export default BookingConfirm
