import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions";
import { BarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner";
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonLoading,
    IonPage,
    IonRow,
    IonSearchbar,
    IonToast,
    useIonViewWillEnter,
} from "@ionic/react";
import { qrCodeOutline, scanOutline, trashBin } from "ionicons/icons";
import { useEffect, useState } from "react";
import "../assets/scss/Orders_List.scss";
import Cookies from 'universal-cookie';
import { postRequest } from "../ApiServices";
import { prepareOrderStructure } from "../functions";
import { Preferences } from "@capacitor/preferences";
import { useHistory } from "react-router";

interface ChildProps {
    Orders: any;
    type: any
}

const All_Orders_List: React.FC<ChildProps> = (props) => {
    const history = useHistory();
    const cookies = new Cookies();
    
    const [orders, setOrders] = useState<any>(props.Orders);
    const [orderNumber, setOrderNumber] = useState<any>('');
    const [showToast, setShowToast] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [showLoading, setShowLoading] = useState<boolean>(false);

    useIonViewWillEnter(()=>{
        
    });

    const openScanner = async () => {
        await AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.CAMERA).then(async (result)=>{
            console.log('Has permission?',result.hasPermission);
            if(!result.hasPermission){
                AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.CAMERA);
            }else{
                const data = await BarcodeScanner.scan({
                    showFlipCameraButton : true, // iOS and Android
                    showTorchButton : true,
                    prompt : "Place a barcode or qr code inside the scan area",
                    resultDisplayDuration: 0
                });
                console.log(`Barcode data: ${data.text}`);
                let order_number: any = data.text;
                order_number = order_number.split('=');
                order_number = order_number[1];
                setOrderNumber(order_number);
                handleApiRequest(order_number);
            }
        }, (err)=> {
            console.log(err);
        });
      };

      const handleSearchOrder = () => {
        setShowLoading(true);
        handleApiRequest(orderNumber);
      }
    
      const handleApiRequest = (order_number: any) => {
        let dtlc = cookies.get('dtlc');
        postRequest('order/show', {
          order_number: order_number
        },dtlc.token.token).then(async (data)=>{
          setOrderNumber('');
          setShowLoading(false);
          if(data.data){
            prepareOrderStructure(data.data).then(async (result: any)=>{
              await Preferences.set({
                key: 'api-data',
                value: JSON.stringify(result),
              });
              // cookies.set('dtlc-order-details', data.data);
              history.push('/view_order');
            }).catch((err: any)=>{
              console.log(err);
            });
          }else{
            setAlertMessage('Order not found!');
            setShowToast(true);
          }
        }).catch((err)=>{
          console.log(err);
        });
      }
    
      const handleOrderNumberChange = (value: any) => {
        setOrderNumber(value);
      }

      const handleOrderClick = (item: any) => {
        prepareOrderStructure(item).then(async (result: any)=>{
            await Preferences.set({
              key: 'api-data',
              value: JSON.stringify(result),
            });
            // cookies.set('dtlc-order-details', data.data);
            history.push('/view_order');
          }).catch((err: any)=>{
            console.log(err);
          });
      }
     
    return (
        <div className="driver_detail">
            <div className="parcel_content">
                <IonGrid>
                    {/* <h4 className="heading">Driver history</h4> */}
                    <IonRow className="position-relative"> 
                        <IonSearchbar show-clear-button="never" placeholder='Order Number #' className="ion-no-padding ion-margin-bottom" onIonChange={(event)=>handleOrderNumberChange(event.detail.value)}
                        value={orderNumber}></IonSearchbar>                
                        <span className="search-icon" onClick={()=>handleSearchOrder()}></span>
                        <IonIcon class="scan-icon" icon={qrCodeOutline} onClick={()=>openScanner()}/>      
                        <div className="main-wrapper-block">
                            {props.Orders.type === 'custom-date' || props.Orders.type === 'today'?
                                props.Orders.orders.length > 0?
                                    props.Orders.orders.map((item: any, index: any)=>{
                                        return(
                                            <div className="parcel-detail" onClick={()=>handleOrderClick(item)} key={index}>
                                                <IonCol size="2" className="sidebar-color ion-padding"></IonCol>
                                                <IonCol size="10" className="content">
                                                    {/* <p>{item.address}</p> */}
                                                    <h4>#{item.order_number}</h4>
                                                    {item.order_statuses? <p>{item.order_statuses[item.order_statuses.length - 1].status}</p> : ''}
                                                    <p><b>{item.order_nature === 'MULTI_PICK_MULTI_DROP'? 'Multiple pickups and multiple dropoffs' : item.order_nature === 'MULTI_PICK_SINGLE_DROP'? 'Multiple pickups and single dropoff' : item.order_nature === 'SINGLE_PICK_MULTI_DROP'? 'Single pickup and multiple dropoffs' : item.order_nature === 'SINGLE_PICK_SINGLE_DROP'? 'Single pickup and single dropoff' : ''}</b></p>
                    
                                                    <div className="parcel">
                                                        <p><IonImg src={item.type === 'urgent'? require('../assets/images/running.png') : item.type === 'same day'? require('../assets/images/stopwatch.png') : item.type === 'schedule'? require('../assets/images/calendar.png') : ''} className="type-img"/></p>
                                                    </div>
                                                </IonCol>
                                            </div>
                                        )
                                    })
                                    :
                                    <h3>No orders</h3>
                                :
                                props.Orders.type === 'monthly'?
                                    Object.entries(props.Orders.orders).map((item: any, index: any)=>{
                                        return(
                                            <div key={index} className="main-block">
                                                <h3 className="main-block-title">{item[0]}</h3>
                                                {item[1].length > 0?
                                                    item[1].map((item: any, index: any)=>{
                                                        return(
                                                            <div className="parcel-detail" onClick={()=>handleOrderClick(item)} key={index}>
                                                                <IonCol size="2" className="sidebar-color ion-padding"></IonCol>
                                                                <IonCol size="10" className="content">
                                                                    {/* <p>{item.address}</p> */}
                                                                    <h4>#{item.order_number}</h4>
                                                                    {item.order_statuses? <p>{item.order_statuses[item.order_statuses.length - 1].status}</p> : ''}
                                                                    <p><b>{item.order_nature === 'MULTI_PICK_MULTI_DROP'? 'Multiple pickups and multiple dropoffs' : item.order_nature === 'MULTI_PICK_SINGLE_DROP'? 'Multiple pickups and single dropoff' : item.order_nature === 'SINGLE_PICK_MULTI_DROP'? 'Single pickup and multiple dropoffs' : item.order_nature === 'SINGLE_PICK_SINGLE_DROP'? 'Single pickup and single dropoff' : ''}</b></p>
                                    
                                                                    <div className="parcel">
                                                                        <p><IonImg src={item.type === 'urgent'? require('../assets/images/running.png') : item.type === 'same day'? require('../assets/images/stopwatch.png') : item.type === 'schedule'? require('../assets/images/calendar.png') : ''} className="type-img"/></p>
                                                                    </div>
                                                                </IonCol>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <h4 className="no-title">No orders</h4>
                                                }
                                            </div>
                                        )
                                    })
                                :
                                props.Orders.type === 'weekly'?
                                Object.entries(props.Orders.orders).map((item: any, index: any)=>{
                                    return(
                                        <div key={index} className="main-block">
                                            <h3 className="main-block-title">{item[0]}</h3>
                                            {Object.entries(item[1]).map((item: any, index: any)=>{
                                                return(
                                                    <div className="main-wrapper-block" key={index}>
                                                        <h4 className="week-title">{item[0]}</h4>
                                                        {item[1].length > 0?
                                                            item[1].map((item: any, index: any)=>{
                                                                return(
                                                                    <div className="parcel-detail" onClick={()=>handleOrderClick(item)} key={index}>
                                                                        <IonCol size="2" className="sidebar-color ion-padding"></IonCol>
                                                                        <IonCol size="10" className="content">
                                                                            {/* <p>{item.address}</p> */}
                                                                            <h4>#{item.order_number}</h4>
                                                                            {item.order_statuses? <p>{item.order_statuses[item.order_statuses.length - 1].status}</p> : ''}
                                                                            <p><b>{item.order_nature === 'MULTI_PICK_MULTI_DROP'? 'Multiple pickups and multiple dropoffs' : item.order_nature === 'MULTI_PICK_SINGLE_DROP'? 'Multiple pickups and single dropoff' : item.order_nature === 'SINGLE_PICK_MULTI_DROP'? 'Single pickup and multiple dropoffs' : item.order_nature === 'SINGLE_PICK_SINGLE_DROP'? 'Single pickup and single dropoff' : ''}</b></p>
                                            
                                                                            <div className="parcel">
                                                                                <p><IonImg src={item.type === 'urgent'? require('../assets/images/running.png') : item.type === 'same day'? require('../assets/images/stopwatch.png') : item.type === 'schedule'? require('../assets/images/calendar.png') : ''} className="type-img"/></p>
                                                                            </div>
                                                                        </IonCol>
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <h6 className="no-title">No orders</h6>
                                                        }
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    )
                                })
                                :
                                    ''
                            }
                        </div>
                    </IonRow>
                    <IonLoading
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={"Please wait..."}
                    />
                    <IonToast
                        isOpen={showToast}
                        position="bottom"
                        onDidDismiss={() => setShowToast(false)}
                        color={"danger"}
                        message={alertMessage}
                        duration={2500}
                    />
                </IonGrid>
            </div>
        </div>
    );
};

export default All_Orders_List;
