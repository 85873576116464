import {
    IonButton,
    IonContent,
    IonFooter,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonTitle,
    IonToast,
    IonToolbar,
  } from "@ionic/react";
  import { arrowForward, mailOutline } from "ionicons/icons";
  import React, { useState, useRef } from "react";
  import { Link, useHistory } from "react-router-dom";
  import "../assets/scss/Auth.scss";
  import {postRequest } from '../ApiServices';
  import Cookies from 'universal-cookie';
  import { device } from "../Layout";
  
const Forgot_Password_Component = () => {
    const [email, setEmail] = useState<any>("");
    const [showLoading, setShowLoading] = useState(false);
    const [showToast1, setShowToast1] = useState(false);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const history = useHistory();
    const itemRef = useRef<any>([]);
    const fieldsRef = useRef<any>([]);
    const itemHandler = async (type: string) => {
      for (let item in fieldsRef.current) {
        if (item === 'email' && email === '') {
          fieldsRef.current[item].className = "sc-ion-label-ios-h sc-ion-label-ios-s ios md floating-label";
        }
      }
  
      for (let item in itemRef.current) {
        if (item === "email") {
          itemRef.current[item].className = "";
        }
  
      }
      if (type === "email") {
        itemRef.current[type].className = "item-shadow";
        fieldsRef.current[type].className = "sc-ion-label-ios-h sc-ion-label-ios-s ios md top-label";
      }
    };
    
    const forgot = () => {
      setShowLoading(true);
      postRequest('auth/forgot-password-check', {email:email}).then(async (data)=>{
        
        if(data.status){
          setShowLoading(false);
          setError(false);
        }else{
          setError(true);
          setShowLoading(false);
        }
        setAlertMessage(data.message);
        setShowToast1(true);
      }).catch((err)=>{
        console.log(err);
      });

    }
  return (
    <>
         <div className="auth-wrapper-inner">
          <h1>Forgot Password</h1>
          <p className="subTitle">
            Please insert your email for recover password.
          </p>
          <IonItem
            lines="none"
            ref={el => (itemRef.current['email'] = el)}
            onClick={() => itemHandler("email")}
          >
            <IonIcon icon={mailOutline} size="small" />
            <div className="position-relative">
              <IonLabel
                ref={el => (fieldsRef.current['email'] = el)}
                className="floating-label"
              >
                EMAIL
              </IonLabel>
              <IonInput
                value={email}
                onIonChange={(e) => setEmail(e.detail.value!)}
              ></IonInput>
            </div>
          </IonItem>
          <div className="justify-content-end">
            <IonButton shape="round" onClick={forgot}>
              Forgot
              <IonIcon icon={arrowForward} />
            </IonButton>
          </div>
        </div>
        <IonToast
        isOpen={showToast1}
        position="bottom"
        onDidDismiss={() => setShowToast1(false)}
        color={error ? "danger" : "primary"}
        message={alertMessage}
        duration={2000}
      />
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
    </>
  )
}

export default Forgot_Password_Component
