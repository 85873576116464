import React, { useEffect, useState } from 'react';
import {
    IonButtons,
    IonContent,
    IonMenu,
    IonMenuButton,
    IonPage,
    IonImg,
    IonItem,
    IonIcon,
    IonList,
    IonLabel,
    IonAvatar,
    useIonViewWillEnter,
    IonMenuToggle,
    IonBadge,
    IonToast,
    IonSpinner,
} from '@ionic/react';
import { ribbonOutline, bodyOutline, fileTrayOutline, carSportOutline, chatbubbleEllipsesOutline, notificationsOutline, logOutOutline, searchOutline, timeOutline, settingsOutline, documentOutline, fileTrayFullOutline, briefcaseOutline, timerOutline, speedometerOutline, qrCodeOutline, bicycleOutline} from 'ionicons/icons';
import "../assets/scss/Menu.scss";
import { useHistory } from 'react-router';
import Cookies from 'universal-cookie';
import { Capacitor } from '@capacitor/core';
import { menuController } from "@ionic/core";
import { getCookieData, log_me_out } from '../functions';
import { ActionPerformed, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { disableBackgroundMode } from '../BackgroundgeolocationSettings';

const Menu: React.FC = () => {
    const history = useHistory();
    const cookies = new Cookies();

    const [newNotification, setNewNotification] = useState(false);
    const [userData, setUserData] = useState<any>('');
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<any>('');

    useEffect(()=>{
        if(Capacitor.platform !== "web"){
            PushNotifications.checkPermissions().then((res) => {
              if (res.receive !== "granted") {
                PushNotifications.requestPermissions().then((res) => {
                  if (res.receive === "denied") {
                    // showToastMsg("Push Notification permission denied");
                  } else {
                    // showToastMsg("Push Notification permission granted");
                    handleAddNotificationListener();
                  }
                });
              } else {
                handleAddNotificationListener();
              }
            });
        }

        return () => {
            setNewNotification(false);
            setUserData('');
            setShowToast(false);
            setToastMessage('');
        }
    }, []);

    const handleAddNotificationListener = () => {
        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener(
          "pushNotificationReceived",
          (notification: PushNotificationSchema) => {
            let notificationObj: any = {
              notifications: [
                {
                  title: notification.title,
                  body: notification.body,
                  id: 1,
                },
              ],
            };
            LocalNotifications.schedule(notificationObj);
            setNewNotification(true);
          }
        );
    
        // Method called when tapping on a notification
        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification: ActionPerformed) => {
            history.push("/notifications");
            // if (cookies.get("dtlc")) {
            //   history.push("/notifications");
            // }
          }
        );
    
        LocalNotifications.addListener(
          "localNotificationActionPerformed",
          (notification: any) => {
            history.push("/notifications");
            // if (cookies.get("dtlc")) {
            // }
          }
        );
    }

    useIonViewWillEnter(() => {
        let cookie_data = getCookieData('dtlc');
        if(cookie_data){
            setUserData(cookie_data.user_data);
        }
    })

    const handleMenu = () => {
        let cookie_data = getCookieData('dtlc');
        if(cookie_data){
            setUserData(cookie_data.user_data);
        }
    }

    return (
        <>
            <IonMenu contentId="main-content" onIonWillOpen={() => handleMenu()} swipe-gesture={false} type="overlay">
                <div className="ion-no-padding">
                    <div className="driver-sidebar-profile ">
                        <div className="driver-profile">
                            <IonAvatar className="avatar-img">
                                <IonImg src={userData? userData.image_url : require('../assets/images/dummy-img.jpg')} />
                            </IonAvatar>
                            <div className="profile-content">
                                <h4>Hello, {userData? userData.first_name : ''}</h4>
                                <p>{userData? userData.email : ''}</p>
                            </div>
                        </div>

                    
                    <IonList className='side-profile-list'>
                        <IonMenuToggle>
                            {userData && userData.role_id === 2? 
                                <>
                                   <IonItem lines='none' className='pointer' onClick={()=>history.push("/customer_panel")}>
                                        <IonIcon icon={speedometerOutline} />
                                        <IonLabel>Dashboard</IonLabel>
                                    </IonItem>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/bookings")}>
                                        <IonIcon icon={searchOutline} />
                                        <IonLabel>My Bookings</IonLabel>
                                    </IonItem>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/order_history")}>
                                        <IonIcon icon={timeOutline} />
                                        <IonLabel>Order History</IonLabel>
                                    </IonItem>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/customer_care")}>
                                        <IonIcon icon={documentOutline} />
                                        <IonLabel>Customer Care</IonLabel>
                                    </IonItem>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/user_profile")}>
                                        <IonIcon icon={settingsOutline} />
                                        <IonLabel>Settings</IonLabel>
                                    </IonItem>
                                </>
                            :
                            userData && userData.role_id === 3?
                                <>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/driver_panel")}>
                                        <IonIcon icon={speedometerOutline} />
                                        <IonLabel>Dashboard</IonLabel>
                                    </IonItem>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/inprocess_orders")}>
                                        <IonIcon icon={bicycleOutline} />
                                        <IonLabel>Inprocess Jobs</IonLabel>
                                    </IonItem>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/driver_job")}>
                                        <IonIcon icon={briefcaseOutline} />
                                        <IonLabel>Pending Jobs</IonLabel>
                                    </IonItem>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/jobs_history")}>
                                        <IonIcon icon={timeOutline} />
                                        <IonLabel>Jobs History</IonLabel>
                                    </IonItem>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/driver_documents")}>
                                        <IonIcon icon={fileTrayFullOutline} />
                                        <IonLabel>Documents</IonLabel>
                                    </IonItem>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/driver_setting")}>
                                        <IonIcon icon={settingsOutline} />
                                        <IonLabel>Settings</IonLabel>
                                    </IonItem>
                                    {/* <IonItem lines='none' onClick={()=>history.push("/")}>
                                        <IonIcon icon={qrCodeOutline} />
                                        <IonLabel>Scan Order</IonLabel>
                                    </IonItem> */}
                                </>
                            :
                            userData && userData.role_id === 5?
                                <>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/dispatch_panel")}>
                                        <IonIcon icon={speedometerOutline} />
                                        <IonLabel>Dashboard</IonLabel>
                                    </IonItem>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/new_orders")}>
                                        <IonIcon icon={ribbonOutline} />
                                        <IonLabel>New Orders</IonLabel>
                                    </IonItem>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/manage_customer")}>
                                        <IonIcon icon={bodyOutline} />
                                        <IonLabel>Manage Customers</IonLabel>
                                    </IonItem>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/all_orders")}>
                                        <IonIcon icon={fileTrayOutline} />
                                        <IonLabel>All Order</IonLabel>
                                    </IonItem>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/manage_driver")}>
                                        <IonIcon icon={carSportOutline} />
                                        <IonLabel>Manage Drivers</IonLabel>
                                    </IonItem>
                                    <IonItem lines='none' className='pointer' onClick={()=>history.push("/customer_queries")}>
                                        <IonIcon icon={chatbubbleEllipsesOutline} />
                                        <IonLabel>Customer Queries</IonLabel>
                                    </IonItem>
                                    {/* <IonItem lines='none' onClick={()=>history.push("")}>
                                        <IonIcon icon={qrCodeOutline} />
                                        <IonLabel>Scan Order</IonLabel>
                                    </IonItem> */}
                                </>
                            :
                            ''
                            }
                            <IonItem lines='none' className='pointer' onClick={async ()=>{
                                // let result = await log_me_out();
                                // console.log(result);
                                log_me_out();
                                if(userData && userData.role_id === 3 && Capacitor.isNative){
                                    disableBackgroundMode();
                                }
                                menuController.toggle();
                                history.replace('/login');

                                // if(result){
                                //     if(userData && userData.role_id === 3){
                                //         stopGettingLocation();
                                //     }
                                //     menuController.toggle();
                                //     history.replace('/login');
                                // }else{
                                //     console.log('Something went wrong!');
                                //     setToastMessage('Something went wrong!');
                                //     setShowToast(true);
                                // }
                            }}>
                                <IonIcon icon={logOutOutline} />
                                <IonLabel>Logout</IonLabel>
                            </IonItem>
                        </IonMenuToggle>
                    </IonList>
                    </div>
                    
                </div>
            </IonMenu>
            <div id="main-content">
                <div className="menu">
                    <div className="iontolbar">
                        <div className="notification" onClick={(()=>{
                            setNewNotification(false);
                            history.push("/notifications");
                        })}>
                            {newNotification? <IonBadge></IonBadge> : ''}
                            <IonIcon className={newNotification? "ion-icon notifications-icon-btn" : "ion-icon"} icon={notificationsOutline} />
                        </div>
                        <IonButtons slot="end" className="ion-btn">
                            <IonMenuButton >
                                <IonImg src={require('../assets/images/black-menu.png')} />
                            </IonMenuButton>
                        </IonButtons>
                    </div>
                </div>
            </div>
            <IonToast
                isOpen={showToast}
                position="bottom"
                onDidDismiss={() => setShowToast(false)}
                color={"danger"}
                message={toastMessage}
                duration={2500}
            />
        </>
    );
}
export default Menu;