import React, { useRef, useState } from "react";
import {
  IonButton,
  IonCol,
  IonLoading,
  IonContent,
  IonImg,
  IonPage,
  IonRow,
  IonLabel,
  IonGrid,
  IonAvatar,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonPopover,
  IonInput,
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonSearchbar,
  IonToast,
  IonIcon,
} from "@ionic/react";
import { AppHeader } from "../components/AppHeader";
import "../assets/scss/Manage_Customer.scss";
import { useHistory } from "react-router";
import Cookies from "universal-cookie";
import { postRequest } from "../ApiServices";
import { Preferences } from "@capacitor/preferences";
import { chevronDownSharp } from "ionicons/icons";

const Manage_Customer: React.FC = () => {
  const history = useHistory();
  const cookies = new Cookies();
  const popover = useRef<HTMLIonPopoverElement>(null);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };
  const [showLoading, setShowLoading] = useState<any>(true);
  const [allCustomers, setAllCustomers] = useState<any>([]);
  const [customerStatus, setCustomerStatus] = useState<any>('All');
  const [customerCallSign, setCustomerCallSign] = useState<any>('');
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<any>('');

  useIonViewWillEnter(() => {
    let dtlc = cookies.get("dtlc");
    if (dtlc && dtlc.token) {
      setShowLoading(true);
      postRequest("dispatcher/all-customer", {}, dtlc.token.token)
        .then((res: any) => {
          setShowLoading(false);
          if (res.status) {
            setAllCustomers(res.data);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  });

  const handleApiRequest = (status: any) => {
    let dtlc = cookies.get("dtlc");
    setCustomerStatus(status);
    if (dtlc && dtlc.token) {
      setPopoverOpen(false);
      setShowLoading(true);
      postRequest("dispatcher/all-customer", {
        status: status
      }, dtlc.token.token)
        .then((res: any) => {
          setShowLoading(false);
          if (res.status) {
            setAllCustomers(res.data);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }

  const handleCustomerClick = async (index: any) => {
    await Preferences.set({
      key: 'profile-data',
      value: JSON.stringify(allCustomers[index]),
    });

    history.push("/customer_profile_view");
  }

  const handleCustomerSearch = async () => {
    if (customerCallSign === '' || customerCallSign === ' ') {
      setToastMessage('Invalid customer call sign');
      setShowToast(true);
      return;
    }

    let index = 0;
    for await (let customer of allCustomers) {
      if (customer.call_sign === customerCallSign.toUpperCase()) {
        handleCustomerClick(index);
        break;
      }
      index++;
    }
  }

  return (
    <IonPage>
      <IonContent className="linear_bg">
        <AppHeader from="" />
        <div className="manage-customer-wrapper">
          <h2>Manage Customer</h2>
          <div className="d-flex align-items-center">
            <div className="position-relative w-full">
              <IonSearchbar
                show-clear-button="never"
                placeholder="Customer Call Sign"
                className="ion-no-padding"
                value={customerCallSign}
                onIonChange={(e) => {
                  setCustomerCallSign(e.detail.value);
                }}
              ></IonSearchbar>
              <span className="search-icon" onClick={() => handleCustomerSearch()}></span>
            </div>
            <div onClick={openPopover} className="select-list ion-d-flex ion-justify-content-between ion-align-items-center">
              <span className="ion-text-capitalize ion-fs-14">{customerStatus}</span>
              <IonIcon size='small' icon={chevronDownSharp} />
            </div>
            <IonPopover size="auto" ref={popover} isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
              <IonContent class="ion-padding-start ion-padding-end">
                <IonItem lines='none'>
                  <IonLabel className="ion-no-margin" onClick={() => handleApiRequest('all')}>All</IonLabel>
                </IonItem>
                <IonItem lines='none'>
                  <IonLabel className="ion-no-margin" onClick={() => handleApiRequest('active')}>Active</IonLabel>
                </IonItem>
                <IonItem lines='none'>
                  <IonLabel className="ion-no-margin" onClick={() => handleApiRequest('non-active')}>Non Active</IonLabel>
                </IonItem>
              </IonContent>
            </IonPopover>
            {/* <IonList mode="md" className="select-list ion-no-padding">
              <IonItem lines="none">
                <IonSelect interface="popover" placeholder="All" onIonChange={(e) => {
                  setCustomerStatus(e.detail.value);
                  handleApiRequest(e.detail.value);
                }}>
                  <IonSelectOption value="all">All</IonSelectOption>
                  <IonSelectOption value="active">Active</IonSelectOption>
                  <IonSelectOption value="non-active">Non Active</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonList> */}
          </div>
          {allCustomers.length > 0 ?
            allCustomers.map((item: any, index: any) => {
              return (
                <div key={index} className="customer-card" onClick={() => { handleCustomerClick(index) }}>
                  <div className="id-content">
                    <IonLabel>Customer id</IonLabel>
                    <p>{item.call_sign}</p>
                  </div>
                  <div className="customer-content">
                    <div className="user-content">
                      <IonAvatar>
                        <img
                          alt="Silhouette of a person's head"
                          src={item.image_url ? item.image_url : require("../assets/images/avatar-person-img.png")}
                        />
                      </IonAvatar>
                      <div>
                        <h4>{item.full_name}</h4>
                        <p>{item.email}</p>
                        {item.is_active !== "0" ?
                          <div className="ion-btn">
                            <IonButton
                              shape="round"
                              size="small"
                              className="active-btn"
                            >
                              Active
                            </IonButton>
                          </div>
                          :
                          <div className="ion-btn">
                            <IonButton
                              shape="round"
                              size="small"
                              className="non-active-btn"
                            >
                              Non Active
                            </IonButton>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
            :
            <h3 className="no-customer">No customers found</h3>
          }
        </div>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(false)}
        color={"danger"}
        message={toastMessage}
        duration={2500}
      />
    </IonPage >
  );
};

export default Manage_Customer;
