import {
  IonContent,
  IonFooter,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import "../assets/scss/Auth.scss";
import { AppHeader } from "../components/AppHeader";
import Forgot_Password_Component from "../components/Forgot_Password_Component";
const Forgot: React.FC = () => {
 
  return (
    <IonPage>
      <IonContent>
        <AppHeader from='forgot-password'/>
        <div className="auth-wrapper">
      <Forgot_Password_Component/>
      </div>
      </IonContent>
{/* 
      <IonFooter className="ion-no-border auth-footer">
        <IonToolbar>
          <IonTitle> <p className="ion-text-center">
            Don't have account?
            <Link to="/signup">
              <span> Sign up</span>
            </Link>
          </p>
          </IonTitle>
        </IonToolbar>
      </IonFooter> */}
 
    </IonPage>
  );
};

export default Forgot;
