import {
  IonCol,
  IonGrid,
  IonRow,
  IonImg,
  IonPage,
  IonContent,
  IonLoading,
  IonButton,
  IonCheckbox,
  IonIcon,
  IonItem,
  IonInput,
  useIonViewWillEnter,
  IonToast,
} from "@ionic/react";
// import "../../assets/scss/Desktop_Package_Detail.scss";
import "../../assets/scss/InputForms.scss";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { validater } from '../../validate';
import Sidebar from "../../components/Sidebar";
import Cookies from "universal-cookie";
import { Preferences } from "@capacitor/preferences";
import { postRequest } from "../../ApiServices";
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';

const Desktop_Contact_Detail: React.FC = () => {
  const history = useHistory();
  const cookies = new Cookies();

  const [isShown, setIsShown] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [senderDetail, setSenderDetail] = useState<any>({
    first_name: "",
    phone_no: "",
    email: ""
  });
  const [setting, setSetting] = useState<any>('');
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [orderAmount, setOrderAmount] = useState<any>(0);

  let total_distance: any = 0;

  useIonViewWillEnter(async () => {
    let order_details;
    const { value } = await Preferences.get({ key: 'dtlc-order-details' });
    if (value) {
      order_details = JSON.parse(value);
      if (order_details.sender_detail) {
        setSenderDetail(order_details.sender_detail);
      }
    }
  });

  const senderHandle = async () => {
    var data = {
      Name: senderDetail.first_name,
      Phone: senderDetail.phone_no,
      Email: senderDetail.email
    };
    var validation = validater(data);
    if (!validation.status) {
      await setAlertMessage('' + validation.message);
      setError(true);
      setShowToast(true);
      return;
    } else {
      setError(false);
      let order_details: any;
      const { value } = await Preferences.get({ key: 'dtlc-order-details' });
      if (value) {
        order_details = JSON.parse(value);
        order_details = setDetails(order_details);
      }

      let order_nature = '';
      if (order_details.pickup_address.length > 1 && order_details.dropoff_address.length > 1) {
        order_nature = 'MULIT_PICK_MULTI_DROP';
      } else if (order_details.pickup_address.length === 1 && order_details.dropoff_address.length === 1) {
        order_nature = 'SINGLE_PICK_SINGLE_DROP';
      } else if (order_details.pickup_address.length > 1 && order_details.dropoff_address.length === 1) {
        order_nature = 'MULTI_PICK_SINGLE_DROP';
      } else if (order_details.pickup_address.length === 1 && order_details.dropoff_address.length > 1) {
        order_nature = 'SINGLE_PICK_MULTI_DROP';
      }

      order_details.order_nature = order_nature;
      order_details.total = "40";
      order_details.subtotal = "40";
      order_details.payment_method = "cash";
      await Preferences.set({
        key: 'dtlc-order-details',
        value: JSON.stringify(order_details),
      });

      // history.push("/order-status");

      // const url = `https://map.googleapis.com/maps/api/distancematrix/json?origins=${order_details.pickup_address[0].latitude},${order_details.pickup_address[0].longitude}&destination=${order_details.pickup_address[1].latitude},${order_details.pickup_address[1].longitude}`;
      // const response = await fetch(url);
      // const jsonData = await response.json();
      // console.log(jsonData);
      setShowLoading(true);
      postRequest('general-setting', {}, '').then(data => {
        setSetting(data.data);
        if (order_details.pickup_address.length === 1 && order_details.dropoff_address.length === 1) {
          handleOneToOneDistance(order_details, data.data);
        } else if (order_details.pickup_address.length === 1 && order_details.dropoff_address.length > 1) {
          handleCalculateDistance2(order_details, 0, 1, data.data);
        } else if (order_details.pickup_address.length > 1 && order_details.dropoff_address.length > 1) {
          handleCalculateDistance(order_details, 0, 1, data.data);
        } else if (order_details.pickup_address.length > 1 && order_details.dropoff_address.length === 1) {
          handleCalculateDistance(order_details, 0, 1, data.data, 'many-to-one');
        }
      }).catch(err => console.log(err));
    }
  }

  const handleOneToOneDistance = (order_details: any, setting: any) => {
    var distanceService: any = new google.maps.DistanceMatrixService();
    distanceService.getDistanceMatrix({
      origins: [order_details.pickup_address[order_details.pickup_address.length - 1].latitude + ',' + order_details.pickup_address[order_details.pickup_address.length - 1].longitude],
      destinations: [order_details.dropoff_address[0].latitude + ',' + order_details.dropoff_address[0].longitude],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      durationInTraffic: true,
      avoidHighways: false,
      avoidTolls: false
    },
      (response: any, status: any) => {
        let distance_text = response.rows[0].elements[0].distance.text;
        let unit = distance_text.substring(distance_text.length - 2, distance_text.length);
        let distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
        if (unit === 'ft') {
          distance_value = distance_value / 5280;
        } else if (unit === 'mi') {
          distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
        }
        total_distance = total_distance + distance_value;

        //determining whether it is inside or outside the working hours
        handleDetermineInOutWorkingHours(order_details, setting);
      });
  }

  const handleCalculateDistance = (order_details: any, num: any = 0, other: any = 1, setting: any, type: any = '') => {
    var distanceService: any = new google.maps.DistanceMatrixService();
    distanceService.getDistanceMatrix({
      origins: [order_details.pickup_address[num].latitude + ',' + order_details.pickup_address[num].longitude],
      destinations: [order_details.pickup_address[other].latitude + ',' + order_details.pickup_address[other].longitude],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      durationInTraffic: true,
      avoidHighways: false,
      avoidTolls: false
    },
      (response: any, status: any) => {
        let distance_text = response.rows[0].elements[0].distance.text;
        let unit = distance_text.substring(distance_text.length - 2, distance_text.length);
        let distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
        if (unit === 'ft') {
          distance_value = distance_value / 5280;
        } else if (unit === 'mi') {
          distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
        }
        total_distance = total_distance + distance_value;

        num = other;
        other = other + 1;
        if (order_details.pickup_address.length > other) {
          if (type === 'many-to-one') {
            handleCalculateDistance(order_details, num, other, setting, 'many-to-one');
          } else {
            handleCalculateDistance(order_details, num, other, setting);
          }
        } else if (type === 'many-to-one') {
          handleOneToOneDistance(order_details, setting);
        } else if (type === '') {
          handleCalculateDistance2(order_details, 0, 1, setting);
        }
      });
  }

  const handleCalculateDistance2 = (order_details: any, num: any = 0, other: any = 1, setting: any) => {
    var distanceService: any = new google.maps.DistanceMatrixService();
    distanceService.getDistanceMatrix({
      origins: [order_details.dropoff_address[num].latitude + ',' + order_details.dropoff_address[num].longitude],
      destinations: [order_details.dropoff_address[other].latitude + ',' + order_details.dropoff_address[other].longitude],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      durationInTraffic: true,
      avoidHighways: false,
      avoidTolls: false
    },
      (response: any, status: any) => {
        let distance_text = response.rows[0].elements[0].distance.text;
        let unit = distance_text.substring(distance_text.length - 2, distance_text.length);
        let distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
        if (unit === 'ft') {
          distance_value = distance_value / 5280;
        } else if (unit === 'mi') {
          distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
        }
        total_distance = total_distance + distance_value;

        num = other;
        other = other + 1;
        if (order_details.dropoff_address.length > other) {
          // console.log(order_details.pickup_address.length, other);
          handleCalculateDistance2(order_details, num, other, setting);
        } else {
          var distanceService: any = new google.maps.DistanceMatrixService();
          distanceService.getDistanceMatrix({
            origins: [order_details.pickup_address[order_details.pickup_address.length - 1].latitude + ',' + order_details.pickup_address[order_details.pickup_address.length - 1].longitude],
            destinations: [order_details.dropoff_address[0].latitude + ',' + order_details.dropoff_address[0].longitude],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.IMPERIAL,
            durationInTraffic: true,
            avoidHighways: false,
            avoidTolls: false
          },
            async (response: any, status: any) => {
              let distance_text = response.rows[0].elements[0].distance.text;
              let unit = distance_text.substring(distance_text.length - 2, distance_text.length);
              let distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
              if (unit === 'ft') {
                distance_value = distance_value / 5280;
              } else if (unit === 'mi') {
                distance_value = parseFloat(response.rows[0].elements[0].distance.text.match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
              }
              total_distance = total_distance + distance_value;

              //determining whether it is inside or outside the working hours
              handleDetermineInOutWorkingHours(order_details, setting);
            });
        }
      });
  }

  const handleDetermineInOutWorkingHours = async (order_details: any, setting: any) => {
    let outOfWorkingHours: boolean = false;
    for await (let pickup_address of order_details.pickup_address) {
      for await (let meetup_point of pickup_address.meetup_points) {
        let working_hours_starting = setting.starts_at;
        let working_hours_ending = setting.ends_at;
        working_hours_starting = working_hours_starting.split(':');
        working_hours_ending = working_hours_ending.split(':');
        let working_hours_starting_hours = parseInt(working_hours_starting[0]);
        let working_hours_starting_minutes = parseInt(working_hours_starting[1]);
        let working_hours_ending_hours = parseInt(working_hours_ending[0]);
        let working_hours_ending_minutes = parseInt(working_hours_ending[1]);
        let meetup_at_24h = convertTime12to24(meetup_point.meetup_at);
        if (!(parseInt(meetup_at_24h.hours) > working_hours_starting_hours) && (parseInt(meetup_at_24h.hours) < working_hours_ending_hours)) {
          outOfWorkingHours = true;
        } else if ((parseInt(meetup_at_24h.hours) === working_hours_starting_hours) && (parseInt(meetup_at_24h.minutes) < working_hours_starting_minutes)) {
          outOfWorkingHours = true;
        } else if ((parseInt(meetup_at_24h.hours) === working_hours_ending_hours) && (parseInt(meetup_at_24h.minutes) > working_hours_ending_minutes)) {
          outOfWorkingHours = true;
        }

        if (outOfWorkingHours) {
          break;
        }
      }
      if (outOfWorkingHours) {
        break;
      }
    }

    let amount = 0;
    if (outOfWorkingHours) {
      amount = (parseFloat(setting.non_paid_base_price) + (total_distance * parseFloat(setting.non_paid_per_mile_price))) + (parseFloat(setting.non_paid_base_price) / 2);
    } else {
      amount = parseFloat(setting.non_paid_base_price) + (total_distance * parseFloat(setting.non_paid_per_mile_price));
    }

    setOrderAmount(amount);
    setShowLoading(false);
    let orderdetails;
    const { value } = await Preferences.get({ key: 'api-data' });
    if (value) {
      orderdetails = JSON.parse(value);
      orderdetails.total = amount;
      orderdetails.subtotal = amount;
      await Preferences.set({ key: 'api-data', value: JSON.stringify(orderdetails) });
    } else {
      const { value } = await Preferences.get({ key: 'dtlc-order-details' });
      if (value) {
        orderdetails = JSON.parse(value);
        orderdetails.total = amount;
        orderdetails.subtotal = amount;
        await Preferences.set({ key: 'dtlc-order-details', value: JSON.stringify(orderdetails) });
      }
    }
  }

  const convertTime12to24 = (time12h: any) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM' || modifier === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }

    return {
      hours: hours,
      minutes: minutes
    };
  }

  const tConvert = (time: any) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  const setDetails = (order_details: any) => {
    order_details.sender_detail = {};
    order_details.sender_detail.first_name = senderDetail.first_name;
    order_details.sender_detail.name = senderDetail.first_name;
    order_details.sender_detail.phone_no = senderDetail.phone_no;
    order_details.sender_detail.email = senderDetail.email;
    return order_details;
  }

  const passState = () => {
    setIsShown(!isShown)
  }

  const handleCreateOrder = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [
        {
          description: 'Courier Service',
          amount: {
            value: Math.ceil(orderAmount),
            currency_code: 'GBP'
          },
        },
      ],
      application_context: {
        shipping_preference: 'NO_SHIPPING'
      }
    }).then((orderID: any) => {
      return orderID;
    });
  }

  const handleOnApprove = (data: any, actions: any) => {
    return actions.order.capture().then((details: any) => {
      const { payer } = details;
      history.push("/order-status");
    })
  }

  const handleOnError = (data: any, actions: any) => {
    console.log('An error occured during payment');
  }

  return (
    <IonPage>
      <IonContent>
        <div className="desktop-input desktop-wrapper">
          <IonGrid>
            <IonRow>
              <Sidebar passState={passState} />
              <IonCol
                size="12"
                sizeLg="9.5"
                className={isShown ? "rightbar rightbar-none" : "rightbar"}
              >
                <div className="inner-rightbar">
                  <div className=" input_rightbar_content">
                    <IonGrid>
                      <IonRow className="input_card">
                        <IonCol size="12" sizeMd="3" className="input-content">
                          <div className="ion-icon">
                            <IonImg
                              src={require("../../assets/images/parcel-package-detail.png")}
                            />
                          </div>
                          <IonIcon />
                        </IonCol>
                        <IonCol size="12" sizeMd="9" className="contact-form">
                          <div className="order_details plr-20">
                            <h3 className="mb_20 page_title scale-up-center">Please fill in your contact details</h3>
                            <div className="mb_10 w_100">
                              <IonItem className="detail_input delivery_input scale-up-center" lines="none" >
                                <IonInput placeholder="Name" type="text"
                                  value={
                                    senderDetail ? senderDetail.first_name : ""
                                  }
                                  onIonChange={(e) => {
                                    let sender_contact_detail = senderDetail;
                                    sender_contact_detail.first_name = e.detail.value;
                                    setSenderDetail(sender_contact_detail);
                                  }} />
                              </IonItem>
                            </div>
                            <div className="mb_10 w_100">
                              <IonItem className="detail_input delivery_input scale-up-center" lines="none">
                                <IonInput placeholder="+44 7911 123456" type="tel"
                                  value={
                                    senderDetail ? senderDetail.phone_no : ""
                                  }
                                  onIonChange={(e) => {
                                    let sender_contact_detail = senderDetail;
                                    sender_contact_detail.phone_no = e.detail.value;
                                    setSenderDetail(sender_contact_detail);
                                  }} />
                              </IonItem>
                            </div>
                            <div className="mb_10 w_100">
                              <IonItem className="detail_input delivery_input scale-up-center" lines="none">
                                <IonInput placeholder="email" type="text"
                                  value={
                                    senderDetail ? senderDetail.email : ""
                                  }
                                  onIonChange={(e) => {
                                    let sender_contact_detail = senderDetail;
                                    sender_contact_detail.email = e.detail.value;
                                    setSenderDetail(sender_contact_detail);
                                  }} />
                              </IonItem>
                            </div>
                            <div className="privacy-checkbox">
                              <IonCheckbox
                                checked={checked}
                                onIonChange={(e) =>
                                  setChecked(e.detail.checked)
                                }
                              />
                              <p className="check-box-disc">
                                By clicking next you agree to our{" "}
                                <span>Terms and Conditions</span> and{" "}
                                <span> Privacy Policy.</span>
                              </p>
                            </div>
                            <PayPalScriptProvider
                              options={{
                                "client-id": 'AaMV3DF2kMLH63KaWZ7gdaqmcRqlecf8Yr6LbUDmjpUe0Gb_JDE3qhDST1oV8TUJtCfMW0QZqCmwr78a',
                                "currency": 'GBP',
                                "intent": 'capture'
                              }}
                            >

                              {orderAmount === 0 ?

                                <IonButton expand="block" className="scale-up-center" disabled={!checked} onClick={senderHandle}>Next</IonButton>

                                :
                                <div style={{ marginTop: '20px' }}>
                                  <p><b>Total: </b>£{orderAmount}</p>
                                  <PayPalButtons style={{ layout: 'vertical' }}
                                    createOrder={handleCreateOrder}
                                    onApprove={handleOnApprove}
                                  />
                                </div>
                              }
                            </PayPalScriptProvider>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(false)}
        color={error ? "danger" : "primary"}
        message={alertMessage}
        duration={1500}
      />
    </IonPage>
  );
};
export default Desktop_Contact_Detail;
