import {
    IonContent,
    IonPage,

} from '@ionic/react';
import { AppHeader } from '../components/AppHeader';
import type { OverlayEventDetail } from '@ionic/core';
import Driver_Documents_component from '../components/Driver_Documents_component';
const Driver_Documents = () => {

    return (
     <IonPage>
       <IonContent>
            <div className="document-wrapper">
                <AppHeader from=''/>
                <Driver_Documents_component/>
            </div>  
        </IonContent>
     </IonPage>
    );
}

export default Driver_Documents;

