import {
  IonCol,
  IonGrid,
  IonRow,
  IonImg,
  IonPage,
  IonContent,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  useIonViewWillEnter,
} from "@ionic/react";
import { locationOutline } from "ionicons/icons";
import "../../assets/scss/Desktop_Size.scss";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Cookies from "universal-cookie";

const Desktop_Size: React.FC = () => {
  const history = useHistory();
  const cookies = new Cookies();
  const smallBox = useRef<any>(null);
  const largeBox = useRef<any>(null);
  const mediumBox = useRef<any>(null);

  const [packageSize, setPackageSize] = useState<any>(null);
  const [isShown, setIsShown] = useState(false);

  const passState = () => {
    setIsShown(!isShown);
  };

  useIonViewWillEnter(() => {
    let order_details;
    if (cookies.get("desktop-dtlc-order-details")) {
      order_details = cookies.get("desktop-dtlc-order-details");
      setPackageSize(order_details.package_size);
      handleClick(order_details.package_size);
    }
  });

  const handleClick = (type: string) => {
    smallBox.current.className =
      "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
    mediumBox.current.className =
      "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
    largeBox.current.className =
      "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
    if (type === "small") {
      smallBox.current.className =
        "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios border_style";
    } else if (type === "medium") {
      mediumBox.current.className =
        "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios border_style";
    } else if (type === "large") {
      largeBox.current.className =
        "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios border_style";
    }
  };
  const sizeClick = (type: string) => {
    handleClick(type);
    let order_details: any = {};
    if (cookies.get("desktop-dtlc-order-details")) {
      order_details = cookies.get("desktop-dtlc-order-details");
      order_details.package_size = type;
      setPackageSize(type);
    } else {
      order_details.package_size = type;
      setPackageSize(type);
    }
    cookies.set("desktop-dtlc-order-details", order_details);
  };

  return (
    <IonPage>
      <IonContent>
        <div className="desktop-wrapper">
          <IonGrid>
            <IonRow>
              <Sidebar passState={passState} />
              <IonCol
                size="12"
                sizeLg="9.5"
                className={isShown ? "rightbar rightbar-none" : "rightbar"}
              >
                <div className="size_rightbar_content">
                  <IonGrid>
                    <p className="package-heading ">
                      {" "}
                      <span> What size</span> is your package?
                    </p>

                    <IonRow className="boxes-content">
                      <IonCol size="12" sizeMd="3" className="size-card-boxes ">
                        <IonButtons
                          ref={smallBox}
                          onClick={() => {
                            sizeClick("small")
                            history.push('/desktop_contact_detail');
                          }}
                        >
                          <div className="Size-card">
                            <IonImg
                              src={require("../../assets/images/small-box.png")}
                            />
                            <h3>Small</h3>
                            <p className="">
                              This package is use for small tablet.
                            </p>
                          </div>
                        </IonButtons>
                      </IonCol>
                      <IonCol size="12" sizeMd="3" className="size-card-boxes">
                        <IonButtons
                          ref={mediumBox}
                          onClick={() => {
                            sizeClick("medium");
                            history.push('/desktop_contact_detail');
                          }}
                        >
                          <div className="Size-card">
                            <IonImg
                              src={require("../../assets/images/medium-box.png")}
                            />
                            <h3>Medium</h3>
                            <p className="">
                              This package is use for medium tablet.
                            </p>
                          </div>
                        </IonButtons>
                      </IonCol>
                      <IonCol size="12" sizeMd="3" className="size-card-boxes">
                        <IonButtons
                          ref={largeBox}
                          onClick={() => {
                            sizeClick("large");
                            history.push('/desktop_contact_detail');
                          }}
                        >
                          <div className="Size-card">
                            <IonImg
                              src={require("../../assets/images/large-box.png")}
                            />
                            <h3>Large</h3>
                            <p className="">
                              This package is use for large tablet.
                            </p>
                          </div>
                        </IonButtons>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Desktop_Size;
