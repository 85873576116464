import { IonAccordion, IonAccordionGroup, IonContent, IonItem, IonLabel, IonPage } from '@ionic/react'
import React, { useRef } from 'react'
import { AppHeader } from '../components/AppHeader'
import '../assets/scss/User_Profile.scss';
const Privacy_Policy: React.FC = () => {

  return (
    <IonPage>
      <IonContent className='overflow'>
        <AppHeader from=''/>
        <div className='wrapper'>
          {/* <h3 className='ion-text-center'>Terms & Conditions</h3> */}
          <h4 className='mtb'>Privacy Policy</h4>
          <p>It is a long established fact that a reader will be
            distracted by the readable content of a page when looking at its layout.
            The point of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using
            'Content here, content here', making it look like readable English.</p>
          <p className='mtb'>
            Many desktop publishing packages and web page editors now use Lorem Ipsum as their
            default model text, and a search for 'lorem ipsum' will uncover many web sites
            still in their infancy.
          </p>
          <p>
            Various versions have evolved over the years, sometimes by accident, 
            sometimes on purpose (injected humour and the like).
          </p>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Privacy_Policy;
