import React, { useState } from 'react';
import { IonPage, IonContent, IonIcon, IonFooter, IonButton, IonToast, IonModal, useIonViewWillEnter, IonLoading } from "@ionic/react"
import { checkmarkCircleOutline } from "ionicons/icons"
import '../assets/scss/Order_Status.scss'
import { useHistory } from 'react-router-dom';
import { postRequest } from '../ApiServices';
import Cookies from "universal-cookie";
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';

const Order_Status: React.FC = () => {
  const history = useHistory();
  const cookies = new Cookies();
  const [showLoading, setShowLoading] = useState<any>(true);
  const [showToast, setShowToast] = useState<any>(false);
  const [alertMessage, setAlertMessage] = useState<any>('');
  const [orderNumber, setOrderNumber] = useState<any>('');

  useIonViewWillEnter(async () => {
    console.log('inside ion view will enter');
    setShowLoading(true);
    let order_details: any = {};

    const { value } = await Preferences.get({ key: 'dtlc-order-details' });
    if (value) {
      order_details = JSON.parse(value);
      if(Object.keys(order_details).length >= 16){
        
        let dtlc: any = '';
        let url = 'order/save';
        if(cookies.get('dtlc')){
          url = 'order/store';
          dtlc = cookies.get('dtlc');
          dtlc = dtlc.token.token;
        }
        await Preferences.remove({key: 'dtlc-order-details'});

        postRequest(url, order_details, dtlc).then(async (res)=>{
          if(res.status){
            setShowLoading(false);
            setOrderNumber(res.order_number);
            cookies.set('dtlc-order-number', res.order_number);
            order_details.order_number = res.order_number;
            await Preferences.set({
              key: 'api-data',
              value: JSON.stringify(order_details),
            });
            await Preferences.set({
              key: 'dtlc-order-details',
              value: JSON.stringify(order_details),
            });
          }
        }).catch((err)=>{
          console.log(err);
        });
      }else{
        setAlertMessage('Order can not be placed at the moment');
        setShowToast(true);
        setShowLoading(false);
        history.push('/view_order');
      }
    }else{
      setShowLoading(false);
      setAlertMessage('Order can not be placed at the moment');
      setShowLoading(false);
      setShowToast(true);
      history.push('/view_order');
    }
  });
  
  const handleOrderAgain = () => {
    if (!Capacitor.isNative) {
      history.push('/login');
    }else{
      history.push('/choices');
    }
  }

  return (
    <IonPage>
        <IonContent>
        {showLoading? 
          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Please wait...'}
          />
        :
          <div className="order-wrapper">
            <div>
              <IonIcon icon={checkmarkCircleOutline} className="ion-text-center"/>
              <h4 className="ion-text-center ion-padding-bottom">Thank you for your order</h4>
              <h5 className="ion-text-center">Track with order no.</h5>
              <p className="ion-text-center">#{orderNumber}</p>
              <IonButton onClick={()=>{
                if(cookies.get('dtlc')){
                  history.push("/view_order");
                }else{
                  history.push("/view_order");
                }
                }}>
                View Order
              </IonButton>
              <h6 className='ion-text-center'><b>or</b></h6>
              <h5 onClick={()=>handleOrderAgain()} className='ion-text-center clr-green'><b>Order Again</b></h5>
            </div>
          </div>
        }
        </IonContent>
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(false)}
        color="danger"
        message={alertMessage}
        duration={1500}
      />
    </IonPage>
  )
}

export default Order_Status
