import { IonContent, IonLoading, IonPage, IonRow, IonToast, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'
import React, { useState, useRef } from 'react';
import "../assets/scss/Driver_Profile_Edit.scss";
import { useHistory } from 'react-router';
import { BookingHeader } from '../components/BookingHeader';
import { postRequest } from '../ApiServices';
import Cookies from 'universal-cookie';
import { GoogleMap } from '@capacitor/google-maps';
import { Preferences } from '@capacitor/preferences';


const Driver_Location_Tracking: React.FC = () => {
    const history = useHistory();
    const cookies = new Cookies();
    let driver_location_interval: any;
    const mapRef = useRef<HTMLElement>();
    let newMap: GoogleMap;

    const [showLoading, setShowLoading] = useState<any>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<any>('');
    const [driverId, setDriverId] = useState<any>('');

    

    useIonViewWillLeave(()=>{
        clearInterval(driver_location_interval);
    });

    useIonViewWillEnter(async ()=>{
        const { value } = await Preferences.get({ key: 'profile-data' });
        if(value){
            let driver_profile_detail = JSON.parse(value);
            setDriverId(driver_profile_detail.id);

            handleApiRequest(driver_profile_detail.id);
            driver_location_interval = setInterval(()=>{
                handleApiRequest(driver_profile_detail.id);
            }, 2000);
        }
    });

    const handleMarkerUpdate = async (coords: any) => {
        // Add a marker to the map
        const cameraId = await newMap.setCamera({
            coordinate: {
                lat :coords.lat, 
                lng: coords.lng
            },
            zoom: 16,
            bearing: 0,
            angle: 0,
            animate: true,
        });
        
        const markerId = await newMap.addMarker({
            coordinate: {
                lat: coords.lat,
                lng: coords.lng
            },
            // iconUrl: require('../assets/images/custom-map-pin.png'),
            // iconSize: {
            //     width: 10,
            //     height: 20
            // },
        });
        await newMap.removeMarker(cookies.get('dtlc-map-marker-id'));
        cookies.set('dtlc-map-marker-id', markerId);
    }

    const createMap = async (coords: any) => {
        if (!mapRef.current) return;
    
        newMap = await GoogleMap.create({
          id: 'my-cool-map',
          element: mapRef.current,
          apiKey: 'AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw',
          config: {
            center: {
              lat: coords.lat,
              lng: coords.lng
            },
            zoom: 16
          }
        });

        // Add a marker to the map
        const markerId = await newMap.addMarker({
            coordinate: {
                lat: coords.lat,
                lng: coords.lng
            },
            // iconUrl: require('../assets/images/custom-map-pin.png'),
            // iconSize: {
            //     width: 10,
            //     height: 20
            // }
        });
        cookies.set('dtlc-map-marker-id', markerId);
    }

    const handleApiRequest = (driver_id: any) => {
        let dtlc = cookies.get('dtlc');
        if(dtlc && dtlc.token){
            postRequest('drivers/get-lat-long', {
                id: driver_id
            }, dtlc.token.token).then(result=> {
                if(result.status){
                    if(!result.data.lat || !result.data.long){
                        createMap({
                            lat: 51.511576061709754,
                            lng: -0.13043658908426428,
                        });
                    }else{
                        if(!newMap){
                            createMap({
                                lat: parseFloat(result.data.lat),
                                lng: parseFloat(result.data.long)
                            });
                            // createMap({
                            //     lat: 51.511576061709754,
                            //     lng: -0.13043658908426428
                            // });
                        }else{
                            handleMarkerUpdate({
                                lat: parseFloat(result.data.lat),
                                lng: parseFloat(result.data.long)
                            });
                        }
                    }
                }
            }).catch(err=>console.log(err));
        }
    }

  return (
    <IonPage>
        <IonContent className='map-tracking-ioncontent'>
        <BookingHeader from=''/>
            <div className='driver-profile-edit-wrapper'>
                <div className='time-wrapper'>
                    <h4>Track Driver's location on map</h4>
                    <IonRow>
                        <capacitor-google-map ref={mapRef} style={{
                            display: 'inline-block',
                            width: window.screen.width - 40,
                            height: window.screen.height - 225
                        }}></capacitor-google-map>
                    </IonRow>
                </div>
            </div>
        </IonContent>
        <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Please wait..."}
        />
        <IonToast
          isOpen={showToast}
          position="bottom"
          onDidDismiss={() => setShowToast(false)}
          color={"primary"}
          message={toastMessage}
          duration={2500}
        />
    </IonPage>
  )
}

export default Driver_Location_Tracking;
