import {
  IonCol,
  IonGrid,
  IonRow,
  IonImg,
  IonPage,
  IonContent,
  IonButton,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import "../../assets/scss/Desktop_Summary_Order.scss";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "../../components/Sidebar";

import { getRequest, postRequest } from "../../ApiServices";
import Cookies from 'universal-cookie';

const Desktop_Summary_Order: React.FC = () => {
  const [isShown, setIsShown] = useState(false);
  
  const passState = () => {
    setIsShown(!isShown);
  };

  let timerr: any;
  let minutes = 1;
  let seconds = 5;
  const history = useHistory();
  const [over, setOver] = useState(false);
  const [time, setTime] = useState<any>({
    minutes: minutes,
    seconds: seconds
  });
  const cookies = new Cookies();

  useIonViewWillLeave(()=>{
    clearInterval(timerr);
  });

  useIonViewWillEnter( async() => {
    clearInterval(timerr);
    
    if(cookies.get("desktop-dtlc-timer-start-time")){
      timerr = setInterval(() => tick(), 1000);
      
      let d:any = new Date();
      let current_time = d.getTime();

      let koib = cookies.get("desktop-dtlc-timer-start-time");
      let timer_start_time:any = new Date(Number(koib));
      timer_start_time = timer_start_time.getTime();
      let time_difference = current_time - timer_start_time;
      let sec = time_difference / 1000;
      let total_allowed_seconds = (minutes * 60) + seconds;

      if(sec > total_allowed_seconds){
        setOver(true);
        console.log("your time is up, you can't replace order now!");
        clearInterval(timerr);
        minutes = 0;
        seconds = 0;
        setTime({
          minutes: minutes,
          seconds: seconds
        });
        // //All Products
        // const products= await getRequest("/products")
        
        // //single products
        const singleProduct = await getRequest("/products/1")

        //add product
        const productProperty={
          title: 'test product',
          price: 13.5,
          description: 'lorem ipsum set',
          image: 'https://i.pravatar.cc',
          category: 'electronic'
        }

        
        const addProduct= await postRequest("/products", productProperty)
        
    
      }else{
        total_allowed_seconds = total_allowed_seconds - sec;
        let mints = total_allowed_seconds / 60;
        let secons = mints - parseInt(mints.toString());
        secons = Math.ceil(secons * 60);
        mints = parseInt(mints.toString());
        minutes = mints;
        seconds = secons;
        setTime({
            minutes: mints,
            seconds: secons
        });
      }
    
    }else{
      let d = new Date();
      cookies.set("desktop-dtlc-timer-start-time", d.getTime());
      timerr = setInterval(() => tick(), 1000);
    }
  });

  const tick = async () => {
    if (minutes === 0 && seconds === 0) {
      setOver(true);
      clearInterval(timerr);
    }
    else if (seconds !== 0) {
      seconds -= 1;
      setTime({
        minutes: minutes,
        seconds: seconds
      });
    } else if (seconds === 0) {
      seconds = 59;
      minutes -= 1;
      setTime({
        minutes: minutes,
        seconds: seconds
      });
    }
  };
  return (
    <IonPage>
      <IonContent>
        <div className="desktop-wrapper">
          <IonGrid className="ion-no-padding">
            <IonRow>
              <Sidebar passState={passState} />
              <IonCol
                size="12"
                sizeLg="9.5"
                className={
                  isShown
                    ? "rightbar ion-padding rightbar-none"
                    : "rightbar ion-padding"
                }
              >
                <div className="inner-rightbar">
                  <div className=" summary-detail-rightbar-content">
                    <IonGrid>
                      <IonRow className="pickup_card">
                        <IonCol size="12" sizeMd="3" className="pickup-content">
                          <div className="ion-icon">
                            <IonImg
                              src={require("../../assets/images/Done.png")}
                            />
                          </div>
                        </IonCol>
                        <IonCol size="12" sizeMd="9" className="summary-order">
                          <div className="summary-order-content">
                                   <div className="timer">
              {over ? <strong>"Time's up!"</strong> : <strong>{time.minutes.toString().padStart(2, "0")}:{time.seconds.toString().padStart(2, "0")}</strong>}
            </div>
                            <h2>Almost done!</h2>
                            <p className="order-disc">
                              Please proceed with the payment and a courier will
                              shortly take care of your order.
                            </p>
                            <div className="information-box">
                              <div className="box1">
                                <h3>up to 7:20 pm</h3>
                                <p>Delivery Time</p>
                              </div>
                              <div className="box2">
                                <h3>£ 12.00</h3>
                                <p>Order Price</p>
                              </div>
                            </div>

                            <div className="pickup-btn">
                              <IonButton onClick={() => history.push('/desktop_order_status')}>Next</IonButton>
                            </div>
                          </div>

                          <div className="clock-img">
                   
                            <IonImg
                              src={require("../../assets/images/appclock.png")}
                            />
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Desktop_Summary_Order;
