import React, { useState } from 'react'
import { IonButton, IonCol, IonContent, IonImg, IonLoading, IonPage, IonRow, IonSearchbar, useIonViewWillEnter, IonToast, useIonViewWillLeave } from '@ionic/react';
import { AppHeader } from '../components/AppHeader';
import "../assets/scss/Manage_Driver.scss";
import { useHistory } from 'react-router';
import Cookies from 'universal-cookie';
import { postRequest } from '../ApiServices';
import { Preferences } from '@capacitor/preferences';

const Manage_Driver: React.FC = () => {
  const history = useHistory();
  const cookies = new Cookies();

  const [showLoading, setShowLoading] = useState<any>(true);
  const [drivers, setDrivers] = useState<any>([]);
  const [driverSearchCallSign, setDriverSearchCallSign] = useState<any>('');
  const [searchedDriver, setSearchedDriver] = useState<any>([]);
  const [showToast, setShowToast] = useState<any>(false);
  const [alertMessage, setAlertMessage] = useState<any>('');

  useIonViewWillEnter(() => {
    setShowLoading(true);
    let dtlc = cookies.get('dtlc');
    if(dtlc && dtlc.token){
      postRequest("drivers/all", {}, dtlc.token.token)
      .then((res: any) => {
        setShowLoading(false);
        if (res.status) {
          setDrivers(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
    }
  });

  const handleSearchDriver = () => {
    if(driverSearchCallSign !== ''){
      let searched_driver = drivers.filter((driver: any) => driver.call_sign === driverSearchCallSign);
      if(searched_driver.length <= 0){
        setAlertMessage('No driver associated with this call sign');
        setShowToast(true);
      }else{
        setSearchedDriver(searched_driver);
      }
    }
  }

  const handleDriverItemClick = async (index: any) => {
    let data: any;
    if(searchedDriver.length > 0){
      data = searchedDriver[index];
    }else if(drivers.length > 0){
      data = drivers[index];
    }

    await Preferences.set({
      key: 'profile-data',
      value: JSON.stringify(data),
    });
    history.push('/driver_profile_edit');
  }

  return (
    <IonPage>
      <IonContent className='linear_bg'>
        <AppHeader from=''/>
        <div className="manage-driver-wrapper">
          <h2>Manage Drivers</h2>
          <div className="position-relative">
        <IonSearchbar
          show-clear-button="never"
          placeholder="Driver Call Sign"
          className="ion-no-padding ion-margin-bottom"
          value={driverSearchCallSign}
          onIonChange={(e)=>{
            if(e.detail.value === ''){
              setSearchedDriver([]);
            }
            setDriverSearchCallSign(e.detail.value)
          }}
        ></IonSearchbar>
        <span className="search-icon" onClick={()=>handleSearchDriver()}></span>
      </div>
          {searchedDriver.length > 0? 
            searchedDriver.map((item: any,index: any)=>{
              return(
                <div className='card' key={index} onClick={() => {
                  handleDriverItemClick(index);
                }}>
                <IonRow className='driver-row'>
                  <IonCol size='3'>
                    <div className='driver-image'>
                      <IonImg src={item.image_url}/>
                    </div>
                  </IonCol>
                  <IonCol size='9'>
                    <div className='driver-disc'>
                       <div>
                       <h4>{item.full_name}</h4>
                        <p>{item.call_sign}</p>
                       </div>
                       <IonButton shape='round'>{item.status}</IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              </div>
              );
            })
            :
            drivers.length > 0?
            drivers.map((item: any,index: any)=>{
              return(
                <div className='card' key={index} onClick={() => {
                  handleDriverItemClick(index);
                }}>
                <IonRow className='driver-row'>
                  <IonCol size='3'>
                    <div className='driver-image'>
                      <IonImg src={item.image_url}/>
                    </div>
                  </IonCol>
                  <IonCol size='9'>
                    <div className='driver-disc'>
                       <div>
                       <h4>{item.full_name}</h4>
                        <p>{item.call_sign}</p>
                       </div>
                       <IonButton shape='round'>{item.status}</IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              </div>
              );
            })
            :
            <h3>No drivers found</h3>
          }
        </div>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(false)}
        color={"danger"}
        message={alertMessage}
        duration={2500}
      />
    </IonPage>
  )
}

export default Manage_Driver
