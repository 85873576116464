import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonTextarea,
  IonImg,
  IonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import { BookingHeader } from "../components/BookingHeader";
import { useEffect, useState } from "react";
import { validater } from "../validate";
import Cookies from "universal-cookie";
import "../assets/scss/Delivery_Details.scss";
import { Preferences } from "@capacitor/preferences";
interface ChildProps {
  passState: any;
  OrderDetails: any;
}

const Pickup_Detailed_Location: React.FC<ChildProps> = (props) => {
  const cookies = new Cookies();

  const [stepChild, setStepChild] = useState("third");
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [orderDetails, setOrderDetails] = useState<any>(props.OrderDetails);

  useEffect(()=>{
    async function initilizeOrder(){
      const { value } = await Preferences.get({ key: 'dtlc-order-details' });
      if (value) {
      // if (cookies.get("dtlc-order-details")) {
        let order_details = JSON.parse(value);
        setOrderDetails(order_details);
      }
    }
    initilizeOrder();
  },[]);

  useIonViewWillEnter(async () => {
    const { value } = await Preferences.get({ key: 'dtlc-order-details' });
    if (value) {
      let order_details = JSON.parse(value);
      setOrderDetails(order_details);
    }
  });

  const handlePickupLocationDetails = async () => {
    let stop = false;
    let order_details = orderDetails;
    for (const index in order_details.pickup_address){
      let result = validater({
        location_name: order_details.pickup_address[index].location_name,
        location_detail: order_details.pickup_address[index].location_detail,
      });
      if(!result.status){
        stop = true;
        setAlertMessage(`Pickup address ${parseInt(index) + 1}:  ${result.message}`);
        setShowToast(true);
        break;
      }
    }
    if(stop){
      return;
    }else{
      setStepChild(stepChild);
      props.passState(stepChild);
    }

    // let found = false;
    // for await (let item of orderDetails.pickup_address) {
    //   if(item.location_name === '' || item.location_detail === ''){
    //     await setAlertMessage('Some field is empty');
    //     setShowToast(true);
    //     found = true;
    //     break;
    //   }
    // }
    // if(!found){
    //   setStepChild(stepChild);
    //   props.passState(stepChild);
    // }
  };

  const handleOrderDetailChange = async (value: any, index: any, type: any) => {
    let order_details = orderDetails;
    if(type === 'location-name'){
      order_details.pickup_address[index].location_name = value;
      order_details.pickup_address[index].place_name = value;
      setOrderDetails(order_details);
    }else if(type === 'location-detail'){
      order_details.pickup_address[index].location_detail = value;
      setOrderDetails(order_details);
    }else if(type === 'location-notes'){
      order_details.pickup_address[index].notes = value;
      setOrderDetails(order_details);
    }
    
    await Preferences.set({
      key: 'dtlc-order-details',
      value: JSON.stringify(order_details),
    });
    // cookies.set("dtlc-order-details", order_details);
  }

  return (
    <>
      <IonContent fullscreen overflow-scroll="true" className="backdrop-effect linear_bg position-relative">
        <BookingHeader from=''/>
        <div>
        <div className="wrapper order_details">
          <h3 className=" mb_20 page_title scale-up-center">
            Complete the pick-up location details
          </h3>

          {orderDetails.pickup_address.map((item: any, index: any)=>{
            return(
              <div key={index} className="address-detail-bx">
                <div className="address-detail-outer">
                  {orderDetails.pickup_address.length > 1? 
                    <span className={'previous_input'}>
                      {index + 1}
                    </span>
                      : 
                    <IonImg className="loc-img" src={require('../assets/images/location.png')} />
                  }
                  <h5 className="mb_0 loc-addr">{item.address}</h5>
                </div>
                <div className="mb_10 w_100">
                  <IonItem
                    className="detail_input delivery_input scale-up-center "
                    lines="none"
                  >
                    <IonInput
                      placeholder="Location name"
                      type="text"
                      value={item.location_name}
                      onIonChange={(e) => handleOrderDetailChange(e.target.value, index, 'location-name')}
                    />
                  </IonItem>
                </div>
                <div className="mb_10 w_100">
                  <IonItem
                    className="detail_input delivery_input scale-up-center "
                    lines="none"
                  >
                    <IonTextarea
                      placeholder="Location details"
                      value={item.location_detail}
                      onIonChange={(e) => handleOrderDetailChange(e.target.value, index, 'location-detail')}
                    />
                  </IonItem>
                </div>
                <div className="mb_10 w_100">
                  <IonItem
                    className="detail_input delivery_input scale-up-center "
                    lines="none"
                  >
                    <IonTextarea
                      placeholder="Do we need to know anything extra?"
                      value={item.notes}
                      onIonChange={(e) => handleOrderDetailChange(e.target.value, index, 'location-notes')}
                    />
                  </IonItem>
                </div>
              </div>
            );
          })}

          <IonButton
            expand="block"
            className="scale-up-center ion-margin-top"
            onClick={() => handlePickupLocationDetails()}
          >
            Next
          </IonButton>
        </div>
        </div>
      </IonContent>
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(false)}
        color={"danger"}
        message={alertMessage}
        duration={1500}
      />
    </>
  );
};

export default Pickup_Detailed_Location;