import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonToast,
} from "@ionic/react";
import { arrowForward, eyeOutline, lockClosedOutline, mailOutline } from "ionicons/icons";
import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { api_url } from "../App";
import axios from "axios";
import "../assets/scss/Auth.scss";
import Cookies from "universal-cookie";
import { postRequest } from "../ApiServices";

const Change_Password_Component: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
 const [showPassword, setShowPassword] = useState<any>({
  current_psw: false,
  new_psw:false,
  confirm_psw: false,
 });
  const [error, setError] = useState<boolean>(false);
  const cookies = new Cookies();
  let token =cookies.get("token");
  const history = useHistory();
  const passwordHandler = (value: any)=>{
    setShowPassword({...showPassword, [value]: !showPassword[value]})
  }
  interface passwordDetail {
     current_password: string;
    new_password: string;
    password_confirmation: string;
  }
  const [passwordDetail, setpasswordDetail] = useState<any>({
    current_password: '',
    new_password: '',
    password_confirmation: '',
  });
  const itemRef = useRef<any>([]);
  const fieldsRef = useRef<any>([]);
  const itemHandler = async (type: string) => {
    for (let item in fieldsRef.current) {
    const {current_password, new_password, password_confirmation}= passwordDetail;
      if (item === 'currentPassword' && current_password === '') {
        fieldsRef.current[item].className = "sc-ion-label-ios-h sc-ion-label-ios-s ios md floating-label";
      }
      else if (item === 'newPassword' && new_password === '') {
        fieldsRef.current[item].className = "sc-ion-label-ios-h sc-ion-label-ios-s ios md floating-label";
      }
      else if (item === 'confirmPassword' && password_confirmation === '') {
        fieldsRef.current[item].className = "sc-ion-label-ios-h sc-ion-label-ios-s ios md floating-label";
      }
    }

    for (let item in itemRef.current) {
      if (item === "currentPassword") {
        itemRef.current[item].className = "";
      }
      else if (item === "newPassword") {
        itemRef.current[item].className = "";
      }
      else if (item === "confirmPassword") {
        itemRef.current[item].className = "";
      }
    }

    if (type === "currentPassword") {
      itemRef.current[type].className = "item-shadow";
      fieldsRef.current[type].className = "sc-ion-label-ios-h sc-ion-label-ios-s ios md top-label";
    }
    else if (type === "newPassword") {
      itemRef.current[type].className = "item-shadow";
      fieldsRef.current[type].className = "sc-ion-label-ios-h sc-ion-label-ios-s ios md top-label";
    }
    else if (type === "confirmPassword") {
      itemRef.current[type].className = "item-shadow";
      fieldsRef.current[type].className = "sc-ion-label-ios-h sc-ion-label-ios-s ios md top-label";
    }
  };
  const changePassword = () => {
    if(passwordDetail.current_password === '' || passwordDetail.current_password === ' ' || passwordDetail.new_password === '' || passwordDetail.new_password === ' ' || passwordDetail.password_confirmation === '' || passwordDetail.password_confirmation === ' '){
      setError(true);
      setAlertMessage('Some field is missing');
      setShowToast1(true);
      return;
    }
    let dtlc;
    if(cookies.get('dtlc')){
      dtlc = cookies.get('dtlc');
      setShowLoading(true);
      // const {current_password, new_password, password_confirmation} = passwordDetail;
      postRequest('update-password', passwordDetail, dtlc.token.token).then(res => {
        if (res.status === true) {
          setError(false);
          setShowLoading(false);
          setAlertMessage(res.message);
          setShowToast1(true);
        } else {
          setError(true);
          setShowLoading(false);
          setAlertMessage(res.message);
          setShowToast1(true);
        }
      }).catch((err)=>{
        console.log(err);
      });
    }
  }
  return (
    <>
       <div className="auth-wrapper-inner">
          <h1>Change Password</h1>
          <p className="subTitle">Change your password.</p>

          <IonItem
            lines="none"
            ref={el => (itemRef.current['currentPassword'] = el)}
            onClick={() => itemHandler("currentPassword")}
          >
            <IonIcon icon={lockClosedOutline} size="small" />
            <div className="position-relative">
              <IonLabel
                ref={el => (fieldsRef.current['currentPassword'] = el)}
                className="floating-label"
              >
                CURRENT PASSWORD
              </IonLabel>
              <IonInput
                value={passwordDetail.current_password}
                type={showPassword.current_psw ? "text" : "password"}
                onIonChange={(e) => {
                  let user_data = passwordDetail;
                  user_data.current_password = e.detail.value;
                  setpasswordDetail(user_data);
                }}
              ></IonInput>
            </div>
            <IonIcon className="eye-icon" icon={eyeOutline} onClick={() => passwordHandler('current_psw')}/>
          </IonItem>
          <IonItem
            lines="none"
            ref={el => (itemRef.current['newPassword'] = el)}
            onClick={() => itemHandler("newPassword")}
          >
            <IonIcon icon={lockClosedOutline} size="small" />
            <div className="position-relative">
              <IonLabel
                ref={el => (fieldsRef.current['newPassword'] = el)}
                className="floating-label"
              >
                NEW PASSWORD
              </IonLabel>
              <IonInput
                value={passwordDetail.new_password}
                type={showPassword.new_psw ? "text" : "password"}
                onIonChange={(e) => {
                  let user_data = passwordDetail;
                  user_data.new_password = e.detail.value;
                  setpasswordDetail(user_data);
                }}
              ></IonInput>
            </div>
            <IonIcon className="eye-icon" icon={eyeOutline} onClick={() => passwordHandler('new_psw')}/>
          </IonItem>
          <IonItem
            lines="none"
            ref={el => (itemRef.current['confirmPassword'] = el)}
            onClick={() => itemHandler("confirmPassword")}
          >
            <IonIcon icon={lockClosedOutline} size="small" />
            <div className="position-relative">
              <IonLabel
                ref={el => (fieldsRef.current['confirmPassword'] = el)}
                className="floating-label"
              >
                CONFIRM PASSWORD
              </IonLabel>
              <IonInput
                value={passwordDetail.password_confirmation}
                type={showPassword.confirm_psw ? "text" : "password"}
                onIonChange={(e) => {
                  let user_data = passwordDetail;
                  user_data.password_confirmation = e.detail.value;
                  setpasswordDetail(user_data);
                }}
              ></IonInput>
            </div>
            <IonIcon className="eye-icon" icon={eyeOutline} onClick={() => passwordHandler('confirm_psw')}/>
          </IonItem>
          <div className="justify-content-end">
            <IonButton shape="round" onClick={changePassword} >
              Change Password
              <IonIcon icon={arrowForward} />
            </IonButton>
          </div>
        </div>
        <IonToast
          isOpen={showToast1}
          position="bottom"
          onDidDismiss={() => setShowToast1(false)}
          color={error ? "danger" : "primary"}
          message={alertMessage}
          duration={2500}
        />
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
    </>
  )
}

export default Change_Password_Component
