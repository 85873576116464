import { IonAlert, IonAvatar, IonButton, IonContent, IonImg, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPage, IonToast, useIonViewWillEnter } from '@ionic/react'
import {AppHeader}  from '../components/AppHeader'
import Cookies from 'universal-cookie';
import { getCookieData } from '../functions';
import {
    Camera,  CameraResultType, CameraSource, CameraDirection
  } from "@capacitor/camera";
import '../assets/scss/Edit_Profile.scss';
import { useEffect, useState } from 'react';
import axios from "axios";
import { postRequest } from '../ApiServices';
const Edit_Profile = () => {
    const [image, setImage] = useState<any>("");
    const [selectedImage, setSelectedImage] = useState<any>();
    const [showLoading, setShowLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [cookieData, setCookieData] = useState<any>();
    const [input, setInput] = useState<any>([]);
    const [alertHeader, setAlertHeader] = useState<any>('');
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');
    const [showAlert1, setShowAlert1] = useState(false);
    const [user, setUser] = useState<any>({});

    const cookies = new Cookies();
    
    let token = cookies.get("token");
    useIonViewWillEnter(async () => {
      let cookie_data = getCookieData('dtlc');
      setCookieData(cookie_data);
      setShowLoading(true);
        await postRequest("account-setting-get", {}, cookie_data.token.token)
        .then(async (data) => {
          if (data.status) {
            setShowLoading(false);
            await setUser(data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      });
    
    const handlePictureChange = async (image:any) => {
        postRequest('update-profile-picture', {
            "image":image
        },
        cookieData.token.token);
    };

      const takePicture = async () => {
        const cameraResult = await Camera.getPhoto({
          quality: 50,
          allowEditing: true,
          resultType: CameraResultType.Base64,
          source: CameraSource.Photos,
        });
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        let base64Image = "data:image/jpeg;base64," + cameraResult.base64String;
        setImage(base64Image);
        handlePictureChange(base64Image);
      };
      const handleInput = (type:string)=>{
        if(type == 'name'){
            let arr:any = [
                {
                    name: 'name',
                    type: 'text',
                    value: user.name
                }
            ];
            setInput(arr);
            setAlertHeader('Edit Name');
            setShowAlert1(true);
            setType(type);
        }else if(type == 'email'){
            let arr:any = [
                {
                    name: 'email',
                    type: 'text',
                    value: user.email
                }
            ];
            setInput(arr);
            setAlertHeader('Edit Email');
            setShowAlert1(true);
            setType(type);
        }else if(type == 'phone'){
            let arr:any = [
                {
                    name: 'phone',
                    type: 'text',
                    value: user.phn_no
                }
            ];
            setInput(arr);
            setAlertHeader('Edit Phone Number');
            setShowAlert1(true);
            setType(type);
        }else if(type == 'address'){
            let arr:any = [
                {
                    name: 'address',
                    type: 'text',
                    placeholder: 'Enter address',
                    value: user.address
                }
            ];
            setInput(arr);
            setAlertHeader('Edit Address');
            setShowAlert1(true);
            setType(type);
        }
        
    }
    const handleInputUpdate = async (alertData:any)=>{
    
        let data = {
            name: user.name,
            email: user.email,
            phn_no: user.phn_no,
        };
        if(type == 'name'){
            await setUser({
                ...user,
                name: alertData.name
            });
            data.name = alertData.name;
        }else if(type == 'email'){
            await setUser({
                ...user,
                email: alertData.email
            });
            data.email = alertData.email;
        }else if(type == 'phone'){
            if(!/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(alertData.phone)){
                setMessage('Invalid phone number');
                setShowToast(true);
                return;
            }else{
                await setUser({
                    ...user,
                    phn_no: alertData.phone
                });
                data.phn_no = alertData.phone
            }
        }

        updateCall(data);
    }

    const updateCall = async (data:any) =>{
        let cookie_data = getCookieData('dtlc');
        setCookieData(cookie_data);
        setShowLoading(true);
        await postRequest("account-setting-update", user, cookie_data.token.token)
        .then(async (data) => {
            if(data.status){
                setShowLoading(false);
                setAlertMessage(data.message);
                setShowToast(true);
            }
        })
        .catch((err) => {
            console.log(err);
          });
    }
  return (
    <IonPage>
        <IonContent>
            <AppHeader from=''/>
            <div className='edit-profile-wrapper'>
                <div  className='profile-image'>
                    <IonAvatar>
                    <IonImg src={
                        image
                        ? image
                        : selectedImage
                            ? selectedImage
                            : require("../assets/images/profile.jpg")
                    } />
                        <div className='edit_pencile' onClick={()=>{takePicture()}}>
                        <IonImg src={require('../assets/images/simple-pencile.png')} />
                        </div>
                    </IonAvatar>
                </div>

                <IonList className='ion-margin-top profile-list'>
                      <IonItem onClick={() => handleInput('name')}>
                            <IonLabel>
                            <h2>Name</h2>
                            </IonLabel>
                            <IonLabel>
                            <p className='ion-text-right'>{user.first_name}</p>
                            </IonLabel>

                      </IonItem>
                      <IonItem disabled={true} onClick={() => handleInput('email')}>
                        <IonLabel>
                            <h2>Email</h2>
                            </IonLabel>
                            <IonLabel>
                            <p className='ion-text-right'>{user.email}</p>
                            </IonLabel>
                      </IonItem>
                      <IonItem onClick={() => handleInput('phone')}>
                            <IonLabel>
                            <h2>Phone No</h2>
                            </IonLabel>
                            <IonLabel>
                            <p className='ion-text-right'>{user.phone_no}</p>
                            </IonLabel>

                      </IonItem>
                      <IonItem onClick={() => handleInput('address')}>
                            <IonLabel>
                            <h2>Address</h2>
                            </IonLabel>
                            <IonLabel>
                            <p className='ion-text-right'> Enter Your Address</p>
                            </IonLabel>

                      </IonItem>
                  </IonList>
            </div>
        </IonContent>
        <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(false)}
        color={error ? "danger" : "primary"}
        message={alertMessage}
        duration={2000}
      />
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
           <IonAlert
                isOpen={showAlert1}
                onDidDismiss={() => setShowAlert1(false)}
                cssClass='input-alert'
                header={alertHeader}
                inputs={input}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {

                        }
                    },
                    {
                        text: 'Ok',
                        handler: (alertData) => {
                            handleInputUpdate(alertData);
                        }
                    }
                ]}
            />
    </IonPage>
  )
}

export default Edit_Profile
