import { IonCol, IonGrid, IonRow, IonImg, IonPage, IonContent, IonButtons, IonButton, IonModal, IonList, IonDatetime, useIonViewDidEnter, IonIcon, IonInput, IonItem, IonLabel, IonPopover, useIonViewWillEnter } from "@ionic/react";
import "../../assets/scss/Desktop_Booking_Type.scss";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { Preferences } from "@capacitor/preferences";
import Cookies from "universal-cookie";
import { validater } from "../../validate";
import { closeCircle } from "ionicons/icons";


const Desktop_Booking_Type: React.FC = () => {
    const [isShown, setIsShown] = useState(false);
    const [bookingDetail, setBookingDetails] = useState<any>(null);
    const [currentDate, setCurrentDate] = useState<any>('2022-01-01T00:00:00');
    const [selectdate, setSelectDate] = useState("");
    const [maxDate, setMaxDate] = useState<any>('2072-01-01T00:00:00');
    const [showLoading, setShowLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const popover = useRef<HTMLIonPopoverElement>(null);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [dimensions, setDimensions] = useState<any>({
        width: "",
        height: "",
        length: "",
        weight: ""
    });
    const [orderDetails, setOrderDetails] = useState<any>('');
    const [popoverClickedItem, setPopoverClickedItem] = useState<any>('');
    const [weekdays, setWeekdays] = useState<any>([]);
    const [monthNames, setMonthNames] = useState<any>(["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]);

    const history = useHistory();
    const smallBox = useRef<any>(null);
    const largeBox = useRef<any>(null);
    const mediumBox = useRef<any>(null);
    const customBox = useRef<any>(null);
    const documentParcel = useRef<any>(null);
    const clothingParcel = useRef<any>(null);
    const flowerParcel = useRef<any>(null);
    const groceryParcel = useRef<any>(null);
    const modal = useRef<HTMLIonModalElement>(null);
    const datesRef = useRef<any>([]);

    const processingForDate = async () => {
        var curr: any = new Date(); // get current date
        var month = curr.getMonth();
        var year = curr.getFullYear()

        const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
            "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
        ];

        let cdate = new Date();
        let fdate = new Date();
        let current_date = cdate.getDate();

        let future_dates: any;
        const { value } = await Preferences.get({ key: 'dtlc-order-details' });
        if (value) {
            let order_details = JSON.parse(value);
            if (order_details.type === 'same day') {
                future_dates = current_date + 0;
            } else {
                future_dates = current_date + 6;
            }
        } else {
            future_dates = current_date + 6;
        }


        let future_date: any = fdate.setDate(future_dates);
        future_date = new Date(future_date);

        const currentWithoutMsandZ = cdate.toISOString().slice(0, 10);
        const futureWithoutMsandZ = future_date.toISOString().slice(0, 10);

        const weekdays: any = [];
        const startDate = currentWithoutMsandZ;
        const endDate = futureWithoutMsandZ;
        const dateMove = new Date(startDate);
        let strDate = startDate;

        while (strDate < endDate) {
            strDate = dateMove.toISOString().slice(0, 10);
            let broken_date = strDate.split('-');
            let year = broken_date[0];
            let month = Number(broken_date[1]) - 1;
            let dates = broken_date[2];
            weekdays.push(`${dates}-${monthNames[month]}-${year}`);
            dateMove.setDate(dateMove.getDate() + 1);
        };

        if (strDate === endDate) {
            strDate = dateMove.toISOString().slice(0, 10);
            let broken_date = strDate.split('-');
            let year = broken_date[0];
            let month = Number(broken_date[1]) - 1;
            let dates = broken_date[2];
            weekdays.push(`${dates}-${monthNames[month]}-${year}`);
            dateMove.setDate(dateMove.getDate() + 1);
        }
        setWeekdays(weekdays);
        dateSelection(startDate, 0, '');
    }

    const bookingData = async () => {
        if (bookingDetail) {
            if (bookingDetail.package_size && bookingDetail.parcel_type && bookingDetail.pickup_at) {
                var todayDate = new Date().toISOString().slice(0, 10);
                let order_details: any = {};
                const { value } = await Preferences.get({ key: 'dtlc-order-details' });
                if (value) {
                    order_details = JSON.parse(value);
                    order_details.booked_at = todayDate;
                    setBookingDetails(order_details);
                } else {
                    order_details.booked_at = todayDate;
                    setBookingDetails(order_details);
                }
                await Preferences.set({
                    key: 'dtlc-order-details',
                    value: JSON.stringify(order_details),
                });
                history.push("/sender-contact-detail");
            } else {
                setError(true);
                setAlertMessage('Please select all booking details');
                setShowToast(true);
            }
        }
        else {
            setError(true);
            setAlertMessage('Please select all booking details');
            setShowToast(true);
        }
    }

    useIonViewDidEnter(async () => {
        processingForDate();

        const { value } = await Preferences.get({ key: 'dtlc-order-details' });
        if (value) {
            let order_details = JSON.parse(value);
            setOrderDetails(order_details);
        }
        let cdate = new Date();
        let fdate = new Date();
        let current_year = cdate.getFullYear();
        let future_year = current_year + 50;
        let future_date: any = fdate.setFullYear(future_year);
        future_date = new Date(future_date);

        const currentWithoutMsandZ = cdate.toISOString().split('.')[0];
        const futureWithoutMsandZ = future_date.toISOString().split('.')[0];
        setCurrentDate(currentWithoutMsandZ);
        setMaxDate(futureWithoutMsandZ);
    })

    const handleClick = (type: string) => {
        if (smallBox.current && mediumBox.current && largeBox.current && customBox.current) {
            smallBox.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios';
            mediumBox.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios';
            largeBox.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios';
            customBox.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios';

            if (type === 'small') {
                smallBox.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios border_style';
            } else if (type === 'medium') {
                mediumBox.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios border_style';
            } else if (type === 'large') {
                largeBox.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios border_style';
            } else if (type === 'customBox') {
                customBox.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios border_style';
            }
            cookieSetting(type);
        }
    };

    const dimensionClick = async (type: any) => {
        let data = {
            width: dimensions.width,
            height: dimensions.height,
            length: dimensions.length,
            weight: dimensions.weight
        };
        var validation = validater(data);
        if (!validation.status) {
            await setAlertMessage(validation.message);
            setError(true);
            setShowToast(true);
            return;
        } else {
            setError(false);
            handleClick(type);
        }
        setPopoverOpen(false);
    }

    const cookieSetting = async (type: any) => {
        let order_details: any = {};

        const { value } = await Preferences.get({ key: 'dtlc-order-details' });
        if (value) {
            order_details = JSON.parse(value);
        } else {
            order_details.package_size = type;
            setBookingDetails(order_details);
        }

        if (type === 'small') {
            order_details.height = 0;
            order_details.width = 0;
            order_details.length = 0;
            order_details.weight = 1;
        } else if (type === 'medium') {
            order_details.height = 0;
            order_details.width = 0;
            order_details.length = 0;
            order_details.weight = 4;
        } else if (type === 'large') {
            order_details.height = 0;
            order_details.width = 0;
            order_details.length = 0;
            order_details.weight = 10;
        } else {
            order_details = setting(order_details);
        }
        order_details.package_size = type;
        setBookingDetails(order_details);

        await Preferences.set({
            key: 'dtlc-order-details',
            value: JSON.stringify(order_details),
        });
        // cookies.set("dtlc-order-details", order_details);
    }

    const setting = (order_details: any) => {
        order_details.width = dimensions.width;
        order_details.height = dimensions.height;
        order_details.length = dimensions.length;
        order_details.weight = dimensions.weight;
        return order_details;
    }

    const dateSelection = async (item: any, index: any, year: any) => {
        setSelectDate(item);
        if (datesRef.current) {
            for await (let item of datesRef.current) {
                if (item) {
                    item.className = 'ios md button button-solid ion-activatable ion-focusable';
                }
            }
        }

        if (datesRef.current[index]) {
            datesRef.current[index].className = 'ios md button button-solid ion-activatable ion-focusable deateBtn';
        }

        let order_details: any = {};
        const { value } = await Preferences.get({ key: 'dtlc-order-details' });
        if (value) {
            order_details = JSON.parse(value);
            order_details.pickup_at = item;
            order_details.pickup_date_index = index;
            setBookingDetails(order_details);
        } else {
            order_details.pickup_at = item;
            order_details.pickup_date_index = index;
            setBookingDetails(order_details);
        }
        await Preferences.set({
            key: 'dtlc-order-details',
            value: JSON.stringify(order_details),
        });
        // cookies.set("dtlc-order-details", order_details);
    }
    const parcelClick = async (type: string) => {
        if (documentParcel.current && clothingParcel.current && flowerParcel.current && groceryParcel.current) {
            documentParcel.current.className = 'sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios sc-ion-buttons-md-h sc-ion-buttons-md-s md';
            clothingParcel.current.className = 'sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios sc-ion-buttons-md-h sc-ion-buttons-md-s md';
            flowerParcel.current.className = 'sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios sc-ion-buttons-md-h sc-ion-buttons-md-s md';
            groceryParcel.current.className = 'sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios sc-ion-buttons-md-h sc-ion-buttons-md-s md';

            if (type === 'document') {
                documentParcel.current.className = 'sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios sc-ion-buttons-md-h sc-ion-buttons-md-s md border_style';
            } else if (type === 'other') {
                flowerParcel.current.className = 'sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios sc-ion-buttons-md-h sc-ion-buttons-md-s md border_style';
            } else if (type === 'medical') {
                groceryParcel.current.className = 'sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios sc-ion-buttons-md-h sc-ion-buttons-md-s md border_style';
            } else if (type === 'cloth') {
                clothingParcel.current.className = 'sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios sc-ion-buttons-md-h sc-ion-buttons-md-s md border_style';
            }

            let order_details: any = {};
            const { value } = await Preferences.get({ key: 'dtlc-order-details' });
            if (value) {
                order_details = JSON.parse(value);
                order_details.parcel_type = type;
                setBookingDetails(order_details);
            } else {
                order_details.parcel_type = type;
                setBookingDetails(order_details);
            }
            await Preferences.set({
                key: 'dtlc-order-details',
                value: JSON.stringify(order_details),
            });
            // cookies.set("dtlc-order-details", order_details);
        }
    };

    const cookies = new Cookies();
    let token = cookies.get("token");

    useIonViewWillEnter(async () => {
        let order_details;
        const { value } = await Preferences.get({ key: 'dtlc-order-details' });
        if (value) {
            order_details = JSON.parse(value);
            let data: any = {
                width: order_details.width ? order_details.width : '',
                height: order_details.height ? order_details.height : '',
                length: order_details.length ? order_details.length : '',
                weight: order_details.weight ? order_details.weight : ''
            }
            setDimensions(data);
            if (order_details.package_size) {
                handleClick(order_details.package_size);
            }
            if (order_details.pickup_at && order_details.pickup_date_index) {
                dateSelection(order_details.pickup_at, order_details.pickup_date_index, '');
            }
            if (order_details.parcel_type) {
                parcelClick(order_details.parcel_type);
            }
        }
    });

    const handleInputClick = (type: any) => {
        setPopoverClickedItem(type);
    }

    const passState = () => {
        setIsShown(!isShown)
    }

    return (
        <IonPage>
            <IonContent>
                <div className="desktop_booking_type">
                    <IonGrid>
                        <IonRow>
                            <Sidebar passState={passState} />
                            <IonCol size="12" sizeLg="9.5" className="desktop_booking-rightbar">
                                <div className="wrapper">
                                    <h3 className="page_title">Calculate & ship</h3>
                                    <p>Get approximate cost for delivery or pick of your parcel</p>
                                    <IonGrid className="select_boxes">
                                        <IonRow>
                                            <IonCol size="4">
                                                <IonButtons ref={smallBox} onClick={() => (handleClick('small'))}>
                                                    <h6>Small</h6>
                                                    <p> less than 1 kg</p>
                                                    <button>
                                                        <IonImg src={require('../../assets/images/small-box.png')} />
                                                    </button>
                                                </IonButtons>
                                            </IonCol>
                                            <IonCol size="4">
                                                <IonButtons ref={mediumBox} onClick={() => handleClick('medium')}>
                                                    <h6>Medium</h6>
                                                    <p>1-4 kg</p>
                                                    <button>
                                                        <IonImg src={require('../../assets/images/medium-box.png')} />
                                                    </button>
                                                </IonButtons>
                                            </IonCol>
                                            <IonCol size="4">
                                                <IonButtons ref={largeBox} onClick={() => handleClick('large')}>
                                                    <h6>Large</h6>
                                                    <p>4-10 kg</p>
                                                    <button>
                                                        <IonImg src={require('../../assets/images/large-box.png')} />
                                                    </button>
                                                </IonButtons>
                                            </IonCol>
                                            <IonCol size="4">
                                                <IonButtons ref={customBox}
                                                    onClick={() => setPopoverOpen(true)}>
                                                    <h6>custom</h6>
                                                    {/* <p>4-10 kg</p> */}
                                                    <button>
                                                        <IonImg src={require('../../assets/images/custom-package.png')} />
                                                    </button>
                                                </IonButtons>
                                                <IonPopover ref={popover} isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
                                                    <IonContent class="ion-padding">
                                                        {/* <h5>Custom</h5> */}
                                                        <div className="justify-content-end">
                                                            <IonIcon onClick={() => setPopoverOpen(false)} className="close-icon" icon={closeCircle} />
                                                        </div>
                                                        <div className="input_popover">
                                                            <IonLabel className={popoverClickedItem === 'width' ? 'ion-label-active' : dimensions.width !== '' ? "ion-label-active" : ''}>width</IonLabel>
                                                            <IonItem lines="none">
                                                                <IonInput value={dimensions.width} type="text"
                                                                    onIonChange={(e) => {
                                                                        let dimension_details = dimensions;
                                                                        dimensions.width = e.detail.value;
                                                                        setDimensions(dimension_details);
                                                                    }}
                                                                    onClick={() => handleInputClick('width')}
                                                                />
                                                                <span className="unit">inches</span>
                                                            </IonItem>
                                                        </div>
                                                        <div className="input_popover">
                                                            <IonLabel className={popoverClickedItem === 'height' ? 'ion-label-active' : dimensions.height !== '' ? "ion-label-active" : ''}>height</IonLabel>
                                                            <IonItem lines="none">
                                                                <IonInput value={dimensions.height} type="text"
                                                                    onIonChange={(e) => {
                                                                        let dimension_details = dimensions;
                                                                        dimensions.height = e.detail.value;
                                                                        setDimensions(dimension_details);
                                                                    }}
                                                                    onClick={() => handleInputClick('height')}
                                                                />
                                                                <span className="unit">inches</span>
                                                            </IonItem>
                                                        </div>
                                                        <div className="input_popover">
                                                            <IonLabel className={popoverClickedItem === 'length' ? 'ion-label-active' : dimensions.length !== '' ? "ion-label-active" : ''}>length</IonLabel>
                                                            <IonItem lines="none">
                                                                <IonInput value={dimensions.length} type="text"
                                                                    onIonChange={(e) => {
                                                                        let dimension_details = dimensions;
                                                                        dimensions.length = e.detail.value;
                                                                        setDimensions(dimension_details);
                                                                    }}
                                                                    onClick={() => handleInputClick('length')}
                                                                />
                                                                <span className="unit">inches</span>
                                                            </IonItem>
                                                        </div>
                                                        <div className="input_popover">
                                                            <IonLabel className={popoverClickedItem === 'weight' ? 'ion-label-active' : dimensions.weight !== '' ? "ion-label-active" : ''}>weight</IonLabel>
                                                            <IonItem lines="none" className="popitm">
                                                                <IonInput value={dimensions.weight} type="text"
                                                                    onIonChange={(e) => {
                                                                        let dimension_details = dimensions;
                                                                        dimensions.weight = e.detail.value;
                                                                        setDimensions(dimension_details);
                                                                    }}
                                                                    onClick={() => handleInputClick('weight')}
                                                                />
                                                                <span className="unit">kg</span>
                                                            </IonItem>
                                                        </div>
                                                        <div className="justify-content-end">
                                                            <IonButton
                                                                onClick={() => dimensionClick('customBox')} shape="round">
                                                                Okay
                                                            </IonButton>
                                                        </div>
                                                    </IonContent>
                                                </IonPopover>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                    <div className="booking_date">
                                        <h4>What is the booking date?</h4>
                                        <IonGrid className="booking_day">
                                            <IonRow className="booking_delivery">
                                                {weekdays.map((item: any, index: any) => {
                                                    let date = item;
                                                    let broken_date = date.split('-');
                                                    let year = broken_date[2];
                                                    date = broken_date[0];
                                                    let month = broken_date[1];
                                                    var monthIndex = (monthNames.indexOf(month) + 1).toString().padStart(2, "0");

                                                    return (
                                                        <IonCol size="3" key={index}>
                                                            <IonButton ref={el => (datesRef.current[index] = el)} onClick={() => dateSelection(year + '-' + monthIndex + '-' + date, index, year)}>{date} {month}</IonButton>
                                                        </IonCol>
                                                    );
                                                })
                                                }
                                                {/* <IonCol size="4">
                                            <IonButton className="fz" id="open-custom-dialog" expand="block" >
                                                Select date
                                            </IonButton>
                                            </IonCol> */}
                                            </IonRow>
                                        </IonGrid>
                                        <IonModal id="example-modal" ref={modal} trigger="open-custom-dialog">
                                            <div className="modal_wrapper">

                                                <IonList lines="none">
                                                    <IonDatetime
                                                        min={currentDate}
                                                        max={maxDate}
                                                        presentation="date"
                                                        onIonChange={(e: any) => setSelectDate(e.detail.value!)}
                                                    />
                                                    {/* <IonItem button={true} detail={false} onClick={dismiss}>
                                            <IonIcon icon={personCircle}></IonIcon>
                                            <IonLabel>Item 1</IonLabel>
                                        </IonItem>
                                        <IonItem button={true} detail={false} onClick={dismiss}>
                                            <IonIcon icon={personCircle}></IonIcon>
                                            <IonLabel>Item 2</IonLabel>
                                        </IonItem>
                                        <IonItem button={true} detail={false} onClick={dismiss}>
                                            <IonIcon icon={personCircle}></IonIcon>
                                            <IonLabel>Item 3</IonLabel>
                                        </IonItem> */}
                                                </IonList>
                                            </div>
                                        </IonModal>
                                        {/* {isActive ? (
                                        <div>
                                            <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            minDate={new Date()}
                                            inline
                                            />
                                            <IonIcon icon={closeOutline} onClick={closeCalander} />
                                        </div>
                                        ) : (
                                        ""
                                        )} */}
                                    </div>
                                    <div>
                                        <h4>Select parcel type</h4>
                                        <IonGrid className="selecting_parcel">
                                            <IonRow>
                                                <IonCol size="3">
                                                    <IonButtons ref={documentParcel} onClick={() => parcelClick('document')}>
                                                        <h6>Document</h6>
                                                        <button>
                                                            <IonImg src={require("../../assets/images/documents.jpg")} />
                                                            {/* <IonIcon icon={documentsOutline} /> */}
                                                        </button>
                                                    </IonButtons>
                                                </IonCol>
                                                <IonCol size="3">
                                                    <IonButtons ref={clothingParcel} onClick={() => parcelClick('cloth')} >
                                                        <h6>Clothing</h6>
                                                        <button>
                                                            <IonImg src={require("../../assets/images/clothing.png")} />
                                                            {/* <IonIcon icon={shirtOutline} /> */}
                                                        </button>
                                                    </IonButtons>
                                                </IonCol>
                                                <IonCol size="3">
                                                    <IonButtons ref={groceryParcel} onClick={() => parcelClick('medical')}>
                                                        <h6>Medical</h6>
                                                        <button>
                                                            <IonImg src={require("../../assets/images/stethoscope.png")} />
                                                            {/* <IonIcon icon={documentsOutline} /> */}
                                                        </button>
                                                    </IonButtons>
                                                </IonCol>
                                                <IonCol size="3">
                                                    <IonButtons ref={flowerParcel} onClick={() => parcelClick('other')}>
                                                        <h6>Others</h6>
                                                        <button>
                                                            <IonImg src={require("../../assets/images/other.png")} />
                                                            {/* <IonIcon icon={flowerOutline} /> */}
                                                        </button>
                                                    </IonButtons>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </div>
                                    <IonButton className="next_btn" onClick={() => { bookingData() }}>Next</IonButton>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Desktop_Booking_Type;
