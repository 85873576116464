import { Preferences } from "@capacitor/preferences";
import { IonAvatar, IonButton, IonLoading, IonContent, IonImg, IonInput, IonItem, IonLabel, IonList, IonPage, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { useState } from "react";
import "../assets/scss/Customer_Profile_View.scss";
import { AppHeader } from "../components/AppHeader";
import Orders_List from "../components/Orders_List";
import Cookies from 'universal-cookie';
import { postRequest } from "../ApiServices";

const Customer_Profile_View: React.FC = () => {
  const cookies = new Cookies();

  const [customerInfo, setCustomerInfo] = useState<any>({});
  const [userInfo, setUserInfo] = useState(true);
  const [showLoading, setShowLoading] = useState<any>(true);
  const [customerOrders, setCustomerOrders] = useState<any>([]);

  useIonViewWillEnter(async ()=>{
    const { value } = await Preferences.get({ key: 'profile-data' });
    let customer_data:any;
    if(value){
      customer_data = JSON.parse(value);
      setCustomerInfo(JSON.parse(value));
    }
    let dtlc = cookies.get('dtlc');
    if(dtlc && dtlc.token){
      postRequest("dispatcher/customer-order-show", {
        id: customer_data.id
      }, dtlc.token.token)
      .then((res: any) => {
        setShowLoading(false);
        if (res.status) {
          setCustomerOrders(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
    }
  });

  useIonViewWillLeave(()=>{
    setCustomerOrders([]);
  });

  return (
    <IonPage>
        <IonContent className="linear_bg">
            <AppHeader from=""/>
            <div className='customer_profile_wrapper'>
          <div className='avatar-wrapper'>
            <IonAvatar>
              {/* <IonImg src={require('../assets/images/profile.jpg')} /> */}
              <IonImg src={customerInfo && customerInfo.image_url? customerInfo.image_url : require("../assets/images/profile.jpg")} />
            </IonAvatar>
          </div>
        
            <div className="timeline-tabs">
              <div className="justify-content-around">
              <div className="ion-padding" onClick={()=>{setUserInfo(true)}}>User Info</div>
              <div className="ion-padding" onClick={()=>{setUserInfo(false)}}>Orders</div>
              </div> 
            </div>
       
          <div className={userInfo ? "info-wrapper": "timeline-wrapper"}>         
          </div>
              {
              userInfo?
              <>
                <div className="inner-wrapper">
                <IonList>
                  <IonItem>
                      <IonLabel>
                          First Name
                      </IonLabel>
                      <h6>{customerInfo && customerInfo.first_name? customerInfo.first_name : ''}</h6>
                  </IonItem>
                  <IonItem>
                      <IonLabel>
                          Last Name
                      </IonLabel>
                      <h6>{customerInfo && customerInfo.last_name? customerInfo.last_name : ''}</h6>
                  </IonItem>
                  <IonItem>
                      <IonLabel>
                          Email
                      </IonLabel>
                      <h6>{customerInfo && customerInfo.email? customerInfo.email : ''}</h6>
                  </IonItem>
                  <IonItem>
                      <IonLabel>
                          Phone No.
                      </IonLabel>
                      <h6>{customerInfo && customerInfo.phone_no? customerInfo.phone_no : ''}</h6>
                  </IonItem>
                  <IonItem>
                      <IonLabel>
                          Call Sign
                      </IonLabel>
                      <h6>{customerInfo && customerInfo.call_sign? customerInfo.call_sign : ''}</h6>
                  </IonItem>
                  {customerInfo && customerInfo.company?
                    <IonItem>
                        <IonLabel>
                            Company
                        </IonLabel>
                        <h6>{customerInfo && customerInfo.company? customerInfo.company.name : ''}</h6>
                    </IonItem>
                    :
                    ''
                  }
                </IonList>
                </div>
              </>
              :
              <>
              <div className="ion-margin-top inner-wrapper">
                <Orders_List Orders={customerOrders}/>
                </div>
              </>
            }

        </div>
        </IonContent>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
    </IonPage>
  )
}

export default Customer_Profile_View
