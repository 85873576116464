import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react'
import Customer_Sidebar from '../../components/Customer_Sidebar'
import Jobs_History_Component from '../../components/Jobs_History_Component'

const Desktop_Jobs_History: React.FC = () => {
  return (
    <IonPage>
        <IonContent>
            <IonRow>
                <Customer_Sidebar/>
                <IonCol size="12" sizeLg="9.5" className="ion-padding">
                    <Jobs_History_Component/>
                </IonCol>
            </IonRow>
        </IonContent>
    </IonPage>
  )
}

export default Desktop_Jobs_History
