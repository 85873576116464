import { IonButton, IonContent, IonIcon, IonImg, IonPage } from '@ionic/react'
import { AppHeader } from '../components/AppHeader'
import "../assets/scss/Order_Overview.scss";
import Order_Overview_Buttons from '../components/Order_Overview_Buttons';
import Order_Overview_Cards from '../components/Order_Overview_Cards';
const Order_Overview: React.FC = () => {
  return (
    <IonPage>
      <IonContent className='linear_bg'>
        <AppHeader from=''/>
        <div className='order-overview-wrapper'>
            <h2>Order Details</h2>
            <Order_Overview_Buttons/>
            <Order_Overview_Cards/>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Order_Overview
