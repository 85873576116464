import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonTextarea,
  IonToast
} from "@ionic/react";
import {
  notificationsOutline,
  arrowBackOutline,
  personOutline,
  callOutline,
  mailOutline,
  arrowForwardOutline,
} from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router";
import { postRequest } from "../ApiServices";
import { validater } from "../validate";
import Cookies from 'universal-cookie';
import "../../src/assets/scss/Customer_Care.scss";

const Customer_Care: React.FC = () => {
  const cookies = new Cookies();
  const history = useHistory();

  const [contactForm, setContactForm] = useState<any>({
    name: '',
    email: '',
    message: ''
  });
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<any>('');
  const [error, setError] = useState<boolean>(false);

  const handleSubmitQuery = () => {
    let result = validater(contactForm);
    if(result.status){
      setError(false);
      let dtlc = cookies.get('dtlc');
      if(dtlc && dtlc.token){
        postRequest('contact-us',contactForm, dtlc.token.token).then((res: any)=>{
          if(res.status){
            setToastMessage(res.message);
            setShowToast(true);
            setContactForm({
              name: '',
              email: '',
              message: ''
            });
          }
        }).catch((err: any)=>{
          console.log(err);
        });
      }
    }else{
      setError(true);
      setToastMessage(result.message);
      setShowToast(true);
    }
  }

  return (
    <IonPage>
      <IonContent>
        <div className="customer-care-wrapper">
          <div className="customer-care-content">
            <div className="back-icon-content">
              <IonIcon
                className="notifications-icon-btn"
                icon={arrowBackOutline}
                onClick={() => {
                  history.goBack();
                }}
              />
              <IonLabel>Customer Care</IonLabel>
            </div>
            <IonImg
              src={require("../assets/images/customer-care-persons.png")}
            />

            <IonGrid>
              <IonRow>
                <IonCol size="6">
                  <div className="contact-boxes">
                    <IonIcon
                      className="notifications-icon-btn"
                      icon={callOutline}
                    />
                    <h6>Call Us</h6>
                    <p>+44 234 8763 44</p>
                  </div>
                </IonCol>

                <IonCol size="6">
                  <div className="contact-boxes">
                    <IonIcon
                      className="notifications-icon-btn"
                      icon={mailOutline}
                    />
                    <h6>Email</h6>
                    <p>infodtlc@gmail.com</p>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>

          <div className="contact-form">
            <h3>Quick Contact</h3>

            <div className="input-contact">
              <IonItem className="street-input-address-form" lines="none">
                <div className="input-form">
                  <IonLabel>Name<span>*</span></IonLabel>
                  <IonInput
                    placeholder="Name"
                    type="text"
                    className="input-field"
                    value={contactForm.name}
                    onIonChange={(event)=>{
                      let form = contactForm;
                      form.name = event.detail.value;
                      setContactForm(form);
                    }}
                  />
                </div>
              </IonItem>
              <IonItem className="street-input-address-form" lines="none">
                <div className="input-form">
                  <IonLabel>Email<span>*</span></IonLabel>
                  <IonInput
                    placeholder="infodtlc@gmail.com"
                    type="text"
                    className="input-field"
                    value={contactForm.email}
                    onIonChange={(event)=>{
                      let form = contactForm;
                      form.email = event.detail.value;
                      setContactForm(form);
                    }}
                  />
                </div>
              </IonItem>

              <IonItem className="street-input-address-form" lines="none">
                <div className="input-form">
                  <IonLabel>Messaage<span>*</span></IonLabel>
                  <IonTextarea
                    className="text-area"
                    placeholder="Enter your message"
                    value={contactForm.message}
                    onIonChange={(event)=>{
                      let form = contactForm;
                      form.message = event.detail.value;
                      setContactForm(form);
                    }}
                  />
                </div>
              </IonItem>

              <div className="form-btn">
                <button onClick={()=>handleSubmitQuery()}>
                  <p>Send</p>
                  <IonIcon
                    className="notifications-icon-btn"
                    icon={arrowForwardOutline}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(false)}
        color={error ? "danger" : "primary"}
        message={toastMessage}
        duration={2500}
      />
    </IonPage>
  );
};

export default Customer_Care;
