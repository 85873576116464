import { IonAvatar, IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonLoading, IonPage, IonToast, useIonViewWillEnter } from '@ionic/react'
import React, { useState } from 'react'
import { handLeft, settings, receipt, notificationsOutline, informationCircle,chatbubbles, lockClosedSharp } from "ionicons/icons";
import { AppHeader } from '../components/AppHeader'
import { api_url } from "../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import '../assets/scss/User_Profile.scss';
import { useHistory } from 'react-router';
import { postRequest } from '../ApiServices';
import { getCookieData } from '../functions';
const User_Profile: React.FC = () => {
  const history= useHistory();
  const [userData, setUserData] = useState({
    first_name:"",
    phone_no:""
  });
  const [profile, setProfile] = useState<any>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [cookieData, setCookieData] = useState<any>();
  const cookies = new Cookies();
  let token = cookies.get("token");
  useIonViewWillEnter(async () => {
    // let cookie_data = getCookieData('dtlc');
    // setCookieData(cookie_data);
    // setShowLoading(true);
    // return new Promise(async (resolve,reject) => {
    //   await postRequest("account-setting-get", {}, cookie_data.token.token)
    //   .then(async (data) => {
    //     if (data.status) {
    //       setShowLoading(false);
    //       await setProfile(data.data);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // });
  });
  
  return (
    <IonPage>
      <IonContent>
        <AppHeader from=''/>
        {/* <IonIcon className="notifications_icon" icon={notificationsOutline} /> */}
        <div className='wrapper'>
          {/* <div className='imge_profile'>
            <IonAvatar>
              <IonImg src={require('../assets/images/profile.jpg')} />
              <div className='edit_pencile' onClick={()=>{
                history.push("edit-profile")
              }}>
                <IonImg src={require('../assets/images/simple-pencile.png')} />
              </div>
            </IonAvatar>
            <h3 className='ion-text-center'>{userData.first_name}</h3>
            <p>{userData.phone_no}</p>
          </div> */}
        
          <IonList className='profile_list'>
            <IonItem onClick={()=> history.push("/user_information")}>
              <IonIcon icon={settings} />
              <IonLabel>Account Settings</IonLabel>
            </IonItem>
            <IonItem onClick={()=> history.push("/change_password")}>
              <IonIcon icon={lockClosedSharp} />
              <IonLabel>Change Password</IonLabel>
            </IonItem>
            <IonItem onClick={()=> history.push("/about_app")}>
              <IonIcon icon={informationCircle} />
              <IonLabel>About App</IonLabel>
            </IonItem>
            <IonItem onClick={()=> history.push("/privacy_policy")}>
              <IonIcon icon={receipt} />
              <IonLabel>PrivacyPolicy</IonLabel>
            </IonItem>
            <IonItem onClick={()=> history.push("/terms_conditions")}>
              <IonIcon icon={handLeft} />
              <IonLabel>Terms And Conditions</IonLabel>
            </IonItem>
            <IonItem lines='none' onClick={()=> history.push("/helps_faqs")}>
              <IonIcon icon={chatbubbles} />
              <IonLabel>Help & FAQs</IonLabel>
            </IonItem>
            {/* <IonItem lines='none' className='logout_item'>
              <IonIcon icon={logOut} />
              <IonLabel>Logout</IonLabel>
            </IonItem> */}
          </IonList>
        </div>
        <IonToast
          isOpen={showToast1}
          position="bottom"
          onDidDismiss={() => setShowToast1(false)}
          color={error ? "danger" : "primary"}
          message={alertMessage}
          duration={2500}
        />
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
      </IonContent>
    </IonPage>
  )
}

export default User_Profile;
