import { IonAvatar, IonContent, IonIcon, IonImg, IonLabel, IonLoading, IonPage, IonSearchbar, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'
import React, { useState } from 'react'
import Cookies from 'universal-cookie'
import { postRequest } from '../ApiServices'
import { AppHeader } from '../components/AppHeader'
import Orders_List from '../components/Orders_List'
import "../assets/scss/Driver_Rejection.scss";
import { useHistory } from 'react-router'
import { qrCodeOutline } from 'ionicons/icons'
import { Preferences } from '@capacitor/preferences'

const Order_Transfers = () => {
  const cookies = new Cookies();
  const history = useHistory();
  const [showLoading, setShowLoading] = useState<any>(true);
  const [transferOrders, setTransferOrders] = useState([]);

  useIonViewWillEnter(()=>{
    setShowLoading(true);
    let dtlc = cookies.get('dtlc');
    if(dtlc && dtlc.token){
      postRequest("jobs/get-transferred-jobs-to-other-driver", {
      }, dtlc.token.token)
      .then((res: any) => {
        setShowLoading(false);
        if (res.status) {   
          setTransferOrders(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
    }
  });

  useIonViewWillLeave(()=>{
    setTransferOrders([]);
    // setShowLoading(true);
  });

  const handleOrderDeclineReasonClick = async (index: any) => {
    await Preferences.set({
      key: 'transferred-by-driver-order',
      value: JSON.stringify(transferOrders[index]),
    });
    history.push("/order_transfer_reason");
  }

  return (
    <IonPage>
        <IonContent className='linear_bg'>
            <AppHeader from='mobile-order-booking'/>
            <div className='driver-rejection-wrapper'>
                <h2 className='ion-text-center'>Order Transfers</h2>
                <div className="position-relative">
                  <IonSearchbar show-clear-button="never" placeholder='Order Number #' className="ion-no-padding"
                        value=""></IonSearchbar>                
                        <span className="search-icon"></span>
                        <IonIcon class="scan-icon" icon={qrCodeOutline}/>   
                </div>
                {
                  transferOrders.map((item: any, index: any)=>{
                    return(
                        <div className='card' key={index} onClick ={()=>handleOrderDeclineReasonClick(index)}>
                            {/* <IonAvatar>
                                <IonImg src={item.driver.image_url}/>
                            </IonAvatar> */}  
                                {
                                    item.type==='urgent'
                                    ?                       
                                        <IonImg className="delivery-type-img" src={require('../assets/images/running.png')}/>
                                    :
                                    item.type==='same day'?                                    
                                        <IonImg className="delivery-type-img" src={require('../assets/images/stopwatch.png')}/>
                                    :
                                    item.type==='schedule'
                                    ?                                      
                                        <IonImg className="delivery-type-img" src={require('../assets/images/calendar.png')}/>
                                    :
                                        ''
                                }
                            <div>
                                <div className='d-flex'><IonLabel>Order #:</IonLabel><h6>{item.order_number}</h6></div>
                                <div className='d-flex'><IonLabel>Order Type:</IonLabel><h6>{item.type}</h6></div>
                                <div className='d-flex'><IonLabel>Reason:</IonLabel><h6>{item.order_transfers[0].transferred_reason}</h6></div>
                            </div>
                        </div>
                    )
                  })
                }
            </div>
        </IonContent>
        <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Please wait..."}
        />
    </IonPage>
  )
}

export default Order_Transfers
