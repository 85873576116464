import { IonCol,IonRow, IonContent, IonPage, IonToast, IonIcon, IonImg, useIonViewWillEnter, IonBadge, RefresherEventDetail, IonRefresher, IonRefresherContent, useIonViewWillLeave, IonSpinner, IonGrid } from '@ionic/react'
import "../assets/scss/dispatch.scss";
import { documentTextOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import Menu from '../components/Menu';
import Cookies from 'universal-cookie';
import { postRequest } from '../ApiServices';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';
import { useEffect, useState } from 'react';
import { Preferences } from '@capacitor/preferences';
import { prepareOrderStructure } from "../functions";
import { Capacitor, Plugins } from '@capacitor/core';

const Dispatch: React.FC = () => {
    const history = useHistory();
    const cookies = new Cookies();

    const [counts, setCounts] = useState<any>({
        completed_order_count: 0,
        delivered_order_count: 0,
        new_order_count: 0,
        rejected_order_count: 0,
        requested_order_count: 0,
        un_read_contact_us_count: 0,
        transferred_order_count: 0
    });
    const [showToast, setShowToast] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<any>('');
    const [showSpinner, setShowSpinner] = useState<boolean>(true);

    useEffect(()=>{
    if (Capacitor.isNative) {
        Plugins.App.addListener("backButton", (e: any) => {
        // e.stopPropagation();

        if (window.location.pathname === "/dispatch_panel") {
            // Show A Confirm Box For User to exit app or not
            let ans = window.confirm("Confirm exit App?");
            if (ans) {
            Plugins.App.exitApp();
            }
        }
        });
    }
    }, []);

    useIonViewWillEnter(() => {
        setShowSpinner(true);
        let dtlc = cookies.get('dtlc');
        if(dtlc){
            if(dtlc.user_data.role_id === 2){
                history.replace('/customer_panel');
            }else if(dtlc.user_data.role_id === 3){
                history.replace('/driver_panel');
            }
            postRequest('dispatcher/get-counts', {}, dtlc.token.token).then((data)=>{
                setCounts(data.data);
                setShowSpinner(false);
            }).catch((err)=>{
                console.log(err);
            }); 
        }else{
            history.replace('/login');
        }     
    });

    useIonViewWillLeave(()=>{
        setCounts({
            completed_order_count: 0,
            delivered_order_count: 0,
            new_order_count: 0,
            rejected_order_count: 0,
            requested_order_count: 0,
            un_read_contact_us_count: 0,
            transferred_order_count: 0
        });
    });

    function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
        let dtlc = cookies.get('dtlc');
        if(dtlc){
            if(dtlc.user_data.role_id === 2){
                history.replace('/customer_panel');
            }else if(dtlc.user_data.role_id === 3){
                history.replace('/driver_panel');
            }
            postRequest('dispatcher/get-counts', {}, dtlc.token.token).then((data)=>{
                if(data.status){
                    setCounts(data.data);
                    // Any calls to load data go here
                    event.detail.complete();
                }
                
            }).catch((err)=>{
                console.log(err);
            }); 
        }else{
            history.replace('/login');
        }
      }

    const openScanner = async () => {
        await AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.CAMERA).then(async (result)=>{
            console.log('Has permission?',result.hasPermission);
            if(!result.hasPermission){
                AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.CAMERA);
            }else{
                const data = await BarcodeScanner.scan({
                    showFlipCameraButton : true, // iOS and Android
                    showTorchButton : true,
                    prompt : "Place a barcode or qr code inside the scan area",
                    resultDisplayDuration: 0
                });
                console.log(`Barcode data: ${data.text}`);
                let order_number: any = data.text;
                order_number = order_number.split('=');
                order_number = order_number[1];
                handleApiRequest(order_number);
            }
        }, (err)=> {
            console.log(err);
        });
    };

    const handleApiRequest = (order_number: any) => {
        let dtlc = cookies.get('dtlc');
        postRequest('order/show', {
          order_number: order_number
        },dtlc.token.token).then(async (data)=>{
          if(data.data){
            prepareOrderStructure(data.data).then(async (result: any)=>{
              await Preferences.set({
                key: 'api-data',
                value: JSON.stringify(result),
              });
              // cookies.set('dtlc-order-details', data.data);
              history.push('/view_order');
            }).catch((err: any)=>{
              console.log(err);
            });
          }else{
            setAlertMessage('Order not found!');
            setShowToast(true);
          }
        }).catch((err)=>{
          console.log(err);
        });
    }


  return (
    <IonPage>
      <IonContent className="linear_bg">
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
      <div className="dispatch-wrapper overflow-auto h-100">
      <Menu/>
        {showSpinner? 
            <div className='spinner-wrapper'>
                <IonSpinner name="circular"></IonSpinner>
            </div>
            :
            ''
        }
        
        <h2>Dispatch <br></br> Management</h2>
        <IonGrid>
        <IonRow>
            <IonCol size='6'>
                <div className='card' onClick={() => {
                    history.push('/new_orders');
                }}>
                {counts.new_order_count > 0? <IonBadge>{counts.new_order_count}</IonBadge> : ''}
                <IonImg src={require("../assets/images/calculator.png")}/>
                <h6>New Orders</h6>
                </div>
            </IonCol>
            <IonCol size='6'>
                <div className='card' onClick={() => {
                    history.push('/order_requests');
                }}>
                {counts.requested_order_count > 0? <IonBadge>{counts.requested_order_count}</IonBadge> : ''}
                <IonImg src={require("../assets/images/hotel-bell.png")}/>
                <h6>Order Request</h6>
                </div>
            </IonCol>
            <IonCol size='6'>
                <div className='card' onClick={() => {
                  history.push('/driver_rejection');
                }}>
                  {counts.rejected_order_count > 0? <IonBadge>{counts.rejected_order_count}</IonBadge>  : ''}
                 <IonImg src={require("../assets/images/reject.png")}/>
                <h6>Driver Rejection</h6>
                </div>
            </IonCol>
            <IonCol size='6'>
                <div className='card' onClick={() => {
                  history.push('/order_transfers');
                }}>
                  {counts.transferred_order_count > 0? <IonBadge>{counts.transferred_order_count}</IonBadge>  : ''}
                 <IonImg src={require("../assets/images/moving.png")}/>
                <h6>Order Transfers</h6>
                </div>
            </IonCol>
            <IonCol size='6'>
                <div className='card' onClick={() => {
                    history.push('/delivered_orders');
                }}>
                {/* {counts.completed_order_count > 0? <IonBadge>{counts.completed_order_count}</IonBadge> : ''} */}
                <IonImg src={require("../assets/images/delivered.png")}/>
                <h6>Completed Orders</h6>
                </div>
            </IonCol>
            <IonCol size='6'>
                <div className='card' onClick={() => {
                    history.push('/all_orders');
                }}>
                 <IonImg src={require("../assets/images/test.png")}/>
                <h6>All Orders</h6>
                </div>
            </IonCol>
            <IonCol size='6'>
                <div className='card' onClick={() => {
                    history.push('/manage_driver');
                }}>
                <IonImg src={require("../assets/images/courier.png")}/>
                <h6>Manage Drivers</h6>
                </div>
            </IonCol>
            <IonCol size='6'>
                <div className='card'  onClick={() => {
                    history.push('/manage_customer');
                }}>
                <IonImg src={require("../assets/images/people.png")}/>
                <h6>Manage Customers</h6>
                </div>
            </IonCol>
            <IonCol size='6'>
                <div onClick={()=>{
                    openScanner();
                }} className='card'>
                <IonImg src={require("../assets/images/barcode.png")}/>
                <h6>Scan</h6>
                </div>
            </IonCol>
            <IonCol size='6'>
                <div className='card' onClick={() => {
                    history.push('/customer_queries');
                }}>
                {counts.un_read_contact_us_count > 0? <IonBadge>{counts.un_read_contact_us_count}</IonBadge> : ''}
                <IonImg src={require("../assets/images/chat.png")}/>
                <h6>Customer Queries</h6>
                </div>
            </IonCol>
        </IonRow>
        </IonGrid>
      </div>
      </IonContent>
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(false)}
        color={"danger"}
        message={alertMessage}
        duration={2500}
      />
    </IonPage>
  )
}

export default Dispatch
