import {
    IonAvatar,
    IonContent,
    IonImg,
    IonPage,
} from "@ionic/react";
import "../assets/scss/Jobs_History.scss";

const Jobs_History_Component: React.FC = () => {
  return (
    <div className="job-history">
    <div className="driver-job-content">
        <div className="driver-job-header">
            <IonAvatar className="avatar-img">
                <IonImg src={require('../assets/images/avatar-person-img.png')} />
            </IonAvatar>
            <div className="profile-content">
                <h6>User Name</h6>
                <p>i am driver</p>
            </div>
        </div>
        <div className="job-history-content">

            {/* <div className="driver-performance1">
                <h3>Mr.Alan</h3>
                <div className="order-detail">
                    <h5>Order complete</h5>
                    <p>270+</p>
                </div>
                <div className="order-detail">
                    <h5>Order incomplete</h5>
                    <p>23+</p>
                </div>
                <div className="order-detail">
                    <h5>Order Pending</h5>
                    <p>70+</p>
                </div>



            </div> */}
            <div className="job-timing">
                <h4>Job Timing</h4>
                <p>8Am To 5Pm</p>
            </div>

            <div className="vehicle-type">
                <h4>Vehicle</h4>
                <p>bike</p>
            </div>

            <h4>Driver Performance</h4>

            <div className="driver-performance">
                <h5>Order Complete</h5>
                {/* <IonButton>270+</IonButton> */}
                <div className="order-btn">
                    <p>270+</p>
                </div>
            </div>

            <div className="driver-performance">
                <h5>Order Pending</h5>
                <div className="order-btn">
                    <p>70+</p>
                </div>
            </div>
            <div className="driver-performance">
                <h5>Order Incomplete</h5>
                <div className="order-btn">
                    <p>20+</p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Jobs_History_Component
