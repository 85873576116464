export const validater = (data) => {
    for (var i in data) {
        let field_name = i;
        if (i === 'firstName') {
            field_name = 'First Name';
        } else if (i === 'first_name') {
            field_name = 'First name';
        } else if (i === 'last_name') {
            field_name = 'Last name';
        } else if (i === 'phone_no') {
            field_name = 'Phone number';
        } else if (i === 'phone_code') {
            field_name = 'Phone code';
        } else if (i === 'location_name') {
            field_name = 'Location name';
        } else if (i === 'location_detail') {
            field_name = 'Location detail';
        } else if (i === 'notes') {
            field_name = 'Notes';
        } else if (i === 'pickup_address') {
            field_name = 'Pickup address';
        } else if (i === 'dropoff_address') {
            field_name = 'Dropoff address';
        } else if( i === 'pickup_address_district') {
            field_name = 'Pickup adress district';
        } else if(i === 'pickup_address_postcode'){
            field_name = 'Pickup address postcode';
        } else if(i === 'dropoff_address_district') {
            field_name = 'Dropoff address district';
        }else if(i === 'dropoff_address_postcode') {
            field_name = 'Dropoff address postcode';
        }else if(i === 'gender') {
            field_name = 'Gender';
        }else if(i === 'address') {
            field_name = 'Address';
        }else if(i === 'reason'){
            field_name = 'Reason';
        }else if(i === 'location_name'){
            field_name = 'Location name';
        }else if(i === 'location_detail'){
            field_name = 'Location detail';
        }

        if (data[i] === '' || data[i] === ' ' || !data[i]) {
            return {
                status: false,
                message: `${field_name} is required`
            }
        } else if (i === 'location_name') {
            if (!/^[ A-Za-z0-9.,"'-/#@%&/]+$/.test(data[i])) {
                return {
                    status: false,
                    message: `${field_name} not valid`
                }
            }
        } else if (i === 'location_detail') {
            if (!/^[ A-Za-z0-9.,"'-/#@%&/]+$/.test(data[i])) {
                return {
                    status: false,
                    message: `${field_name} not valid`
                }
            }
        } else if (i === 'notes') {
            if (!/^[ A-Za-z0-9.,"'-/#@%&/]+$/.test(data[i])) {
                return {
                    status: false,
                    message: `${field_name} not valid`
                }
            }
        } else if (i === 'Name' || i === 'firstName' || i === 'name' || i === 'first_name') {
            if (!/^[ A-Za-z]+$/.test(data[i])) {
                return {
                    status: false,
                    message: `${field_name} not valid`
                }
            }
        } else if (i === 'last_name') {
            if (!/^[ A-Za-z]+$/.test(data[i])) {
                return {
                    status: false,
                    message: `${field_name} not valid`
                }
            }
        } else if (i === 'phone_code') {
            if (!/^[+44]+$/.test(data[i])) {
                return {
                    status: false,
                    message: `${field_name} not valid, only UK phone numbers allowed`
                }
            }
        } else if (i === 'Phone' || i === 'phone' || i === 'phone_no') {
            if (!/(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(data[i])) {
                return {
                    status: false,
                    message: `${field_name} not valid`
                }
            }
        } else if (i === 'Email' || i === 'email') {
            if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(data[i])) {
                return {
                    status: false,
                    message: `${field_name} not valid`
                }
            }
        } else if (i === 'width') {
            if (!/^[0-9]+$/.test(data[i])) {
                return {
                    status: false,
                    message: `${field_name} not valid`
                }
            }
        } else if (i === 'height') {
            if (!/^[0-9]+$/.test(data[i])) {
                return {
                    status: false,
                    message: `${field_name} not valid`
                }
            }
        } else if (i === 'length') {
            if (!/^[0-9]+$/.test(data[i])) {
                return {
                    status: false,
                    message: `${field_name} not valid`
                }
            }
        } else if (i === 'weight') {
            if (!/^[0-9]+$/.test(data[i])) {
                return {
                    status: false,
                    message: `${field_name} not valid`
                }
            }
        } else if(data[i] && Object.keys(data[i]).length === 0 && Object.getPrototypeOf(data[i]) === Object.prototype) {
            return {
                status: false,
                message: `${field_name} is required`
            }
        } else if(i === 'gender') {
            if (!/^[ A-Za-z]+$/.test(data[i])) {
                return {
                    status: false,
                    message: `${field_name} not valid`
                }
            }
        } else if(i === 'address') {
            if (data[i] === '') {
                return {
                    status: false,
                    message: `${field_name} is required`
                }
            }
        }
    }
    return {
        status: true,
        message: ''
    };
};
