import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react'
import { useHistory } from 'react-router'
import '../../src/assets/scss/Navbar.scss'

const Navbar: React.FC = () => {
    const history = useHistory();
  return (
    <div className='navbar'>

            <IonGrid>
                <IonRow className='justify-content-center'>
                    <IonCol sizeSm="6" sizeLg="5">
                    <IonImg src={require("../assets/images/dtlc-cropped-white.png")} />
                    </IonCol>
                    <IonCol sizeSm="6" sizeLg="5">
                        <ul>
                            <li onClick={(()=>history.replace("/home"))}>
                                Book Parcel
                            </li>
                        </ul>
                    </IonCol>
                </IonRow>
            </IonGrid>
       
    </div>
  )
}

export default Navbar
