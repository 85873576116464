import {IonContent, IonPage, useIonViewWillEnter} from '@ionic/react'
import Customer_Panel_Component from '../components/Customer_Panel_Component';
import Menu from '../components/Menu';
import Cookies from 'universal-cookie';
import { useHistory } from "react-router";
import { useEffect } from 'react';
import { Plugins, Capacitor } from "@capacitor/core";
import { Preferences } from '@capacitor/preferences';

const Customer_Panel: React.FC = () => {
  const cookies = new Cookies();
  const history = useHistory();

  useEffect(()=>{
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e: any) => {
        // e.stopPropagation();

        if (window.location.pathname === "/customer_panel") {
          // Show A Confirm Box For User to exit app or not
          let ans = window.confirm("Confirm exit App?");
          if (ans) {
            Plugins.App.exitApp();
          }
        }
      });
    }
  }, []);

  useIonViewWillEnter(async ()=>{
    const { value } = await Preferences.get({ key: 'api-data' });
    if(value){
      await Preferences.remove({ key: 'api-data'});
    }

    const order = await Preferences.get({ key: 'dtlc-order-details' });
    if(order.value){
      await Preferences.remove({ key: 'dtlc-order-details'});
    }

    let dtlc = cookies.get('dtlc');
    if(dtlc){
      if(dtlc.user_data.role_id === 5){
        history.replace('/dispatch_panel');
      }else if(dtlc.user_data.role_id === 3){
        history.replace('/driver_panel');
      }
    }else{
      history.replace('/login');
    }
  });

  return (
    <IonPage>
        <IonContent className='customer_bg'>
            <div className="driver-panel">
                <Menu/>
                <Customer_Panel_Component/>
            </div>
        </IonContent>
    </IonPage>
  )
}

export default Customer_Panel