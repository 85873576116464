import {
  IonBadge, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInput,
  IonItem, IonLabel, IonList, IonPage, IonRow, IonSelect, IonSelectOption,
} from "@ionic/react";
import {locationOutline, calendarOutline, timeOutline, personOutline, callOutline, chevronDownOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import "../assets/scss/Book_Now.scss";
import { AppHeader } from "../components/AppHeader";

const Book_Now: React.FC = () => {
  const history = useHistory();
  return (
    <IonPage>
      <IonContent fullscreen className="linear_bg">
      <AppHeader from='book now'/>
        <div className="wrapper">
          <h3 className="page_title">Book order</h3>
          {/* <div className="local_services">
            <IonButton className="booking_btn">
              <div className="btn_content">
                <IonIcon icon={timeOutline} />
                <IonLabel>Delivery now</IonLabel>
              </div>
            </IonButton>
            <IonButton className="booking_btn">
              <div className="btn_content">
                <IonIcon icon={calendarOutline} />
                <IonLabel>Schedule pickup</IonLabel>
              </div>
            </IonButton>
          </div> */}
          <IonList className="order_list">
            <div className="list_label">
              <IonBadge>1</IonBadge>
              <h4>Pickup address</h4>
            </div>
            <div className="list_inputs">
              <IonItem>
                <IonInput placeholder="Address" type="text"></IonInput>
                <IonIcon icon={locationOutline} />
              </IonItem>
              <IonItem>
                <IonInput placeholder="Sender name" type="text"></IonInput>
                <IonIcon icon={personOutline} />
              </IonItem>
              <IonItem>
                <IonInput placeholder="Contact number" type="tel"></IonInput>
                <IonIcon icon={callOutline} />
              </IonItem>
              <IonItem>
                <IonInput placeholder="others" type="text"></IonInput>
              </IonItem>
            </div>

          </IonList>
          <IonList className="order_list2">
            <div className="list_label">
              <IonBadge>2</IonBadge>
              <h4>Delivery address</h4>
            </div>
            <div className="list_inputs">
              <IonItem>
                <IonInput placeholder="Address" type="text"></IonInput>
                <IonIcon icon={locationOutline} />
              </IonItem>
              <IonItem>
                <IonInput placeholder="Receiver name" type="text"></IonInput>
                <IonIcon icon={personOutline} />
              </IonItem>
              <IonItem>
                <IonInput placeholder="Contact number" type="tel"></IonInput>
                <IonIcon icon={callOutline} />
              </IonItem>
              <IonItem>
                <IonInput placeholder="others" type="text"></IonInput>
              </IonItem>
            </div>
          </IonList>
          {/* <div className="select_parcel">
          <h4>Select parcel type</h4>
          <IonItem className="parcel_item">
        <IonSelect placeholder="What are you sending?">
          <IonSelectOption value="Document">Document</IonSelectOption>
          <IonSelectOption value="Clothing">Clothing</IonSelectOption>
          <IonSelectOption value="Groceiers">Groceiers</IonSelectOption>
          <IonSelectOption value="Flowers">Flowers</IonSelectOption>
        </IonSelect>
        <IonIcon  className="chevronDownOutline" icon={chevronDownOutline}/>
      </IonItem>
          </div> */}
          {/* <IonGrid className="parcel_weight">
            <h4>Select parcel weight</h4>
            <IonRow>
              <IonCol size="4">
                <IonButton>Up to 1 kg</IonButton>
              </IonCol>
              <IonCol size="4">
              <IonButton>Up to 5 kg</IonButton>
              </IonCol>
              <IonCol size="4">
              <IonButton>Up to 10 kg</IonButton>
              </IonCol>
              <IonCol size="4">
              <IonButton>Up to 15 kg</IonButton>
              </IonCol>
              <IonCol size="4">
              <IonButton>Up to 20 kg</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid> */}
          <div className="net_amount">
            <h4>Total amount</h4>
            <IonLabel color={"primary"}>85.00 Rs</IonLabel>
          </div>
          <IonButton className="next_btn" onClick={()=>{history.push("/booking_confirmation")}}>Next</IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Book_Now;
