import {
    IonAvatar,
    IonBadge,
    IonButtons,
    IonCol,
    IonGrid,
    IonImg,
    IonLoading,
    IonRefresher,
    IonRefresherContent,
    IonModal,
    IonRow,
    IonToast,
    useIonViewWillEnter,
    IonSpinner,
} from "@ionic/react";
import { useRef, useState } from "react";
import { useHistory } from "react-router";
import "../assets/scss/Driver_Panel.scss";
import { getCookieData, prepareOrderStructure } from "../functions";
import Menu from "./Menu";
import { device } from "../Layout";
import Cookies from 'universal-cookie';
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions";
import { BarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner";
import { postRequest } from "../ApiServices";
import { Preferences } from "@capacitor/preferences";
import { RefresherEventDetail } from '@ionic/core';

const Driver_Panel_Component: React.FC = () => {
    // const modal = useRef<HTMLIonModalElement>(null);

    const history = useHistory();
    const cookies = new Cookies();

    const [userData, setUserData] = useState<any>(null);
    const [alertMessage, setAlertMessage] = useState<any>('');
    const [showToast, setShowToast] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [counts, setCounts] = useState<any>({
        inprogress_job_count: 0,
        pending_job_count: 0
    });
    const [showSpinner, setShowSpinner] = useState<boolean>(true);

    useIonViewWillEnter(() => {
        setShowSpinner(true);
        let cookie_data = getCookieData('dtlc');
        setUserData(cookie_data.user_data);
        getCounts();
    });

    const getCounts = () => {
        let dtlc = cookies.get('dtlc');
        if (dtlc && dtlc.token) {
            postRequest('drivers/job-count', {
            }, dtlc.token.token).then(async (data) => {
                setCounts(data.data);
                setShowSpinner(false);
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    const refreshCounts = async (event: CustomEvent<RefresherEventDetail>) => {
        let dtlc = cookies.get('dtlc');
        if (dtlc && dtlc.token) {
            postRequest('drivers/job-count', {
            }, dtlc.token.token).then(async (data) => {
                setCounts(data.data);
                event.detail.complete();
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    const openScanner = async () => {
        await AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.CAMERA).then(async (result) => {
            console.log('Has permission?', result.hasPermission);
            if (!result.hasPermission) {
                AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.CAMERA);
            } else {
                const data = await BarcodeScanner.scan({
                    showFlipCameraButton: true, // iOS and Android
                    showTorchButton: true,
                    prompt: "Place a barcode or qr code inside the scan area",
                    resultDisplayDuration: 0
                });
                console.log(`Barcode data: ${data.text}`);
                let order_number: any = data.text;
                order_number = order_number.split('=');
                order_number = order_number[1];
                handleApiRequest(order_number);
            }
        }, (err) => {
            console.log(err);
        });
    };

    const handleApiRequest = (order_number: any) => {
        let dtlc = cookies.get('dtlc');
        if (dtlc && dtlc.token) {
            setShowLoading(true);
            postRequest('order/show', {
                order_number: order_number
            }, dtlc.token.token).then(async (data) => {
                if (data.data) {
                    setShowLoading(false);
                    prepareOrderStructure(data.data).then(async (result: any) => {
                        await Preferences.set({
                            key: 'api-data',
                            value: JSON.stringify(result),
                        });
                        // cookies.set('dtlc-order-details', data.data);
                        history.push('/meetup_scan');
                    }).catch((err: any) => {
                        console.log(err);
                    });
                } else {
                    setAlertMessage('Order not found!');
                    setShowToast(true);
                    setShowLoading(false);
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    // const dismiss = () => {
    //     modal.current?.dismiss();
    // }

    return (
        <div className="driver-panel">
            <IonRefresher slot="fixed" onIonRefresh={refreshCounts}>
                <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            <Menu />

            {showSpinner ?
                <div className='spinner-wrapper'>
                    <IonSpinner name="circular"></IonSpinner>
                </div>
                :
                ''
            }
            <div className="driver-profile ">
                <IonAvatar className="avatar-img">
                    <IonImg src={userData !== null ? userData.image_url : require('../assets/images/dummy-img.jpg')} />
                </IonAvatar>
                <div className="profile-content">
                    <h6>Hello, {userData?.first_name}</h6>
                    <p>{userData?.email}</p>
                </div>
            </div>

            <div className="panel-content">
                <h3>Dashboard</h3>
                <div className="Driver-link">
                    <IonGrid >
                        <IonRow>
                            <IonCol size="6">
                                <div className="space-box">
                                    <div className="category_boxes">
                                        {counts.inprogress_job_count > 0 ?
                                            <IonBadge>{counts.inprogress_job_count}</IonBadge>
                                            :
                                            ''
                                        }
                                        <IonButtons onClick={() => { history.push("/inprocess_orders") }}>
                                            <IonImg src={require('../assets/images/fast-delivery.png')} />
                                            <p>InProcess Orders</p>
                                        </IonButtons>
                                    </div>
                                </div>
                            </IonCol>
                            <IonCol size="6">
                                <div className="space-box">
                                    <div className="category_boxes">
                                        {counts.pending_job_count > 0 ?
                                            <IonBadge>{counts.pending_job_count}</IonBadge>
                                            :
                                            ''
                                        }
                                        <IonButtons onClick={() => { history.push("/driver_job") }}>
                                            <IonImg src={require('../assets/images/jobs.png')} />
                                            <p>Pending Jobs</p>
                                        </IonButtons>
                                    </div>
                                </div>
                            </IonCol>
                            <IonCol size="6">
                                <div className="space-box">
                                    <div className="category_boxes">
                                        <IonButtons onClick={() => { history.push("/jobs_history") }}>
                                            <IonImg src={require('../assets/images/jobs-histroy.png')} />
                                            <p>Jobs History</p>
                                        </IonButtons>
                                    </div>
                                </div>
                            </IonCol>

                            <IonCol size="6">
                                <div className="space-box">
                                    <div className="category_boxes">
                                        <IonButtons onClick={() => { history.push("/driver_documents") }}>
                                            <IonImg src={require('../assets/images/document.png')} />
                                            <p>Documents</p>
                                        </IonButtons>
                                    </div>
                                </div>
                            </IonCol>
                            <IonCol size="6">
                                <div className="space-box">
                                    <div className="category_boxes">
                                        <IonButtons onClick={() => { history.push("/driver_setting") }}>
                                            <IonImg src={require('../assets/images/settings.png')} />
                                            <p>Settings</p>
                                        </IonButtons>
                                    </div>
                                </div>
                            </IonCol>
                            <IonCol size="6">
                                <div className="space-box">
                                    <div className="category_boxes">
                                        <IonButtons onClick={() => { openScanner() }}>
                                            <IonImg src={require('../assets/images/barcode.png')} />
                                            <p>Scan</p>
                                        </IonButtons>
                                    </div>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </div>
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={"Please wait..."}
            />
            <IonToast
                isOpen={showToast}
                position="bottom"
                onDidDismiss={() => setShowToast(false)}
                color={"danger"}
                message={alertMessage}
                duration={2500}
            />
        </div>
    );
};

export default Driver_Panel_Component;