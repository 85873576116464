import React from 'react'
import { Link } from 'react-router-dom';
import { IonContent, IonImg, IonPage } from '@ionic/react'

import "../../assets/scss/NotFound.scss";
const NotFount: React.FC = () => {
  return (
    <IonPage>
       <IonContent>
        <div className='error-wrapper'>
            <div>
                <IonImg src={require("../../assets/images/search.png")} />
                <h1 className=''>404</h1>
                <p className='text'>oops! nothing was found</p>
                <p>
                  This page you are looking for might have been had its named changed or is temporarily unavailable<Link to="/home"> Return to Home Page</Link>
                </p>
            </div>
        </div>
       </IonContent>
    </IonPage>
  )
}

export default NotFount
