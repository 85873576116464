import {
  IonPage,
  IonContent,
  IonIcon,
  IonLoading,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { checkmarkOutline, closeOutline } from "ionicons/icons";
import { useState, useRef } from "react";
import "../assets/scss/View_Order.scss";
import BookingConfirm from "../components/BookingConfirm";
import Cookies from "universal-cookie";
import { postRequest } from "../ApiServices";
import { BookingHeader } from "../components/BookingHeader";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Preferences } from "@capacitor/preferences";
import Order_Receiving from "../components/Order_Receiving";
import Order_Overview_Buttons from "../components/Order_Overview_Buttons";

const View_Order = () => {
  const { state } = useLocation();
  const cookies = new Cookies();
  const history: any = useHistory();

  const childRef = useRef<any>();

  const [myTimeline, setmyTimeline] = useState(false);
  const [orderNumber, setOrderNumber] = useState<any>("");
  const [orderDetails, setOrderDetails] = useState<any>({});
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [viewOrder, setViewOrder] = useState<boolean>(true);
  const [orderAddressId, setOrderAddressId] = useState<any>('');

  useIonViewWillEnter(async () => {
    let order_details;
    const { value } = await Preferences.get({ key: "api-data" });
    if (value) {
      order_details = JSON.parse(value);
      let order_number;
      order_number = order_details.order_number;
      setOrderNumber(order_number);
      setOrderDetails(order_details);
    }else{
      const { value } = await Preferences.get({ key: "dtlc-order-details" });
      if (value) {
        order_details = JSON.parse(value);
        let order_number;
        order_number = order_details.order_number;
        setOrderNumber(order_number);
        setOrderDetails(order_details);
      }
    }
  });

  const proofView = (args: any) => {
    setOrderAddressId(args);
    setViewOrder(!viewOrder);
    if(args === 'back'){
      handleViewOrderInfo();
    }
  };

  const handleViewOrderInfo = () => {
    setmyTimeline(false);
    setTimeout(() => {
      childRef.current.getAlert();
    }, 50);
  };

  return (
    <IonPage>
      <IonContent className="linear_bg">
        {viewOrder ? (
          <>
            <BookingHeader from="mobile-order-booking" />
            <div className="view-order-wrapper">
              {orderDetails.is_delayed?
                <>
                  <h4 style={{color: 'red'}} className="page_title ion-no-margin">DELAYED:</h4>
                  <p style={{textAlign: 'center'}}>{orderDetails.delayed_reason}</p>
                </>
                :
                ''
              }
              <h3 className="page_title ion-padding-bottom ion-no-margin">
                order details
              </h3>
              <div className="wrapper-inner">
                <Order_Overview_Buttons />
                <div className="justify-content-between ion-padding">
                  <h6>Tracking No.</h6>
                  <h6>#{orderNumber}</h6>
                </div>
                <div className="timeline-tabs">
                  <div className="justify-content-around">
                    <div
                      className="ion-padding"
                      onClick={() => handleViewOrderInfo()}
                    >
                      Order Info
                    </div>
                    <div
                      className="ion-padding"
                      onClick={() => setmyTimeline(true)}
                    >
                      Timeline
                    </div>
                  </div>
                </div>
                <div
                  className={myTimeline ? "timeline-wrapper" : "info-wrapper"}
                >
                  {
                    myTimeline ? (
                      <div className="planning-timeline">
                        <ul className="timeline-list">
                          {orderDetails.order_statuses && orderDetails.order_statuses.length > 0? 
                            orderDetails.order_statuses.map((item: any, index: any) => {
                              return (
                                <li key={index}>
                                  <IonIcon icon={item.status === 'CANCELLED'? closeOutline : checkmarkOutline} />
                                  <div
                                    className={
                                      orderDetails.order_statuses.length > 0 && index !== (orderDetails.order_statuses.length - 1)? "timeline-detail active"
                                        : 
                                      "timeline-detail pending"
                                    }
                                  >
                                    <h6>{item.status}</h6>
                                    {/* <p>9 Aug, 3:00 pm</p>
                                    <p>Faisalabad</p> */}
                                  </div>
                                </li>
                              );
                            })
                            :
                            <li>
                              <IonIcon icon={checkmarkOutline} />
                              <div className="timeline-detail pending">
                                <h6>Pending</h6>
                                {/* <p>9 Aug, 3:00 pm</p>
                                <p>Faisalabad</p> */}
                              </div>
                            </li>
                          }

                          {/* <li>
                            <div className="timeline-detail pending">
                              <h6>Package Delivered</h6>
                              <p>9 Aug, 3:00 pm</p>
                              <p>Faisalabad</p>
                            </div>
                          </li> */}
                        </ul>
                      </div>
                    ) : (
                      <BookingConfirm proofView={proofView} ref={childRef} />
                    )
                    // Object.keys(orderDetails).length > 0?
                    //   <BookingConfirm orderDetails = {orderDetails} proofView={proofView}/>
                    // :
                    //   ''
                  }
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Order_Receiving proofView={proofView} orderAddressId={orderAddressId}/>
          </>
        )}
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
    </IonPage>
  );
};

export default View_Order;
