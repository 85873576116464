import { IonContent, IonLoading, IonPage, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react"
import { useState } from "react";
import Cookies from "universal-cookie";
import { postRequest } from "../ApiServices";
import { AppHeader } from "../components/AppHeader"
import Orders_List from "../components/Orders_List"
import Pending_Orders_List from "../components/Pending_Orders_List";

const Inprocess_Orders: React.FC = () => {
  const cookies = new Cookies();
  const [showLoading, setShowLoading] = useState<any>(true);
  const [customerOrders, setCustomerOrders] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [orderTransfer, setOrderTransfer] = useState(false);

  useIonViewWillEnter(() => {
    setShowLoading(true);
    let dtlc = cookies.get('dtlc');
    if (dtlc && dtlc.token) {
      postRequest("jobs/accepted", {}, dtlc.token.token)
        .then((res: any) => {
          setShowLoading(false);
          if (res.status) {
            setCustomerOrders(res.data);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  });

  useIonViewWillLeave(() => {
    setCustomerOrders([]);
    // setShowLoading(true);
  });
  
  return (
    <IonPage>
        <IonContent className="linear_bg">
        <AppHeader  from=''/>
        <div className="page-space">
        <h2 className='ion-text-center ion-margin-bottom'>Inprocess Orders</h2>
          {/* {customerOrders.length > 0 ?
            <Orders_List Orders={customerOrders} />
            :
            !showLoading ?
              <h3>No orders found</h3>
              :
              ''
          } */}
          {customerOrders.length> 0?
              <Pending_Orders_List stateValue = {orderTransfer} Orders={customerOrders}/>
              :
              !showLoading? 
                  <h3>No orders found</h3>
                  :
                  ''
          }
        </div>
        </IonContent>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
    </IonPage>
  )
}

export default Inprocess_Orders
