import { IonAccordion, IonAccordionGroup, IonContent, IonItem, IonLabel, IonPage } from '@ionic/react'
import React, { useRef } from 'react'
import { AppHeader } from '../components/AppHeader'
import '../assets/scss/User_Profile.scss';
const Helps_FAQs: React.FC = () => {

  return (
    <IonPage>
      <IonContent className='overflow'>
        <AppHeader from=''/>
        <div className='wrapper faq-wrapper'>
          {/* <h3 className='ion-text-center'>Helps & FAQs</h3> */}
          <h4 className='mtb fs-14'>Main Title</h4>
          <IonAccordionGroup>
            <IonAccordion value="first">
              <IonItem slot="header">
                <IonLabel>It is a long established fact fact that a reader will be</IonLabel>
              </IonItem>
              <div className="content" slot="content">
                It is a long established fact that a reader will be
                distracted by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less normal
                distribution of letters, as opposed to using
                'Content here, content here', making it look like readable English.
              </div>
            </IonAccordion>
            <IonAccordion value="second">
              <IonItem slot="header" >
                <IonLabel>It is a long established fact fact that a reader will be</IonLabel>
              </IonItem>
              <div className="content" slot="content">
                It is a long established fact that a reader will be
                distracted by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less normal
                distribution of letters, as opposed to using
                'Content here, content here', making it look like readable English.
              </div>
            </IonAccordion>
            <IonAccordion value="third">
              <IonItem slot="header">
                <IonLabel>It is a long established fact fact that a reader will be</IonLabel>
              </IonItem>
              <div className="content" slot="content">
                It is a long established fact that a reader will be
                distracted by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less normal
                distribution of letters, as opposed to using
                'Content here, content here', making it look like readable English.
              </div>
            </IonAccordion>
          </IonAccordionGroup>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Helps_FAQs;
