import {
  IonContent,
  IonFooter,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Link, useHistory } from "react-router-dom";
import { AppHeader } from "../components/AppHeader";
import Login_Component from "../components/Login_Component";
import { useEffect } from "react";
import { Capacitor, Plugins } from "@capacitor/core";


const Login: React.FC = () => {

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e: any) => {
        // Show A Confirm Box For User to exit app or not
        let ans = window.confirm("Confirm exit App?");
        if (ans) {
          Plugins.App.exitApp();
        }
      });
    }
  }, []);

  return (
    <IonPage>
      <IonContent>
        <AppHeader from="mobile" />
        <div className="auth-wrapper">
          <Login_Component />
        </div>
      </IonContent>
      {/* <IonFooter className="ion-no-border auth-footer">
        <IonToolbar>
          <IonTitle> <p className="ion-text-center">
            Don't have account?
            <Link to="/signup">
              <span> Sign up</span>
            </Link>
          </p>
          </IonTitle>
        </IonToolbar>
      </IonFooter> */}
    </IonPage>
  );
};

export default Login;