import {
    IonContent,
    IonPage,
    useIonViewWillEnter
} from "@ionic/react";
import Driver_Panel_Component from "../components/Driver_Panal_Component";
import Cookies from 'universal-cookie';
import { useHistory } from "react-router";
import { useEffect } from "react";
import { Capacitor, Plugins } from "@capacitor/core";
import { backgroundModeSettings, startGettingLocation } from "../BackgroundgeolocationSettings";
// import { BackgroundMode } from '@awesome-cordova-plugins/background-mode';
// import { Network } from "@capacitor/network";

const Driver_Panel: React.FC = () => {
    const history = useHistory();
    const cookies = new Cookies();

    useEffect(()=>{
        if (Capacitor.isNative) {
            Plugins.App.addListener("backButton", (e: any) => {
            // e.stopPropagation();

            if (window.location.pathname === "/driver_panel") {
                // Show A Confirm Box For User to exit app or not
                let ans = window.confirm("Confirm exit App?");
                if (ans) {
                Plugins.App.exitApp();
                }
            }
            });

            backgroundModeSettings();
        }
    }, []);

    useIonViewWillEnter(async ()=>{
        // await Network.getStatus().then(result=>{
        //     console.log(result);
        // }).catch(err=>console.log(err));

        let dtlc = cookies.get('dtlc');
        if(dtlc){
            if(dtlc.user_data.role_id === 2){
                history.replace('/customer_panel');
            }else if(dtlc.user_data.role_id === 5){
                history.replace('/dispatch_panel');
            }else{
                if (Capacitor.isNative) {
                    startGettingLocation();
                }
            }
        }else{
            history.replace('/login');
        }
    });
    
    return (
        <IonPage>
            <IonContent fullscreen className="driver_bg">
                    <Driver_Panel_Component/>
            </IonContent>

        </IonPage>
    );
};

export default Driver_Panel;