import {
  IonCol,
  IonGrid,
  IonRow,
  IonImg,
  IonPage,
  IonContent,
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonButtons,
} from "@ionic/react";
import "../../assets/scss/Desktop_Pickup_Time.scss";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "../../components/Sidebar";

const Desktop_Pickup_Time: React.FC = () => {
  const [isShown, setIsShown] = useState(false);
  const passState = () => {
    setIsShown(!isShown);
  };

  const pickupLimit = useRef<any>(null);
  const pickupTime = useRef<any>(null);
  const amMode = useRef<any>(null);
  const pmMode = useRef<any>(null);
  const [number, setNumber] = useState<number>();
  const [number2, setNumber2] = useState<number>();
  const [active1, setActive1] = useState<any>(false);
  const [active2, setActive2] = useState<any>(false);
  const history = useHistory();

  const handleClick = (type: string) => {
    pickupLimit.current.className =
      "pick_up_choice md button button-solid ion-activatable ion-focusable pick_up_choice ios button button-solid ion-activatable ion-focusable";
    pickupTime.current.className =
      "pick_up_choice md button button-solid ion-activatable ion-focusable pick_up_choice ios button button-solid ion-activatable ion-focusable";
    if (type === "timelimit") {
      setActive1(true);
      setActive2(false);
      pickupLimit.current.className =
        "pick_up_choice md button button-solid ion-activatable ion-focusable pick_up_choice ios button button-solid ion-activatable ion-focusable border_style position-relative";
    } else if (type === "pickupt") {
      setActive2(true);
      setActive1(false);
      pickupTime.current.className =
        "pick_up_choice md button button-solid ion-activatable ion-focusable pick_up_choice ios button button-solid ion-activatable ion-focusable border_style position-relative";
    }
  };

  const modeClick = (type: string) => {
    amMode.current.className =
      "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios ";
    pmMode.current.className =
      "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios ";
    if (type === "am") {
      amMode.current.className =
        "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios btn-bg";
    } else if (type === "pm") {
      pmMode.current.className =
        "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios btn-bg";
    }
  };

  const hourInput = (e: any) => {
    if (e.target.value! >= 12) {
      e.target.value = 12;
      setNumber(e.target.value);
    } else if (e.target.value! <= 0) {
      e.target.value = 0;
      setNumber(e.target.value);
    }
  };
  const mintInput = (e: any) => {
    if (e.target.value >= 59) {
      e.target.value = 59;
      setNumber2(e.target.value);
    } else if (e.target.value <= 0) {
      e.target.value = 0;
      setNumber2(e.target.value);
    }
  };
  return (
    <IonPage>
      <IonContent>
        <div>
          <div className="desktop-home desktop-wrapper">
            <IonGrid className="ion-no-padding">
              <IonRow>
                <IonCol size="12" sizeLg="3">
                  <Sidebar passState={passState} />
                </IonCol>
                <IonCol size="12" sizeLg="9">
                  <div
                    className={isShown ? "rightbar rightbar-none" : "rightbar"}
                  >
                    <h5 className="mt-50 ptb-30 p-media-none ion-text-center">
                      Choose a time for the pickup
                    </h5>
                    <IonGrid className="mtb-5-10">
                      <IonRow>
                        <IonCol
                          size="12"
                          sizeLg="6"
                          className="ion-padding position-relative"
                        >
                          <IonImg
                            className={
                              active1
                                ? "pickup_standard_orignal"
                                : "pickup_standard"
                            }
                            src={require("../../assets/images/testpng.png")}
                          />
                          <IonButton
                            className="pick_up_choice"
                            ref={pickupLimit}
                            onClick={() => {
                              handleClick("timelimit");
                            }}
                          >
                            <div className="btn_text">
                              <div className="text_only">
                                <h4>Standard</h4>
                                <p>Pick-up between:</p>
                              </div>
                              <div className="time_limit">
                                <h5>
                                  <b>8:00 am</b> and <b>8:00 pm</b>
                                </h5>
                              </div>
                              <div className="price">
                                <h5>£12.00</h5>
                              </div>
                            </div>
                          </IonButton>
                        </IonCol>
                        <IonCol size="12" sizeLg="6" className="ion-padding">
                          <IonImg
                            className={
                              active2
                                ? "pickup_standard_orignal"
                                : "pickup_standard"
                            }
                            src={require("../../assets/images/testimg.png")}
                          />
                          <IonButton
                            className="pick_up_choice"
                            ref={pickupTime}
                            onClick={() => {
                              handleClick("pickupt");
                            }}
                          >
                            <div className="btn_text">
                              <div className="text_only">
                                <h4>Fixed time</h4>
                                <p>I need a pick-up at:</p>
                              </div>
                              <div className="time-with-mode">
                                <div className="select-time">
                                  <IonItem lines="none">
                                    <IonInput
                                      placeholder="00"
                                      min="1"
                                      max="12"
                                      type="number"
                                      defaultValue={number}
                                      onIonInput={hourInput}
                                    ></IonInput>
                                  </IonItem>
                                  <p>:</p>
                                  <IonItem lines="none">
                                    <IonInput
                                      placeholder="00"
                                      min="0"
                                      max="60"
                                      type="number"
                                      defaultValue={number2}
                                      onIonInput={mintInput}
                                    ></IonInput>
                                  </IonItem>
                                </div>
                                <div className="select-time-mode">
                                  <IonButtons
                                    ref={amMode}
                                    onClick={() => {
                                      modeClick("am");
                                    }}
                                  >
                                    AM
                                  </IonButtons>
                                  <IonButtons
                                    ref={pmMode}
                                    onClick={() => {
                                      modeClick("pm");
                                    }}
                                  >
                                    PM
                                  </IonButtons>
                                </div>
                              </div>
                              <div className="price">
                                <h5>£24.50</h5>
                              </div>
                            </div>
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    <div className="ion-padding-horizontal">
                      <IonButton expand="block" className="ion-margin-bottom">
                        Next
                      </IonButton>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Desktop_Pickup_Time;
