import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonToast,
  IonLoading,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";

import { useEffect, useRef, useState } from "react";
import { BookingHeader } from "../components/BookingHeader";
import "../assets/scss/Delivery_Types.scss";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { postRequest } from "../ApiServices";
import { Preferences } from '@capacitor/preferences';

const Booking_Type: React.FC = () => {
  const cookies = new Cookies();
  const history = useHistory();

  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [bookingType, setBookingType] = useState<any>(null);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [deliveryTypes, setDeliveryTypes] = useState<any>([]);

  const urgentBox = useRef<any>(null);
  const sameDayBox = useRef<any>(null);
  const scheduleBox = useRef<any>(null);
  const documentParcel = useRef<any>(null);
  const clothingParcel = useRef<any>(null);
  const flowerParcel = useRef<any>(null);
  const groceryParcel = useRef<any>(null);

  useEffect(()=>{}, []);

  const handleNext = () => {
    if (bookingType) {
      history.push("/delivery_details");
    } else {
      setAlertMessage('Please select a booking type');
      setShowToast(true);
    }
  }

  useIonViewWillLeave(()=>{
    urgentBox.current.className = "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
    sameDayBox.current.className = "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
    scheduleBox.current.className = "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
  });

  useIonViewWillEnter(() => {
    let dtlc = cookies.get('dtlc');
    if (dtlc && dtlc.token) {
      setShowLoading(true);
      postRequest('delivery-types', {}, dtlc.token.token).then(async (data) => {
        if (data.status) {
          setShowLoading(false);
          setDeliveryTypes(data.data);

          let order_details;
          // if (cookies.get("dtlc-order-details")) {
          //   order_details = cookies.get("dtlc-order-details");
          //   setBookingType(order_details.type);
          //   boxHighlighting(order_details.type, data.data);
          // }
          const { value } = await Preferences.get({ key: 'dtlc-order-details' });
          if (value) {
            order_details = JSON.parse(value);
            setBookingType(order_details.type);
            boxHighlighting(order_details.type, data.data);
          }
        } else {
          setShowLoading(false);
          if (data.message) {
            setAlertMessage(data.message);
            setShowToast(true);
          }
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  });

  const boxHighlighting = (type: any, delivery_types: any = deliveryTypes) => {
    if(urgentBox.current && sameDayBox.current && scheduleBox.current){
      urgentBox.current.className = "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
      sameDayBox.current.className = "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
      scheduleBox.current.className = "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
      if (type === "urgent") {
        urgentBox.current.className = "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios border_style";
      } else if (type === "same day") {
        sameDayBox.current.className = "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios border_style";
      } else if (type === "schedule") {
        scheduleBox.current.className = "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios border_style";
      }
  
      delivery_types.forEach((item: any, index: any) => {

        if (item.code === 'urgent_delivery' && !item.is_active) {
          urgentBox.current.className = "disabled-btn sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
        } else if (item.code === 'same_day_delivery' && !item.is_active) {
          sameDayBox.current.className = "disabled-btn sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
        } else if (item.code === 'schedule_delivery' && !item.is_active) {
          scheduleBox.current.className = "disabled-btn sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
        }
      });
    }
  }

  const handleClick = async (type: string) => {
    boxHighlighting(type);

    let order_details: any = {};
    const { value } = await Preferences.get({ key: 'dtlc-order-details' });
    if (value) {
      order_details = JSON.parse(value);
      order_details.type = type;
      setBookingType(type);
    } else {
      order_details.type = type;
      setBookingType(type);
    }
    await Preferences.set({
      key: 'dtlc-order-details',
      value: JSON.stringify(order_details),
    });

    // if(cookies.get("dtlc-order-details")){
    //   order_details = cookies.get("dtlc-order-details");
    //   order_details.type = type;
    //   setBookingType(type);
    // }else{
    //   order_details.type = type;
    //   setBookingType(type);
    // }
    // cookies.set("dtlc-order-details", order_details);
  };


  return (
    <IonPage>
      <IonContent className="linear_bg">
        <BookingHeader from="" />
        <div className="delivery-wrapper">
          <div className="wrapper-inner ion-padding">
            <h3 className="ion-text-center">Delivery Types</h3>
            <p className="ion-text-center">
              Get approximate cost for delivery or pick of your parcel
            </p>
            <IonGrid className="delivery_boxes">
              <IonRow>
                {deliveryTypes.map((item: any, index: any) => {
                  return (
                    <IonCol size="4" key={index}>
                      <IonButtons
                        className={item.is_active ? '' : 'disabled-btn '}
                        ref={item.name === 'Urgent Delivery' ? urgentBox : item.name === 'Same Day Delivery' ? sameDayBox : item.name === 'Schedule Delivery' ? scheduleBox : urgentBox}
                        onClick={() => {
                          if (item.is_active) {
                            handleClick(item.name === 'Urgent Delivery' ? 'urgent' : item.name === 'Same Day Delivery' ? 'same day' : item.name === 'Schedule Delivery' ? 'schedule' : '')
                          }
                        }}
                      >
                        <div>
                          <IonImg
                            src={item.name === 'Urgent Delivery' ? require("../assets/images/running.png") : item.name === 'Same Day Delivery' ? require("../assets/images/stopwatch.png") : item.name === 'Schedule Delivery' ? require("../assets/images/calendar.png") : ''}
                          />
                        </div>
                        <h6>{item.name}</h6>
                      </IonButtons>
                    </IonCol>
                  )
                })}
              </IonRow>
            </IonGrid>
            <IonButton expand="block" className="ion-margin-bottom" onClick={() => { handleNext() }}>
              Next
            </IonButton>
          </div>
        </div>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(!showToast)}
        color="danger"
        message={alertMessage}
        duration={1500}
      />
    </IonPage>
  );
};

export default Booking_Type;
