import { IonPage, IonContent, IonIcon, IonList, IonItem, IonInput, IonButton, useIonViewWillEnter } from "@ionic/react";
import { BookingHeader } from "../components/BookingHeader";
import BookingConfirm from "../components/BookingConfirm";
import { useHistory } from "react-router-dom";
import { useRef, useState } from "react";
import Cookies from "universal-cookie";
import '../assets/scss/Booking_Confirmation.scss';
import { Preferences } from "@capacitor/preferences";

const Booking_Confirmation: React.FC = () => {
  const cookies = new Cookies();
  const history = useHistory();
  const childRef = useRef();
  const [paymentdetail, setPaymentDetail] = useState<any>({});

  useIonViewWillEnter(async()=>{
    
  });

  const confirmHandler = async () => {
    let order_details: any = {};
    const { value } = await Preferences.get({ key: 'dtlc-order-details' });
    if (value) {
      order_details = JSON.parse(value);
      order_details.total = "40";
      order_details.subtotal = "40";
      order_details.payment_method = "cash";
      setPaymentDetail(order_details);
    } else {
      order_details.total = "40";
      order_details.subtotal = "40";
      order_details.payment_method = "cash";
      setPaymentDetail(order_details);
    }
    await Preferences.set({
      key: 'dtlc-order-details',
      value: JSON.stringify(order_details),
    });
    // cookies.set("dtlc-order-details", order_details);
    history.push("/sender_contact_detail");
  }

  return (
    <IonPage>
      <IonContent>
        <BookingHeader from='booing confirmation'/>
        <div className="booking-wrapper">
          <div className="wrapper-inner">
            <h3 className="page_title ion-padding-bottom">booking confirmation</h3>
            <BookingConfirm proofView={(args: any)=>{}} ref={childRef}/>
            <div className="payment-wrapper ion-padding">
              {/* <IonList className="order_list">
                <h5>Payment Option</h5>
                <div className="list_inputs">
                  <IonItem>
                    <IonInput placeholder="Coupencode" type="text"></IonInput>
                    <IonButton>Apply</IonButton>
                  </IonItem>
                  <IonItem>
                    <IonIcon icon={cashOutline} className="ion-margin-end" />
                    <IonInput placeholder="Cash on Delivery" type="text"></IonInput>
                  </IonItem>
                  <IonItem lines="none" className="ion-margin-bottom">
                    <IonIcon icon={cardOutline} className="ion-margin-end" />
                    <IonInput placeholder="Pay Now" type="tel"></IonInput>
                    <IonIcon icon={checkmarkOutline} className="checkmark-icon" />
                  </IonItem>
                </div>
              </IonList> */}
              <IonButton onClick={() => {confirmHandler()}}>
                Confirm & Book
              </IonButton>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
export default Booking_Confirmation;