import {
  IonContent,
  IonIcon,
  IonPage,
  IonList,
  IonItem,
  useIonViewWillEnter,
  IonLoading,
  IonButton,
  IonCol,
  IonToast,
  IonRefresher,
  IonRefresherContent
} from "@ionic/react";
import {
  mailOutline,
  notificationsOutline,
  arrowBackOutline,
} from "ionicons/icons";
import { AppHeader } from "../components/AppHeader";
import "../assets/scss/Notifications.scss";
import { useState } from "react";
import { postRequest } from "../ApiServices";
import { getCookieData } from "../functions";
import { RefresherEventDetail } from '@ionic/core';
import { Capacitor } from '@capacitor/core';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';


const Notifications: React.FC = () => {
  const [notifications, setNotifications] = useState<any>([]);
  const [singleNotification, setSingleNotification] = useState<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [showSingleNotification, setShowSingleNotification] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [cookieData, setCookieData] = useState<any>();

  useIonViewWillEnter(async () => {
    getNotifications();
    if (Capacitor.platform !== 'web') {
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          //setNewNotification(true);
        }
      );
    }
  }, []);

  const getNotifications = async () => {
    await setNotifications([]);
    let cookie_data = getCookieData('dtlc');
    setCookieData(cookie_data);
    setShowLoading(true);
    return new Promise(async (resolve, reject) => {
      await postRequest("user-notifications", {}, cookie_data.token.token)
        .then(async (data) => {
          if (data.status) {
            setShowLoading(false);
            await setNotifications(data.data);
            resolve(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }


  const handleMarkAllAsRead = () => {
    postRequest("user-notifications/mark-as-read-all", {}, cookieData.token.token)
      .then(async (data) => {
        if (data.status) {
          let allNotifications = notifications;
          for await (let notification of allNotifications) {
            notification.is_read = 1;
          }
          setNotifications(allNotifications);
          setAlertMessage(data.message);
          setShowToast(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleMarkSingleAsRead = (notificationId: any, show: boolean = true, index: any = '') => {
    postRequest("user-notifications/mark-as-read-single", { id: notificationId }, cookieData.token.token)
      .then(async (data) => {
        if (data.status && show) {
          if (index !== '') {
            let allNotifications = notifications;
            allNotifications[index].is_read = 1;
            setNotifications(allNotifications);
          }
          setAlertMessage(data.message);
          setShowToast(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleNotificationList = (data: any) => {
    handleMarkSingleAsRead(data.id, false);
    setSingleNotification({
      title: data.title,
      message: data.message
    });
    setShowSingleNotification(true);
    let allNotifications = notifications;
    allNotifications[data.index].is_read = 1;
    setNotifications(allNotifications);
  }

  const refreshNotifications = async (event: CustomEvent<RefresherEventDetail>) => {
    if (await getNotifications()) {
      event.detail.complete();
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen className="linear_bg">
        <IonRefresher slot="fixed" onIonRefresh={refreshNotifications}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {showSingleNotification ?
          <>
            <div className="notification-header">
              <div className="header_toolbar">
                <IonButton className="header_back_button" onClick={() => {
                  setShowSingleNotification(!showSingleNotification);
                }}>
                  <IonIcon icon={arrowBackOutline} size="large" />
                </IonButton>
              </div>
            </div>
            <div className="notification-details-wrapper">
              <div className="notification-description">
                <div className="notification-description-inner">
                  <h4>{singleNotification.title}</h4>
                  <p>{singleNotification.message}</p>
                </div>
              </div>
            </div>
          </>
          :
          <>
            <AppHeader from='' />
            <div className="notification">
              <IonIcon
                className="notifications-icon-btn"
                icon={notificationsOutline}
              />
            </div>

            <div className="notification-wrapper">
              <h3 className="notification-title">Notifications</h3>
              <h6 onClick={() => handleMarkAllAsRead()}>Mark all as read</h6>
              <IonList className="notifications-content">
                {notifications.length > 0 ?
                  notifications.map((item: any, index: any) => {
                    return (
                      <IonItem className={item.is_read ? "" : "msg-read"} key={index}>
                        <IonCol onClick={() => {
                          handleNotificationList({
                            id: item.id,
                            title: item.title,
                            message: item.message,
                            index: index
                          });
                        }}>
                          <div className="msg-content">
                            <h4>{item.title}</h4>
                            <div className="align-items-center justify-content-between"><p>{item.message}</p></div>
                          </div>
                        </IonCol>
                        <div>
                          <IonIcon slot="end" icon={mailOutline} className="ion-text-end" onClick={() => {
                            handleNotificationList({
                              id: item.id,
                              title: item.title,
                              message: item.message,
                              index: index
                            });
                          }}></IonIcon>
                          {!item.is_read ? <h6 className="read-msg" onClick={() => handleMarkSingleAsRead(item.id, true, index)}>mark as read</h6> : ''}
                        </div>
                      </IonItem>
                    );
                  })
                  :
                  <IonItem>
                    <IonCol>
                      <h3 className="no-notification">No notifications found</h3>
                    </IonCol>
                  </IonItem>
                }
              </IonList>
            </div>
          </>
        }
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(false)}
        color={"primary"}
        message={alertMessage}
        duration={2500}
      />
    </IonPage>
  );
};

export default Notifications;