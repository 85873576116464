import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonRow,
  IonToast,
  IonSearchbar,
  IonSlide,
  IonLoading,
  IonSlides,
  IonSpinner,
  useIonViewWillEnter,
  isPlatform
} from "@ionic/react";
import "../assets/scss/Customer_Panel.scss";
import { qrCodeOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';
import { postRequest } from "../ApiServices";
import { useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import { Preferences } from '@capacitor/preferences';
import { prepareOrderStructure } from "../functions";

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  spaceBetween: 20,
  autoplay: true,
};

const Customer_Panel_Component: React.FC = () => {
  const history = useHistory();
  const cookies = new Cookies();

  const [showToast, setShowToast] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [orderNumber, setOrderNumber] = useState<any>('');
  const [slides, setSlides] = useState<any>([]);

  useEffect(()=>{
    return () => {
      setShowToast(false);
      setAlertMessage('');
      setShowLoading(false);
      setOrderNumber('');
      setSlides([]);
    }
  }, []);

  useIonViewWillEnter(()=>{
    let dtlc = cookies.get('dtlc');
    if(dtlc && dtlc.token){
      postRequest('slides',{}, dtlc.token.token).then(res=>{
        setSlides(res.data);
      }).catch(err=>console.log(err));
    }
  });

  const openScanner = async () => {
    await AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.CAMERA).then(async (result)=>{
        console.log('Has permission?',result.hasPermission);
        if(!result.hasPermission){
            AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.CAMERA);
        }else{
            const data = await BarcodeScanner.scan({
                showFlipCameraButton : true, // iOS and Android
                showTorchButton : true,
                prompt : "Place a barcode or qr code inside the scan area",
                resultDisplayDuration: 0
            });
            console.log(`Barcode data: ${data.text}`);
            let order_number: any = data.text;
            order_number = order_number.split('=');
            order_number = order_number[1];
            setOrderNumber(order_number);
            handleApiRequest(order_number);
        }
    }, (err)=> {
        console.log(err);
    });
  };

  const handleSearchOrder = () => {
    if(orderNumber === '' || orderNumber === ' '){
      setAlertMessage('Order number required');
      setShowToast(true);
      return;
    }
    setShowLoading(true);
    handleApiRequest(orderNumber);
  }

  const handleApiRequest = (order_number: any) => {
    let dtlc = cookies.get('dtlc');
    postRequest('order/show', {
      order_number: order_number
    },dtlc.token.token).then(async (data)=>{
      setOrderNumber('');
      setShowLoading(false);
      if(data.data){
        prepareOrderStructure(data.data).then(async (result: any)=>{
          await Preferences.set({
            key: 'api-data',
            value: JSON.stringify(result),
          });
          // cookies.set('dtlc-order-details', data.data);
          history.push('/view_order');
        }).catch((err: any)=>{
          console.log(err);
        });
      }else{
        setAlertMessage('Order not found!');
        setShowToast(true);
      }
    }).catch((err)=>{
      console.log(err);
    });
  }

  const handleOrderNumberChange = (value: any) => {
    setOrderNumber(value);
  }

  return (
    <div className="customer-panel-wrapper">
      <h3 className="ion-text-center ion-padding ion-no-margin">Dashboard</h3>
      <div className="position-relative ion-margin-horizontal">
        <IonSearchbar
          show-clear-button="never"
          placeholder="Order Number #"
          className="ion-no-padding ion-margin-bottom"
          onIonChange={(event)=>handleOrderNumberChange(event.detail.value)}
          value={orderNumber}
        ></IonSearchbar>
        <span className="search-icon" onClick={()=>handleSearchOrder()}></span>
        <IonIcon class="scan-icon" icon={qrCodeOutline} onClick={()=>openScanner()} />
      </div>
      
        {slides.length > 0?
          <IonSlides
            pager={true}
            options={slideOpts}
            className="ion-margin-horizontal ion-padding-top"
          >
            {slides.map((item: any, index: any)=>{
              return(
                <IonSlide key={index}>
                  <IonImg src={item.attachment.file_name_url} />
                </IonSlide>
              );
            })}
          </IonSlides>
          :
          <div className="spin-wrapper">
            <IonSpinner color="primary" name="circular"></IonSpinner>
          </div>
        }
      <IonGrid className="panel_boxes">
        <IonRow>
          <IonCol size="6">
            <div className="space-box">
              <IonButtons onClick={() => history.push("bookings")}>
                <div>
                  <IonImg src={require("../assets/images/parcel.png")} />
                </div>
                <h6>My Bookings</h6>
              </IonButtons>
            </div>
          </IonCol>
          <IonCol size="6">
            <div className="space-box">
              <IonButtons onClick={() => history.push("order_history")}>
                <div>
                  <IonImg src={require("../assets/images/jobs-histroy.png")} />
                </div>
                <h6>Order History</h6>
              </IonButtons>
            </div>
          </IonCol>
          <IonCol size="6">
            <div className="space-box">
              <IonButtons onClick={() => history.push("customer_care")}>
                <div>
                  <IonImg src={require("../assets/images/headphone.png")} />
                </div>
                <h6>Customer Care</h6>
              </IonButtons>
            </div>
          </IonCol>
          <IonCol size="6">
            <div className="space-box">
              <IonButtons onClick={() => history.push("user_profile")}>
                <div>
                  <IonImg src={require("../assets/images/settings.png")} />
                </div>
                <h6>Settings</h6>
              </IonButtons>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonButton
        className="ion-padding-horizontal book-parcel"
        onClick={() => {
          if(isPlatform('mobile')){
            history.push("delivery_types");
          }else if(isPlatform('desktop')){
            history.push('/home');
          }
        }}
      >
        {" "}
        <IonImg src={require("../assets/images/delivery-boy.png")} />
        Book Parcel
      </IonButton>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(false)}
        color={"danger"}
        message={alertMessage}
        duration={2500}
      />
    </div>
  );
};

export default Customer_Panel_Component;

