import Cookies from 'universal-cookie';
import { device } from "./Layout";
import { postRequest } from './ApiServices';
import { Preferences } from '@capacitor/preferences';
import { stopDriverLocationSave } from './BackgroundgeolocationSettings';

const cookies = new Cookies();

export const prepareOrderStructure = async (data) => {
    let order_details = data;
    for await(let item of order_details.pickup_address){
        console.log(item);
        item.address = item.user_address.address;
        item.city = item.user_address.city;
        item.district = item.user_address.district;
        item.latitude = item.user_address.latitude;
        item.longitude = item.user_address.longitude;
        item.notes = item.user_address.notes;
        item.place_detail = item.user_address.place_detail;
        item.place_name = item.user_address.place_name;
        item.postcode = item.user_address.postcode;
        item.street = item.user_address.street;
        item.contact = {};
        item.contact.first_name = item.user_address.contact.first_name;
        item.contact.phone_no = item.user_address.contact.phone_no;
        item.contact.email = item.user_address.contact.email;
        delete item.user_address;
    }
    
    for await(let item of order_details.dropoff_address){
        item.address = item.user_address.address;
        item.city = item.user_address.city;
        item.district = item.user_address.district;
        item.latitude = item.user_address.latitude;
        item.longitude = item.user_address.longitude;
        item.notes = item.user_address.notes;
        item.place_detail = item.user_address.place_detail;
        item.place_name = item.user_address.place_name;
        item.postcode = item.user_address.postcode;
        item.street = item.user_address.street;
        item.contact = {};
        item.contact.first_name = item.user_address.contact.first_name;
        item.contact.phone_no = item.user_address.contact.phone_no;
        item.contact.email = item.user_address.contact.email;
        delete item.user_address;
    }
    return new Promise(function(resolve,reject){
        resolve(order_details);
    });
}

const is_login_processing = (data, device) => {
    if(device === 'mobile'){
        if(data.user_data.role_id === 2){
            return {
                is_logged_in: true,
                redirect_to : '/customer_panel',
                origin: 'mobile'
            };
        }else if(data.user_data.role_id === 3){
            return {
                is_logged_in: true,
                redirect_to : '/driver_panel',
                origin: 'mobile'
            };
        }else if(data.user_data.role_id === 5){
            return {
                is_logged_in: true,
                redirect_to : '/dispatch_panel',
                origin: 'mobile'
            };
        }
    }else{
        if(data.user_data.role_id === 2){
            return {
                is_logged_in: true,
                redirect_to : 'desktop_customer_panel',
                origin: 'desktop'
            };
        }else if(data.user_data.role_id === 3){
            return {
                is_logged_in: true,
                redirect_to : 'desktop_driver_panel',
                origin: 'desktop'
            };
        }else if(data.user_data.role_id === 5){
            return {
                is_logged_in: true,
                redirect_to : 'desktop_dispatch_panel',
                origin: 'desktop'
            };
        }
    }
}

export const is_login = () => {
    let data = cookies.get('dtlc');
    if(data && data.token){
        return is_login_processing(data, device);
        // if(device === 'mobile'){
        //     return is_login_processing(data, device);
        //     // if(data.user_data.role_id === 2){

        //     // }
        //     // return {
        //     //     is_logged_in: true,
        //     //     redirect_to : 'customer_panel'
        //     // };
        // }else{
        //     return {
        //         is_logged_in: true,
        //         redirect_to : 'desktop_customer_panel'
        //     };
        // }
    }else{
        return {
            is_logged_in: false,
            redirect_to: '',
            origin: device
        };
    }
}

export const log_me_out = async () => {
    stopDriverLocationSave();
    let dtlc = cookies.get('dtlc');
    if(dtlc && dtlc.token){
        postRequest('auth/logout', {}, dtlc.token.token).then(async (res)=>{
            if(res.status){
                cookies.remove('dtlc');
            }
            // console.log(res);
            // return new Promise((resolve, reject) => {
            //     resolve(true);
            // })
        }).catch((err)=>{
            console.log(err);
        });
    }
    cookies.remove('dtlc');
    cookies.remove('dtlc-order-number');
    cookies.remove('dtlc-order-details');
    await Preferences.remove({ key: 'dtlc-order-details'});
    return true;
    // return new Promise((resolve, reject) => {
    //     resolve(true);
    // })
    // cookies.remove('dtlc');
    // cookies.remove('dtlc-order-number');
    // cookies.remove('dtlc-order-details');
    // await Preferences.remove({ key: 'dtlc-order-details'});
    // cookies.remove('multi-addresses');
    // cookies.remove('specific-index');
    // return true;
}

export const getCookieData = (type) => {
    return cookies.get(type);
}