import { IonButton, IonContent, IonLabel, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react'
import React, { useState } from 'react'
import { AppHeader } from '../components/AppHeader'
import "../assets/scss/Order_Rejection_Details.scss";
import { Preferences } from '@capacitor/preferences';
import { postRequest } from '../ApiServices';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router';

const Order_Rejection_Details = () => {
    const cookies = new Cookies();
    const history = useHistory();

    const [declinedOrder, setDeclinedOrder] = useState<any>(null);
    const [showLoading, setShowLoading] = useState<boolean>(false);

    useIonViewWillEnter(async ()=>{
        const { value } = await Preferences.get({ key: 'declined-by-driver-order' });
        if(value){
            let order_details = JSON.parse(value);
            setDeclinedOrder(order_details);
        }
    });

    const  handleReasonAction = (action: any) => {
        setShowLoading(true);
        let dtlc = cookies.get('dtlc');
        if(dtlc && dtlc.token){
            let status;
            if(action === 'accept'){
                status = 'ACCEPTED';
            }else if(action === 'decline'){
                status = 'DECLINED';
            }

            postRequest('dispatcher/reject-or-accept-reason', {
                id: declinedOrder.id,
                status: status
            }, ).then(res=>{
                if(res.status){
                    setShowLoading(false);
                    history.push('/dispatch_panel');
                }
            }).catch(err=>console.log(err));
        }
    }

  return (
    <IonPage>
        <IonContent className='linear_bg'>
            <AppHeader from=''/>
            <div className='order-rejection-details-wrapper'>
                <h2>Rejection Details</h2>
                <div className='inner-wrapper'>
                    <div className="buttons-wrapper">
                        <IonButton onClick={()=>handleReasonAction('accept')}>Accept</IonButton>
                        <IonButton fill="outline" onClick={()=>handleReasonAction('decline')}>Decline</IonButton>
                    </div>
                    <div className='rejection-details'>
                        <div className='d-flex'><IonLabel>Order #:</IonLabel><h6>{declinedOrder? declinedOrder.order.order_number : ''}</h6></div>
                        <div className='d-flex'><IonLabel>Driver:</IonLabel><p>{declinedOrder? declinedOrder.driver.call_sign : ''}</p><span onClick={()=>history.push('/manage_driver')}>View Profile</span></div>
                        {declinedOrder && declinedOrder.order.user? 
                                <div className='d-flex'><IonLabel>Customer:</IonLabel><p>{declinedOrder? declinedOrder.order.user.call_sign : ''}</p><span onClick={()=>history.push('/manage_customer')}>View Profile</span></div> 
                            :
                            declinedOrder && declinedOrder.order?
                                <>
                                    <div className='d-flex'><IonLabel>Customer:</IonLabel></div>
                                    <p>{declinedOrder.order.name}</p>
                                    <p>{declinedOrder.order.email}</p>
                                    <p>{declinedOrder.order.phone_no}</p>
                                </>
                                :
                                ''
                        }
                        <div><h3>Reason:</h3><p>{declinedOrder? declinedOrder.declined_reason : ''}</p></div>
                    </div>
                </div>
            </div>
        </IonContent>
        <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Please wait..."}
        />
    </IonPage>
  )
}

export default Order_Rejection_Details
