import { IonBackdrop, IonButton, IonButtons, IonCheckbox, IonContent, IonImg, IonInput, IonItem, IonPage, IonToast, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { BookingHeader } from "../components/BookingHeader";
import Cookies from "universal-cookie";
import { validater } from '../validate';
import "../assets/scss/Delivery_Details.scss";
import { useState } from "react";
import { Preferences } from "@capacitor/preferences";

const Sender_Contact_Details: React.FC = () => {
  const history = useHistory();
  const cookies = new Cookies();

  const [checked, setChecked] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [senderDetail, setSenderDetail] = useState<any>({
    first_name: "",
    phone_no: "",
    email: ""
  });

  useIonViewWillLeave(() => {

  });

  useIonViewWillEnter(async () => {
    setSenderDetail({
      first_name: "",
      phone_no: "",
      email: ""
    });
    setChecked(false);
    let order_details;
    const { value } = await Preferences.get({ key: 'dtlc-order-details' });
    
    if (value) {
      order_details = JSON.parse(value);
      if (order_details.sender_detail) {
        setSenderDetail(order_details.sender_detail);
      }
    }
  });

  const senderHandle = async () => {
    var data = {
      Name: senderDetail.first_name,
      Phone: senderDetail.phone_no,
      Email: senderDetail.email
    };
    var validation = validater(data);
    if (!validation.status) {
      await setAlertMessage('' + validation.message);
      setError(true);
      setShowToast(true);
      return;
    } else {
      setError(false);
      let order_details;
      const { value } = await Preferences.get({ key: 'dtlc-order-details' });
      if (value) {
        order_details = JSON.parse(value);
        order_details = setDetails(order_details);
      }

      let order_nature = '';
      if (order_details.pickup_address.length > 1 && order_details.dropoff_address.length > 1) {
        order_nature = 'MULIT_PICK_MULTI_DROP';
      } else if (order_details.pickup_address.length === 1 && order_details.dropoff_address.length === 1) {
        order_nature = 'SINGLE_PICK_SINGLE_DROP';
      } else if (order_details.pickup_address.length > 1 && order_details.dropoff_address.length === 1) {
        order_nature = 'MULTI_PICK_SINGLE_DROP';
      } else if (order_details.pickup_address.length === 1 && order_details.dropoff_address.length > 1) {
        order_nature = 'SINGLE_PICK_MULTI_DROP';
      }

      order_details.order_nature = order_nature;
      await Preferences.set({
        key: 'dtlc-order-details',
        value: JSON.stringify(order_details),
      });
      history.push("/order_status");
    }
  }
  const setDetails = (order_details: any) => {
    order_details.sender_detail = {};
    order_details.sender_detail.first_name = senderDetail.first_name;
    order_details.sender_detail.name = senderDetail.first_name;
    order_details.sender_detail.phone_no = senderDetail.phone_no;
    order_details.sender_detail.email = senderDetail.email;
    return order_details;
  }

  return (
    <IonPage>
      <IonContent fullscreen className="backdrop-effect  linear_bg overflow">
        {/* {backDrop?<IonBackdrop className="backdrop-bg" />:""} */}
        <BookingHeader from='' />
        <div className="order_details plr-20">
          <h3 className="mb_20 page_title scale-up-center">Please fill in your contact details</h3>
          <div className="mb_10 w_100">
            <IonItem className="detail_input delivery_input scale-up-center" lines="none" >
              <IonInput placeholder="Name" type="text"
                value={
                  senderDetail ? senderDetail.first_name : ""
                }
                onIonChange={(e) => {
                  let sender_contact_detail = senderDetail;
                  sender_contact_detail.first_name = e.detail.value;
                  setSenderDetail(sender_contact_detail);
                }} />
            </IonItem>
          </div>
          <div className="mb_10 w_100">
            <IonItem className="detail_input delivery_input scale-up-center" lines="none">
              <IonInput placeholder="+44 7911 123456" type="tel"
                value={
                  senderDetail ? senderDetail.phone_no : ""
                }
                onIonChange={(e) => {
                  let sender_contact_detail = senderDetail;
                  sender_contact_detail.phone_no = e.detail.value;
                  setSenderDetail(sender_contact_detail);
                }} />
            </IonItem>
          </div>
          <div className="mb_10 w_100">
            <IonItem className="detail_input delivery_input scale-up-center" lines="none">
              <IonInput placeholder="email" type="text"
                value={
                  senderDetail ? senderDetail.email : ""
                }
                onIonChange={(e) => {
                  let sender_contact_detail = senderDetail;
                  sender_contact_detail.email = e.detail.value;
                  setSenderDetail(sender_contact_detail);
                }} />
            </IonItem>
          </div>
          <div className="privacy-checkbox">
            <IonCheckbox
              checked={checked}
              onIonChange={(e) =>
                setChecked(e.detail.checked)
              }
            />
            <p className="check-box-disc">
              By clicking next you agree to our{" "}
              <span>Terms and Conditions</span> and{" "}
              <span> Privacy Policy.</span>
            </p>
          </div>
          <IonButton expand="block" className="scale-up-center" disabled={!checked} onClick={senderHandle}>Next</IonButton>
        </div>
      </IonContent>
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(false)}
        color={error ? "danger" : "primary"}
        message={alertMessage}
        duration={1500}
      />
    </IonPage>
  );
};

export default Sender_Contact_Details;
