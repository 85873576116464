import { IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonLoading, IonPage, IonSearchbar, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react"
import { closeCircle, chevronForwardOutline } from "ionicons/icons"
import Menu from "../components/Menu";
import "../assets/scss/Order_History.scss";
import { postRequest } from "../ApiServices";
import Cookies from 'universal-cookie';
import { getCookieData } from '../functions';
import { useState } from "react";
import { useHistory } from "react-router";
import { AppHeader } from "../components/AppHeader";
import { prepareOrderStructure } from "../functions";
import { Preferences } from '@capacitor/preferences';

const Order_History = () => {
  const cookies = new Cookies();
  const history = useHistory();

  const [showLoading, setShowLoading] = useState(false);
  const [allOrders, setAllOrders] = useState<any>([]);
  const [searchedOrders, setSearchedOrders] = useState<any>([]);
  const [orderNumber, setOrderNumber] = useState<any>('');

  useIonViewWillEnter(() => {
    setShowLoading(true);
    let dtlc = cookies.get('dtlc');
    if (dtlc && dtlc.token) {
      postRequest('order/customer-order-history', {}, dtlc.token.token).then(async (res: any) => {
        let all_orders: any = [];
        for await (let order of res.data) {
          await prepareOrderStructure(order).then(async (result: any) => {
            all_orders.push(result);
          }).catch((err: any) => {
            console.log(err);
          });
        }
        setAllOrders(all_orders);
        setShowLoading(false);
      }).catch((err: any) => {
        console.log(err);
      });
    }
  });

  useIonViewWillLeave(() => {
    setAllOrders([]);
    setSearchedOrders([]);
  });

  const handleSearch = (event: any) => {
    setOrderNumber(event.detail.value);
    let searched_order = allOrders.filter((order: any) => order.order_number === parseInt(event.detail.value));
    setSearchedOrders(searched_order);
  }

  const handleOrderTap = async (index: any, type: any) => {
    let order;
    if (type === 'order') {
      order = allOrders[index];
    } else if (type === 'search-order') {
      order = searchedOrders[index];
    }
    await Preferences.set({
      key: 'api-data',
      value: JSON.stringify(order),
    });
    // cookies.set('dtlc-order-details', data.data);
    history.push('/view_order');
  }

  return (
    <IonPage>
      <IonContent>
        <div className="order-history-wrapper">
          <AppHeader from='' />
          <div className="history-header">
            <h3 className="ion-text-center">Order History</h3>
            <IonSearchbar placeholder='Order Tracking' clearIcon={closeCircle} value={orderNumber} onIonChange={(event) => { handleSearch(event) }} className="ion-no-padding ion-margin-bottom"></IonSearchbar>
          </div>
          <div className="order-list">
            <h4>My Packages</h4>
            <IonList>

              {orderNumber === '' ?
                allOrders.length > 0 ?
                  allOrders.map((item: any, index: any) => {
                    return (
                      <div onClick={() => handleOrderTap(index, 'order')} key={index} className="order-list-item">
                        <IonItem >
                          <IonImg src={item.type === 'urgent' ? require('../assets/images/running-white.png') : item.type === 'same day' ? require('../assets/images/stopwatch-white.png') : item.type === 'schedule' ? require('../assets/images/calendar-white.png') : ''} className="success-icon" />
                          <IonLabel>
                            <p className="order-no">OrderNumber#</p>
                            <h2 className="order-no-number">{item.order_number}</h2>
                            <p className="order-status">{item.type}</p>
                          </IonLabel>
                          <IonIcon icon={chevronForwardOutline}></IonIcon>
                        </IonItem>
                      </div>
                    )
                  })
                  :
                  <h2>No orders found</h2>
                :
                searchedOrders.length > 0 ?
                  searchedOrders.map((item: any, index: any) => {
                    return (
                      <div key={index} className="order-list-item">
                        <IonItem lines="none" onClick={() => handleOrderTap(index, 'search-order')} className="ion-margin-horizontal">
                          <IonImg src={item.type === 'urgent' ? require('../assets/images/running-white.png') : item.type === 'same day' ? require('../assets/images/stopwatch-white.png') : item.type === 'schedule' ? require('../assets/images/calendar-white.png') : ''} className="success-icon" />
                          <IonLabel>
                            <h2>order details</h2>
                            <p>Detail set to true - detail arrow displays on both modes </p>
                          </IonLabel>
                          <IonIcon icon={chevronForwardOutline}></IonIcon>
                        </IonItem>
                      </div>
                    )
                  })
                  :
                  <h2>No orders found</h2>
              }

            </IonList>
          </div>
        </div>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
    </IonPage>
  )
}

export default Order_History
