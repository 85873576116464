import React, { useState, useRef, useEffect } from 'react'
import { IonAvatar, IonButton, IonCol, IonHeader, IonIcon, IonImg, IonInput, IonLabel, IonRow, IonLoading, IonToast, useIonViewWillEnter, IonContent, IonModal } from '@ionic/react'
import "../assets/scss/Order_Receiving.scss"
import { addCircleOutline, arrowBackOutline, closeCircle, removeOutline } from 'ionicons/icons';
import { Camera, CameraResultType, CameraSource, CameraDirection} from "@capacitor/camera";
import { BASE_URL, postRequest } from '../ApiServices';
import { Plugins, Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from '@capacitor/filesystem';
import axios from "axios";
import Cookies from 'universal-cookie';
import Signature from "signature_pad";
import { Preferences } from '@capacitor/preferences';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner';
import JsBarcode from "jsbarcode";
import { prepareOrderStructure } from '../functions';
// import * as JsBarcode from 'jsbarcode';
// import { JsBarcode } from 'JsBarcode';
// var JsBarcode = require('jsbarcode');

interface ChildProps {
    proofView: any;
    orderAddressId: any
}

const Order_Receiving: React.FC<ChildProps> = (props) => {
    const cookies = new Cookies();

    const [orderDetails, setOrderDetails] = useState<any>();
    const [orderAddressId, setOrderAddressId] = useState<any>('');
    const [recipientName, setRecipientName] = useState<any>('');
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<any>('');
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [courierProofImages, setCourierProofImages] = useState<any>([]);
    // const [signatureImage, setSignatureImage] = useState<any>();
    const [signaturePad, setSignaturePad] = useState<any>();
    // const [savedSignature, setSavedSignature] = useState<any>("");
    const [canvasWidth, setCanvasWidth] = useState<any>(0);
    const [proofDetails, setProofDetails] = useState<any>({
        name: '',
        signature: '',
        pictures: [],
    });
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalImageDetails, setModalImageDetails] = useState<any>({
        type: '',
        index: ''
    });
    const [barcodeText, setBarcodeText] = useState<any>('false');

    const canvasRef = useRef<any>(null);
    const barcodeDivRef = useRef<any>(null);

    const IMAGE_DIR = 'stored-images';
    const SIGNATURE_DIR = 'stored-signatures';

    useIonViewWillEnter(()=>{
        console.log('ion view will enter');
        // loadFiles();
        removeAllPhotos();
    });

    useEffect(()=>{
        removeAllPhotos();
        
        let pieces = props.orderAddressId.toString().split('-');
        if(pieces.length >= 2){
            setReadOnly(true);
            orderInformation(pieces[0], parseInt(pieces[1]), parseInt(pieces[2]));
            setBarcodeText('true');
        }else{
            orderInformation();
            setOrderAddressId(parseInt(props.orderAddressId));
        }
        
        setCanvasWidth(window.innerWidth - 40);
        let canvas = canvasRef.current;
        canvas.getContext("2d").scale(1, 1);
        let tempSignaturePad = new Signature(canvas, {
            backgroundColor: "rgb(255, 255, 255)",
            penColor: "#005584"
        });
        setSignaturePad(tempSignaturePad);
        loadFiles();
    }, []);

    const orderInformation = async (address_type: any = '', order_address_index: any = '', meetup_point_index: any ='') => {
        const { value } = await Preferences.get({ key: "api-data" });
        let order_details;
        if (value) {
            order_details = JSON.parse(value);
            setOrderDetails(order_details);
        }
        if(address_type !== '' && order_address_index !== '' && meetup_point_index !== ''){
            let data: any = {
                name: '',
                signature: '',
                pictures: [],
                barcode: ''
            };
            if(address_type === 'pickup'){
                let proof_pictures = [];
                data.name = order_details.pickup_address[order_address_index].meetup_points[meetup_point_index].order_proof.name;
                for await (let item of order_details.pickup_address[order_address_index].meetup_points[meetup_point_index].order_proof.attachments){
                    if(item.context === 'order-proof-signature'){
                        data.signature = item.file_name_url;
                    }else if(item.context === 'order-proof-image'){
                        proof_pictures.push({
                            image: item.file_name_url,
                            name: item.file_name
                        });
                    }
                }
                data.pictures = proof_pictures;
                setProofDetails(data);
                
                const canvas = document.createElement("canvas");
                JsBarcode(canvas, order_details.pickup_address[order_address_index].meetup_points[meetup_point_index].barcode);
                barcodeDivRef.current.appendChild(canvas);
            }else if(address_type === 'dropoff'){
                let proof_pictures = [];
                data.name = order_details.dropoff_address[order_address_index].meetup_points[meetup_point_index].order_proof.name;
                for await (let item of order_details.dropoff_address[order_address_index].meetup_points[meetup_point_index].order_proof.attachments){
                    if(item.context === 'order-proof-signature'){
                        data.signature = item.file_name_url;
                    }else if(item.context === 'order-proof-image'){
                        proof_pictures.push({
                            image: item.file_name_url,
                            name: item.file_name
                        });
                    }
                }
                data.pictures = proof_pictures;
                setProofDetails(data);

                const canvas = document.createElement("canvas");
                JsBarcode(canvas, order_details.dropoff_address[order_address_index].meetup_points[meetup_point_index].barcode);
                barcodeDivRef.current.appendChild(canvas);
            }
        }
    }

    // const handleSave = () => {
    //     setSavedSignature(signaturePad.toDataURL());
    // };

    const handleClear = () => {
        signaturePad.clear();
    };

    const handleGoBack = (addressType: any) => {
        removeAllPhotos();
        props.proofView(addressType);
    }

    const handleSaveProof = async () => {
        if(recipientName === '' || recipientName === ' '){
            setToastMessage(`Person's name is required`);
            setShowToast(true);
            return;
        }else if(signaturePad._isEmpty){
            setToastMessage(`Signature is required`);
            setShowToast(true);
            return;
        }else if(courierProofImages.length <= 0){
            setToastMessage(`Atleast one proof picture is required`);
            setShowToast(true);
            return;
        }else if(barcodeText === 'false'){
            setToastMessage(`Please scan the barcode`);
            setShowToast(true);
            return;
        }

        const signature_response = await fetch(signaturePad.toDataURL());
        const signature_blob = await signature_response.blob();

        setShowLoading(true);
        let dtlc = cookies.get('dtlc');
        if(dtlc && dtlc.token){
            const formData = new FormData();
            formData.append('order_id', orderDetails.id);
            formData.append('name', recipientName);
            formData.append('signature', signature_blob);
            formData.append('barcode', barcodeText.toString());

            for await(let item of courierProofImages){
                const response = await fetch(item.data);
                const blob = await response.blob();
                formData.append('proof_attachments[]', blob, item.name);
            }

            if(typeof(props.orderAddressId) === 'number'){
                formData.append('order_meetup_point_id', orderAddressId);
                const config = {
                    headers: {
                    'content-type': 'multipart/form-data',
                    "Access-Control-Allow-Origin": '*',
                    Authorization: `Bearer ${dtlc.token.token}`
                    }
                };
                axios
                .post(BASE_URL + 'drivers/order-proof', formData, config)
                .then(async (res: any) => {
                    if(res.data.status){
                        let dtlc;
                        if(cookies.get('dtlc')){
                            dtlc = cookies.get('dtlc');
                            postRequest('order/show',{
                                order_number: orderDetails.order_number
                            }, dtlc.token.token).then(data=>{
                                prepareOrderStructure(data.data).then(async (result: any)=>{
                                    await Preferences.set({
                                    key: 'api-data',
                                    value: JSON.stringify(result),
                                    });
                                    removeAllPhotos();
                                    handleGoBack('back');
                                }).catch((err: any)=>{
                                    console.log(err);
                                });
                            }).catch(err=>console.log(err));
                        }
                    }
                })
                .catch((error: any) => {
                    console.log(error);
                });
            }else{
                props.orderAddressId.forEach((item: any, index: any)=>{
                    // // Create a test FormData object
                    // var formData = new FormData();
                    // formData.append('key1', 'value1');
                    // formData.append('key2', 'value2');

                    // // Display the key/value pairs
                    // for (var pair of formData.entries()) {
                    // console.log(pair[0]+ ', ' + pair[1]); 
                    // }

                    formData.append('order_meetup_point_id', item);
                    handleMultipleProofUpload(formData, dtlc, index);
                });
            }
        }
    }
    const handleMultipleProofUpload = (formData: any, dtlc: any, index: any) => {
        const config = {
            headers: {
            'content-type': 'multipart/form-data',
            "Access-Control-Allow-Origin": '*',
            Authorization: `Bearer ${dtlc.token.token}`
            }
        };
        axios
        .post(BASE_URL + 'drivers/order-proof', formData, config)
        .then(async (res: any) => {
            if(res.data.status && props.orderAddressId.length - 1 === index){
                let dtlc;
                if(cookies.get('dtlc')){
                    dtlc = cookies.get('dtlc');
                    postRequest('order/show',{
                        order_number: orderDetails.order_number
                    }, dtlc.token.token).then(data=>{
                        prepareOrderStructure(data.data).then(async (result: any)=>{
                            await Preferences.set({
                                key: 'api-data',
                                value: JSON.stringify(result),
                            });
                            removeAllPhotos();
                            handleGoBack('back');
                        }).catch((err: any)=>{
                        console.log(err);
                        });
                    }).catch(err=>console.log(err));
                }
            }
        })
        .catch((error: any) => {
            console.log(error);
        });
    }

    const removeAllPhotos = () => {
        Filesystem.readdir({
            directory: Directory.Data,
            path: IMAGE_DIR
        }).then(async result => {
            if(result.files.length > 0){
                for await (let file of result.files){
                    Filesystem.deleteFile({
                        directory: Directory.Data,
                        path: IMAGE_DIR+'/'+file.name
                    });
                }
            }
        }, async err => {
            console.log('err: ', err);
        }).then(_ => {
        })
    }

    const loadFiles = async () => {
        console.log('inside load files function');
        Filesystem.readdir({
          directory: Directory.Data,
          path: IMAGE_DIR
        }).then(result => {
          if(result.files.length <= 0){
            setShowLoading(false);
          }
          loadFileData(result.files);
          readSignatureDirectory();
        }, async err => {
          console.log('hello I am here');
          console.log('err: ', err);
          await Filesystem.mkdir({
            directory: Directory.Data,
            path: IMAGE_DIR
          }).then(data =>{
            console.log(data);
          } );
          readSignatureDirectory();
        }).then(_ => {
          setShowLoading(false);
        })
    }

    const readSignatureDirectory = async () => {
        Filesystem.readdir({
            directory: Directory.Data,
            path: SIGNATURE_DIR
          }).then(result => {
            
          }, async err => {
            console.log('err: ', err);
            await Filesystem.mkdir({
              directory: Directory.Data,
              path: SIGNATURE_DIR
            })
          }).then(_ => {
            setShowLoading(false);
          })
    }

    const loadFileData = async (fileNames: any) => {
        let courier_images = [];
        for await (let f of fileNames) {
          const filePath = `${IMAGE_DIR}/${f.name}`;
          const readFile = await Filesystem.readFile({
            directory: Directory.Data,
            path: filePath
          });
    
          let courier_image = {
            name: f.name,
            path: filePath,
            data: `data:image/png;base64,${readFile.data}`
          }
          courier_images.push(courier_image);
        }
        setCourierProofImages(courier_images);
        setShowLoading(false);
    }

    const handleCamera = () => {
        // Camera.checkPermissions().then((res)=>{
        //     res.photos
        // }).catch(err=>console.log(err));
        // return;
        Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Uri,
          source: CameraSource.Camera,
        })
        .then((cameraResult: any) => {
          // image.webPath will contain a path that can be set as an image src.
          // You can access the original file using image.path, which can be
          // passed to the Filesystem API to read the raw data of the image,
          // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
            saveImage(cameraResult);
        })
        .catch((er: any) => {
          console.log(er);
        });
    }

    const saveImage = async (photo:any) => {
        const base64Data = await readAsBase64(photo);
        const fileName = new Date().getTime() + '.png';
        const savedFile = await Filesystem.writeFile({
          directory: Directory.Data,
          path: `${IMAGE_DIR}/${fileName}`,
          data: base64Data
        }).then(() => {
          loadFiles();
        });
    }
    const saveSignature = async (base64Data: any) => {
        const fileName = 'signature.png';
        const savedFile = await Filesystem.writeFile({
            directory: Directory.Data,
            path: `${SIGNATURE_DIR}/${fileName}`,
            data: base64Data
        }).then(() => {
            
        });
    }

    const readAsBase64 = async (photo: any) => {
        if (Capacitor.platform !== "web") {
          // Read the file into base64 format
          const file = await Filesystem.readFile({
            path: photo.path
          });
          return file.data;
        }
        else {
          // Fetch the photo, read as a blob, then convert to base64 format
          const response = await fetch(photo.webPath);
          const blob = await response.blob();
          return await convertBlobToBase64(blob) as string;
        }
    }

    const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

    const handleCourierImageRemove = async (path: any) => {
        await Filesystem.deleteFile({
            directory: Directory.Data,
            path: path
          });
        loadFiles();
    }

    const handleImageClick = (type: any, index: any) =>{
        setModalImageDetails({
            type: type,
            index: index
        });
        setShowModal(true);
    }

    const openScanner = async () => {
        await AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.CAMERA).then(async (result)=>{
            console.log('Has permission?',result.hasPermission);
            if(!result.hasPermission){
                AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.CAMERA);
            }else{
                const data = await BarcodeScanner.scan({
                    showFlipCameraButton : true, // iOS and Android
                    showTorchButton : true,
                    prompt : "Place a barcode or qr code inside the scan area",
                    resultDisplayDuration: 0
                });
                if(!data.cancelled){
                    setBarcodeText(data.text);
                    const canvas = document.createElement("canvas");
                    JsBarcode(canvas, data.text);
                    barcodeDivRef.current.appendChild(canvas);
                }
            }
        }, (err)=> {
            console.log(err);
        });
    };

    return (
        <>
            <IonHeader className="header_top ion-no-border">
            <div className="header_toolbar">
                <IonButton
                className="header_back_button"
                onClick={()=> handleGoBack('back')}
                >
                <IonIcon icon={arrowBackOutline} size="large" />
                </IonButton>
            </div>
            </IonHeader>
            <IonModal isOpen={showModal} className="proof-image-modal">
              <IonContent>
                <div className="modal-content">
                  <div className="modal-header">
                    <h1
                      onClick={() => setShowModal(false)}
                      className="modal-close"
                    >
                      <IonIcon icon={closeCircle} />
                    </h1>
                  </div>
                  <div className="modal-body">
                        <IonImg
                            src={modalImageDetails.type === 'proof'? 
                                proofDetails.pictures[modalImageDetails.index].image
                                :
                                modalImageDetails.type === 'upload' && courierProofImages[modalImageDetails.index]?
                                    courierProofImages[modalImageDetails.index].data
                                :
                                ''
                            }
                            className="modal-image"
                        />
                    <div className="modal-actions">
                      <h5 className="caption-img-heading">{modalImageDetails.type === 'proof'?
                            proofDetails.pictures[modalImageDetails.index].name
                            :
                            modalImageDetails.type === 'upload' && courierProofImages[modalImageDetails.index]?
                                courierProofImages[modalImageDetails.index].name
                            :
                            ''
                        }</h5>
                    </div>
                  </div>
                </div>
              </IonContent>
            </IonModal>
            <div className='order-receiving-wrapper'>
                <div className='inner-wrapper'>
                <div className='body'>
                    <h3>Courier Proof</h3>
                    <h4>Person's Name</h4>
                    {readOnly?
                        <p>{proofDetails.name}</p>
                        :
                        <IonInput placeholder='Enter name' onIonChange={(e)=>setRecipientName(e.detail.value)}></IonInput>
                    }
                    
                    <IonRow>
                        <IonCol size='6' className='col-sign-text'><h4>Signature</h4></IonCol>
                        <IonCol size='6' className='col-clear'>
                            {readOnly?
                                ''
                                :
                                <IonButton onClick={()=> handleClear()}>Clear</IonButton>
                            }
                        </IonCol>
                    </IonRow>
                    {readOnly?
                        <IonImg src={proofDetails.signature} />
                        :
                        <canvas className="signature-canvas" ref={canvasRef} width={canvasWidth} height='250'></canvas>
                    }
                    
                    {/* <div className="card">
                        
                    </div> */}
                    <h4>Picture</h4>
                    <div className="avatar-cards">
                        <IonRow>
                            {readOnly?
                                proofDetails.pictures.map((item: any, index: any)=>{
                                    return(
                                        <IonCol key={index} size='4'>
                                            <div>
                                            <IonAvatar onClick={() => handleImageClick('proof', index)}>
                                                <IonImg src={item.image}/>
                                            </IonAvatar>
                                            <p>{item.name}</p>
                                            </div>
                                        </IonCol>
                                    )
                                })
                                :
                                <>
                                    {courierProofImages.map((item: any, index: any)=>{
                                        return(
                                            <IonCol key={index} size='4'>
                                                <div>
                                                <IonIcon class='remove-icon' src={removeOutline} onClick={() => handleCourierImageRemove(item.path)}/>
                                                <IonAvatar onClick={() => handleImageClick('upload', index)}>
                                                    <IonImg src={item.data}/>
                                                </IonAvatar>
                                                <p>{item.name}</p>
                                                </div>
                                            </IonCol>
                                        )
                                    })}
                                    {courierProofImages.length <= 3?
                                        <IonCol size='4'>
                                            <div>
                                                <span>
                                                    <IonIcon src={addCircleOutline} onClick={()=>handleCamera()}/>
                                                </span>
                                            </div>
                                        </IonCol>
                                        :
                                        ''
                                    }
                                </>
                            }
                        </IonRow>
                    </div>
                    <h4>Barcode</h4>
                    {barcodeText === 'false'? 
                        <IonButton className='ion-margin-bottom btn-tertiary' onClick={()=>{openScanner()}}>Scan Barcode</IonButton>
                        :
                        <div ref={barcodeDivRef}></div>
                    }
                    {readOnly?
                        ""
                        :
                        <IonButton expand='block' onClick={()=> handleSaveProof()}>Save Proof</IonButton>
                    }
                </div>
                </div>
            </div>
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={"Please wait..."}
            />
            <IonToast
                isOpen={showToast}
                position="bottom"
                onDidDismiss={() => setShowToast(false)}
                color={"danger"}
                message={toastMessage}
                duration={2500}
            />
        </>
    )
}

export default Order_Receiving

