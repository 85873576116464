import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
  isPlatform,
  useIonViewWillEnter,
} from "@ionic/react";
import {
  arrowForward,
  eyeOutline,
  lockClosedOutline,
  mailOutline,
} from "ionicons/icons";
import React, { useState, useRef, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import "../assets/scss/Auth.scss";
import { postRequest } from "../ApiServices";
import { device } from "../Layout";
import { is_login } from "../functions";
import { Device } from "@capacitor/device";
import {
  PushNotificationSchema,
  PushNotifications,
  Token,
  ActionPerformed,
} from "@capacitor/push-notifications";
import {
  LocalNotifications,
  LocalNotificationSchema,
} from "@capacitor/local-notifications";
import { Capacitor } from "@capacitor/core";
import { validater } from "../validate";
import { Preferences } from "@capacitor/preferences";

const Login_Component: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [deviceToken, setDeviceToken] = useState<any>('');

  const history = useHistory();
  const cookies = new Cookies();

  interface userDetail {
    email: string;
    password: string;
  }
  const [userDetail, setuserDetail] = useState<any>({
    email: "",
    password: "",
    device_token: "",
    device_os: "",
    device_platform: ""
  });

  const itemRef = useRef<any>([]);
  const fieldsRef = useRef<any>([]);

  useIonViewWillEnter(async () => {
    await Preferences.remove({ key: 'dtlc-order-details'});
    // let resultt = is_login();
    // if (resultt?.is_logged_in) {
    //   history.push(resultt.redirect_to);
    // }
    if(isPlatform('desktop')){
      if(cookies.get('dtlc')){
        history.push('/customer_panel');
      }
    }else{
      deviceInfo();
      let dtlc = cookies.get('dtlc');
      if(dtlc){
        if(dtlc.user_data.role_id === 2){
          history.replace('/customer_panel');
        }else if(dtlc.user_data.role_id === 3){
          history.replace('/driver_panel');
        }else if(dtlc.user_data.role_id === 5){
          history.replace('/dispatch_panel');
        } 
      }
    }
  });

  const forgotPage = () => {
    if (isPlatform('desktop')) {
      window.location.replace(
        window.location.origin + "/desktop_forgot_password"
      );
      // history.replace('/desktop_customer_panel');
    } else {
      history.replace("/forgot");
    }
  };

  const itemHandler = async (type: string) => {
    for (let item in fieldsRef.current) {
      const { email, password } = userDetail;
      if (item === "email" && email === "") {
        fieldsRef.current[item].className =
          "sc-ion-label-ios-h sc-ion-label-ios-s ios md floating-label";
      } else if (item === "password" && password === "") {
        fieldsRef.current[item].className =
          "sc-ion-label-ios-h sc-ion-label-ios-s ios md floating-label";
      }
    }

    for (let item in itemRef.current) {
      if (item === "email") {
        itemRef.current[item].className = "";
      } else if (item === "password") {
        itemRef.current[item].className = "";
      }
    }
    if (type === "email") {
      itemRef.current[type].className = "item-shadow";
      fieldsRef.current[type].className =
        "sc-ion-label-ios-h sc-ion-label-ios-s ios md top-label";
    } else if (type === "password") {
      itemRef.current[type].className = "item-shadow";
      fieldsRef.current[type].className =
        "sc-ion-label-ios-h sc-ion-label-ios-s ios md top-label";
    }
  };

  const login = async () => {
    setShowLoading(true);
    let data = {
      email: userDetail.email,
    };
    let result = validater(data);
    if(!result.status){
      setShowLoading(false);
      setAlertMessage(result.message);
      setShowToast1(true);
    }else{
      postRequest("auth/login", userDetail)
      .then(async (data) => {
        if (data.status) {
          setShowLoading(false);
          await cookies.set("dtlc", {
            token: data.token,
            user_data: data.user_data,
          });
          if(isPlatform('desktop')){
          // if(!Capacitor.isNative){
            history.push('/customer_panel');
            window.location.reload();
          }else{
            if(data.user_data.role_id === 2){
              history.replace('/customer_panel');
            }else if(data.user_data.role_id === 3){
              history.replace('/driver_panel');
            }else if(data.user_data.role_id === 5){
              history.replace('/dispatch_panel');
            }
          }
          // let resultt = is_login();
          // // await setResult(resultt?.is_logged_in);
          // if (resultt?.is_logged_in) {
          //   if(resultt && resultt.origin === 'mobile'){
          //     history.push(resultt.redirect_to);
          //   }else{
          //       window.location.replace(
          //           window.location.origin + '/' + resultt.redirect_to
          //       );
          //   }
          // }
        } else {
          setError(true);
          setShowLoading(false);
          setAlertMessage(data.message);
          setShowToast1(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  useEffect(()=>{
    if(Capacitor.platform !== "web"){
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== "granted") {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === "denied") {
              // showToastMsg("Push Notification permission denied");
            } else {
              // showToastMsg("Push Notification permission granted");
              register();
            }
          });
        } else {
          register();
        }
      });
    }
  }, [])

  const register = () => {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", (token: Token) => {
      //showToastMsg("Push registration success");

      let date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      cookies.set("dtlc-device-token", token, {
        path: "/",
        expires: date,
        sameSite: "lax",
      });
      setDeviceToken(token);
      let user_detail = userDetail;
      user_detail.device_token = token;
      setuserDetail(user_detail);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      console.log("Error on registration: " + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        let notificationObj: any = {
          notifications: [
            {
              title: notification.title,
              body: notification.body,
              id: 1,
            },
          ],
        };
        LocalNotifications.schedule(notificationObj);
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        history.push("/notifications");
        // if (cookies.get("dtlc")) {
        //   history.push("/notifications");
        // }
      }
    );

    LocalNotifications.addListener(
      "localNotificationActionPerformed",
      (notification: any) => {
        history.push("/notifications");
        // if (cookies.get("dtlc")) {
        // }
      }
    );
  };

  const deviceInfo = async () => {
    const info = await Device.getInfo();
    let user_detail = userDetail;
    user_detail.device_os = info.operatingSystem;
    user_detail.device_platform = info.platform;
    setuserDetail(user_detail);
  };

  return (
    <>
      <div className="auth-wrapper-inner">
        <h1>Login</h1>
        <p className="subTitle">Please sign in to continue.</p>
        <IonItem
          lines="none"
          ref={(el) => (itemRef.current["email"] = el)}
          onFocus={() => itemHandler("email")}
        >
          <IonIcon icon={mailOutline} size="small" />
          <div className="input-wrapper">
            <IonLabel
              ref={(el) => (fieldsRef.current["email"] = el)}
              className="floating-label"
            >
              EMAIL
            </IonLabel>
            <IonInput
              value={userDetail.email}
              type="text"
              onIonChange={(e) => {
                let user_data = userDetail;
                user_data.email = e.detail.value;
                setuserDetail(user_data);
              }}
            ></IonInput>
          </div>
        </IonItem>
        <IonItem
          lines="none"
          ref={(el) => (itemRef.current["password"] = el)}
          onFocus={() => itemHandler("password")}
        >
          <IonIcon icon={lockClosedOutline} size="small" />
          <div className="input-wrapper">
            <IonLabel
              ref={(el) => (fieldsRef.current["password"] = el)}
              className="floating-label"
            >
              PASSWORD
            </IonLabel>
            <IonInput
              value={userDetail.password}
              type="password"
              onIonChange={(e) => {
                let user_data = userDetail;
                user_data.password = e.detail.value;
                setuserDetail(user_data);
              }}
            ></IonInput>
          </div>
          <span onClick={forgotPage}>
            <h6>Forgot</h6>
          </span>
        </IonItem>
        <div className="justify-content-end">
          <IonButton shape="round" onClick={login}>
            Login
            <IonIcon icon={arrowForward} />
          </IonButton>
        </div>
      </div>
      <IonToast
        isOpen={showToast1}
        position="bottom"
        onDidDismiss={() => setShowToast1(false)}
        color={"danger"}
        message={alertMessage}
        duration={2000}
      />
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
    </>
  );
};

export default Login_Component;
