import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import { arrowForward, lockClosedOutline, mailOutline } from "ionicons/icons";
import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../assets/scss/Auth.scss";
import Login_Component from "../../components/Login_Component";
import Navbar from "../../components/Navbar";

const Desktop_Login: React.FC = () => {
   
  return (
    <IonPage>
      <IonContent>
        <Navbar/>
        <IonGrid className="desktop-auth-grid">
          <IonRow className="justify-content-center align-items-center">
            <IonCol size="12" sizeLg="5">
              <div className="desktop-auth-wrapper">
              <Login_Component/>
              </div>
            </IonCol>
            <IonCol size="12" sizeLg="5" className="ion-padding-top image-col">
              <div>
                <IonImg src={require("../../assets/images/image-1.webp")} />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Desktop_Login;
