import {
    IonButton,
    IonContent,
    IonItem,
    IonPage,
    IonToast,
    IonDatetime,
    useIonViewWillEnter,
    IonLabel,
  } from "@ionic/react";
import { BookingHeader } from "../components/BookingHeader";
import { useEffect, useState } from "react";
import "../assets/scss/Delivery_Details.scss";
import { Preferences } from "@capacitor/preferences";

interface ChildProps {
passState: any;
OrderDetails: any;
}
  
const Pickup_Meetup_Points: React.FC<ChildProps> = (props) => {

const [orderDetails, setOrderDetails] = useState<any>(null);
const [pickupAddresses, setPickupAddresses] = useState<any>([]);
const [dropoffAddresses, setDropoffAddresses] = useState<any>([]);
const [showToast, setShowToast] = useState(false);
const [alertMessage, setAlertMessage] = useState<string>("");

useEffect(()=>{
    abc();
},[]);

const handlePickupLocationDetails = async () => {
    let allow = true;
    for await (let address of pickupAddresses) {
        if(!address.meetup_points || address.meetup_points.length <= 0){
            setAlertMessage('Atleast one pickup meetup point is required');
            setShowToast(true);
            allow = false;
            break;
        }else{
            for await (let meetup_point of address.meetup_points){
                if(meetup_point.name === '' || meetup_point.meetup_at === ''){
                    setAlertMessage('Some field is missing');
                    setShowToast(true);
                    allow = false
                    break;
                }
            }
        }
    }
    for await (let address of dropoffAddresses) {
        if(!address.meetup_points || address.meetup_points.length <= 0){
            setAlertMessage('Atleast one dropoff meetup point is required');
            setShowToast(true);
            allow = false;
            break;
        }else{
            for await (let meetup_point of address.meetup_points){
                if(meetup_point.name === '' || meetup_point.meetup_at === ''){
                    setAlertMessage('Some field is missing');
                    setShowToast(true);
                    allow = false;
                    break;
                }
            }
        }
    }
    if(allow){
        let order_details = orderDetails;
        order_details.pickup_address = pickupAddresses;
        order_details.dropoff_address = dropoffAddresses;
        await Preferences.set({
            key: 'dtlc-order-details',
            value: JSON.stringify(order_details),
          });

        props.passState("second"); 
    }  
};

const abc = async () => {
    const { value } = await Preferences.get({ key: 'dtlc-order-details' });
    if (value) {
      let order_details = JSON.parse(value);
      setPickupAddresses(order_details.pickup_address);
      setDropoffAddresses(order_details.dropoff_address);
      setOrderDetails(order_details);
    }
}

useIonViewWillEnter(async ()=>{
    // const { value } = await Preferences.get({ key: 'dtlc-order-details' });
    // if (value) {
    //   let order_details = JSON.parse(value);
    //   setOrderDetails(order_details);
    // }
    // console.log('view enter');
});

const handleAddMeetupPoint = (type: any, index: any) => {
    if(type === 'pickup'){
        let pickup_addresses = pickupAddresses;
        if(pickup_addresses[index].meetup_points){
            pickup_addresses[index].meetup_points.push({
                name: '',
                meetup_at: formatAMPM(new Date()),
                order_proof: null
            });
        }else{
            pickup_addresses[index].meetup_points = [{
                name: '',
                meetup_at: formatAMPM(new Date()),
                order_proof: null
            }];
        }
        
        setPickupAddresses(handleSetState(pickup_addresses));
    }else if(type === 'dropoff'){
        let dropoff_addresses = dropoffAddresses;
        if(dropoff_addresses[index].meetup_points){
            dropoff_addresses[index].meetup_points.push({
                name: '',
                meetup_at: formatAMPM(new Date()),
                order_proof: null
            });
        }else{
            dropoff_addresses[index].meetup_points = [{
                name: '',
                meetup_at: formatAMPM(new Date()),
                order_proof: null
            }];
        }
        
        setDropoffAddresses(handleSetState(dropoff_addresses));
    }
}

const handleRemoveMeetupPoint = (type: any, meetup_point_index: any, address_index: any) => {
    if(type === 'pickup'){
        let pickup_addresses = pickupAddresses;
        pickup_addresses[address_index].meetup_points.splice(meetup_point_index, 1);
        
        setPickupAddresses(handleSetState(pickup_addresses));
    }else if(type === 'dropoff'){
        let dropoff_addresses = dropoffAddresses;
        dropoff_addresses[address_index].meetup_points.splice(meetup_point_index, 1);
        
        setDropoffAddresses(handleSetState(dropoff_addresses));
    }
}

const handleSetState = (data: any) => {
    let local_data = data;
    local_data = JSON.stringify(local_data);
    local_data = JSON.parse(local_data);
    return local_data;
}

const formatAMPM = (date: any) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

const handleSetMeetupPoint = (value: any, type: any, meetup_point_index: any, address_index: any) => {
    if(type === 'pickup'){
        let meetup_time = formatAMPM(new Date(value));

        let pickup_addresses = pickupAddresses;
        pickup_addresses[address_index].meetup_points[meetup_point_index].meetup_at = meetup_time;
        setPickupAddresses(handleSetState(pickup_addresses));
    }else if(type === 'dropoff'){
        let meetup_time = formatAMPM(new Date(value));

        let dropoff_addresses = dropoffAddresses;
        dropoff_addresses[address_index].meetup_points[meetup_point_index].meetup_at = meetup_time;
        setDropoffAddresses(handleSetState(dropoff_addresses));
    }
}

const handleSetMeetupName = (value: any, type: any, meetup_point_index: any, address_index: any) => {
    if(type === 'pickup'){
        let pickup_addresses = pickupAddresses;
        pickup_addresses[address_index].meetup_points[meetup_point_index].name = value;
        setPickupAddresses(handleSetState(pickup_addresses));
    }else if(type === 'dropoff'){
        let dropoff_addresses = dropoffAddresses;
        dropoff_addresses[address_index].meetup_points[meetup_point_index].name = value;
        setDropoffAddresses(handleSetState(dropoff_addresses));
    }
}
  
return (
        <IonContent fullscreen overflow-scroll="true" className="backdrop-effect linear_bg position-relative">
            <BookingHeader from=''/>
            <div>
                <div className="wrapper order_details">
                <h3 className=" mb_20 page_title scale-up-center">
                    Meetup Points
                </h3>
                
                <div>
                    {pickupAddresses.length > 0 && pickupAddresses.map((item: any, index: any)=>{
                        return(
                            <div key={index}>
                                <div className="d-flex">
                                    {pickupAddresses.length > 1?
                                        <h4>{index + 1}</h4>
                                        :
                                        ''
                                    }
                                    <h5 className="address-txt"><b>{item.address}</b></h5>
                                </div>
                                <div>
                                {item.meetup_points && item.meetup_points.map((item: any, meetup_point_index: any)=>{
                                        return(
                                            <div key={meetup_point_index} className="meetup-box">
                                                <div className="w_100 d-flex align-items-center mt_25 mb_10">
                                                    <IonItem className="delivery_input scale-up-center" lines="none">
                                                        <input
                                                            className="meetup-name-inp"
                                                            type="text"
                                                            placeholder="Meetup point name"
                                                            value={item.name}
                                                            onChange={(e)=>handleSetMeetupName(e.target.value, 'pickup', meetup_point_index, index)}
                                                        />
                                                    </IonItem>
                                                    <IonButton className="meetup-remove-btn" onClick={()=>{handleRemoveMeetupPoint('pickup', meetup_point_index, index)}}><span>-</span></IonButton>
                                                </div>
                                                <p className="mb_10"><b>Set meetup time</b></p>
                                                <IonDatetime onIonChange={(e)=>{handleSetMeetupPoint(e.detail.value, 'pickup', meetup_point_index, index)}} presentation="time" showDefaultTitle={true} className="mb_20"></IonDatetime>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                                
                                <div onClick={()=>handleAddMeetupPoint('pickup', index)} className="align-items-center mb-add-pick-meetup">
                                    <IonButton>+</IonButton> 
                                    <h4 className="ion-padding-start">add pickup meetup point</h4>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div>
                    {dropoffAddresses.length > 0 && dropoffAddresses.map((item: any, index: any)=>{
                        return(
                            <div key={index}>
                                <div className="d-flex">
                                    {dropoffAddresses.length > 1?
                                        <h4>{index + 1}</h4>
                                        :
                                        ''
                                    }
                                    <h5 className="address-txt"><b>{item.address}</b></h5>
                                </div>
                                {item.meetup_points?
                                    item.meetup_points.map((item: any, meetup_point_index: any)=>{
                                        return(
                                            <div key={meetup_point_index} className="meetup-box">
                                                <div className="w_100 d-flex align-items-center mt_25 mb_10">
                                                    <IonItem className=" delivery_input scale-up-center" lines="none">
                                                        <input
                                                            className="meetup-name-inp"
                                                            type="text"
                                                            placeholder="Meetup point name"
                                                            onChange={(e)=>handleSetMeetupName(e.target.value, 'dropoff', meetup_point_index, index)}
                                                        />
                                                    </IonItem>
                                                    <IonButton className="meetup-remove-btn" onClick={()=>{handleRemoveMeetupPoint('dropoff', meetup_point_index, index)}}><span>-</span></IonButton>
                                                </div>
                                                <p className="mb_10"><b>Set meetup time</b></p>
                                                <IonDatetime onIonChange={(e)=>handleSetMeetupPoint(e.detail.value, 'dropoff', meetup_point_index, index)} presentation="time" showDefaultTitle={true} className="mb_20"></IonDatetime>
                                            </div>
                                        )
                                    })
                                :
                                ''
                                }
                                <div onClick={()=>handleAddMeetupPoint('dropoff', index)} className="align-items-center mb-add-pick-meetup">
                                    <IonButton>+</IonButton> 
                                    <h4 className="ion-padding-start">add dropoff meetup point</h4>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <IonButton className="scale-up-center ion-margin-top"
                    expand="block"
                    onClick={() =>{
                        handlePickupLocationDetails();
                    }}>
                    Next
                </IonButton>
                </div>
            </div>
            <IonToast
                isOpen={showToast}
                position="bottom"
                onDidDismiss={() => setShowToast(false)}
                color={"danger"}
                message={alertMessage}
                duration={1500}
            />
        </IonContent>
)
};
  
export default Pickup_Meetup_Points;