import Cookies from 'universal-cookie';
import {BackgroundGeolocationPlugin} from "@capacitor-community/background-geolocation";
import { BackgroundMode } from 'capacitor-plugin-background-mode';
import { Capacitor, Plugins, registerPlugin } from "@capacitor/core";
import { postRequest } from './ApiServices';

const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>("BackgroundGeolocation");
const {Modals} = Plugins;
let watcherId: any = false;
const cookies = new Cookies();
let driver_corrdinates_arr: any = [];
let driver_location_interval: any;

export default ()=>{}

export const disableBackgroundMode = () => {
    BackgroundMode.disable();
    let driver_location_watch_id = cookies.get('dtlc-driver-location-watch-id');
    if(driver_location_watch_id){
        BackgroundGeolocation.removeWatcher({
            id: driver_location_watch_id
        });
    }
}

export const backgroundModeSettings = () => {
    enableBackgroundModeAndAddListener();
    // startGettingLocation();
    initiateDriverLocationSave();
}

const enableBackgroundModeAndAddListener = () => {
    BackgroundMode.enable();
    // BackgroundMode.setSettings();   //will be used to add silent feature in the app meaning app will be in the background without the notification
    BackgroundMode.addListener('appInBackground', () => {
        // console.log('app is now running in background');
        BackgroundMode.disableWebViewOptimizations();
        BackgroundMode.disableBatteryOptimizations();
        BackgroundMode.isIgnoringBatteryOptimizations().then(res=>{
            if(!res.isIgnoring){
                BackgroundMode.disableBatteryOptimizations();
            }
        }).catch(err=>console.log(err));
        // BackgroundMode.wakeUp();
        // BackgroundMode.unlock();
    });
}

export const startGettingLocation = () => {
    BackgroundMode.isEnabled().then((res: any) => {
        if(res.enabled){
            xyz();
        }else{
            enableBackgroundModeAndAddListener();
            xyz();
        }
    }).catch(err=>console.log(err));
}

const xyz = () =>{
    BackgroundMode.checkForegroundPermission().then(result=>{
        if(!result.enabled){
            BackgroundMode.requestForegroundPermission().then((result: any)=>{
            }).catch(err=>console.log(err));
            addBackgroundWatcher();
        }else{
            console.log('Foreground permission already granted');
            addBackgroundWatcher();
        }
    }).catch(err=>console.log(err));

    if(watcherId){
        BackgroundGeolocation.removeWatcher({
            id: watcherId
        });
    }
}

const addBackgroundWatcher = () => {
    BackgroundGeolocation.addWatcher(
        {
            // If the "backgroundMessage" option is defined, the watcher will
            // provide location updates whether the app is in the background or the
            // foreground. If it is not defined, location updates are only
            // guaranteed in the foreground. This is true on both platforms.
    
            // On Android, a notification must be shown to continue receiving
            // location updates in the background. This option specifies the text of
            // that notification.
            backgroundMessage: "Your movement is being tracked",
    
            // The title of the notification mentioned above. Defaults to "Using
            // your location".
            backgroundTitle: "Tracking You.",
    
            // Whether permissions should be requested from the user automatically,
            // if they are not already granted. Defaults to "true".
            requestPermissions: true,
    
            // If "true", stale locations may be delivered while the device
            // obtains a GPS fix. You are responsible for checking the "time"
            // property. If "false", locations are guaranteed to be up to date.
            // Defaults to "false".
            stale: false,
    
            // The minimum number of metres between subsequent locations. Defaults
            // to 0.
            distanceFilter: 0
        },
        (location: any, error: any) => {
            if (error) {
                if (error.code === "NOT_AUTHORIZED") {
                    Modals.confirm({
                        title: "Location Required",
                        message: (
                            "This app needs your location, " +
                            "but does not have permission.\n\n" +
                            "Open settings now?"
                        )
                    }).then(function ({value}: any) {
                        if (value) {
                            // It can be useful to direct the user to their device's
                            // settings when location permissions have been denied.
                            // The plugin provides 'openSettings' to do exactly
                            // this.
                            BackgroundGeolocation.openSettings();
                        }
                    });
                }
                return console.error(error);
            }
            collectDriverCoordinates(location);
        }
    ).then((watcher_id) => {
        // When a watcher is no longer needed, it should be removed by calling
        // 'removeWatcher' with an object containing its ID.
        watcherId = watcher_id;
        cookies.set('dtlc-driver-location-watch-id', watcher_id);
    });
}

export const collectDriverCoordinates = (data: any) => {
    driver_corrdinates_arr.push({
        latitude: data.latitude,
        longitude: data.longitude
    });
}

export const initiateDriverLocationSave = () => {
    driver_location_interval = setInterval(()=> {
        saveDriverLocation();
    }, 3000);
}

export const stopDriverLocationSave = () => {
    clearInterval(driver_location_interval);
}

const saveDriverLocation = async () => {
    // await Network.getStatus().then(result=>{
    //     console.log(result);
    // }).catch(err=>console.log(err));
    if(driver_corrdinates_arr.length > 0){
        let dtlc = cookies.get('dtlc');
        if(dtlc){
            let latest_coords = driver_corrdinates_arr[driver_corrdinates_arr.length - 1];
            postRequest('drivers/tracking-update', {
                latitude: latest_coords.latitude,
                longitude: latest_coords.longitude
            }, dtlc.token.token).then((result)=>{
                if(result.status){
                    driver_corrdinates_arr = [];
                }
            }).catch(err=>console.log(err));
        }
    }
}