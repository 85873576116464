import React, { useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import AppR from "./App";
import MobileApp from "./mobileApp";
import AuthApp from "./authApp";
import { isPlatform, useIonViewWillEnter } from '@ionic/react';
import { Plugins, Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { useHistory } from "react-router";
const { App } = Plugins;
export let device: string;

const Layout: React.FC = () => {
  let history = useHistory();
  let dtlc: any;
  const cookies = new Cookies();

  const removePreferences = async () => {
    await Preferences.remove({ key: 'dtlc-order-details' });
  }

  useEffect(() => {
    dtlc = cookies.get('dtlc');
    
    if (Capacitor.isNative) {
      const onPause = App.addListener('appStateChange', (state: any) => {
        if (!state.isActive) {
          console.log('App is paused');
          removePreferences();
          // Add your code to handle app pause here
        }
      });

      const onResume = App.addListener('appStateChange', (state: any) => {
        if (state.isActive) {
          console.log('App is resumed');
          // console.log(dtlc.user_data.role_id);
          if (dtlc && dtlc.user_data.role_id === 2) {
            history.push("/customer_panel");
          }
          // Add your code to handle app resume here
        }
      });

      // Clean up the event listeners when the component unmounts
      return () => {
        onPause.remove();
        onResume.remove();
      };
    }

  }, []);
  if (isPlatform('desktop')) {
    // if(!Capacitor.isNative){
    if (dtlc && dtlc.token) {
      return (<AppR is_logged_in={true} />);
    } else {
      return (<AppR is_logged_in={false} />);
    }
  } else {
    return (<MobileApp />);
  }
};
export default Layout;
