import {
  IonCol,
  IonGrid,
  IonRow,
  IonImg,
  IonPage,
  IonContent,
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
  useIonViewWillEnter,
  IonToast,
  IonModal,
  IonInput,
  useIonViewWillLeave,
} from "@ionic/react";

// import "../../assets/scss/Desktop_Postcode.scss";
import "../../assets/scss/InputForms.scss";
import "../../assets/scss/Desktop_Postcode.scss";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Cookies from "universal-cookie";
import { validater } from "../../validate";
import { Preferences } from "@capacitor/preferences";
import { GoogleMap } from '@capacitor/google-maps';

const Desktop_Postcode: React.FC = () => {
  const cookies = new Cookies();
  const history = useHistory();
  const pickupMapRef = useRef<Array<HTMLElement>>([]);
  const dropoffMapRef = useRef<Array<HTMLElement>>([]);
  let newMap: Array<GoogleMap> = [];
  let dropMap: Array<GoogleMap> = [];
  let markerId: any = [];

  const [isShown, setIsShown] = useState(false);
  const [pickupAddresses, setPickupAddresses] = useState<any>([]);
  const [dropoffAddresses, setDropoffAddresses] = useState<any>([]);
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [orderDetails, setOrderDetails] = useState<any>(null);

  let pickupGoogleAutocomplete: any = [];
  let dropoffGoogleAutocomplete: any = [];

  useEffect(() => {
    return () => {
      setIsShown(false);
      setPickupAddresses([]);
      setDropoffAddresses([]);
      setShowToast(false);
      setAlertMessage("");
      setOrderDetails(null);
    };
  }, []);



  const googlePlacesApiInitilizerOnInput = async () => {
    const pickupAddressInput = document.getElementsByClassName(
      "google-auto-complete-places-pickup-inp"
    ) as any;

    for (let i = 0; i < pickupAddressInput.length; i++) {
      let autocomplete = new google.maps.places.Autocomplete(pickupAddressInput[i], {
        componentRestrictions: { country: "uk" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
      });
      
      autocomplete.addListener('place_changed', pickupFillIn);
      pickupGoogleAutocomplete.push(autocomplete);
    }
    
    function pickupFillIn(this: any){
      let place = this.getPlace();
      handleAddressesDetails("pickup", place);
    }
    

    const dropoffAddressInput = document.getElementsByClassName(
      "google-auto-complete-places-dropoff-inp"
    ) as any;

    for (let i = 0; i < dropoffAddressInput.length; i++) {
      let autocomplete = new google.maps.places.Autocomplete(dropoffAddressInput[i], {
        componentRestrictions: { country: "uk" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
      });
      
      autocomplete.addListener('place_changed', dropoffFillIn);
      dropoffGoogleAutocomplete.push(autocomplete);
    }
    
    function dropoffFillIn(this: any) {
      var place = this.getPlace();
      handleAddressesDetails("dropoff", place);
    }
  }

  const handleAddressesDetails = async (type: any, googlePlaceData: any) => {
    let address = "";
    let postcode = "";
    
    for await (const component of googlePlaceData.address_components) {
      for await (const type of component.types) {
        if (type === "postal_code") {
          postcode = component.long_name;
        }
      }
      if(address !== ''){
        address += ', '+component.long_name;
      }else{
        address += component.long_name;
      }
    }
    if(type === 'pickup'){
      let pickup_addresses = pickupAddresses;
      pickup_addresses[cookies.get('dtlc-address-index')].address = address;
      pickup_addresses[cookies.get('dtlc-address-index')].district = googlePlaceData.address_components[1].long_name;
      pickup_addresses[cookies.get('dtlc-address-index')].postcode = postcode;
      pickup_addresses[cookies.get('dtlc-address-index')].latitude = googlePlaceData.geometry.location.lat();
      pickup_addresses[cookies.get('dtlc-address-index')].longitude = googlePlaceData.geometry.location.lng();
      setPickupAddresses(handleSetState(pickup_addresses));
      handleMapProcessing('pickup');
    }else if(type === 'dropoff'){
      let dropoff_addresses = dropoffAddresses;
      dropoff_addresses[cookies.get('dtlc-address-index')].address = address;
      dropoff_addresses[cookies.get('dtlc-address-index')].district = googlePlaceData.address_components[1].long_name;
      dropoff_addresses[cookies.get('dtlc-address-index')].postcode = postcode;
      dropoff_addresses[cookies.get('dtlc-address-index')].latitude = googlePlaceData.geometry.location.lat();
      dropoff_addresses[cookies.get('dtlc-address-index')].longitude = googlePlaceData.geometry.location.lng();
      setDropoffAddresses(handleSetState(dropoff_addresses));
      handleMapProcessing('dropoff');
    }
  };

  useIonViewWillLeave(()=>{
    setPickupAddresses([]);
    setDropoffAddresses([]);
  });

  useIonViewWillEnter(async () => {
    const { value } = await Preferences.get({ key: 'dtlc-order-details' });
    if (value) {
      let order_details = JSON.parse(value);
      setOrderDetails(order_details);
      if(order_details.pickup_address && order_details.dropoff_address){
        setPickupAddresses(handleSetState(order_details.pickup_address));
        setDropoffAddresses(handleSetState(order_details.dropoff_address));

        handleMapProcessing('pickup', handleSetState(order_details.pickup_address));
        handleMapProcessing('dropoff', handleSetState(order_details.dropoff_address));
      }
    }
    
    setTimeout(() => {
      googlePlacesApiInitilizerOnInput();
    }, 500);
  });

  const handleSetState = (data: any) => {
    let local_data = data;
    local_data = JSON.stringify(local_data);
    local_data = JSON.parse(local_data);
    return local_data;
}

  const handleAddNewAddress = (type: any) => {
    if(type === 'pickup'){
      let pickup_addresses = pickupAddresses;
      pickup_addresses.push({
        location_name: "",
        location_detail: "",
        notes: "",
        address: "",
        street: "",
        postcode: "",
        place_name: "",
        place_detail: "",
        latitude: 51.507621660098614,
        longitude: -0.1428042481715496,
        city: "",
        district: "",
        contact: {
          first_name: "",
          phone_no: "",
          email: "",
        },
      });
      setPickupAddresses(handleSetState(pickup_addresses));
    }else if(type === 'dropoff'){
      let dropoff_addresses = dropoffAddresses;
      dropoff_addresses.push({
        location_name: "",
        location_detail: "",
        notes: "",
        address: "",
        street: "",
        postcode: "",
        place_name: "",
        place_detail: "",
        latitude: 51.507621660098614,
        longitude: -0.1428042481715496,
        city: "",
        district: "",
        contact: {
          first_name: "",
          phone_no: "",
          email: "",
        },
      });
      setDropoffAddresses(handleSetState(dropoff_addresses));
    }
    
    setTimeout(async () => {
      googlePlacesApiInitilizerOnInput();
      handleMapProcessing(type);
    }, 500);
  }

  const handleMapProcessing = async (type: any, addressArr: any = "") => {
    if(type === 'pickup'){
      if(addressArr !== ""){
        for (let item in addressArr) {
          // if(!newMapIdxArr.includes(parseInt(item))){
            newMap[parseInt(item)] = await GoogleMap.create({
              id: 'pickup-map'+parseInt(item), // Unique identifier for this map instance
              element: pickupMapRef.current[parseInt(item)], // reference to the capacitor-google-map element
              apiKey: 'AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw', // Your Google Maps API Key
              config: {
                center: {
                  // The initial position to be rendered by the map
                  lat: parseFloat(addressArr[parseInt(item)].latitude),
                  lng: parseFloat(addressArr[parseInt(item)].longitude),
                },
                zoom: 15, // The initial zoom level to be rendered by the map
              },
            });
            markerId[parseInt(item)] = await newMap[parseInt(item)].addMarker({
              coordinate: {
                lat: parseFloat(addressArr[parseInt(item)].latitude),
                lng: parseFloat(addressArr[parseInt(item)].longitude),
              },
              draggable: true
            });
            newMap[parseInt(item)].setOnMarkerDragEndListener(async (res: any)=>{
              const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.latitude},${res.longitude}&key=AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw`);
              const jsonData = await response.json();
              let found: boolean = false;
              let route: any;
              let street_address: any;
              let post_code: any;
              jsonData.results.forEach((item: any)=>{
                if(item.types.includes("street_address")){
                  street_address = item.formatted_address;
                  post_code = item.address_components[item.address_components.length - 1].long_name;
                  found = true;
                }else if(item.types.includes("route") && !found){
                  route = item.formatted_address;
                  post_code = item.address_components[item.address_components.length - 1].long_name;
                }
              });
  
  
              let pickup_addresses = addressArr;
              pickup_addresses[parseInt(item)].postcode = post_code;
              pickup_addresses[parseInt(item)].address = found? street_address : route;
              pickup_addresses[parseInt(item)].latitude = res.latitude;
              pickup_addresses[parseInt(item)].longitude = res.longitude;
              setPickupAddresses(handleSetState(pickup_addresses));
            });
          //   newMapIdxArr.push(parseInt(item));
          // }
        }
      }else{
        for (let item in pickupAddresses) {
          // if(!newMapIdxArr.includes(parseInt(item))){
            newMap[parseInt(item)] = await GoogleMap.create({
              id: 'pickup-map'+parseInt(item), // Unique identifier for this map instance
              element: pickupMapRef.current[parseInt(item)], // reference to the capacitor-google-map element
              apiKey: 'AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw', // Your Google Maps API Key
              config: {
                center: {
                  // The initial position to be rendered by the map
                  lat: parseFloat(pickupAddresses[parseInt(item)].latitude),
                  lng: parseFloat(pickupAddresses[parseInt(item)].longitude),
                },
                zoom: 15, // The initial zoom level to be rendered by the map
              },
            });
            markerId[parseInt(item)] = await newMap[parseInt(item)].addMarker({
              coordinate: {
                lat: parseFloat(pickupAddresses[parseInt(item)].latitude),
                lng: parseFloat(pickupAddresses[parseInt(item)].longitude),
              },
              draggable: true
            });
            newMap[parseInt(item)].setOnMarkerDragEndListener(async (res: any)=>{
              const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.latitude},${res.longitude}&key=AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw`);
              const jsonData = await response.json();
              let found: boolean = false;
              let route: any;
              let street_address: any;
              let post_code: any;
              jsonData.results.forEach((item: any)=>{
                if(item.types.includes("street_address")){
                  street_address = item.formatted_address;
                  post_code = item.address_components[item.address_components.length - 1].long_name;
                  found = true;
                }else if(item.types.includes("route") && !found){
                  route = item.formatted_address;
                  post_code = item.address_components[item.address_components.length - 1].long_name;
                }
              });
  
  
              let pickup_addresses = pickupAddresses;
              pickup_addresses[parseInt(item)].postcode = post_code;
              pickup_addresses[parseInt(item)].address = found? street_address : route;
              pickup_addresses[parseInt(item)].latitude = res.latitude;
              pickup_addresses[parseInt(item)].longitude = res.longitude;
              setPickupAddresses(handleSetState(pickup_addresses));
            });
          //   newMapIdxArr.push(parseInt(item));
          // }
        }
      }
    }else if(type === 'dropoff'){
      if(addressArr !== ""){
        for (let item in addressArr) {
          dropMap[parseInt(item)] = await GoogleMap.create({
            id: 'dropoff-map'+parseInt(item), // Unique identifier for this map instance
            element: dropoffMapRef.current[parseInt(item)], // reference to the capacitor-google-map element
            apiKey: 'AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw', // Your Google Maps API Key
            config: {
              center: {
                // The initial position to be rendered by the map
                lat: parseFloat(addressArr[parseInt(item)].latitude),
                lng: parseFloat(addressArr[parseInt(item)].longitude),
              },
              zoom: 15, // The initial zoom level to be rendered by the map
            },
          });
          markerId[parseInt(item)] = await dropMap[parseInt(item)].addMarker({
            coordinate: {
              lat: parseFloat(addressArr[parseInt(item)].latitude),
              lng: parseFloat(addressArr[parseInt(item)].longitude),
            },
            draggable: true
          });
          dropMap[parseInt(item)].setOnMarkerDragEndListener(async (res: any)=>{
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.latitude},${res.longitude}&key=AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw`);
            const jsonData = await response.json();
            let found: boolean = false;
            let route: any;
            let street_address: any;
            let post_code: any;
            jsonData.results.forEach((item: any)=>{
              if(item.types.includes("street_address")){
                street_address = item.formatted_address;
                post_code = item.address_components[item.address_components.length - 1].long_name;
                found = true;
              }else if(item.types.includes("route") && !found){
                route = item.formatted_address;
                post_code = item.address_components[item.address_components.length - 1].long_name;
              }
            });
  
  
            let dropoff_addresses = addressArr;
            dropoff_addresses[parseInt(item)].postcode = post_code;
            dropoff_addresses[parseInt(item)].address = found? street_address : route;
            dropoff_addresses[parseInt(item)].latitude = res.latitude;
            dropoff_addresses[parseInt(item)].longitude = res.longitude;
            setDropoffAddresses(handleSetState(dropoff_addresses));
          });
        }
      }else{
        for (let item in dropoffAddresses) {
          dropMap[parseInt(item)] = await GoogleMap.create({
            id: 'dropoff-map'+parseInt(item), // Unique identifier for this map instance
            element: dropoffMapRef.current[parseInt(item)], // reference to the capacitor-google-map element
            apiKey: 'AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw', // Your Google Maps API Key
            config: {
              center: {
                // The initial position to be rendered by the map
                lat: parseFloat(dropoffAddresses[parseInt(item)].latitude),
                lng: parseFloat(dropoffAddresses[parseInt(item)].longitude),
              },
              zoom: 15, // The initial zoom level to be rendered by the map
            },
          });
          markerId[parseInt(item)] = await dropMap[parseInt(item)].addMarker({
            coordinate: {
              lat: parseFloat(dropoffAddresses[parseInt(item)].latitude),
              lng: parseFloat(dropoffAddresses[parseInt(item)].longitude),
            },
            draggable: true
          });
          dropMap[parseInt(item)].setOnMarkerDragEndListener(async (res: any)=>{
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.latitude},${res.longitude}&key=AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw`);
            const jsonData = await response.json();
            let found: boolean = false;
            let route: any;
            let street_address: any;
            let post_code: any;
            jsonData.results.forEach((item: any)=>{
              if(item.types.includes("street_address")){
                street_address = item.formatted_address;
                post_code = item.address_components[item.address_components.length - 1].long_name;
                found = true;
              }else if(item.types.includes("route") && !found){
                route = item.formatted_address;
                post_code = item.address_components[item.address_components.length - 1].long_name;
              }
            });
  
  
            let dropoff_addresses = dropoffAddresses;
            dropoff_addresses[parseInt(item)].postcode = post_code;
            dropoff_addresses[parseInt(item)].address = found? street_address : route;
            dropoff_addresses[parseInt(item)].latitude = res.latitude;
            dropoff_addresses[parseInt(item)].longitude = res.longitude;
            setDropoffAddresses(handleSetState(dropoff_addresses));
          });
        }
      }
    }
  }

  const handleRevomeAddress = (type: any, index: any) => {
    if(type === 'pickup'){
      let pickup_addresses = pickupAddresses;
      pickup_addresses.splice(index, 1);
      setPickupAddresses(handleSetState(pickup_addresses));
    }else if(type === 'dropoff'){
      let dropoff_addresses = dropoffAddresses;
      dropoff_addresses.splice(index, 1);
      setDropoffAddresses(handleSetState(dropoff_addresses));
    }
  }

  const passState = () => {
    setIsShown(!isShown);
  };

  const handleNext = async () => {
    let allowed = true;
    if(pickupAddresses.length <= 0){
      setAlertMessage('Please add atleast one pickup address');
      setShowToast(true);
      return;
    }
    if(dropoffAddresses.length <= 0){
      setAlertMessage('Please add atleast one dropoff address');
      setShowToast(true);
      return;
    }
    for await (let pickup_address of pickupAddresses) {
      if(pickup_address.address === '' || pickup_address.postcode === '' || pickup_address.latitude === '' || pickup_address.longitude === ''){
        handleSetToast('Some field is missing');
        allowed = false;
        break;
      }
    }
    for await (let dropoff_address of dropoffAddresses) {
      if(dropoff_address.address === '' || dropoff_address.postcode === '' || dropoff_address.latitude === '' || dropoff_address.longitude === ''){
        handleSetToast('Some field is missing');
        allowed = false;
        break;
      }
    }
    if(allowed){
      let order_details = orderDetails;
      order_details.pickup_address = pickupAddresses;
      order_details.dropoff_address = dropoffAddresses;

      await Preferences.set({
        key: 'dtlc-order-details',
        value: JSON.stringify(order_details),
      }).then((res)=>{
        history.push('/meetup-points');
      });
    }
  };

  const handleSetToast = (message: any) => {
    setAlertMessage(message);
    setShowToast(true);
    return;
  }
  

  return (
    <IonPage>
      <IonContent>

        <div className="desktop-input desktop-wrapper">
          <IonGrid>
            <IonRow>
              <Sidebar passState={passState} />
              <IonCol
                size="12"
                sizeLg="9.5"
                className={isShown ? "rightbar rightbar-none" : "rightbar"}
              >
                <div className="inner-rightbar">
                  <div className=" input_rightbar_content">
                    <IonGrid>
                      <IonRow className="input_card">
                        <IonCol size="12" sizeMd="3" className="input-content">
                          <div className="ion-icon">
                            <IonImg
                              src={require("../../assets/images/postcodes.png")}
                            />
                          </div>
                          <IonIcon />
                        </IonCol>
                        <IonCol size="12" sizeMd="9" className="contact-form">
                        <div>
                          <div className="order_details plr-20">
                            <div>
                              <h3 className="page_title scale-up-center ">
                                Enter details for your parcel’s journey
                              </h3>
                              
                                {pickupAddresses.map((item: any, index: any)=>{
                                  return(
                                    <div key={index} className="addr-bx mt_25">
                                      <div className="w_100 d-flex align-items-center">
                                        {pickupAddresses.length > 1?
                                          <span className={Object.keys(item).length > 0? 'previous_input' : 'current_input'}>
                                            {index + 1}
                                          </span>
                                          :
                                          ''
                                        }
                                        <IonItem
                                          className=" delivery_input scale-up-center"
                                          lines="none"
                                        >
                                          <input
                                            className="html_input google-auto-complete-places-pickup-inp"
                                            type="text"
                                            placeholder="Pickup address"
                                            id={`google-auto-complete-places-${index}`}
                                            value={item.address}
                                            onChange={(e) => {
                                              let pickup_addresses = pickupAddresses;
                                              pickup_addresses[index].address = e.target.value;
                                              setPickupAddresses(handleSetState(pickup_addresses));
                                              cookies.set('dtlc-address-index', index);
                                            }}
                                          />
                                        </IonItem>
                                        <IonButton onClick={()=> handleRevomeAddress('pickup', index)}>-</IonButton>
                                      </div>
                                      <IonInput placeholder="Postcode" className="addr-detail-inp" value={item.postcode} onIonChange={(e) => {
                                        let pickup_addresses = pickupAddresses;
                                        pickup_addresses[index].postcode = e.detail.value;
                                        setPickupAddresses(handleSetState(pickup_addresses));
                                      }}></IonInput>
                                      <capacitor-google-map ref={(el: any) => pickupMapRef.current[index] = el} style={{
                                          display: 'inline-block',
                                          width: '100%',
                                      }}></capacitor-google-map>





                                      <IonInput style={{display: 'none'}} placeholder="District" className="addr-detail-inp" value={item.district} onIonChange={(e) => {
                                        let pickup_addresses = pickupAddresses;
                                        pickup_addresses[index].district = e.detail.value;
                                        setPickupAddresses(handleSetState(pickup_addresses));
                                      }}></IonInput>
                                      <IonInput style={{display: 'none'}} placeholder="Latitude" className="addr-detail-inp" value={item.latitude} onIonChange={(e) => {
                                        let pickup_addresses = pickupAddresses;
                                        pickup_addresses[index].latitude = e.detail.value;
                                        setPickupAddresses(handleSetState(pickup_addresses));
                                      }}></IonInput>
                                      <IonInput style={{display: 'none'}} placeholder="Longitude" className="addr-detail-inp" value={item.longitude} onIonChange={(e) => {
                                        let pickup_addresses = pickupAddresses;
                                        pickup_addresses[index].longitude = e.detail.value;
                                        setPickupAddresses(handleSetState(pickup_addresses));
                                      }}></IonInput>
                                    </div>
                                  )
                                })}
                              <div onClick={() => handleAddNewAddress('pickup')} className="align-items-center ion-margin-top mb_20">
                                <IonButton>+</IonButton> 
                                <h4 className="ion-padding-start">Add new pickup address</h4>
                              </div>

                              {/* ////////////////////////////////////////////////////////////////////////////////// */}
                              {/* following is the dropoff address fields */}
                              {/* ////////////////////////////////////////////////////////////////////////////////// */}
                              {dropoffAddresses.map((item: any, index: any)=>{
                                  return(
                                    <div key={index} className="addr-bx mt_25">
                                      <div className="w_100 d-flex align-items-center">
                                        {dropoffAddresses.length > 1?
                                          <span className={Object.keys(item).length > 0? 'previous_input' : 'current_input'}>
                                            {index + 1}
                                          </span>
                                          :
                                          ''
                                        }
                                        <IonItem
                                          className=" delivery_input scale-up-center"
                                          lines="none"
                                        >
                                          <input
                                            className="html_input google-auto-complete-places-dropoff-inp"
                                            type="text"
                                            placeholder="Dropoff address"
                                            id={`google-auto-complete-places-${index}`}
                                            value={item.address}
                                            onChange={(e) => {
                                              let dropoff_addresses = dropoffAddresses;
                                              dropoff_addresses[index].address = e.target.value;
                                              setDropoffAddresses(handleSetState(dropoff_addresses));
                                              cookies.set('dtlc-address-index', index);
                                            }}
                                          />
                                        </IonItem>
                                        <IonButton onClick={()=> handleRevomeAddress('dropoff', index)}>-</IonButton>
                                      </div>
                                      <IonInput placeholder="Postcode" className="addr-detail-inp" value={item.postcode} onIonChange={(e) => {
                                        let dropoff_addresses = dropoffAddresses;
                                        dropoff_addresses[index].postcode = e.detail.value;
                                        setDropoffAddresses(handleSetState(dropoff_addresses));
                                      }}></IonInput>
                                      <capacitor-google-map ref={(el: any) => dropoffMapRef.current[index] = el} style={{
                                          display: 'inline-block',
                                          width: '100%',
                                      }}></capacitor-google-map>
                                      <IonInput style={{display: 'none'}} placeholder="District" className="addr-detail-inp" value={item.district} onIonChange={(e) => {
                                        let dropoff_addresses = dropoffAddresses;
                                        dropoff_addresses[index].district = e.detail.value;
                                        setDropoffAddresses(handleSetState(dropoff_addresses));
                                      }}></IonInput>
                                      <IonInput style={{display: 'none'}} placeholder="Latitude" className="addr-detail-inp" value={item.latitude} onIonChange={(e) => {
                                        let dropoff_addresses = dropoffAddresses;
                                        dropoff_addresses[index].latitude = e.detail.value;
                                        setDropoffAddresses(handleSetState(dropoff_addresses));
                                      }}></IonInput>
                                      <IonInput style={{display: 'none'}} placeholder="Longitude" className="addr-detail-inp" value={item.longitude} onIonChange={(e) => {
                                        let dropoff_addresses = dropoffAddresses;
                                        dropoff_addresses[index].longitude = e.detail.value;
                                        setDropoffAddresses(handleSetState(dropoff_addresses));
                                      }}></IonInput>
                                    </div>
                                  )
                                })}
                              <div onClick={() => handleAddNewAddress('dropoff')} className="align-items-center ion-margin-top mb_10">
                                <IonButton>+</IonButton> 
                                <h4 className="ion-padding-start">Add new dropoff address</h4>
                              </div>

                              <IonButton expand="block"
                                className="scale-up-center ion-margin-top"
                                onClick={handleNext}
                              >
                                Next
                              </IonButton>
                            </div>
                          </div>
                        </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(false)}
        color="danger"
        message={alertMessage}
        duration={1500}
      />
    </IonPage>
  );
};
export default Desktop_Postcode;
