import {
  IonCol,
  IonGrid,
  IonRow,
  IonImg,
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonItem,
  IonInput,
  useIonViewWillEnter,
  IonToast,
} from "@ionic/react";
// import "../../assets/scss/Desktop_Dropoff_Contact.scss";
import "../../assets/scss/InputForms.scss";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Cookies from "universal-cookie";
import { validater } from '../../validate';
import { Preferences } from "@capacitor/preferences";
const Desktop_Dropoff_Contact: React.FC = () => {
  const cookies = new Cookies();
  const history = useHistory();

  const [isShown, setIsShown] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [orderDetails, setOrderDetails] = useState<any>();

  useIonViewWillEnter(async () => {
    const { value } = await Preferences.get({ key: 'dtlc-order-details' });
    if (value) {
      let order_details = JSON.parse(value);
      setOrderDetails(order_details);
    }
  });

  const handledropoffContactDetails = async () => {
    let stop = false;
    let order_details = orderDetails;
    for (const index in order_details.dropoff_address){
      let result = validater({
        name: order_details.dropoff_address[index].contact.first_name,
        phone_no: order_details.dropoff_address[index].contact.phone_no,
        email: order_details.dropoff_address[index].contact.email
      });
      if(!result.status){
        stop = true;
        setAlertMessage(`Dropoff contact ${parseInt(index) + 1}: ${result.message}`);
        setShowToast(true);
        break;
      }
    }
    if(stop){
      return;
    }else{
      await Preferences.set({
        key: 'dtlc-order-details',
        value: JSON.stringify(orderDetails),
      });
      history.push("/booking-type");
    }
  };

  const handleOrderContactChange = async (value: any, index: any, type: any) => {
    let order_details = orderDetails;

    if(type === 'name'){
      order_details.dropoff_address[index].contact.first_name = value;
      setOrderDetails(order_details);
    }else if(type === 'phone'){
      order_details.dropoff_address[index].contact.phone_no = value;
      setOrderDetails(order_details);
    }else if(type === 'email'){
      order_details.dropoff_address[index].contact.email = value;
      setOrderDetails(order_details);
    }
    
    await Preferences.set({
      key: 'dtlc-order-details',
      value: JSON.stringify(order_details),
    });
    // cookies.set("dtlc-order-details", order_details);
  }

  const passState = () => {
    setIsShown(!isShown);
  };

  return (
    <IonPage>
      <IonContent>
        <div className="desktop-input desktop-wrapper">
          <IonGrid>
            <IonRow>
              <Sidebar passState={passState} />
              <IonCol
                size="12"
                sizeLg="9.5"
                className={isShown ? "rightbar rightbar-none" : "rightbar"}
              >
                <div className="inner-rightbar">
                  <div className=" input_rightbar_content">
                    <IonGrid>
                      <IonRow className="input_card">
                        <IonCol size="12" sizeMd="3" className="input-content">
                          <div className="ion-icon">
                            <IonImg
                              src={require("../../assets/images/dropOff-contact-detail.png")}
                            />
                          </div>
                          <IonIcon />
                        </IonCol>
                        <IonCol size="12" sizeMd="9" className="contact-form">
                          <div>
                            <div className="wrapper order_details">
                              <h3 className="mb_20 page_title scale-up-center ">Who should we contact for the drop-off?</h3>
                              
                              {orderDetails && orderDetails.dropoff_address.map((item: any, index: any)=>{
                                return(
                                  <div key={index} className="address-detail-bx">
                                    <div className="address-detail-outer">
                                      {orderDetails.pickup_address.length > 1? 
                                        <span className={'previous_input'}>
                                          {index + 1}
                                        </span>
                                          : 
                                        <IonImg className="loc-img" src={require('../../assets/images/contact-us.png')} />
                                      }
                                      <h5 className="mb_0 loc-addr">{item.address}</h5>
                                    </div>
                                    <div className="mb_10 w_100">
                                      <IonItem className="delivery_input scale-up-center " lines="none">
                                        <IonInput placeholder="Name" type="text"
                                          value={item.contact.first_name}
                                          onIonChange={(e) => handleOrderContactChange(e.target.value, index, 'name')} />
                                      </IonItem>
                                    </div>
                                    <div className="mb_10 w_100">
                                      <IonItem className="delivery_input scale-up-center " lines="none">
                                        <IonInput placeholder="+44 7911 123456" type="tel"
                                          value={item.contact.phone_no}
                                          onIonChange={(e) => handleOrderContactChange(e.target.value, index, 'phone')} />
                                      </IonItem>
                                    </div>
                                    <div className="mb_10 w_100">
                                      <IonItem className="delivery_input scale-up-center " lines="none">
                                        <IonInput placeholder="Email address" type="text"
                                          value={item.contact.email}
                                          onIonChange={(e) => handleOrderContactChange(e.target.value, index, 'email')} />
                                      </IonItem>
                                    </div>
                                  </div>
                                )
                              })
                              }

                              <IonButton expand="block" className="scale-up-center ion-margin-top" onClick={() => handledropoffContactDetails()}>Next</IonButton>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(false)}
        color={"danger"}
        message={alertMessage}
        duration={1500}
      />
    </IonPage>
  );
};
export default Desktop_Dropoff_Contact;
