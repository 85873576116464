import { IonAlert, IonAvatar, IonButton, IonContent, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPage, IonSelect, IonSelectOption, IonToast, useIonViewWillEnter } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import {
  Camera, CameraResultType, CameraSource, CameraDirection
} from "@capacitor/camera";
import { AppHeader } from '../components/AppHeader'
import { api_url } from "../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router';
import { loadFlags } from "../constants";
import countryList from "../countries";
import '../assets/scss/User_Profile.scss';
import { postRequest } from '../ApiServices';
import { getCookieData } from '../functions';
import { validater } from '../validate';

const User_Information: React.FC = () => {
  const [userData, setUserData] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    phone_code: ""
  });
  var [image, setImage] = useState<any>("");
  const [selectedImage, setSelectedImage] = useState<any>();
  const [showAlert, setShowAlert] = useState(false);
  const [countryFlag, setCountryFlag] = useState<number>(0);
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [cookieData, setCookieData] = useState<any>(null);
  const options = {
    cssClass: "select-dropdown",
  };
  const cookies = new Cookies();
  const history = useHistory();
  let token = cookies.get("token");

  useIonViewWillEnter(async () => {
    let cookie_data = getCookieData('dtlc');
    setCookieData(cookie_data);
    setShowLoading(true);

    await postRequest("account-setting-get", {}, cookie_data.token.token)
      .then(async (res) => {

        setShowLoading(false);

        if (res.status === true) {
          setSelectedImage(res.data.image_url);
          setUserData(res.data);
          setShowLoading(false);
          for (const country in countryList) {
            if (countryList[country].dialCode == res.data.phone_code) {
              const flag: number = parseInt(country);
              setCountryFlag(flag);
            }
          }
        } else {
          setError(true);
          setShowLoading(false);
          setAlertMessage(res.data.message);
          setShowToast(true);
        }

      })
      .catch((err) => {
        console.log({ err: err.message });
      });
  })

  const takePicture = async () => {
    const cameraResult = await Camera.getPhoto({
      quality: 70,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
    })
      .then(async (cameraResult) => {
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)

        let base64Image = "data:image/jpeg;base64," + cameraResult.base64String;
        setImage(base64Image);
        let dtlc = cookies.get('dtlc');

        if (dtlc && dtlc.token) {
          setShowLoading(true);
          postRequest('update-profile-picture', {
            image: base64Image
          }, dtlc.token.token).then((res: any) => {
            if (res.status) {
              dtlc.user_data.image_url = res.image_url;
              cookies.set('dtlc', dtlc);
              setShowLoading(false);
              setAlertMessage(res.message);
              setShowToast(true);
            }
          }).catch((err: any) => {
            console.log(err);
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleInputUpdate = async () => {
    let data = {
      first_name: userData.first_name,
      last_name: userData.last_name,
      phone_no: userData.phone_code + userData.phone_no,
      phone_code: userData.phone_code
    };

    let result = validater(data);
    if (result.status) {
      setError(false);
      let dtlc = cookies.get('dtlc');
      if (dtlc && dtlc.token) {
        setShowLoading(true);
        postRequest('account-setting-update', {
          first_name: data.first_name,
          last_name: data.last_name,
          phone_no: userData.phone_no,
          phone_code: data.phone_code
        }, dtlc.token.token).then((res) => {
          if (res.status) {
            setError(false);
          } else {
            setError(true);
          }
          setAlertMessage(res.message);
          setShowToast(true);
          setShowLoading(false);
        }).catch((err: any) => {
          console.log(err);
        });
      }
    } else {
      setError(true);
      setAlertMessage(result.message);
      setShowToast(true);
    }
  };

  return (
    <IonPage>
      <IonContent>
        <AppHeader from='' />
        <div className='user-info-wrapper'>
          <div className='imge_profile'>
            <IonAvatar>
              {/* <IonImg src={require('../assets/images/profile.jpg')} /> */}
              <IonImg src={
                image
                  ? image
                  : selectedImage
                    ? selectedImage
                    : require("../assets/images/profile.jpg")
              } />
              <div className='edit_pencile' onClick={takePicture}>
                <IonImg src={require('../assets/images/simple-pencile.png')} />
              </div>
            </IonAvatar>
          </div>
          <h6 onClick={() => history.push("/change_password")}>Change Password</h6>
          <IonList className='user_info'>
            <div className='item_detail'>
              <IonLabel>First name</IonLabel>
              <IonItem lines='none' className='info_item'>
                <IonInput type='text' value={userData.first_name} placeholder='First name'
                  onIonChange={(e) => {
                    let user_detail = userData;
                    user_detail.first_name = e.detail.value;
                    setUserData(user_detail);
                  }}
                />
              </IonItem>
            </div>
            <div className='item_detail'>
              <IonLabel>Last name</IonLabel>
              <IonItem lines='none' className='info_item'>
                <IonInput type='text' value={userData.last_name} placeholder='Last name'
                  onIonChange={(e) => {
                    let user_detail = userData;
                    user_detail.last_name = e.detail.value;
                    setUserData(user_detail);
                  }}
                />
              </IonItem>
            </div>
            {/* {cookieData?
              <div className='item_detail'>
                <IonLabel>Company</IonLabel>
                <IonItem lines='none' className='info_item'>
                  <IonInput type='text' readonly value={cookieData.user_data.company} placeholder='Company Name'
                  />
                </IonItem>
              </div>
              :
              ''
            } */}
            <div className='item_detail'>
              <IonLabel>Email</IonLabel>
              <IonItem lines='none' className='info_item'>
                <IonInput type='text' readonly value={userData.email} placeholder='Email'
                />
              </IonItem>
            </div>
            <div className='item_detail'>
              <IonLabel>Phone number</IonLabel>
              <IonItem lines='none' className='info_item'>
                <div className="phone-no-input d-flex ion-align-items-center plr_none">
                  <IonImg src={countryList[countryFlag].flag} alt="" />
                  <div className="phone-code-list" onClick={() => loadFlags()}>
                    <IonSelect
                      value={userData.phone_code + "-" + countryFlag}
                      interface="action-sheet"
                      interfaceOptions={options}
                      onIonChange={(e) => {
                        let user_data = userData;
                        let country_detail = e.detail.value;
                        country_detail = country_detail.split('-');
                        user_data.phone_code = country_detail[0];
                        setCountryFlag(country_detail[1]);
                      }}
                    >
                      {countryList.map((country: any, index: number) => {
                        return (
                          <IonSelectOption
                            value={country.dialCode + "-" + index}
                            key={index}
                          >
                            {country.dialCode}
                          </IonSelectOption>
                        );
                      })}
                    </IonSelect>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 8.5L12 15.5L5 8.5"
                        stroke="#868686"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <IonInput className='pl_10' type='tel' value={userData.phone_no} placeholder='Phone number'
                  onIonChange={(e) => {
                    let user_detail = userData;
                    user_detail.phone_no = e.detail.value;
                    setUserData(user_detail);
                  }}
                />
              </IonItem>
            </div>
            <IonButton shape="round"
              onClick={() => { handleInputUpdate() }}
            >Update profile</IonButton>
          </IonList>
        </div>
        <IonToast
          isOpen={showToast}
          position="bottom"
          onDidDismiss={() => setShowToast(false)}
          color={error ? "danger" : "primary"}
          message={alertMessage}
          duration={2500}
        />
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
      </IonContent>
    </IonPage>
  )
}

export default User_Information;
