import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewWillLeave,
} from "@ionic/react";
import {
  arrowForward,
  eyeOutline,
  lockClosedOutline,
  mailOutline,
  personOutline,
} from "ionicons/icons";
import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppHeader } from "../components/AppHeader";
import { loadFlags } from "../constants";
import countryList from "../countries";
import { api_url } from "../App";
import axios from "axios";
import "../assets/scss/Auth.scss";

const SignUp: React.FC = () => {

  const [countryFlag, setCountryFlag] = useState<number>(15);
  const [phone_code, setPhoneCode] = useState<string>("+44");
  const [showLoading, setShowLoading] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  const passwordHandler = () => {
    setShowPassword(!showPassword)
  }

  interface userDetail {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    phone_number: string;
  }
  const [userDetail, setuserDetail] = useState<any>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone_number: '',
  });


  const itemRef = useRef<any>([]);
  const fieldsRef = useRef<any>([]);
  const history = useHistory();
  const itemHandler = async (type: string) => {
    const { first_name, last_name, email, password, phone_number } = userDetail;

    for (let item in fieldsRef.current) {
      if (item === "fname" && first_name === "") {
        fieldsRef.current[item].className =
          "sc-ion-label-ios-h sc-ion-label-ios-s ios md floating-label";
      } else if (item === "lname" && last_name === "") {
        fieldsRef.current[item].className =
          "sc-ion-label-ios-h sc-ion-label-ios-s ios md floating-label";
      } else if (item === "email" && email === "") {
        fieldsRef.current[item].className =
          "sc-ion-label-ios-h sc-ion-label-ios-s ios md floating-label";
      } else if (item === "password" && password === "") {
        fieldsRef.current[item].className =
          "sc-ion-label-ios-h sc-ion-label-ios-s ios md floating-label";
      } else if (item === "phoneNo" && phone_number === "") {
        fieldsRef.current[item].className =
          "sc-ion-label-ios-h sc-ion-label-ios-s ios md floating-label";
      }
    }

    for (let item in itemRef.current) {
      if (item === "fname") {
        itemRef.current[item].className = "";
      } else if (item === "lname") {
        itemRef.current[item].className = "";
      } else if (item === "email") {
        itemRef.current[item].className = "";
      } else if (item === "password") {
        itemRef.current[item].className = "";
      } else if (item === "phoneNo") {
        itemRef.current[item].className = "";
      }
    }
    if (type === "fname") {
      itemRef.current[type].className = "item-shadow";
      fieldsRef.current[type].className =
        "sc-ion-label-ios-h sc-ion-label-ios-s ios md top-label";
    } else if (type === "lname") {
      itemRef.current[type].className = "item-shadow";
      fieldsRef.current[type].className =
        "sc-ion-label-ios-h sc-ion-label-ios-s ios md top-label";
    } else if (type === "email") {
      itemRef.current[type].className = "item-shadow";
      fieldsRef.current[type].className =
        "sc-ion-label-ios-h sc-ion-label-ios-s ios md top-label";
    } else if (type === "password") {
      itemRef.current[type].className = "item-shadow";
      fieldsRef.current[type].className =
        "sc-ion-label-ios-h sc-ion-label-ios-s ios md top-label";
    } else if (type === "phoneNo") {
      itemRef.current[type].className = "item-shadow";
      fieldsRef.current[type].className =
        "sc-ion-label-ios-h sc-ion-label-ios-s ios md top-label";
    }
  };

  const signup = () => {
    setShowLoading(true);
    // const { first_name, last_name, email, password, phone_number } = userDetail;

    axios
      .post(
        api_url + "auth/register",
        {
          first_name: userDetail.first_name,
          last_name: userDetail.last_name,
          email: userDetail.email,
          password: userDetail.password,
          phone_code: userDetail.phone_code,
          phone_no: userDetail.phone_number,
          role: "customer",
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (res) => {
        if (res.data.status === true) {
          setShowLoading(false);
          history.push("/login");
          setAlertMessage(res.data.message);
          setShowToast1(true);
        } else {
          setError(true);
          setShowLoading(false);
          setAlertMessage(res.data.message);
          setShowToast1(true);
        }
      })
      .catch((err) => {
        console.log({ err: err.message });
      });
  };
  const { setFirst_Name, setLast_Name, setEmail, setPassword, setPhone_Number } =
    userDetail;
  useIonViewWillLeave(() => {
    setFirst_Name("");
    setLast_Name("");
    setEmail("");
    setPassword("");
    setPhoneCode("");
    setPhone_Number("");
    setCountryFlag(0);
  });
  const options = {
    cssClass: "select-dropdown",
  };
  return (
    <IonPage>
      <IonContent>
        <AppHeader from='' />
        <div className="auth-wrapper auth-wrapper-inner">
          <h1>Sign Up</h1>
          <p className="subTitle">Sign into your account</p>
          <IonItem
            lines="none"
            ref={(el) => (itemRef.current["fname"] = el)}
            onClick={() => itemHandler("fname")}
          >
            <IonIcon icon={personOutline} size="small" />
            <div className="position-relative">
              <IonLabel
                ref={(el) => (fieldsRef.current["fname"] = el)}
                className="floating-label"
              >
                FIRST NAME
              </IonLabel>
              <IonInput
                value={userDetail.first_name}
                type="text"
                onIonChange={(e) => {
                  let user_data = userDetail;
                  user_data.first_name = e.detail.value;
                  setuserDetail(user_data);
                }}
              ></IonInput>
            </div>
          </IonItem>
          <IonItem
            lines="none"
            ref={(el) => (itemRef.current["lname"] = el)}
            onClick={() => itemHandler("lname")}
          >
            <IonIcon icon={personOutline} size="small" />
            <div className="position-relative">
              <IonLabel
                ref={(el) => (fieldsRef.current["lname"] = el)}
                className="floating-label"
              >
                LAST NAME
              </IonLabel>
              <IonInput
                value={userDetail.last_name}
                type="text"
                onIonChange={(e) => {
                  let user_data = userDetail;
                  user_data.last_name = e.detail.value;
                  setuserDetail(user_data);
                }}
              ></IonInput>
            </div>
          </IonItem>
          <IonItem
            lines="none"
            ref={(el) => (itemRef.current["email"] = el)}
            onClick={() => itemHandler("email")}
          >
            <IonIcon icon={mailOutline} size="small" />
            <div className="position-relative">
              <IonLabel
                ref={(el) => (fieldsRef.current["email"] = el)}
                className="floating-label"
              >
                EMAIL
              </IonLabel>
              <IonInput
                value={userDetail.email}
                type="text"
                onIonChange={(e) => {
                  let user_data = userDetail;
                  user_data.email = e.detail.value;
                  setuserDetail(user_data);
                }}
              ></IonInput>
            </div>
          </IonItem>
          <IonItem
            lines="none"
            ref={(el) => (itemRef.current["password"] = el)}
            onClick={() => itemHandler("password")}
          >
            <IonIcon icon={lockClosedOutline} size="small" />
            <div className="position-relative">
              <IonLabel
                ref={(el) => (fieldsRef.current["password"] = el)}
                className="floating-label"
              >
                PASSWORD
              </IonLabel>
              <IonInput
                value={userDetail.password}
                type={showPassword ? "text" : "password"}
                onIonChange={(e) => {
                  let user_data = userDetail;
                  user_data.password = e.detail.value;
                  setuserDetail(user_data);
                }}
              ></IonInput>
            </div>
            <IonIcon className="eye-icon" icon={eyeOutline} onClick={passwordHandler} />
          </IonItem>

          <IonItem
            lines="none"
            ref={(el) => (itemRef.current["phoneNo"] = el)}
            onClick={() => itemHandler("phoneNo")}
          >
            <div className="phone-no-input d-flex ion-align-items-center">
              <IonImg src={countryList[countryFlag].flag} alt="" />
              <div className="phone-code-list" onClick={() => loadFlags()}>
                <IonSelect
                  value={phone_code + "-" + countryFlag}
                  interface="action-sheet"
                  interfaceOptions={options}
                  onIonChange={(e) => {
                    let country_detail = e.detail.value;
                    country_detail = country_detail.split("-");
                    setPhoneCode(country_detail[0]);
                    setCountryFlag(country_detail[1]);
                  }}
                >
                  {countryList.map((country: any, index: number) => {
                    return (
                      <IonSelectOption
                        value={country.dialCode + "-" + index}
                        key={index}
                      >
                        {country.dialCode}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 8.5L12 15.5L5 8.5"
                    stroke="#868686"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="position-relative">
              <IonLabel
                ref={(el) => (fieldsRef.current["phoneNo"] = el)}
                className="floating-label"
              >
                PHONE NUMBER
              </IonLabel>

              <IonInput
                value={userDetail.phone_number}
                type="text"
                onIonChange={(e) => {
                  let user_data = userDetail;
                  user_data.phone_number = e.detail.value;
                  setuserDetail(user_data);
                }}
              ></IonInput>
            </div>
          </IonItem>
          <div className="justify-content-end">
            <IonButton shape="round" onClick={signup}>
              Sign Up
              <IonIcon icon={arrowForward} />
            </IonButton>
          </div>
        </div>
      </IonContent>
      <IonFooter className="ion-no-border auth-footer">
        <IonToolbar>
          <IonTitle>
            <p className="ion-text-center">
              Already an accout?
              <Link to="/login">
                <span> Login</span>
              </Link>
            </p>
          </IonTitle>
        </IonToolbar>
      </IonFooter>
      <IonToast
        isOpen={showToast1}
        position="bottom"
        onDidDismiss={() => setShowToast1(false)}
        color={error ? "danger" : "primary"}
        message={alertMessage}
        duration={2500}
      />
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
    </IonPage>
  );
};

export default SignUp;
