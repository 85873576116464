import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Custom css */
import "./theme/basic.scss";

import Desktop_Home from "./pages/desktop/Desktop_Home";
import Desktop_Booking_Type from "./pages/desktop/Desktop_Booking_Type";
import Desktop_Postcode from "./pages/desktop/Desktop_Postcode";
import Desktop_Pickup from "./pages/desktop/Desktop_Pickup";
import Desktop_Dropoff from "./pages/desktop/Desktop_Dropoff";
import Desktop_Pickup_Time from "./pages/desktop/Desktop_Pickup_Time";
import Desktop_Pickup_Contact from "./pages/desktop/Desktop_Pickup_Contact";
import Desktop_Dropoff_Contact from "./pages/desktop/Desktop_Dropoff_Contact";
import Desktop_Size from "./pages/desktop/Desktop_Size";
import Desktop_Summary_Order from "./pages/desktop/Desktop_Summary_Order";
import Desktop_Contact_Detail from "./pages/desktop/Desktop_Contact_Detail";
import NotFount from "./pages/desktop/NotFount";
import Desktop_Login from "./pages/desktop/Desktop_Login";
import Desktop_Signup from "./pages/desktop/Desktop_Signup";
import Desktop_ChangePassword from "./pages/desktop/Desktop_ChangePassword";
import Desktop_ForgotPassword from "./pages/desktop/Desktop_ForgotPassword";
import Desktop_Order_Status from "./pages/desktop/Desktop_Order_Status";
import Desktop_View_Order from "./pages/desktop/Desktop_View_Order";
import Desktop_Customer_Panel from "./pages/desktop/Desktop_Customer_Panel";
import Desktop_Driver_Panel from "./pages/desktop/Desktop_Driver_Panel";
import Desktop_Jobs_History from "./pages/desktop/Desktop_Jobs_History";
import Desktop_Driver_Documents from "./pages/desktop/Desktop_Driver_Documents";
import Desktop_Driver_Setting from "./pages/desktop/Desktop_Driver_Setting";
import Desktop_Driver_Jobs from "./pages/desktop/Desktop_Driver_Jobs";
import Order_Status from "./pages/Order_Status";
import View_Order from "./pages/View_Order";
import Customer_Panel from "./pages/Customer_Panel";
import Desktop_Meetup_Points from "./pages/desktop/Desktop_Meetup_points";
import Delivery_Types from "./pages/Delivery_Types";
import Bookings from "./pages/Bookings";
import Order_History from "./pages/Order_History";
import User_Profile from "./pages/User_Profile";
import User_Information from "./pages/User_Information";
import ChangePassword from "./pages/ChangePassword";
import About_App from "./pages/About_App";
import Helps_FAQs from "./pages/Helps_FAQs";
import Privacy_Policy from "./pages/Privacy_Policy";
import Terms_Conditions from "./pages/Terms_Conditions";
import Customer_Care from "./components/Customer_Care";
import Notifications from "./pages/Notifications";
import Delivery_Details from "./pages/Delivery_Details";
import Booking_Type from "./pages/Booking_Types";
import Booking_Confirmation from "./pages/Booking_Confirmation";
import View_Meetup_Points from "./pages/View_Meetup_Points";

// setupIonicReact();
export let api_url = "http://127.0.0.1:8000/api/";

interface LoginCheck {
  is_logged_in: boolean;
}
const AppR: React.FC<LoginCheck> = (props: LoginCheck) => (

  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/login">
          <Desktop_Login />
        </Route>
        <Route exact path="/home">
          <Desktop_Home />
        </Route>
        <Route exact path="/booking-type">
          <Desktop_Booking_Type />
        </Route>
        <Route exact path="/location">
          <Desktop_Postcode />
        </Route>
        <Route exact path="/meetup-points">
          <Desktop_Meetup_Points />
        </Route>
        <Route exact path="/pickup">
          <Desktop_Pickup />
        </Route>
        <Route exact path="/dropoff">
          <Desktop_Dropoff />
        </Route>
        <Route exact path="/pickup-time">
          <Desktop_Pickup_Time />
        </Route>
        <Route exact path="/pickup-contact">
          <Desktop_Pickup_Contact />
        </Route>
        <Route exact path="/dropoff-contact">
          <Desktop_Dropoff_Contact />
        </Route>
        <Route exact path="/size">
          <Desktop_Size />
        </Route>
        <Route exact path="/sender-contact-detail">
          <Desktop_Contact_Detail />
        </Route>
        <Route exact path="/order-overview">
          <Desktop_Summary_Order />
        </Route>
        <Route exact path="/not-found">
          <NotFount />
        </Route>
        <Route exact path="/order-status">
          {/* <Desktop_Order_Status /> */}
          <Order_Status />
        </Route>
        <Route exact path="/view_order">
          {/* <Desktop_View_Order /> */}
          <View_Order />
        </Route>
        <Route exact path="/forgot-password">
          <Desktop_ForgotPassword />
        </Route>
        <Route exact path="/view-meetup-points">
          <View_Meetup_Points />
        </Route>
        <Route exact path="/customer_panel">
          <Customer_Panel />
        </Route>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        {props.is_logged_in ? (
          <>
            <Route exact path="/">
              <Redirect to="/customer_panel" />
            </Route>
            <Route exact path="/customer_panel">
              <Customer_Panel />
            </Route>
            <Route exact path="/delivery_details">
              <Delivery_Details />
            </Route>
            <Route exact path="/booking_type">
              <Booking_Type />
            </Route>
            <Route exact path="/booking_confirmation">
              <Booking_Confirmation />
            </Route>
            <Route exact path="/jobs-history">
              <Desktop_Jobs_History />
            </Route>
            <Route exact path="/change-password">
              <Desktop_ChangePassword />
            </Route>
            <Route exact path="/bookings">
              <Bookings />
            </Route>
            <Route exact path="/delivery_types">
              <Delivery_Types />
            </Route>
            <Route exact path="/order_history">
              <Order_History />
            </Route>
            <Route exact path="/user_profile" >
              <User_Profile />
            </Route>
            <Route exact path="/user_information">
              <User_Information />
            </Route>
            <Route exact path="/change_password">
              <ChangePassword />
            </Route>
            <Route exact path="/about_app">
              <About_App />
            </Route>
            <Route exact path="/privacy_policy">
              <Privacy_Policy />
            </Route>
            <Route exact path="/helps_faqs">
              <Helps_FAQs />
            </Route>
            <Route exact path="/terms_conditions">
              <Terms_Conditions />
            </Route>
            <Route exact path="/customer_care">
              <Customer_Care />
            </Route>
            <Route exact path="/notifications">
              <Notifications />
            </Route>
            <Route exact path="/view_order">
              <View_Order />
            </Route>
            <Redirect to="/customer_panel" />
          </>
        ) : (
          <>

            {/* <Redirect to="/home" /> */}
            <Redirect to="/not-found" />
          </>
        )}
        {/* <Redirect to="/not-found" /> */}
        {/* <Route path="*">
            <NotFount />
        </Route> */}
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default AppR;
