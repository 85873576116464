import { IonAccordion, IonAccordionGroup, IonItem, IonLabel } from '@ionic/react'
import "../assets/scss/Driver_Setting.scss";

const Desktop_Setting_Component = () => {
  return (
    <div className="setting-wrapper">
    <h4 className="setting-heading">Settings</h4>

    <IonAccordionGroup>
        <IonAccordion value="first">
            <IonItem slot="header" >
                <IonLabel>Rules</IonLabel>
            </IonItem>
            <div className="ion-padding accordion-content" slot="content">
                Our company rule is very stick and every driver follow this rule.
            </div>
        </IonAccordion>
        <IonAccordion value="second">
            <IonItem slot="header" >
                <IonLabel>Terms and conditions</IonLabel>
            </IonItem>
            <div className="ion-padding accordion-content" slot="content">
                Our company rule is very stick and every driver follow this rule.
            </div>
        </IonAccordion>
        <IonAccordion value="third">
            <IonItem slot="header" >
                <IonLabel>Privacy policy</IonLabel>
            </IonItem>
            <div className="ion-padding accordion-content" slot="content">
                Our company rule is very stick and every driver follow this rule.
            </div>
        </IonAccordion>
    </IonAccordionGroup>
</div>
  )
}

export default Desktop_Setting_Component
