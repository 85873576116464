import {
  IonContent,
  IonFooter,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { AppHeader } from "../components/AppHeader";
import Change_Password_Component from "../components/Change_Password_Component";

const ChangePassword: React.FC = () => {


  return (
    <IonPage>
      <IonContent>
        <AppHeader from='change password'/>
        <div className="auth-wrapper">
        <Change_Password_Component/>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChangePassword;
