import {
  IonPage,
  IonContent,
  IonButton,
  IonImg,
  IonButtons,
  IonHeader,
  useIonViewWillEnter,
} from "@ionic/react";
import "../assets/scss/Choices.scss";
import { Redirect, useHistory } from "react-router";
import Cookies from "universal-cookie";
import { is_login } from "../functions";
import { useState } from "react";

const Choices: React.FC = () => {
  const history = useHistory();
  const cookies = new Cookies();

  const [role, setRole] = useState<any>('');

  useIonViewWillEnter(async () => {
    if(cookies.get('dtlc-order-details')){
      cookies.remove('dtlc-order-details');
      cookies.remove('dtlc-timer-start-time');
    }
    if(cookies.get('dtlc')){
      let dtlc = cookies.get('dtlc');
      if(dtlc.user_data.role_id === 2){
        setRole(2);
        // history.push('/customer_panel');
      }else if(dtlc.user_data.role_id === 3){
        history.replace('/driver_panel');
      }else if(dtlc.user_data.role_id === 5){
        history.replace('/dispatch_panel');
      }
    }
  });

  
  if(role === 2){
    return <Redirect to ="/customer_panel"/>
  }else{
    return (
      <IonPage>
        <IonContent>
          <IonHeader className="header_top ion-no-border">
            <div className="header_toolbar">
              <IonImg
                className="header_img"
                src={require("../assets/images/dtlc-logo-final.png")}
              />
            </div>
          </IonHeader>
          <div className="choices_wrapper">
            <div className="image_portion">
              <IonImg src={require("../assets/images/courier-boy.png")} />
            </div>
            <div className="choices_text">
              <div className="text">
                <h3>Courier delivery</h3>
                <p>We deliver at any point of the Earth as soon as possible</p>
              </div>
              <div className="choices_btn">
                {/* <IonButton
                  className="booking_btn"
                  onClick={() => {
                    history.push("/delivery_types");
                  }}
                >
                  book now
                </IonButton> */}
                <IonButtons
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  <button className="login_btn">Log in</button>
                </IonButtons>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
};
export default Choices;

