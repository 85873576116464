import { IonAvatar, IonButton, IonCol, IonIcon, IonImg, useIonViewWillEnter } from "@ionic/react"
import { alertCircleOutline, homeOutline, logOutOutline, personCircleOutline, personOutline } from "ionicons/icons"
import { useRef, useState } from "react";
import Cookies from 'universal-cookie';
import "../../src/assets/scss/Customer_Sidebar.scss"
import { device } from "../Layout";
import { log_me_out } from "../functions";
import { useHistory } from "react-router-dom";

interface PanelMenu {
  title: string;
  icon: string;
  active: boolean
}
const PanelMenus: PanelMenu[] = [
  {
    title: "home",
    icon: homeOutline,
    active: true
  },
  {
    title: "Profile",
    icon: personCircleOutline,
    active: false
  },
  {
    title: "about us",
    icon: alertCircleOutline,
    active: false
  },
  {
    title: "logout",
    icon: logOutOutline,
    active: false
  },
];
const Customer_Sidebar: React.FC = () => {
  const cookies = new Cookies();
  const history = useHistory();

  const menusRef = useRef<any>([]);

  // useIonViewWillEnter

  const activeHandler = async (index: any) => {
    for await (let item of menusRef.current) {
      item.className = 'ios md button button-solid ion-activatable ion-focusable';
    }
    menusRef.current[index].className = 'ios md button button-solid ion-activatable ion-focusable menu-title';

  }

  const logout = () => {

    // if (log_me_out()) {
    //   if (device === 'mobile') {
    //     history.push('/login');
    //   } else {
    //     window.location.href = window.location.origin + '/desktop_login';
    //     // history.push('/desktop_login');
    //   }
    // }
  }

  return (
    <IonCol size="12" sizeLg="2.5" className="ion-padding customer-sidebar">
        <div className="align-items-center">
            <IonAvatar className="ion-margin-end">
                <IonImg src={require("../assets/images/profile.jpg")}/>
            </IonAvatar>
            <h4 className="ion-margin-horizontal">Hi Wood</h4>
            {/* <IonButton className="ion-margin-horizontal">Logout</IonButton> */}
        </div>
        <div className="customer-menu ion-padding-top">
            {PanelMenus.map((item: any, index: any) => {
            if (item.title === 'logout') {
                return (
                <div key={index}>
                    <div className="align-items-center ion-margin-vertical"><div onClick={() => logout()} className="menu-item-inner"><IonIcon className="ion-margin-end" icon={item.icon} size="small" /><span ref={el => (menusRef.current[index] = el)} className={item.active ? "menu-title" : ""}>{item.title}</span></div></div>
                </div>
                );
            } else {
                return (
                    <div key={index}>
                        <div className="align-items-center ion-margin-vertical"><div onClick={() => activeHandler(index)} className="menu-item-inner"><IonIcon className="ion-margin-end" icon={item.icon} size="small" /><span ref={el => (menusRef.current[index] = el)} className={item.active ? "menu-title" : ""}>{item.title}</span></div></div>
                    </div>
                );
            }
            })}
        </div>
    </IonCol>
  )
}

export default Customer_Sidebar
