import axios from "axios";
import Cookies from "universal-cookie";
// export let BASE_URL = 'https://portfolios.sumsols.com/dtlc/api/app/';
export let BASE_URL = 'https://app.dtlc.co.uk/api/app/';
let FAKE_URL = "https://fakestoreapi.com"

let headerConfig = {
    headers: { 
        "content-type": "application/json",
        "Access-Control-Allow-Origin": '*'
    },
};

export const getRequest = async(end_point ,data) =>{
    let url = '';
    if(end_point === 'order-tracking'){
        url = 'https://app.dtlc.co.uk/order-tracking/track?tracking_number='+data.order_number;
    }else{
        url = BASE_URL+end_point;
    }
    return new Promise(function(resolve,reject){
        axios.get(
            url
        ).then((res)=>{
            resolve(res.data);
        }).catch((err)=>{
            console.log(err);
        });
    });
}
export const postRequest= async(end_point, data, token = '') => {
    if(token !== ''){
        headerConfig.headers.Authorization = 'Bearer ' + token //the token is a variable which holds the token
    }
    return new Promise(function(resolve, reject) {
        axios.post(
            BASE_URL+end_point,
            data,
            headerConfig
        ).then((res)=>{
            resolve(res.data);
        }).catch((err)=>{
            console.log(err);
        });
    });
}