import { IonButton, IonContent, IonIcon, IonPage } from "@ionic/react";
import "../../src/assets/scss/NotificationDetails.scss";
import { arrowBackOutline } from "ionicons/icons";
const NotificationDetails: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <div className="notification-header">
        <div className="header_toolbar">
        <IonButton
          className="header_back_button"
          onClick={() => {
            
          }}
        >
          <IonIcon icon={arrowBackOutline} size="large" />
        </IonButton>
      </div>
        </div>
        <div className="notification-details-wrapper">
       
          <div className="notification-description">
         <div className="notification-description-inner">
        <h4>Title</h4>
         <p>
            A Web Developer is responsible for the coding, design and layout of
            a website according to a company's specifications. As the role takes
            into consideration user experience and function, a certain level of
            both graphic design and computer programming is necessary.
          </p>
         </div>
          </div>
     
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NotificationDetails;
