import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions";
import { BarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner";
import { Preferences } from "@capacitor/preferences";
import { IonAvatar, IonButton, IonCheckbox, IonCol, IonGrid, IonIcon, IonImg, IonItem, IonList, IonLoading, IonModal, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonSpinner, IonTextarea, IonToast, useIonAlert } from "@ionic/react";
import { qrCodeOutline, searchOutline } from "ionicons/icons";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useHistory } from "react-router";
import Cookies from "universal-cookie";
import { postRequest } from "../ApiServices";
import "../assets/scss/Pending_Orders_List.scss";
import { prepareOrderStructure } from "../functions";
import { validater } from "../validate";

const Pending_Orders_List = (props: any) => {
    const history = useHistory();
    const cookies = new Cookies();
    const [presentAlert] = useIonAlert();
    
    const [orders, setOrders] = useState<any>(props.Orders);
    const [orderNumber, setOrderNumber] = useState<any>('');
    const [showToast, setShowToast] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [showIonLoading, setShowIonLoading] = useState<boolean>(false);
    const [checkedOrderTransferIds, setCheckedOrderTransferIds] = useState<any>([]);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [drivers, setDrivers] = useState<any>([]);
    const [searchedDrivers, setSearchedDrivers] = useState<any>([]);
    const [jobAssignment, setJobAssignment] = useState<boolean>(false);
    const [reason, setReason] = useState<any>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [orderModal, setOrderModal] = useState<any>('');
    const [userDetails, setUserDetails] = useState<any>({});

    useEffect(()=>{
        async function removeChecks(){
            if(!props.stateValue){
                let all_orders = orders;
                for await (let order of all_orders){
                    if(order.order.checked && order.order.checked === true){
                        order.order.checked = false;
                    }
                }
                setCheckedOrderTransferIds([]);
                setOrders(all_orders);
            }
        }
        removeChecks();
    }, [props.stateValue, orders]);

    // useImperativeHandle(ref, () => ({

    //     getAlert() {
    //       alert("getAlert from Child");
    //     }
    
    // }));

    const openScanner = async () => {
        await AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.CAMERA).then(async (result)=>{
            console.log('Has permission?',result.hasPermission);
            if(!result.hasPermission){
                AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.CAMERA);
            }else{
                const data = await BarcodeScanner.scan({
                    showFlipCameraButton : true, // iOS and Android
                    showTorchButton : true,
                    prompt : "Place a barcode or qr code inside the scan area",
                    resultDisplayDuration: 0
                });
                console.log(`Barcode data: ${data.text}`);
                let order_number: any = data.text;
                order_number = order_number.split('=');
                order_number = order_number[1];
                setOrderNumber(order_number);
                handleApiRequest(order_number);
            }
        }, (err: any)=> {
            console.log(err);
        });
      };

      const handleSearchOrder = () => {
        setShowIonLoading(true);
        handleApiRequest(orderNumber);
      }
    
      const handleApiRequest = (order_number: any) => {
        let dtlc = cookies.get('dtlc');
        postRequest('order/show', {
          order_number: order_number
        },dtlc.token.token).then(async (data)=>{
          setOrderNumber('');
          setShowIonLoading(false);
          if(data.data){
            prepareOrderStructure(data.data).then(async (result: any)=>{
              await Preferences.set({
                key: 'api-data',
                value: JSON.stringify(result),
              });
              // cookies.set('dtlc-order-details', data.data);
              history.push('/view_order');
            }).catch((err: any)=>{
              console.log(err);
            });
          }else{
            setAlertMessage('Order not found!');
            setShowToast(true);
          }
        }).catch((err: any)=>{
          console.log(err);
        });
      }
    
      const handleOrderNumberChange = (value: any) => {
        setOrderNumber(value);
      }

      const handleOrderClick = (index: any) => {
        prepareOrderStructure(orders[index].order).then(async (result: any)=>{
            result.transfer_id = orders[index].id;
            await Preferences.set({
              key: 'api-data',
              value: JSON.stringify(result),
            });
            // cookies.set('dtlc-order-details', data.data);
            history.push('/view_order');
          }).catch((err: any)=>{
            console.log(err);
          });
      }

      const handleOrderCheck = (checked: any, index: any) => {
        let all_orders = orders;
        all_orders[index].order.checked = checked;
        setOrders(all_orders);
        let all = checkedOrderTransferIds;
        if(checked){
            all.push(orders[index].id);
        }else{
            const idx = all.indexOf(orders[index].id);
            if (idx > -1) { // only splice array when item is found
                all.splice(idx, 1); // 2nd parameter means remove one item only
            }
        }
        setCheckedOrderTransferIds(all);
      }

      const handleTransferOrder = () => {
        let result = validater({
            reason: reason
        });
        let dtlc = cookies.get('dtlc');
        if(dtlc.token.token){
            if(result.status){
                setShowLoading(true);
                setOrderModal('pick-driver');
                getAllDrivers();
                setJobAssignment(false);
            }else{
                setAlertMessage(result.message);
                setShowToast(true);
            }
            // postRequest('jobs/transfer', {
            //     id: checkedOrderTransferIds,
            //     driver_id: '',
            //     transferred_reason: ''
            // }).then((res: any)=>{
            //     if(res.status){

            //     }
            // }).catch((err: any)=> console.log(err));
        }
      }

        const getAllDrivers = () => {
            setShowLoading(true);
            postRequest('drivers/all',{},userDetails.token.token).then((res: any)=>{
                if(res.status){
                    setShowLoading(false);
                    setDrivers(res.data);
                }
            }).catch((err: any)=>{
                console.log(err);
            })
        }

      const handleCheckBulkAction = async (type: any)=> {
        let all_orders = orders;
        let transfer_arr = [];
        for await (let order of all_orders){
            if(type === 'check-all'){
                order.order.checked = true;
                transfer_arr.push(order.id);
            }else if(type === 'uncheck-all'){
                if(order.order.checked && order.order.checked === true){
                    order.order.checked = false;
                }
            }
        }
        setCheckedOrderTransferIds(transfer_arr);
        setOrders(all_orders);
      }

        const handleClearSearch = () => {
            setSearchedDrivers([]);
        }

        const handleSearchInput = (input: any) => {
            if(input === ''){
                setSearchedDrivers([]);
            }else{
                let searched_drivers = drivers.filter((driver: any) => driver.full_name.search(input) !== -1 || driver.call_sign.search(input) !== -1);
                setSearchedDrivers(searched_drivers);
            }
        }

      const handleDriverSelect = (driverId: any, fullName: any, callSign: any, index: any, searched_driver: boolean) => {
        presentAlert({
            header: 'Are you sure?',
            message: `<p>This action will assign this job to <b>${fullName}(${callSign})</b></p>`,
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  
                },
              },
              {
                text: 'OK',
                role: 'confirm',
                handler: () => {
                    setShowModal(false);
                    jobAssignToDriver(driverId, index, searched_driver);
                },
              },
            ],
            onDidDismiss: (e: CustomEvent) => {},
          })
    }

    const jobAssignToDriver = (driver_id: any, index: any, searched_driver: boolean) => {
        setShowModal(false);
        setShowLoading(true);
        setShowIonLoading(true);
        let data: any = {};
        if(orderModal === 'pick-driver'){
            data.id = checkedOrderTransferIds;
            data.driver_id = driver_id;
            data.transferred_reason = reason;
        }
        postRequest('jobs/transfer', data, userDetails.token.token).then(async (res)=>{
            if(res.status){
                setShowLoading(false);
                setShowIonLoading(false);
                setJobAssignment(true);
                setShowModal(true);
                setReason('');
                setCheckedOrderTransferIds([]);
                props.cancelTransfer();
            }
        }).catch((err)=>{console.log(err)});
    }

    return (
    <>
        <div className="pending-order-list-wrapper">
        <IonModal class="order-overview-modal" onDidDismiss={()=> {
            setShowModal(false);
            setJobAssignment(false);
        }} isOpen={showModal}>
            <div className='linear_bg'>
                <div className='ion-padding'>
                    {orderModal === 'reason'?
                        <>
                            <h4>Reason:</h4>
                            <IonItem lines='none'>
                                <IonTextarea
                                    placeholder="Type reason here"
                                    autoGrow={true}
                                    rows={10}
                                    onIonChange={(e)=> setReason(e.detail.value)}
                                ></IonTextarea>
                            </IonItem>
                            <IonButton onClick={() => handleTransferOrder()}>
                                Transfer
                            </IonButton>
                        </>
                        :
                        orderModal === 'pick-driver'?
                            jobAssignment?
                            <>
                                <IonImg src={require('../assets/images/verified.png')} />
                                {/* <h4>Order: #{orderDetails.order_number}</h4> */}
                                <h1 className='ion-text-center'>Order{checkedOrderTransferIds.length > 1? 's' : ''} Transferred</h1>
                            </>
                            :
                            showLoading?
                                <div className='spinner-wrapper'>
                                    <IonSpinner name="circular"></IonSpinner>
                                </div>
                            :
                                <>
                                    <h4>Transfer to driver</h4>
                                    <IonSearchbar searchIcon={searchOutline} placeholder="Name or call sign" onIonClear={()=>handleClearSearch()} onIonChange={(e)=>handleSearchInput(e.detail.value)}></IonSearchbar>
                                    <IonList className="driver-list">
                                        {searchedDrivers.length > 0? 
                                            searchedDrivers.map((item: any, index: any)=>{
                                                return(
                                                    <IonItem key={index} onClick={()=>{handleDriverSelect(item.id, item.full_name, item.call_sign, index, true)}}>
                                                        <div>
                                                            <IonAvatar>
                                                                <IonImg src={item.image_url} />
                                                            </IonAvatar>
                                                        </div>
                                                        <div>
                                                            <h5>{item.full_name}</h5>
                                                            <h5>{item.call_sign}</h5>
                                                        </div>
                                                    </IonItem>
                                                )
                                            })
                                            :
                                            drivers.map((item: any, index: any)=>{
                                                if(userDetails && userDetails.user_data.role_id === 3 && userDetails.user_data.id !== item.id){
                                                    return(
                                                        <IonItem key={index} onClick={()=>{handleDriverSelect(item.id, item.full_name, item.call_sign, index, false)}}>
                                                            <div>
                                                                <IonAvatar>
                                                                    <IonImg src={item.image_url} />
                                                                </IonAvatar>
                                                            </div>
                                                            <div>
                                                                <h5>{item.full_name}</h5>
                                                                <h5>{item.call_sign}</h5>
                                                            </div>
                                                        </IonItem>
                                                    )
                                                }else{
                                                    return('')
                                                }
                                            })
                                        }
                                    </IonList>
                                </>
                            :
                            ''
                    }
                </div>
            </div>
        </IonModal>
            <div className="parcel_content">
                <IonGrid>
                        <IonRow className="position-relative"> 
                            <IonSearchbar show-clear-button="never" placeholder='Order Number #' className="ion-no-padding ion-margin-bottom" onIonChange={(event)=>handleOrderNumberChange(event.detail.value)} 
                            value={orderNumber}></IonSearchbar>                
                            <span className="search-icon" onClick={()=>handleSearchOrder()}></span>
                            <IonIcon class="scan-icon" icon={qrCodeOutline} onClick={()=>openScanner()}/>
                            {
                                props.stateValue?
                                <>
                                    <div>
                                        <h6 onClick={()=> handleCheckBulkAction('check-all')}>Check All</h6>
                                        <h6 onClick={()=> handleCheckBulkAction('uncheck-all')}>Uncheck All</h6>
                                        <IonButton shape="round" onClick={()=>{
                                            if(checkedOrderTransferIds.length === 0){
                                                setAlertMessage('Select atleast one order');
                                                setShowToast(true);
                                            }else{
                                                setOrderModal('reason');
                                                setShowModal(true);
                                                let dtlc = cookies.get('dtlc');
                                                if(dtlc && dtlc.token){
                                                    setUserDetails(dtlc);
                                                }
                                            }
                                        }}>Transfer</IonButton>
                                    </div>  
                                </>
                                :
                                ''
                            } 
                           
                            {orders.length > 0?
                                orders.map((item: any, index: any)=>{
                                    return(
                                        <div className="parcel-detail-wrapper"  key={index}>
                                        {
                                            props.stateValue?
                                                <IonCheckbox slot="start" checked={item.order.checked && item.order.checked === true? true : false} onIonChange={(e)=> handleOrderCheck(e.detail.checked, index)} className={"md interactive"}></IonCheckbox> 
                                            :
                                            ''
                                        } 
                                            
                                            <div className="parcel-detail" onClick={()=>handleOrderClick(index)}>
                                                <IonCol size="2" className="sidebar-color ion-padding"></IonCol>
                                                <IonCol size="10" className="content">
                                                    {/* <p>{item.address}</p> */}
                                                    <h4>#{item.order.order_number}</h4>
                                                    <p>{item.order.order_statuses[item.order.order_statuses.length - 1].status}</p>
                                                    {item.is_delayed? 
                                                        <p style={{color: 'red'}}><b>Delayed</b></p>
                                                        :
                                                        ''
                                                    }
                                                    <p><b>{item.order.order_nature === 'MULTI_PICK_MULTI_DROP'? 'Multiple pickups and multiple dropoffs' : item.order.order_nature === 'MULTI_PICK_SINGLE_DROP'? 'Multiple pickups and single dropoff' : item.order.order_nature === 'SINGLE_PICK_MULTI_DROP'? 'Single pickup and multiple dropoffs' : item.order.order_nature === 'SINGLE_PICK_SINGLE_DROP'? 'Single pickup and single dropoff' : ''}</b></p>
                                                    <div className="parcel">
                                                        <p><IonImg src={item.order.type === 'urgent'? require('../assets/images/running.png') : item.order.type === 'same day'? require('../assets/images/stopwatch.png') : item.order.type === 'schedule'? require('../assets/images/calendar.png') : ''} className="type-img"/></p>
                                                    </div>
                                                </IonCol>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <h3>No orders found</h3>
                            }
                        </IonRow>
                        <IonLoading
                            isOpen={showIonLoading}
                            onDidDismiss={() => setShowIonLoading(false)}
                            message={"Please wait..."}
                        />
                        <IonToast
                            isOpen={showToast}
                            position="bottom"
                            onDidDismiss={() => setShowToast(false)}
                            color={"danger"}
                            message={alertMessage}
                            duration={2500}
                        />
                </IonGrid>
            </div>
        </div>
    </>
  )
}

export default Pending_Orders_List
