import { IonAvatar, IonContent, IonIcon, IonImg, IonLabel, IonLoading, IonPage, IonSearchbar, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'
import React, { useState } from 'react'
import Cookies from 'universal-cookie'
import { postRequest } from '../ApiServices'
import { AppHeader } from '../components/AppHeader'
import Orders_List from '../components/Orders_List'
import "../assets/scss/Driver_Rejection.scss";
import { useHistory } from 'react-router'
import { qrCodeOutline } from 'ionicons/icons'
import { Preferences } from '@capacitor/preferences'

const Driver_Rejection = () => {
  const cookies = new Cookies();
  const history = useHistory();
  const [showLoading, setShowLoading] = useState<any>(true);
  const [rejectedOrders, setRejectedOrders] = useState([]);

  useIonViewWillEnter(()=>{
    setShowLoading(true);
    let dtlc = cookies.get('dtlc');
    if(dtlc && dtlc.token){
      postRequest("dispatcher/order-rejection-list", {
      }, dtlc.token.token)
      .then((res: any) => {
        setShowLoading(false);
        if (res.status) {
          setRejectedOrders(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
    }
  });

  useIonViewWillLeave(()=>{
    setRejectedOrders([]);
    // setShowLoading(true);
  });

  const handleOrderDeclineReasonClick = async (index: any) => {
    await Preferences.set({
      key: 'declined-by-driver-order',
      value: JSON.stringify(rejectedOrders[index]),
    });
    history.push("/order_rejection_details");
  }

  return (
    <IonPage>
        <IonContent className='linear_bg'>
            <AppHeader from=''/>
            <div className='driver-rejection-wrapper'>
                <h2 className='ion-text-center'>Driver Rejection</h2>
                <div className="position-relative">
                  <IonSearchbar show-clear-button="never" placeholder='Order Number #' className="ion-no-padding"
                        value=""></IonSearchbar>                
                        <span className="search-icon"></span>
                        <IonIcon class="scan-icon" icon={qrCodeOutline}/>   
                </div>
                {
                  rejectedOrders.map((item: any, index: any)=>{
                    return(
                      <div className='card' key={index} onClick ={()=>handleOrderDeclineReasonClick(index)}>
                          <IonAvatar>
                            <IonImg src={item.driver.image_url}/>
                          </IonAvatar>
                          <div>
                            <div className='d-flex'><IonLabel>Order #:</IonLabel><h6>{item.order.order_number}</h6></div>
                            <div className='d-flex'><IonLabel>Driver:</IonLabel><p>{item.driver.call_sign}</p></div>
                            <div className='d-flex'><IonLabel>Reason:</IonLabel><p>{item.declined_reason}</p></div>
                          </div>
                      </div>
                    )
                  })
                }
            </div>
        </IonContent>
        <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Please wait..."}
        />
    </IonPage>
  )
}

export default Driver_Rejection
