import countryList from "./countries";

export const loadFlags = () => {
    setTimeout(() => {
        var codeOptions = document.querySelectorAll('.select-dropdown .action-sheet-button .action-sheet-button-inner');

        for (let index = 0; index < codeOptions.length - 1; index++) {
            let singleCode = codeOptions[index];
            singleCode.innerHTML = singleCode.innerHTML
                .concat('<img src=' + countryList[index].flag + ' style="width:30px;height:20px;margin-left:25px;box-shadow:rgb(99 99 99 / 20%) 0px 2px 8px 0px;"/>');
        }
    }, 100);
};
