import { IonButton, IonHeader, IonIcon } from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import "../assets/scss/AppHeader.scss";
import Cookies from "universal-cookie";

interface general {
  from: string
}

export const AppHeader: React.FC<general> = (props) => {
  const cookies = new Cookies();
  const history = useHistory();
  return (
    <IonHeader className="header_top ion-no-border">
      <div className="header_toolbar">
        <IonButton
          className="header_back_button"
          onClick={() => {
            // if (props.goingBackFrom === "delivery types") {
            //     cookies.remove('dtlc-order-details');
            // }
            if(props.from === 'mobile'){
              history.push('/choices');
            }else if(props.from === 'mobile-order-booking'){
              if(cookies.get('dtlc')){
                let dtlc = cookies.get('dtlc');
                if(dtlc.user_data.role_id === 2){
                  history.push('/customer_panel');
                }else if(dtlc.user_data.role_id === 3){
                  history.push('/driver_panel');
                }else if(dtlc.user_data.role_id === 5){
                  history.push('/dispatch_panel');
                }
              }else{
                history.push('/choices');
              }
            }else if(props.from === 'forgot-password'){
              history.push('/login');
            }else{
              history.goBack();
            }
          }}
        >
          <IonIcon icon={arrowBackOutline} size="large" />
        </IonButton>
      </div>
    </IonHeader>
  );
};
