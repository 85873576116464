import { IonImg } from "@ionic/react"
import "../assets/scss/Order_Overview.scss";

const Order_Overview_Cards = () => {
  return (
    <>
        <div className='booking-card ion-padding'>
            <div className='d-flex'>
            <h6>Booking No:&nbsp;</h6><span>123456789</span>
            </div>
            <p>Faisalabad Pakistan</p>    
        </div>
            <div className='booking-card ion-padding'>
              <h4>Pickup</h4>
                <div className='pickup-side-border'>
                  <p>Faisalabad Pakistan</p>
                  <p>Faisalabad Pakistan</p>
                </div>
            </div>
            <div className='booking-card ion-padding'>
              <h4>Dropoff</h4>
            
                <div className='dropoff-side-border'>
                  <p>Lahore Pakistan</p>
                  <p>Lahore Pakistan</p>
                </div>
            </div>
            <div className='booking-card ion-padding'>
                <div className='d-flex'><h6>Package Size:&nbsp;</h6><p>Small</p></div>
                <div className='d-flex'><h6>Booking Date:&nbsp;</h6><p>3 Jan</p></div>
                <div className='d-flex'><h6>Parcel Type:&nbsp;</h6><p>Cloths</p></div>
 
            </div>
            <div className='map-card ion-padding-horizontal'>
              <h4>Route Assinging</h4>
              <IonImg src={require('../assets/images/map.jpg')}/>
            </div>
    </>
  )
}

export default Order_Overview_Cards
