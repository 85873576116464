import {
    IonButton,
    IonContent,
    IonImg,
    IonLoading,
    IonPage,
    useIonViewWillEnter,
    useIonViewWillLeave,
} from "@ionic/react";

import { AppHeader } from "../components/AppHeader";
import Menu from "../components/Menu";
import Driver_List from "../components/Orders_List";
import "../assets/scss/Driver_Job.scss";
import Pending_Orders_List from "../components/Pending_Orders_List";
import { useState, useRef } from "react";
import { postRequest } from "../ApiServices";
import Cookies from 'universal-cookie';

const Driver_Job: React.FC = () => {
    const cookies = new Cookies();
    // const childRef = useRef();

    const [orderTransfer, setOrderTransfer] = useState(false);
    const [showLoading, setShowLoading] = useState<boolean>(true);
    const [customerOrders, setCustomerOrders] = useState<any>([]);
    const [transferBtn, setTransferBtn] = useState<boolean>(true);

    useIonViewWillEnter(()=>{
        getAssignedJobs();
    });
    
    useIonViewWillLeave(()=>{
        setCustomerOrders([]);
        // setShowLoading(true);
    });

    const getAssignedJobs = () => {
        setShowLoading(true);
        let dtlc = cookies.get('dtlc');
        if(dtlc && dtlc.token){
          postRequest("drivers/assigned-jobs", {}, dtlc.token.token)
          .then((res: any) => {
            setShowLoading(false);
            if (res.status) {
              setCustomerOrders(res.data);
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
        }
    }
    
    const cancelTransfer = () => {
        setOrderTransfer(false);
        setTransferBtn(true);
        getAssignedJobs();
    }
    
    return (
        <IonPage>
            <IonContent fullscreen className="linear_bg">
                <AppHeader  from='driver jobs'/>
                <div className="transfer-jobs-btn-wrapper">
                    {transferBtn? 
                        <div onClick={()=> {
                            setOrderTransfer(true);
                            setTransferBtn(false);
                        }}><span><IonImg src={require('../assets/images/transfer.png')} /></span><h6>Transfer Jobs</h6></div>
                        :
                        <div onClick={()=> {
                            setOrderTransfer(false);
                            setTransferBtn(true);
                        }}><span><IonImg src={require('../assets/images/cancel.png')} /></span><h6>Cancel Transfer</h6></div>
                    }
                </div>
                <div className="driver-job-content">
                    <h2 className='ion-text-center'>Pending Jobs</h2>
                    <div className="assign-job">
                        <h4>Assigned job</h4>
                        <p>{customerOrders.length}</p>
                    </div>
                    {/* <Driver_List Orders={[]} /> */}
                    {customerOrders.length> 0?
                        <Pending_Orders_List stateValue = {orderTransfer} Orders={customerOrders} cancelTransfer={cancelTransfer}/>
                        :
                        !showLoading? 
                            <h3>No orders found</h3>
                            :
                            ''
                    }
                </div>
            </IonContent>
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={"Please wait..."}
            />
        </IonPage>
    );
};

export default Driver_Job;
