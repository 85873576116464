import {IonButton, IonContent, IonLoading, IonPage, IonSearchbar, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react"
import { AppHeader } from "../components/AppHeader"
import "../assets/scss/All_Orders.scss";
import Orders_List from "../components/Orders_List";
import Cookies from 'universal-cookie';
import { postRequest } from "../ApiServices";
import { useState } from "react";

const Order_Request: React.FC = () => {
  const cookies = new Cookies();

  const [showLoading, setShowLoading] = useState<any>(true);
  const [customerOrders, setCustomerOrders] = useState<any>([]);

  useIonViewWillEnter(()=>{
    setShowLoading(true);
    let dtlc = cookies.get('dtlc');
    if(dtlc && dtlc.token){
      postRequest("dispatcher/order-requests", {
        status: 'pending'
      }, dtlc.token.token)
      .then((res: any) => {
        setShowLoading(false);
        if (res.status) {
          setCustomerOrders(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
    }
  });

  useIonViewWillLeave(()=>{
    setCustomerOrders([]);
    // setShowLoading(true);
  });

  return (
    <IonPage>
        <IonContent className="linear_bg">
            <AppHeader from=""/>
            <div className='all-orders-wrapper'>
                <h2 className='ion-text-center ion-margin-bottom'>Order Requests</h2>
                {customerOrders.length> 0?
                  <Orders_List Orders={customerOrders}/>
                  :
                  !showLoading? 
                    <h3>No orders found</h3>
                    :
                    ''
                }
            </div>
        </IonContent>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
    </IonPage>
  )
}

export default Order_Request
