import { IonAvatar, IonButton, IonCol, IonContent, IonIcon, IonImg, IonLoading, IonPage, IonRow, IonSelect, IonSelectOption, IonToast, useIonViewWillEnter } from '@ionic/react'
import React, { useState } from 'react'
import "../assets/scss/Driver_Profile.scss";
import { IonInput, IonItem, IonLabel, IonList } from '@ionic/react';
import { locationOutline } from 'ionicons/icons';
import { Preferences } from '@capacitor/preferences';
import { BookingHeader } from '../components/BookingHeader';
import { validater } from '../validate';
import { BASE_URL, postRequest } from '../ApiServices';
import Cookies from 'universal-cookie';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import axios from 'axios';

const Driver_Profile: React.FC = () => {
    const cookies = new Cookies();

    const [driverInfo, setDriverInfo] = useState<any>({
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        gender: '',
        phone_no: '',
        address: '',
        image_url: null
    });
    const [previousDriverInfo, setPreviousDriverInfo] = useState<any>({
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        gender: '',
        phone_no: '',
        address: '',
        image_url: null
    });
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<any>('');
    const [error, setError] = useState<boolean>(false);

    useIonViewWillEnter(async () => {
        const { value } = await Preferences.get({ key: 'profile-data' });
        if (value) {
            let info = JSON.parse(value);
            setDriverInfo({
                id: info.id,
                first_name: info.first_name,
                last_name: info.last_name,
                email: info.email,
                gender: info.gender,
                phone_no: info.phone_no,
                address: info.address,
                image_url: info.image_url
            });
            setPreviousDriverInfo({
                id: info.id,
                first_name: info.first_name,
                last_name: info.last_name,
                email: info.email,
                gender: info.gender,
                phone_no: info.phone_no,
                address: info.address,
                image_url: info.image_url
            });
        }
    });
    const handleUpdateDriverInfo = () => {
        let result = validater(driverInfo);
        if (result.status) {
            handleApiRequest();
        } else {
            setAlertMessage(result.message);
            setError(true);
            setShowToast(true);
        }
    }

    const handleApiRequest = () => {
        let dtlc = cookies.get('dtlc');
        if (dtlc && dtlc.token) {
            setShowLoading(true);
            postRequest('drivers/update', driverInfo, dtlc.token.token).then((res: any) => {
                setError(false);
                if (res.status) {
                    setShowLoading(false);
                    setError(false);
                    setAlertMessage(res.message);
                    setShowToast(true);
                    setPreviousDriverInfo({
                        ...previousDriverInfo,
                        first_name: driverInfo.first_name,
                        last_name: driverInfo.last_name
                    });
                }
            }).catch((err: any) => {
                console.log(err);
            });
        }
    }

    const handleCamera = async () => {
        const cameraResult = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Base64,
            source: CameraSource.Camera,
        })
            .then(async (cameraResult) => {
                // image.webPath will contain a path that can be set as an image src.
                // You can access the original file using image.path, which can be
                // passed to the Filesystem API to read the raw data of the image,
                // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
                let base64Image: any = cameraResult.base64String;
                const rawData = atob(base64Image);
                const bytes = new Array(rawData.length);
                for (var x = 0; x < rawData.length; x++) {
                    bytes[x] = rawData.charCodeAt(x);
                }
                const arr = new Uint8Array(bytes);
                const blob = new Blob([arr], { type: 'image/png' });
                if (blob.size > 2000000) {
                    setError(true);
                    setAlertMessage('Image size can not be larger than 2MB');
                    setShowToast(true);
                }

                const formData = new FormData();
                formData.append('id', driverInfo.id);
                formData.append('first_name', driverInfo.first_name);
                formData.append('last_name', driverInfo.last_name);
                formData.append('phone_no', driverInfo.phone_no);
                formData.append('phone_code', '+44');
                formData.append('gender', driverInfo.gender);
                formData.append('address', driverInfo.address);
                formData.append('image', blob, 'file-name');

                let dtlc = cookies.get('dtlc');
                if (dtlc && dtlc.token) {
                    setShowLoading(true);
                    const config = {
                        headers: {
                            "Access-Control-Allow-Origin": '*',
                            'content-type': 'multipart/form-data',
                            Authorization: `Bearer ${dtlc.token.token}`
                        }
                    };
                    axios
                        .post(BASE_URL + 'drivers/update', formData, config)
                        .then(async (res: any) => {
                            if (res.data.status) {
                                let info = driverInfo;
                                info.image_url = `data:image/jpeg;base64,${base64Image}`;
                                setDriverInfo(info);

                                const { value } = await Preferences.get({ key: 'profile-data' });
                                if (value) {
                                    let data = JSON.parse(value);
                                    data.first_name = driverInfo.first_name;
                                    data.last_name = driverInfo.last_name;
                                    data.gender = driverInfo.gender;
                                    data.phone_no = driverInfo.phone_no;
                                    data.address = driverInfo.address;
                                    data.image_url = driverInfo.image_url;

                                    await Preferences.set({
                                        key: 'profile-data',
                                        value: JSON.stringify(data),
                                    });
                                }
                                setShowLoading(false);
                                setAlertMessage(res.data.message);
                                setError(false);
                                setShowToast(true);
                            }
                        })
                        .catch((error: any) => {
                            console.log(error);
                        });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <IonPage>
            <IonContent className='linear_bg'>
                <BookingHeader from='' />
                <div className='driver-profile-wrapper'>
                    <h2>Driver Profile</h2>
                    <IonRow>
                        <IonCol>
                            <div className="profile-header">
                                <div className='avatar-wrapper'>
                                    <IonAvatar className="avatar-img">
                                        <IonImg src={driverInfo.image_url ? driverInfo.image_url : require('../assets/images/driver-job-description.jpg')} />
                                        <div className='edit_pencile'>
                                            <IonImg onClick={() => handleCamera()} src={require('../assets/images/simple-pencile.png')} />
                                        </div>
                                    </IonAvatar>
                                </div>
                                <h4 className='driver-fname'>{previousDriverInfo.first_name && previousDriverInfo.last_name ? previousDriverInfo.first_name + ' ' + previousDriverInfo.last_name : ''}</h4>
                                <IonButton>Driver</IonButton>
                            </div>
                        </IonCol>
                    </IonRow>
                    <div className='profile-disc'>
                        <IonList>
                            <div className='item_detail'>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel>First Name*</IonLabel>
                                        <IonItem lines='none'>
                                            <IonInput onIonChange={(e) => {
                                                let info = driverInfo;
                                                info.first_name = e.detail.value;
                                                setDriverInfo(info);
                                            }} value={driverInfo.first_name?driverInfo.first_name:''}></IonInput>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol>
                                        <IonLabel>Last Name*</IonLabel>
                                        <IonItem lines='none'>
                                            <IonInput onIonChange={(e) => {
                                                let info = driverInfo;
                                                info.last_name = e.detail.value;
                                                setDriverInfo(info);
                                            }} value={driverInfo.last_name ? driverInfo.last_name : ''}></IonInput>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </div>
                            <div className='item_detail'>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel>Enter Your Email Id*</IonLabel>
                                        <IonItem lines='none'>
                                            <IonInput readonly={true} onIonChange={(e) => {
                                                let info = driverInfo;
                                                info.email = e.detail.value;
                                                setDriverInfo(info);
                                            }} value={driverInfo.email ? driverInfo.email : ''}></IonInput>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </div>
                            <div className='item_detail'>
                                <IonRow>
                                    <IonCol>

                                        <IonLabel>Gender*</IonLabel>
                                        <IonItem lines='none' className='select-item'>
                                            <IonSelect interface="popover" onIonChange={(e) => {
                                                let info = driverInfo;
                                                if(info.gender !== ''){
                                                    info.gender = e.detail.value;   
                                                    setDriverInfo(info);
                                                }
                                            }} value={driverInfo.gender ? driverInfo.gender : ''} mode="md">
                                                <IonSelectOption value="select">Select one</IonSelectOption>
                                                <IonSelectOption value="male">Male</IonSelectOption>
                                                <IonSelectOption value="female">Female</IonSelectOption>
                                                <IonSelectOption value="other">Prefer not to specify</IonSelectOption>
                                            </IonSelect>
                                        </IonItem>

                                    </IonCol>
                                    <IonCol>

                                        <IonLabel>Mobile no*</IonLabel>
                                        <IonItem lines='none'>
                                            <IonInput onIonChange={(e) => {
                                                let info = driverInfo;
                                                info.phone_no = e.detail.value;
                                                setDriverInfo(info);
                                            }} value={driverInfo.phone_no ? driverInfo.phone_no : ''}></IonInput>
                                        </IonItem>

                                    </IonCol>
                                </IonRow>


                            </div>
                        </IonList>
                    </div>
                    <div className='address-wrapper'>
                        <h4>Address</h4>
                        <IonList>
                            <IonItem>
                                <IonIcon icon={locationOutline} />
                                <div>
                                    {/* <h6>Canal Road Faisalabad</h6> */}
                                    <IonInput onIonChange={(e) => {
                                        let info = driverInfo;
                                        info.address = e.detail.value;
                                        setDriverInfo(info);
                                    }} value={driverInfo.address ? driverInfo.address : ''}></IonInput>
                                </div>
                            </IonItem>
                        </IonList>
                    </div>
                    <IonButton expand='block' onClick={() => handleUpdateDriverInfo()}>Save</IonButton>
                </div>
            </IonContent>
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={"Please wait..."}
            />
            <IonToast
                isOpen={showToast}
                position="bottom"
                onDidDismiss={() => setShowToast(false)}
                color={error ? "danger" : "primary"}
                message={alertMessage}
                duration={2500}
            />
        </IonPage>
    )
}

export default Driver_Profile
