import { IonAvatar, IonButton, IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonLoading, IonPage, IonToast, useIonViewWillEnter } from '@ionic/react'
import React from 'react'
import { AppHeader } from '../components/AppHeader'
import '../assets/scss/User_Profile.scss';
const About_App: React.FC = () => {
  return (
    <IonPage>
      <IonContent className='overflow'>
        <AppHeader from='about'/>
        <div className='wrapper flex about_app_wrapper'>
          {/* <h3 className='ion-text-center'>About App</h3> */}
          <IonImg className='customer-support' src={require("../assets/images/customer-support.png")}/>
          {/* <h4 className='mtb'>Company Info</h4> */}
          <p>
            It is a long established fact that a reader will be distracted by the readable
            content of a page when looking at its layout. The point of using Lorem Ipsum is
            that it has a more-or-less normal distribution of letters, as opposed to using
            'Content here, content here', making it look like readable English.
          </p>
          <a href="tel: 07404665117"> 
          <IonButton shape="round">
              Call us
            </IonButton>
            </a>
          {/* <h4 className='mtb'>Sub Title</h4>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </p>
          <p className='mtb'>
            The point of using Lorem Ipsum is
            that it has a more-or-less normal distribution of letters, as opposed to using
            'Content here, content here', making it look like readable English.
          </p>
          <p>
            Many desktop publishing packages and web page editors now use Lorem Ipsum
            as their default model text, and a search for 'lorem ipsum' will uncover
            many web sites still in their infancy. 
          </p> */}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default About_App;
