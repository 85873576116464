import {
IonCol,
IonIcon,
IonImg,
useIonViewDidEnter,
useIonViewWillEnter,
useIonViewWillLeave,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "../assets/scss/Sidebar.scss";
import {
arrowBack,
arrowForwardOutline,
calendarOutline,
cardOutline,
checkmarkOutline,
cubeOutline,
locationOutline,
mailOutline,
} from "ionicons/icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { Preferences } from "@capacitor/preferences";

interface general {
  passState: any;
}
interface AppMenu {
  url: string;
  title: string;
  icon: string;
  visited: boolean;
}

// let appMenus: AppMenu[] = [];

const Sidebar: React.FC<general> = (props: general) => {
  const location = useLocation();
  const history = useHistory();
  let cookies = new Cookies();
  let visit = true;

  const [isActive, setActive] = useState(false);
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const [appMenus, setAppMenus] = useState<any>([
    {
      url: "/home",
      title: "delivery type",
      icon: calendarOutline,
      visited: false,
    },
    {
      url: "/location",
      title: "location",
      icon: locationOutline,
      visited: false,
    },
    {
      url: "/meetup-points",
      title: "meetup points details",
      icon: arrowForwardOutline,
      visited: false,
    },
    {
      url: "/pickup",
      title: "pick-up details",
      icon: arrowForwardOutline,
      visited: false,
    },
    {
      url: "/pickup-contact",
      title: "pick-up contact",
      icon: arrowForwardOutline,
      visited: false,
    },
    {
      url: "/dropoff",
      title: "drop-off details",
      icon: arrowBack,
      visited: false,
    },
    {
      url: "/dropoff-contact",
      title: "drop-off contact",
      icon: arrowBack,
      visited: false,
    },
    {
      url: "/booking-type",
      title: "package size",
      icon: cubeOutline,
      visited: false,
    },
    {
      url: "/sender-contact-detail",
      title: "contact details",
      icon: mailOutline,
      visited: false,
    },
    {
      url: "/summary-order",
      title: "payment",
      icon: cardOutline,
      visited: false,
    },
  ]);
  
  let order_details: any;
  let order_timer = cookies.get("desktop-dtlc-timer-start-time");

  

  useEffect(()=>{
    return () => {
      setActive(false);
      setOrderDetails(null);
    }
  }, []);

  useIonViewWillLeave(()=>{
  });

  useIonViewWillEnter(async ()=>{
    const { value } = await Preferences.get({ key: 'dtlc-order-details' });
    if(value){
      order_details = JSON.parse(value);
      setOrderDetails(order_details);
      let app_menus: any = appMenus;
      for await (let item of app_menus){
        if(item.url === '/home' && order_details.type){
          item.visited = true;
        }else if(item.url === '/location' && (order_details.pickup_address && order_details.dropoff_address)){
          item.visited = true;
        }else if(item.url === '/meetup-points' && (order_details.pickup_address && order_details.pickup_address[0].meetup_points && order_details.dropoff_address && order_details.dropoff_address[0].meetup_points)){
          item.visited = true;
        }else if(item.url === '/pickup' && (order_details.pickup_address && order_details.pickup_address[0].location_name)){
          item.visited = true;
        }else if(item.url === '/pickup-contact' && (order_details.pickup_address && order_details.pickup_address[0].contact.first_name)){
          item.visited = true;
        }else if(item.url === '/dropoff' && (order_details.dropoff_address && order_details.dropoff_address[0].location_name)){
          item.visited = true;
        }else if(item.url === '/dropoff-contact' && (order_details.dropoff_address && order_details.dropoff_address[0].contact.first_name)){
          item.visited = true;
        }else if(item.url === '/booking-type' && order_details.package_size){
          item.visited = true;
        }else if(item.url === '/sender-contact-detail' && (order_details.sender_detail && order_details.sender_detail.name)){
          item.visited = true;
        }
      }
      app_menus = JSON.stringify(app_menus);
      app_menus = JSON.parse(app_menus);
      setAppMenus(app_menus);
      // app_menus.forEach((item: any, index: any)=>{
        
      // });
    }else{
      appMenus.forEach((item: any, index: any)=>{
        item.visited = false;
      });
    }
  });
  
  const itemClick = (index: any) => {
    // history.push(appMenus[index].url);
    if(appMenus[index].visited){
        history.push(appMenus[index].url);
    }
  };
  return (
    <IonCol size="12" sizeLg="2.5" className="ion-no-padding">
      <div className="sidebar">
        <div className={isActive ? "auto-sidebar" : "mini-sidebar"}>
          <div className="sidebar-logo">
            <IonImg src={require("../assets/images/dtlc-cropped-white.png")} />
          </div>
          <div className="align-items-center">
            <div
              className="double-arrow"
              onClick={() => {
                setActive(!isActive);
                props.passState();
              }}
            >
              <IonImg src={require("../assets/images/arroedouble.png")} />
            </div>
            <div className="dots-wrapper">
            {appMenus.map((item: any, index: any) => {
              return (
                  <div key={index}>
                    <div className={ isActive ? "d-flex hide-upper-menu" : "d-flex show-upper-menu"}>
                      <div className="d-flex align-items-center">
                        <div className="icon-width">
                          {item.url === location.pathname? 
                            <div className="icon-wrapper">
                              <IonIcon icon={item.icon} size="small" />
                            </div>
                            :
                            item.url !== location.pathname && item.visited? 
                              <IonIcon icon={checkmarkOutline} size="small" />
                            :
                              <div className="dots"></div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
            })}
            
            </div>
            </div>
            <div className="menu">
            {appMenus.map((item: any, index: any) => {
              return (
                <div key={index}>
                  <div className="menu-inner ion-margin-bottom">
                    <div
                      className="d-flex align-items-center"
                      onClick={() => itemClick(index)}
                    >
                      <div className="icon-width">
                        {item.url === location.pathname?
                          <div className="icon-wrapper">
                            <IonIcon icon={item.icon} size="small" />
                          </div>
                          : 
                          item.url !== location.pathname && item.visited? 
                            <IonIcon icon={checkmarkOutline} size="small" />
                          :
                            <div className="dot"></div>
                        }
                      </div>
                      <h5>{item.title}</h5>
                    </div>
                  </div>
                </div>
              );
            })}
            </div>
            {/* {cookies.get('dtlc')? <h5 className="login-btn" onClick={()=>history.push("/customer_panel")}>Dashboard</h5> : <h5 className="login-btn" onClick={()=>{
              history.push("/login")
              }}>Login</h5>} */}
        </div>
      </div>
    </IonCol>
  );
};

export default Sidebar;
