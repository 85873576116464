import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
  } from "@ionic/react";
  import React, { useState, useRef } from "react";
  import { Link, useHistory } from "react-router-dom";
  import "../../assets/scss/Desktop_Auth.scss";
import Forgot_Password_Component from "../../components/Forgot_Password_Component";
  import Navbar from "../../components/Navbar";
  
  const Desktop_ForgotPassword: React.FC = () => {
      const history = useHistory();
      
      
   
    return (
      <IonPage>
        <IonContent>
          <Navbar/>
          <IonGrid className="desktop-auth-grid">
            <IonRow className="justify-content-center align-items-center">
              <IonCol size="12" sizeLg="5">
                <div className="desktop-auth-wrapper">
               <Forgot_Password_Component/>
                   {/* <IonRow className="justify-content-center">
                      <IonCol  size="12" sizeLg="6">
                      <div className="position-relative"><h4 className="ion-text-center"><span>OR</span></h4></div>
                    <IonButton fill="outline" onClick={(()=>history.push("/desktop_signup"))}>
                      sign up
                    </IonButton>
                      </IonCol>
                   </IonRow> */}
                </div>
              </IonCol>
              <IonCol size="12" sizeLg="5" className="ion-padding-top">
                <div>
                  <IonImg src={require("../../assets/images/forgot.png")} />
                </div>
              
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  };
  
  export default Desktop_ForgotPassword;
  