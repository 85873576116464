import { IonButton, IonCheckbox, IonContent, IonHeader, IonIcon, IonPage, useIonViewWillEnter } from "@ionic/react";
import { arrowBackOutline, stopwatchOutline, closeOutline, checkmarkOutline, listOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { AppHeader } from "../components/AppHeader";
import "../assets/scss/View_Meetup_Points.scss";
import { useHistory, useLocation } from "react-router";
import Order_Receiving from "../components/Order_Receiving";
import Cookies from "universal-cookie";
import { Preferences } from "@capacitor/preferences";

const View_Meetup_Points:React.FC =()=>{
    const location: any = useLocation();
    const history = useHistory();
    const cookies = new Cookies();

    const [address, setAddress] = useState<any>(null);
    const [orderMeetupPointId, setOrderMeetupPointId] = useState<any>(null);
    const [addressIndex, setAddressIndex] = useState<any>(null);
    const [viewMeetupPoints, setViewMeetupPoints] = useState<any>(true);
    const [multiPointArr, setMultiPointArr] = useState<any>([]);
    const [allowMultiPointSelect, setAllowMultiPointSelect] = useState<boolean>(false);
    const [userDetails, setUserDetails] = useState<any>(null);
    const [orderDetails, setOrderDetails] = useState<any>(null);
    const [showOptionMultipleSelect, setShowOptionMultipleSelect] = useState<any>(false);

    useIonViewWillEnter(async ()=>{
        handleShowOptionMultipleSelect();
        setAddressIndex(location.state.address_index);
        setAddress(location.state.address);

        let dtlc;
        if(cookies.get('dtlc')){
            dtlc = cookies.get('dtlc');
            setUserDetails(dtlc.user_data);
        }

        const { value } = await Preferences.get({ key: 'api-data' });
        if(value){
            let order_details = JSON.parse(value);
            setOrderDetails(order_details);
        }
    });

    const handleShowOptionMultipleSelect = async () => {
        let meetup_points = location.state.address.meetup_points;
        let found = false;
        for await (let item of meetup_points){
            if(item.order_proof === null){
                found = true;
            }
        }
        setShowOptionMultipleSelect(found);
    }

    const handleBack = () => {
        if(viewMeetupPoints){
            history.goBack();
            setAllowMultiPointSelect(false);
        }else{
            setViewMeetupPoints(true);
        }
    }

    const proofView = async (args: any) => {
        // setMeetupId(args);
        if(args === 'back'){
            const { value } = await Preferences.get({ key: 'api-data' });
            if(value){
                let order_details = JSON.parse(value);
                if(address.type === 'PICK_UP'){
                    setAddress(order_details.pickup_address[addressIndex]);
                }else if(address.type === 'DROP_OFF'){
                    setAddress(order_details.dropoff_address[addressIndex]);
                }
            }
            setViewMeetupPoints(true);
            setAllowMultiPointSelect(false);
            handleShowOptionMultipleSelect();
        }
    };

    const handleMeetupProof = async (meetup_id: any, meetup_index: any) => {
        let dtlc;
        if(cookies.get('dtlc')){
            dtlc = cookies.get('dtlc');
            if(dtlc.user_data.role_id !== 3 && address.meetup_points[meetup_index].order_proof === null){
                return;
            }else{
                const { value } = await Preferences.get({ key: 'api-data' });
                if(value){
                    let order_details = JSON.parse(value);
                    if(order_details.status === 'ASSIGNED'){
                        return;
                    }
                }


                if(!address.meetup_points[meetup_index].order_proof){
                    setOrderMeetupPointId(meetup_id);
                }else{
                    let type;
                    if(address.type === 'PICK_UP'){
                        type = 'pickup';
                    }else if(address.type === 'DROP_OFF'){
                        type = 'dropoff';
                    }
        
                    setOrderMeetupPointId(type+'-'+addressIndex+'-'+meetup_index);
                }
                setViewMeetupPoints(false);
            }
        }else{
            return;
        }
    }

    const handleMultiPointSelect = () => {

        setAllowMultiPointSelect(true);
    }

    const handlePointCheck = (checked: any, index: any) => {
        let multi_point_arr = multiPointArr;
        if(checked){
            multi_point_arr.push(address.meetup_points[index].id);
        }else{
            if(multi_point_arr.indexOf(address.meetup_points[index].id) > -1){
                multi_point_arr.splice(multi_point_arr.indexOf(address.meetup_points[index].id), 1);
            }
        }

        let single_address = address;
        single_address.meetup_points[index].checked = checked;
        setAddress(single_address);

        multi_point_arr = JSON.stringify(multi_point_arr);
        multi_point_arr = JSON.parse(multi_point_arr);
        setMultiPointArr(multi_point_arr);
    }

    const handleCloseMultiPointSelect = async () => {
        let single_address = address;
        for await (let item of single_address.meetup_points){
            item.checked = false;
        }
        setAddress(single_address);
        setMultiPointArr([]);
        setAllowMultiPointSelect(false);
    }

    const handleCheckUncheckAll = async (type: any) =>{
        let single_address = address;
        let multi_point_arr = [];
        if(type === 'check-all'){
            for await (let item of single_address.meetup_points){
                item.checked = true;
                multi_point_arr.push(item.id);
            }
        }else if(type === 'uncheck-all') {
            multi_point_arr = [];
            for await (let item of single_address.meetup_points){
                item.checked = false;
            }

        }
        setAddress(single_address);
        setMultiPointArr(multi_point_arr);
    }

    return(
        <IonPage>
            <IonContent className="linear_bg">
                    {/* <AppHeader from=""/> */}
                    {viewMeetupPoints?
                        <IonHeader className="header_top ion-no-border">
                            <div className="meetup-header">
                                <IonButton className="header_back_button" onClick={()=>handleBack()}>
                                    <IonIcon icon={arrowBackOutline} size="large" />
                                </IonButton>
                            </div>
                        </IonHeader>
                        :
                        ''
                    }
                    <div className={viewMeetupPoints? 'view-meetup-wrapper' : ''}>
                    {viewMeetupPoints?
                        <>
                            <h2>View Meetup</h2>
                            {address && address.meetup_points.length > 1 && userDetails && userDetails.role_id === 3 && orderDetails && orderDetails.status !== 'ASSIGNED'?
                                <>
                                    <div className="single-proof-multi-points">
                                        {showOptionMultipleSelect? 
                                            <p onClick={()=>{handleMultiPointSelect()}}>Upload multiple view meetup proofs</p>
                                            :
                                            ''
                                        }
                                        {allowMultiPointSelect?
                                            <>
                                                <IonIcon icon={closeOutline} size="large" className="selection-decision close-multi-point-selection" onClick={()=>{
                                                    handleCloseMultiPointSelect();
                                                }} />
                                                {multiPointArr.length > 0?
                                                    <>
                                                        <IonIcon icon={checkmarkOutline} size="large" className="selection-decision save-multi-point-selection" onClick={()=>{
                                                            
                                                            setViewMeetupPoints(false);
                                                        }} />
                                                    </>
                                                    :
                                                    ''
                                                }
                                            </>
                                            :
                                            ''
                                        }
                                    </div>
                                    {allowMultiPointSelect?
                                        <div className="single-proof-multi-points">
                                            <p className="all-notall" onClick={()=> handleCheckUncheckAll('check-all')}>Check all</p>
                                            <p className="all-notall" onClick={()=> handleCheckUncheckAll('uncheck-all')}>Uncheck all</p>
                                        </div>
                                        :
                                        ''
                                    }
                                </>
                                :
                                ''
                            }
                            
                            <div className="meetup-points-box">
                                {address && address.meetup_points.map((item: any, index: any)=>{
                                        return(
                                            <div key={index} onClick={()=>{
                                                    if(!allowMultiPointSelect){
                                                        handleMeetupProof(item.id, index);   
                                                    }else{
                                                        console.log('akdflkfwiosf');
                                                    }
                                                }} className="meetup-cards-wrapper">
                                                <div className="meetup-card">
                                                    {allowMultiPointSelect && item.order_proof === null?
                                                        <IonCheckbox slot="start" checked={item.checked && item.checked === true? true : false} onIonChange={(e)=> {handlePointCheck(e.detail.checked, index)}} className={"md interactive"}></IonCheckbox> 
                                                        :
                                                        ''
                                                    }
                                                    <div>
                                                        <h4>{item.name}</h4>
                                                    </div>
                                                    <div className="d-flex">
                                                        <IonIcon src={stopwatchOutline} size="large"/>
                                                        <h6>{item.meetup_at}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        )     
                                    })
                                }
                            </div>
                        </>
                        :
                        <Order_Receiving proofView={proofView} orderAddressId={allowMultiPointSelect? multiPointArr : orderMeetupPointId}/>
                    }
                </div>
            </IonContent>
        </IonPage>
    )
}
export default View_Meetup_Points