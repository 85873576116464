import {
  IonCol,
  IonGrid,
  IonRow,
  IonImg,
  IonPage,
  IonContent,
  IonButtons,
  IonButton,
  IonToast,
  useIonViewWillEnter,
  IonLoading,
  useIonViewWillLeave,
} from "@ionic/react";
import "../../assets/scss/Desktop_Home.scss";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Cookies from "universal-cookie";
import { Preferences } from "@capacitor/preferences";
import { postRequest } from "../../ApiServices";

const Desktop_Home: React.FC = () => {

  const cookies = new Cookies();
  const history = useHistory();
  
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [bookingType, setBookingType] = useState<any>(null);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [deliveryTypes, setDeliveryTypes] = useState<any>([]);
  const [isShown, setIsShown] = useState(false);
  
  const urgentBox = useRef<any>(null);
  const sameDayBox = useRef<any>(null);
  const scheduleBox = useRef<any>(null);

  useEffect(()=>{

    return () => {
      setShowToast(false);
      setAlertMessage('');
      setBookingType(null);
      setShowLoading(true);
      setDeliveryTypes([]);
      setIsShown(false);
    };
  }, []);

  const handleNext = () => {
    if (bookingType) {
      history.push("/location");
    } else {
      setAlertMessage('Please select a booking type');
      setShowToast(true);
    }
  }

  useIonViewWillEnter(async () => {
    const { value } = await Preferences.get({ key: 'api-data' });
    if(value){
      await Preferences.remove({ key: 'api-data'});
    }

    // const order = await Preferences.get({ key: 'dtlc-order-details' });
    // if(order.value){
    //   await Preferences.remove({ key: 'dtlc-order-details'});
    // }

    setShowLoading(true);
    postRequest('delivery-types', {}, '').then(async (data)=>{
      if(data.status){
        setShowLoading(false);
        setDeliveryTypes(data.data);

        let order_details;
        const { value } = await Preferences.get({ key: 'dtlc-order-details' });
        if (value) {
          order_details = JSON.parse(value);
          setBookingType(order_details.type);
          boxHighlighting(order_details.type, data.data);
        }
      }else{
        setShowLoading(false);
        if(data.message){
          setAlertMessage(data.message);
          setShowToast(true);
        }
      }
    }).catch((err)=>{
      console.log(err);
    });
  });

  useIonViewWillLeave(()=>{
    urgentBox.current.className = "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
    sameDayBox.current.className = "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
    scheduleBox.current.className = "sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
    window.location.reload();
  });

  const boxHighlighting = (type:any, delivery_types:any = []) => {
    if(urgentBox.current){
      urgentBox.current.className ="sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
    }
    if(sameDayBox.current){
      sameDayBox.current.className ="sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
    }
    if(scheduleBox.current){
      scheduleBox.current.className ="sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
    }
    
    if(urgentBox.current && sameDayBox.current && scheduleBox.current){
      if (type === "urgent") {
        urgentBox.current.className ="sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios border_style";
      } else if (type === "same day") {
        sameDayBox.current.className ="sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios border_style";
      } else if (type === "schedule") {
        scheduleBox.current.className ="sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios border_style";
      }
    }
    
    delivery_types.forEach((item:any, index:any)=>{
      if(item.code === 'urgent_delivery' && !item.is_active){
        urgentBox.current.className ="disabled-btn sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
      }else if(item.code === 'same_day_delivery' && !item.is_active){
        sameDayBox.current.className ="disabled-btn sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
      }else if(item.code === 'schedule_delivery' && !item.is_active){
        scheduleBox.current.className ="disabled-btn sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios";
      }
    });
  }

  const handleClick = async (type: string) => {
    boxHighlighting(type);

    let order_details: any = {};
    const { value } = await Preferences.get({ key: 'dtlc-order-details' });
    if (value) {
      order_details = JSON.parse(value);
      order_details.type = type;
      setBookingType(type);
    }else{
      order_details.type = type;
      setBookingType(type);
    }
    await Preferences.set({
      key: 'dtlc-order-details',
      value: JSON.stringify(order_details),
    });
  };

  const passState = () => {
    setIsShown(!isShown);
  };

  // const handleNext = () => {
  //   if (bookingType) {
  //     history.push("/desktop_postcode");
  //   } else {
  //     setAlertMessage('Please select a booking type');
  //     setShowToast(true);
  //   }
  // }

  

  return (
    <IonPage>
      <IonContent>
        <div>
          <div className="desktop-home desktop-wrapper">
            <IonGrid className="ion-no-padding">
              <IonRow>
                <Sidebar passState={passState} />
                <IonCol size="12" sizeLg="9.5">
                  <div
                    className={
                      isShown
                        ? "rightbar ion-padding home-inner rightbar-none"
                        : "rightbar ion-padding home-inner"
                    }
                  >
                    <div className="delivery-wrapper">
                      <div className="wrapper-inner ion-padding">
                        <h3 className="ion-text-center">Delivery Types</h3>
                        <p className="ion-text-center">
                          Get approximate cost for delivery or pick of your parcel
                        </p>
                        <IonGrid className="delivery_boxes">
                          <IonRow>
                            {deliveryTypes.map((item:any, index: any)=>{
                              return(
                                <IonCol size="4" key={index}>
                                  <IonButtons
                                    className={item.is_active? '' : 'disabled-btn'}
                                    ref={item.name === 'Urgent Delivery'? urgentBox : item.name === 'Same Day Delivery'? sameDayBox : item.name === 'Schedule Delivery'? scheduleBox : urgentBox}
                                    onClick={() => {
                                      if(item.is_active){
                                        handleClick(item.name === 'Urgent Delivery'? 'urgent' : item.name === 'Same Day Delivery'? 'same day' : item.name === 'Schedule Delivery'? 'schedule' : '')
                                      }
                                    }}
                                  >
                                    <div>
                                      <IonImg
                                        src={item.name === 'Urgent Delivery'? require("../../assets/images/running.png") : item.name === 'Same Day Delivery'? require("../../assets/images/stopwatch.png") : item.name === 'Schedule Delivery'? require("../../assets/images/calendar.png") : ''}
                                      />
                                    </div>
                                    <h6>{item.name}</h6>
                                  </IonButtons>
                                </IonCol>
                              )
                            })}
                          </IonRow>
                        </IonGrid>
                        <IonButton expand="block" className="ion-margin-bottom" onClick={()=>{handleNext()}}>
                          Next
                        </IonButton>
                      </div>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(!showToast)}
        color="danger"
        message={alertMessage}
        duration={1500}
      />
    </IonPage>
  );
};

export default Desktop_Home;
