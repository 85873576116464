import { IonAvatar, IonButton, IonCol, IonContent, IonModal, IonHeader, IonIcon, IonImg, IonLoading, IonPage, IonRow, IonSelect, IonSelectOption, IonToast, useIonViewWillEnter, useIonViewWillLeave, IonButtons, IonTitle, IonToolbar } from '@ionic/react'
import { arrowBackOutline, createOutline, locationOutline, timeOutline } from 'ionicons/icons';
import React, { useState, useRef } from 'react';
import "../assets/scss/Driver_Profile_Edit.scss";
import { useHistory } from 'react-router';
import DriverOrderList from '../components/DriverOrderList';
import { BookingHeader } from '../components/BookingHeader';
import { postRequest } from '../ApiServices';
import Cookies from 'universal-cookie';
import { Preferences } from '@capacitor/preferences';
import { Plugins } from '@capacitor/core';
// import { FileOpener } from '@ionic-native/file-opener';
import { FileOpener } from '@capacitor-community/file-opener';
import { Directory, Filesystem } from '@capacitor/filesystem';
import axios from 'axios';
import Orders_List from '../components/Orders_List';
import { GoogleMap } from '@capacitor/google-maps';

const { Storage } = Plugins;


const Driver_Profile_Edit: React.FC = () => {
    const history = useHistory();
    const cookies = new Cookies();

    const mapRef = useRef<HTMLElement>();

    const [profileView, setProfileView] = useState(true);
    const [orderType, setOrderType] = useState('');
    const [showLoading, setShowLoading] = useState<any>(false);
    const [orders, setOrders] = useState<any>([]);
    const [driverProfile, setDriverProfile] = useState<any>({});
    const [toastMessage, setToastMessage] = useState<any>('');
    const [showToast, setShowToast] = useState<boolean>(false);

    const FILE_KEY = 'files';

    useIonViewWillEnter(async ()=>{
        const { value } = await Preferences.get({ key: 'profile-data' });
        if(value){
            setDriverProfile(JSON.parse(value));
        }
        loadFiles();
    });

    useIonViewWillLeave(()=>{

        setProfileView(true);

        Filesystem.readdir({
            directory: Directory.Data,
            path: FILE_KEY
        }).then(async result => {
            if(result.files.length > 0){
                for await (let file of result.files){
                    Filesystem.deleteFile({
                        directory: Directory.Data,
                        path: FILE_KEY+'/'+file.name
                    });
                }
            }
        }, async err => {
            console.log('err: ', err);
        }).then(_ => {
        })
    });

    const loadFiles = async () => {
        Filesystem.readdir({
            directory: Directory.Data,
            path: FILE_KEY
          }).then(result => {
            if(result.files.length <= 0){
              setShowLoading(false);
            }
            // loadFileData(result.files);
          }, async err => {
            console.log('err: ', err);
            await Filesystem.mkdir({
              directory: Directory.Data,
              path: FILE_KEY
            })
          }).then(_ => {
            setShowLoading(false);
          })
    }

    const getMimeType = (name: any) => {
        if(name.indexOf('pdf') >= 0){
            return 'application/pdf';
        }else if(name.indexOf('word') >= 0){
            return 'application/word';
        }else if(name.indexOf('excel') >= 0){
            return 'application/vnd.ms-excel';
        }else if(name.indexOf('png') >= 0){
            return 'image/png';
        }else if(name.indexOf('jpeg') >= 0 || name.indexOf('jpg') >= 0){
            return 'image/jpeg';
        }else if(name.indexOf('gif') >= 0){
            return 'image/gif';
        }else if(name.indexOf('mp4') >= 0){
            return 'video/mp4';
        }else {
            return false;
        }
    }

    const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader;
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

    const downloadFile = async (attachment_data: any, index: any) => {
        setShowLoading(true);

        let dtlc = cookies.get('dtlc');
        // https://app.dtlc.co.uk/api/app/
        //let urll = 'https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png';
        axios.post('https://app.dtlc.co.uk/api/app/drivers/checklist-download',
        {
            id: driverProfile.checklist[index].id
        },{
            responseType: "blob",
            headers: {
                "Access-Control-Allow-Origin": '*',
                "Accept" : "application/vnd.ms-excel, application/octet-stream, application/json, blob, text/plain, */*",
                "Authorization" : 'Bearer ' + dtlc.token.token
                // 'Access-Control-Allow-Origin': '*'
            },
        }).then(async (response)=>{
            // const fileName = url.substr(url.lastIndexOf('/') + 1);
            // const fileName = (Math.random() + 1).toString(36).substring(7);
            let fileName = (Math.random() + 1).toString(36).substring(7);
            fileName = fileName + attachment_data.type;
            const base64Data = await convertBlobToBase64(response.data) as string;
            
            const savedFile: any = await Filesystem.writeFile({
            directory: Directory.Data,
            path: `${FILE_KEY}/${fileName}`,
            data: base64Data
            }).then((res) => {
                setShowLoading(false);
                const mimeType: any = getMimeType(fileName);
                if(mimeType){
                    FileOpener.open({filePath: res.uri, contentType: mimeType, openWithDefault: true}).then((res)=> console.log('File is opened', res)).catch(error=>console.log('Error opening file', error));
                }
            });
        }).catch((err)=>{
            console.log(err);
        });
    }

    const handleOrderTypeClick = (type: any) => {
        setOrderType(type);
        setProfileView(false);
        setShowLoading(true);
        handleApiRequest(type);
    }

    const handleApiRequest = (type: any) => {
        setOrders([]);
        let dtlc = cookies.get('dtlc');
        if(dtlc && dtlc.token){
            postRequest('dispatcher/driver-orders-show', {
                driver_id : driverProfile.id,
                status : type
            }, dtlc.token.token).then((res: any)=>{
                if(res.status){
                    setShowLoading(false);
                    setOrders(res.data);
                }
            }).catch((err: any)=>{
                console.log(err);
            });
        }
    }

    const handleChecklistAction = (type: any, index: any) => {
        let dtlc = cookies.get('dtlc');
        if(dtlc && dtlc.token){
            let checklist = driverProfile.checklist[index];

            if(type === 'download'){
                downloadFile(checklist.driver_checklist.latest_attachment, index);
            }else if(type === 'accept'){
                setShowLoading(true);
                postRequest('drivers/checklist-approve-or-reject', {
                    id: checklist.id,
                    status: 'APPROVED'
                }, dtlc.token.token).then((res)=>{
                    if(res.status){
                        setToastMessage(res.message);
                        setShowLoading(false);
                        setShowToast(true);
                    }
                }).catch(err=>console.log(err));
            }else if(type === 'decline'){
                setShowLoading(true);
                postRequest('drivers/checklist-approve-or-reject', {
                    id: checklist.id,
                    status: 'REJECTED'
                }, dtlc.token.token).then((res)=>{
                    if(res.status){
                        setToastMessage(res.message);
                        setShowLoading(false);
                        setShowToast(true);
                    }
                }).catch(err=>console.log(err));
            }else if(type === 'remind'){
                setShowLoading(true);
                postRequest('drivers/checklist/reminder', {
                    checklist_id: checklist.id,
                    driver_id: driverProfile.id
                }, dtlc.token.token).then((res)=>{
                    if(res.status){
                        setToastMessage(res.message);
                        setShowLoading(false);
                        setShowToast(true);
                    }
                }).catch(err=>console.log(err));
            }
        }
    }

  return (
    <IonPage>
        <IonContent className='linear_bg'>
            {profileView?
            <>
            <BookingHeader from=''/>
            <div className='driver-profile-edit-wrapper'>
                <h2>Driver Profile</h2>
                <IonRow>
                    <IonCol>
                        <div className="profile-header">
                            <IonButton className="profile-btn" shape='round' onClick={() => {history.push('/driver_profile');}}>View Profile</IonButton>
                            <IonAvatar className="avatar-img position-relative">
                                <IonImg src={driverProfile.image_url} />
                            </IonAvatar>
                            <h4>{driverProfile.full_name}</h4>
                            <IonButton onClick={()=> history.push('/driver_tracking')}>Find on map</IonButton>
                        </div>
                    </IonCol>
                </IonRow>
                <div className='time-wrapper'>
                    <h4>Select order type</h4>
                    <IonRow>
                        <IonCol>
                            <IonAvatar onClick={()=> {
                                handleOrderTypeClick('ASSIGNED');
                            }}>
                            <IonImg src={require('../assets/images/wall-clock.png')}/>
                            </IonAvatar>
                            <h6>Pending</h6>
                        </IonCol>
                        <IonCol>
                            <IonAvatar onClick={()=> {
                                handleOrderTypeClick('TRANSFERRED');
                            }}>
                            <IonImg src={require('../assets/images/data-processing.png')}/>
                            </IonAvatar>
                            <h6>In Process</h6>
                        </IonCol>
                        <IonCol>
                            <IonAvatar onClick={()=>{
                                handleOrderTypeClick('CANCELLED');
                            }}>
                            <IonImg src={require('../assets/images/cancelled.png')}/>
                            </IonAvatar>
                            <h6>Cancelled</h6>
                            </IonCol>
                        <IonCol>
                            <IonAvatar onClick={()=>{
                                handleOrderTypeClick('DELIVERED');
                            }}>
                            <IonImg src={require('../assets/images/box.png')}/>
                            </IonAvatar>
                            <h6>Delivered</h6>
                        </IonCol>
                    </IonRow>
                </div>
                <div className="driver-documents">
                    <h4>Driver documents</h4>
                    <div className="driver-detail">
                        <IonRow>
                            {driverProfile.checklist && driverProfile.checklist.map((item: any, index: any)=>{
                                return(
                                    <IonCol size='4' key={index}>
                                        <div className="driver-content">
                                            {item.driver_checklist && item.driver_checklist.latest_attachment?
                                                <>
                                                    {item.driver_checklist.latest_attachment.type === 'pdf'?
                                                        <IonImg src={require(`../assets/images/pdf.png`)}/>
                                                        :
                                                        item.driver_checklist.latest_attachment.type === 'jpg' || item.driver_checklist.latest_attachment.type === 'jpeg'?
                                                            <IonImg src={require(`../assets/images/jpg.png`)}/>
                                                        :
                                                        item.driver_checklist.latest_attachment.type === 'png'?
                                                            <IonImg src={require(`../assets/images/png.png`)}/>
                                                        :
                                                        item.driver_checklist.latest_attachment.type === 'gif'?
                                                            <IonImg src={require(`../assets/images/gif.png`)}/>
                                                        :
                                                        item.driver_checklist.latest_attachment.type === 'word'?
                                                            <IonImg src={require(`../assets/images/word.png`)}/>
                                                        :
                                                        item.driver_checklist.latest_attachment.type === 'excel'?
                                                            <IonImg src={require(`../assets/images/excel.png`)}/>
                                                        :
                                                            <IonImg src={require(`../assets/images/file-icon.png`)}/>
                                                    }
                                                    
                                                    <p>{item.driver_checklist.latest_attachment.file_name}</p>
                                                    <h6>{item.name}</h6>
                                                    <IonButton shape="round" size="small" onClick={()=> handleChecklistAction('download', index)}>Download</IonButton>        
                                                    <IonButton className='accept-btn' shape="round" size="small" onClick={()=> handleChecklistAction('accept', index)}>Accept</IonButton>
                                                    <IonButton className='decline-btn' shape="round" size="small" onClick={()=> handleChecklistAction('decline', index)}>Decline</IonButton>
                                                    <IonButton className='remind-btn' shape="round" size="small" onClick={()=> handleChecklistAction('remind', index)}>Remind</IonButton>
                                                </>
                                                :
                                                <>
                                                    <IonImg src={require('../assets/images/file-icon.png')}/>
                                                    <h6>{item.name}</h6>
                                                    <IonButton className='remind-btn' shape="round" size="small" onClick={()=> handleChecklistAction('remind', index)}>Remind</IonButton>
                                                </>
                                            }
                                        </div>
                                    </IonCol>
                                )
                            })}
                        </IonRow>
                    </div>
                </div>
            <div className='customer-history'>
                <h4>Driver's Recent Activity</h4>
                {driverProfile.activities && driverProfile.activities.length > 0? 
                    driverProfile.activities.map((item: any,index: any)=>{
                        return(
                            <div className='card' key={index}>
                                <IonRow>
                                {/* <IonCol size='3'>
                                    <div className='driver-image'>
                                    <IonImg src={require('../assets/images/driver-job-description.jpg')}/>
                                    </div>
                                </IonCol> */}
                                <IonCol size='12'>
                                    <div className='driver-disc'>
                                        {item.order?
                                            <div>
                                                <h4>#{item.order.order_number}</h4>
                                                <p>{item.order.status}</p>
                                            </div>   
                                            :
                                            ''
                                        }
                                        {/* <IonButton shape="round">Available</IonButton> */}
                                    </div>
                                </IonCol>
                                </IonRow>
                            </div>
                        );
                    })
                    :
                    <h6>No recent activity found</h6>
                }
            </div>
                </div>
            </>
            :
            <>  
                <IonHeader className="header_top ion-no-border">
                <div className="header_toolbar">
                    <IonButton
                    className="header_back_button"
                    onClick={()=> setProfileView(true)}
                    >
                    <IonIcon icon={arrowBackOutline} size="large" />
                    </IonButton>
                </div>
                </IonHeader>
                <div className='driver-profile-edit-wrapper'>
                    <h2 className='ion-margin-bottom'>{orderType === 'in-process' ? 'In Process' : orderType.charAt(0).toUpperCase() + (orderType.slice(1)).toLowerCase()} orders</h2>
                    {/* <DriverOrderList Orders={orders} OrderType={orderType}/> */}
                    {orders.length > 0 ?
                        <Orders_List Orders={orders} />
                        :
                        !showLoading ?
                        <h3>No orders found</h3>
                        :
                        ''
                    }
                </div>
            </>    
        }
        </IonContent>
        <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Please wait..."}
        />
        <IonToast
          isOpen={showToast}
          position="bottom"
          onDidDismiss={() => setShowToast(false)}
          color={"primary"}
          message={toastMessage}
          duration={2500}
        />
    </IonPage>
  )
}

export default Driver_Profile_Edit
