import React, { useRef, useState } from 'react';
import "../assets/scss/Order_Overview_Buttons.scss";
import {
    IonButton,
    IonModal,
    useIonAlert,
    IonTextarea,
    IonItem,
    IonImg,
    IonList,
    IonSelect,
    IonSpinner,
    IonSelectOption,
    useIonViewWillEnter,
    IonAvatar,
    IonLoading,
    IonSearchbar,
    IonToast
  } from '@ionic/react';
// import { useHistory } from 'react-router';
import Cookies from "universal-cookie";
import { postRequest } from '../ApiServices';
import { Preferences } from '@capacitor/preferences';
import { validater } from '../validate';
import { searchOutline } from 'ionicons/icons';
import { Geolocation } from '@capacitor/geolocation';
import { LaunchNavigator, LaunchNavigatorOptions } from '@awesome-cordova-plugins/launch-navigator';
import { useHistory } from 'react-router';

const Order_Overview_Buttons = () => {
    const history = useHistory();
    // const modal = useRef<HTMLIonModalElement>(null);
    const cookies = new Cookies();
    const delayBoxRef = useRef<Array<HTMLElement>>([]);
    const textareaRef = useRef<any>();

    const [userDetails, setUserDetails] = useState<any>({});
    const [orderModal, setOrderModal] = useState<any>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showIonLoading, setShowIonLoading] = useState<boolean>(false);
    const [drivers, setDrivers] = useState<any>([]);
    const [orderDetails, setOrderDetails] = useState<any>();
    const [searchedDrivers, setSearchedDrivers] = useState<any>([]);
    const [jobAssignment, setJobAssignment] = useState<boolean>(false);
    const [reason, setReason] = useState<any>('');
    const [delayReason, setDelayReason] = useState<any>('');
    const [delayReasonTemplates, setDelayReasonTemplates] = useState<any>(['Heavy traffic jam', 'Severe weather condition', 'Accident occured', 'Flat tyre', 'Vehicle broke down', 'Illnes', 'Other']);
    const [showDelayReasonModal, setShowDelayReasonModal] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<any>('');
    const [showOrderRequestButtons, setShowOrderRequestButtons] = useState<boolean>(true);
    
    const [presentAlert] = useIonAlert();

    useIonViewWillEnter(async ()=>{
        let dtlc = cookies.get('dtlc');
        if(dtlc && dtlc.token){
            setUserDetails(dtlc);
        }

        const { value } = await Preferences.get({ key: 'api-data' });
        let order_details;
        if(value){
            order_details = JSON.parse(value);
            // let order_number;
            // order_number = order_details.order_number;
            setOrderDetails(order_details);
        }
    });

    const handleTransferOrder = () => {
        let result = validater({
            reason: reason
        });
        if(result.status){
            if(orderModal === 'decline'){
                setShowModal(false);
                setShowIonLoading(true);
                postRequest('jobs/accept-or-decline', {
                    id: orderDetails.transfer_id,
                    status: 'DECLINED_BY_DRIVER',
                    declined_reason: reason
                }, userDetails.token.token).then(async (res)=>{
                    if(res.status){
                        let order_details = orderDetails;
                        order_details.status = 'DECLINED_BY_DRIVER';
                        setOrderDetails(order_details);
                        setToastMessage(res.message);
                        setShowToast(true);
                        await Preferences.set({
                            key: 'api-data',
                            value: JSON.stringify(order_details),
                        });
                        setShowIonLoading(false);
                    }
                }).catch((err)=>{
                    console.log(err);
                });
            }else{
                setShowLoading(true);
                setOrderModal('pick-driver');
                getAllDrivers();
                setJobAssignment(false);
            }
        }else{
            setToastMessage(result.message);
            setShowToast(true);
        }
    }

    const handleOrderActionBtnClick=(type:any)=>{
        let status;
        setOrderModal(type);
        if(type === 'assign'){
            setShowModal(true);
            getAllDrivers();
            setJobAssignment(false);
        }else if(type === 'accepted'){
            handleApiRequest('jobs/accept-or-decline', {
                id: orderDetails.transfer_id,
                status: 'ACCEPTED',
            });
        }else if(type === 'decline'){
            setShowModal(true);
        }else if(type === 'transferred'){
            setShowModal(true);
        }else if(type === 'decline-by-dispatcher'){
            status = 'DECLINED';
            acceptOrRejectByDriver(status);
        }else if(type === 'accept-by-dispatcher'){
            status = 'APPROVED';
            acceptOrRejectByDriver(status);
        }else if(type === 'completed-by-dispatcher'){
            status = 'COMPLETED';
            let dtlc = cookies.get('dtlc');
            if(dtlc && dtlc.token){
                setShowIonLoading(true);
                postRequest('dispatcher/update-order-status',{
                    id: orderDetails.id,
                    status: 'COMPLETED',
                }, dtlc.token.token).then(res=>{
                    if(res.status){
                        let order_details = orderDetails;
                        order_details.status = 'COMPLETED';
                        setOrderDetails(order_details);
                        setShowIonLoading(false);
                    }
                    
                }).catch(err=>console.log(err));
            }
        }else if(type === 'delayed-by-driver'){
            console.log(orderDetails);
            setShowDelayReasonModal(true);
        }
    }
    const acceptOrRejectByDriver = (status: any) => {
        setShowIonLoading(true);
        let dtlc = cookies.get('dtlc');
        if(dtlc && dtlc.token){

            postRequest('dispatcher/order-request-approved-or-declined', {
                id: orderDetails.id,
                status: status
            }, dtlc.token.token).then(res=>{
                if(res.status){
                    let order_details = orderDetails;
                    if(status === 'DECLINED'){
                        order_details.status = status;
                    }else{
                        order_details.status = 'PENDING';
                    }
                    
                    setOrderDetails(order_details);
                    setToastMessage(res.message);
                    setShowToast(true);
                    setShowIonLoading(false);
                }
            }).catch(err=>console.log(err));
        }
    }

    const handleApiRequest = (url: any, data: any) => {
        setShowIonLoading(true);
        postRequest(url, data,userDetails.token.token).then((res: any)=>{
            if(res.status){
                let order_details = orderDetails;
                order_details.status = data.status;
                setOrderDetails(order_details);
                setShowIonLoading(false);
                setShowModal(true);
            }
        }).catch((err: any)=>{
            console.log(err);
        })
    }

    const getAllDrivers = () => {
        setShowLoading(true);
        postRequest('drivers/all',{},userDetails.token.token).then((res: any)=>{
            if(res.status){
                setShowLoading(false);
                setDrivers(res.data);
            }
        }).catch((err: any)=>{
            console.log(err);
        })
    }

    const handleClearSearch = () => {
        setSearchedDrivers([]);
    }

    const handleSearchInput = async (input: any) => {
        if(input === '' || input === ' '){
            setSearchedDrivers([]);
        }else{
            if(orderModal === 'pick-driver'){
                let index = 0;
                for await (let driver of drivers) {
                    if(driver.id === userDetails.user_data.id){
                        break;
                    }
                    index ++;
                }
                let all_drivers = drivers;
                all_drivers.splice(index, 1);
                let searched_drivers = all_drivers.filter((driver: any) => driver.full_name.includes(input) || driver.call_sign.includes(input));
                setSearchedDrivers(searched_drivers);
            }else{
                let searched_drivers = drivers.filter((driver: any) => driver.full_name.includes(input) || driver.call_sign.includes(input));
                setSearchedDrivers(searched_drivers);
            }
        }
    }

    const handleDriverSelect = (driverId: any, fullName: any, callSign: any, index: any, searched_driver: boolean) => {
        presentAlert({
            header: 'Are you sure?',
            message: `<p>This action will assign this job to <b>${fullName}(${callSign})</b></p>`,
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  
                },
              },
              {
                text: 'OK',
                role: 'confirm',
                handler: () => {
                    setShowModal(false);
                    jobAssignToDriver(driverId, index, searched_driver);
                    // if(reason !== ''){
                    //     jobTransferToDriver(driverId);
                    // }else{
                        
                    // }
                },
              },
            ],
            onDidDismiss: (e: CustomEvent) => {},
          })
    }

    const jobAssignToDriver = (driver_id: any, index: any, searched_driver: boolean) => {
        setShowLoading(true);
        setShowIonLoading(true);
        let url;
        let order_id;
        let data: any = {};
        if(orderModal === 'assign'){
            url = 'dispatcher/job-assign';
            order_id = orderDetails.id;
            data.id = orderDetails.id;
            data.driver_id = driver_id;
        }else if(orderModal === 'pick-driver'){
            url = 'jobs/transfer';
            order_id = [orderDetails.transfer_id];
            data.id = [orderDetails.transfer_id];
            data.driver_id = driver_id;
            data.transferred_reason = reason;
        }
        postRequest(url, data, userDetails.token.token).then(async (res)=>{
            if(res.status){
                setShowLoading(false);
                setJobAssignment(true);
                // setOrderModal('transfer-success');
                let order_details = orderDetails;
                order_details.driver_id = driver_id;
                // let d;
                // for await(let driver of drivers){
                //     if(driver.id === driver_id){
                //         d = driver;
                //         break;
                //     }
                // }
                // let driver = drivers.filter((driver: any) => driver.id === driver_id);

                if(orderModal === 'pick-driver'){
                    order_details.driver = {};
                }else{
                    order_details.driver = searched_driver? searchedDrivers[index] : drivers[index];
                }
                await Preferences.set({
                    key: 'api-data',
                    value: JSON.stringify(order_details),
                });
                setOrderDetails(order_details);
                setShowIonLoading(false);
                setShowModal(true);
                setReason('');
            }
        }).catch((err)=>{console.log(err)});
    }

    const handleMapView = async () =>{
        let index = 0;
        let way_points = '';
        for await (let address of orderDetails.pickup_address){
            if(index === 0){
                way_points = way_points + address.latitude + ',' + address.longitude;
            }else{
                way_points = way_points + '+to:' + address.latitude + ',' + address.longitude;
            }
            index ++;
        }

        for await (let address of orderDetails.dropoff_address){
            if(index === 0){
                way_points = way_points + address.latitude + ',' + address.longitude;
            }else{
                way_points = way_points + '+to:' + address.latitude + ',' + address.longitude;
            }
            index ++;
        }

        const coordinates = await Geolocation.getCurrentPosition();

        let current_location = coordinates.coords;
        let options: LaunchNavigatorOptions = {
            start: `${current_location.latitude}, ${current_location.longitude}`,
            app: LaunchNavigator.APP.GOOGLE_MAPS
          }
          
        LaunchNavigator.navigate(way_points, options)
        .then(
            success => console.log('Launched navigator'),
            error => console.log('Error launching navigator', error)
        );
    }

    const handleDelayReasonSelect = async (index: any) => {
        for await (let item of delayBoxRef.current) {
            item.className = 'delay-reason-box';
        }
        
        delayBoxRef.current[index].className = 'delay-active-reason';
        if(delayBoxRef.current[index].innerText !== 'Other'){
            setDelayReason(delayBoxRef.current[index].innerText);
            textareaRef.current.style.display = 'none';
        }else if(delayBoxRef.current[index].innerText === 'Other'){
            setDelayReason('');
            textareaRef.current.style.display = 'block';
        }
    }

    const handleSubmitDelayReason = () => {
        if(delayReason === ''){
            setToastMessage('Delay reason is required');
            setShowToast(true);
        }else{
            setShowDelayReasonModal(false);
            setShowLoading(true);
            postRequest('order/delay', {
                order_id: orderDetails.id,
                delayed_reason: delayReason
            }, userDetails.token.token).then(async (res)=>{
                if(res.status){
                    let order_details = orderDetails;
                    order_details.is_delayed = 1;
                    order_details.delayed_reason = delayReason;
                    await Preferences.set({
                        key: 'api-data',
                        value: JSON.stringify(order_details),
                    });
                    setShowLoading(false);
                    setDelayReason('');
                    setToastMessage(res.message);
                    setShowToast(true);
                }
            }).catch((err)=>{console.log(err)});
        }
    }
   
  return (
    <>
        <div className='order-buttons-wrapper'>
            {orderDetails && (orderDetails.status === 'CANCELLED' || orderDetails.status === 'DECLINED')?
                <div>
                    <h5><b>(CANCELLED)</b></h5>
                </div>
                :
                ''
            }
            
            {userDetails.user_data && orderDetails && userDetails.user_data.role_id === 5 && orderDetails && orderDetails.driver_id === null && orderDetails.status === 'PENDING'?
                <IonButton onClick={()=>handleOrderActionBtnClick("assign")}>Assign to driver</IonButton>
                :
                orderDetails && orderDetails.driver && userDetails && userDetails.user_data.role_id === 5 && orderDetails.user?
                <>
                    <h4>Assigned to: {`${orderDetails.driver.full_name} (${orderDetails.driver.call_sign})`}</h4>   
                </>
                :
                ''
            }

            {orderDetails && orderDetails.driver && userDetails.user_data && userDetails.user_data.role_id === 3 && orderDetails.status === 'ASSIGNED' && orderDetails.driver_id === userDetails.user_data.id?
                <>
                    <IonButton onClick={()=>handleOrderActionBtnClick("accepted")}>Accept</IonButton>
                    <IonButton onClick={()=>handleOrderActionBtnClick("decline")}>Decline</IonButton>
                    <IonButton onClick={()=>handleOrderActionBtnClick("transferred")}>Transfer</IonButton>
                </>
                :
                orderDetails && orderDetails.driver && userDetails.user_data && userDetails.user_data.role_id === 3 && orderDetails.driver_id === userDetails.user_data.id && (orderDetails.status === 'ACCEPTED' || orderDetails.status === 'ASSIGNED')? 
                    // <IonButton onClick={()=>handleOrderActionBtnClick("transferred")}>Transfer</IonButton>
                    ''
                :
                orderDetails && userDetails.user_data && userDetails.user_data.role_id === 5 && orderDetails.status === 'PENDING_APPROVAL'?
                    <>
                        <IonButton onClick={()=>handleOrderActionBtnClick("accept-by-dispatcher")}>Accept</IonButton>
                        <IonButton onClick={()=>handleOrderActionBtnClick("decline-by-dispatcher")}>Decline</IonButton>
                    </>
                :
                ''
            }

            {orderDetails && orderDetails.status === 'DELIVERED' && userDetails.user_data.role_id === 5?
                <IonButton onClick={()=>handleOrderActionBtnClick("completed-by-dispatcher")}>Mark as complete</IonButton>
                :
                ''
            }

            {orderDetails && orderDetails.driver && userDetails.user_data && userDetails.user_data.role_id === 3 && orderDetails.driver_id === userDetails.user_data.id && orderDetails.status === 'TRANSFERRED' && !orderDetails.is_delayed?
                <IonButton onClick={()=>handleOrderActionBtnClick("delayed-by-driver")}>Mark as delayed</IonButton>
                :
                ''
            }

            <IonButton className ="secondary-btn" onClick={()=>handleMapView()}>View on map</IonButton>
            
            {/* <IonButton onClick={()=>handleOrderActionBtnClick("status")}>Changed Status</IonButton>
            <IonButton>Scan & Complete</IonButton>
            <IonButton onClick={()=>handleOrderActionBtnClick("accepted")}>Accept</IonButton>
            <IonButton onClick={()=>handleOrderActionBtnClick("transferred")}>Transfer</IonButton>
            <IonButton onClick={()=>handleOrderActionBtnClick("decline")}>Decline</IonButton> */}
                
                <IonModal class="order-overview-modal" onDidDismiss={()=> {
                    setShowDelayReasonModal(false);
                    }} isOpen={showDelayReasonModal}>
                    <div className='linear_bg'>
                        <div className='ion-padding'>
                            <h3 className='ion-no-margin'>Delay reason:</h3>
                            <div className='delay-template-box'>
                                {delayReasonTemplates.map((item: any, index: any)=>{
                                    return(
                                        <div key={index} ref={(el: any) => delayBoxRef.current[index] = el} onClick={()=>{
                                            handleDelayReasonSelect(index);
                                        }} className='delay-reason-box'>{item}</div>
                                    );
                                })}
                            </div>
                            <div className='reason-textarea-box'>
                                <textarea className='reason-textarea' ref={textareaRef}></textarea>
                            </div>
                            <div>
                                <IonButton onClick={() => {
                                    handleSubmitDelayReason();
                                }}>
                                    Submit
                                </IonButton>
                            </div>
                        </div>
                    </div>
                </IonModal>

                <IonModal class="order-overview-modal" onDidDismiss={()=> {
                    setShowModal(false);
                    setJobAssignment(false);
                    }} isOpen={showModal}>
                    <div className='linear_bg'>
                        
                        <div className='ion-padding'>
                            
                        {
                            orderModal === 'status'?
                            <>
                            
                                <h3 className='ion-no-margin'>Order Status</h3>
                                <IonList>
                                    <IonItem>
                                        <IonSelect interface="popover" placeholder="Select">
                                        <IonSelectOption value="picked">Picked </IonSelectOption>
                                        <IonSelectOption value="delivered">Delivered</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </IonList> 
                                <IonButton>Update</IonButton>    
                            
                            </>
                            :
                                orderModal === 'completed-by-dispatcher'?
                                <>
                                    <IonImg src={require('../assets/images/verified.png')} />
                                    <h4 className="ion-text-center">Order: #{orderDetails.order_number}</h4>
                                    <h1 className='ion-text-center'>Order Completed</h1>                 
                                </>
                            :
                                orderModal === 'accepted'?
                                <>
                                    <IonImg src={require('../assets/images/verified.png')} />
                                    <h4 className="ion-text-center">Order: #{orderDetails.order_number}</h4>
                                    <h1 className='ion-text-center'>Order Accepted</h1>                 
                                </>
                            :
                                orderModal === 'transferred'?
                                <>
                                    <h4>Reason:</h4>
                                    <IonItem lines='none'>
                                        <IonTextarea
                                            placeholder="Type reason here"
                                            autoGrow={true}
                                            rows={10}
                                            onIonChange={(e)=> setReason(e.detail.value)}
                                        ></IonTextarea>
                                    </IonItem>
                                    <IonButton onClick={() => handleTransferOrder()}>
                                        Transfer
                                    </IonButton>
                                </>
                                :
                                orderModal === 'decline'?
                                <>
                                    <h4>Reason:</h4>
                                    <IonItem lines='none'>
                                        <IonTextarea
                                            placeholder="Type reason here"
                                            autoGrow={true}
                                            rows={10}
                                            onIonChange={(e)=> setReason(e.detail.value)}
                                        ></IonTextarea>
                                    </IonItem>
                                    <IonButton onClick={() => handleTransferOrder()}>
                                        Decline
                                    </IonButton>
                                </>
                                :
                                orderModal === 'assign'?
                                    jobAssignment?
                                        <>
                                            <IonImg src={require('../assets/images/verified.png')} />
                                            <h4 className="ion-text-center">Order: #{orderDetails.order_number}</h4>
                                            <h1 className='ion-text-center'>Order Assigned</h1>  
                                        </>
                                    :
                                        showLoading?
                                        <div className='spinner-wrapper'>
                                            <IonSpinner name="circular"></IonSpinner>
                                        </div>
                                        :
                                            <>
                                                <h4>Assign Driver</h4>
                                                <IonSearchbar searchIcon={searchOutline} placeholder="Name or call sign" onIonClear={()=>handleClearSearch()} onIonChange={(e)=>handleSearchInput(e.detail.value)}></IonSearchbar>
                                                <IonList className="driver-list">
                                                    {searchedDrivers.length > 0?
                                                        searchedDrivers.map((item: any, index: any)=>{
                                                            return(
                                                                <IonItem key={index} onClick={()=>{handleDriverSelect(item.id, item.full_name, item.call_sign, index, true)}}>
                                                                    <div>
                                                                        <IonAvatar>
                                                                            <IonImg src={item.image_url} />
                                                                        </IonAvatar>
                                                                    </div>
                                                                    <div>
                                                                        <h5>{item.full_name}</h5>
                                                                        <h5>{item.call_sign}</h5>
                                                                    </div>
                                                                </IonItem>
                                                            )
                                                        })
                                                        :
                                                        drivers.map((item: any, index: any)=>{
                                                            return(
                                                                <IonItem key={index} onClick={()=>{handleDriverSelect(item.id, item.full_name, item.call_sign, index, false)}}>
                                                                    <div>
                                                                        <IonAvatar>
                                                                            <IonImg src={item.image_url} />
                                                                        </IonAvatar>
                                                                    </div>
                                                                    <div>
                                                                        <h5>{item.full_name}</h5>
                                                                        <h5>{item.call_sign}</h5>
                                                                    </div>
                                                                </IonItem>
                                                            )
                                                        })
                                                    }
                                                </IonList>
                                            </>
                                :
                                orderModal === 'pick-driver' && reason !== ''?
                                    jobAssignment?
                                    <>
                                        <IonImg src={require('../assets/images/verified.png')} />
                                        <h4 className="ion-text-center">Order: #{orderDetails.order_number}</h4>
                                        <h1 className='ion-text-center'>Order Transferred</h1>
                                    </>
                                    :
                                    showLoading?
                                    <div className='spinner-wrapper'>
                                        <IonSpinner name="circular"></IonSpinner>
                                    </div>
                                    :
                                    <>
                                        <h4>Transfer to driver</h4>
                                        <IonSearchbar searchIcon={searchOutline} placeholder="Name or call sign" onIonClear={()=>handleClearSearch()} onIonChange={(e)=>handleSearchInput(e.detail.value)}></IonSearchbar>
                                        <IonList className="driver-list">
                                            {searchedDrivers.length > 0? 
                                                searchedDrivers.map((item: any, index: any)=>{
                                                    return(
                                                        <IonItem key={index} onClick={()=>{handleDriverSelect(item.id, item.full_name, item.call_sign, index, true)}}>
                                                            <div>
                                                                <IonAvatar>
                                                                    <IonImg src={item.image_url} />
                                                                </IonAvatar>
                                                            </div>
                                                            <div>
                                                                <h5>{item.full_name}</h5>
                                                                <h5>{item.call_sign}</h5>
                                                            </div>
                                                        </IonItem>
                                                    )
                                                })
                                                :
                                                drivers.map((item: any, index: any)=>{
                                                    if(userDetails && userDetails.user_data.role_id === 3 && userDetails.user_data.id !== item.id){
                                                        return(
                                                            <IonItem key={index} onClick={()=>{handleDriverSelect(item.id, item.full_name, item.call_sign, index, false)}}>
                                                                <div>
                                                                    <IonAvatar>
                                                                        <IonImg src={item.image_url} />
                                                                    </IonAvatar>
                                                                </div>
                                                                <div>
                                                                    <h5>{item.full_name}</h5>
                                                                    <h5>{item.call_sign}</h5>
                                                                </div>
                                                            </IonItem>
                                                        )
                                                    }else{
                                                        return('')
                                                    }
                                                })
                                            }
                                        </IonList>
                                    </>
                                :
                                orderModal === 'pick-driver' && reason === ''?
                                    <>
                                        <IonImg src={require('../assets/images/verified.png')} />
                                        <h4 className="ion-text-center">Order: #{orderDetails.order_number}</h4>
                                        <h1 className='ion-text-center'>Order Transferred</h1>
                                    </>
                                    :
                                    ''
                        }
                        </div>
                    </div>
                </IonModal>
                <IonLoading
                    isOpen={showIonLoading}
                    onDidDismiss={() => setShowIonLoading(false)}
                    message={"Please wait..."}
                />
                <IonToast
                    isOpen={showToast}
                    position="bottom"
                    onDidDismiss={() => setShowToast(false)}
                    color={"danger"}
                    message={toastMessage}
                    duration={2500}
                />
        </div>
    </>
  )
}

export default Order_Overview_Buttons
