import {
    IonAvatar,
    IonContent,
    IonImg,
    IonLoading,
    IonPage,
    useIonViewWillEnter,
} from "@ionic/react";

import { AppHeader } from "../components/AppHeader";
import Menu from "../components/Menu";
import Jobs_History_Component from "../components/Jobs_History_Component";
import Cookies from "universal-cookie";
import { useState } from "react";
import { postRequest } from "../ApiServices";
import Pending_Orders_List from "../components/Pending_Orders_List";
import Orders_List from "../components/Orders_List";
const Jobs_History: React.FC = () => {
    const cookies = new Cookies();
    const [showLoading, setShowLoading] = useState<any>(true);
    const [customerOrders, setCustomerOrders] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [orderTransfer, setOrderTransfer] = useState(false);

    useIonViewWillEnter(() => {
        let dtlc = cookies.get('dtlc');
        if (dtlc && dtlc.token) {
          postRequest("jobs/history", {}, dtlc.token.token)
            .then((res: any) => {
              setShowLoading(false);
              if (res.status) {
                setCustomerOrders(res.data);
              }
            })
            .catch((err: any) => {
              console.log(err);
            });
        }
      });

    return (
        <IonPage >
            <IonContent fullscreen className="linear_bg">
                <AppHeader from=''/>
                <div className="job-history">
                    {/* <Menu />
                    <Jobs_History_Component/> */}
                    <h2 className='ion-text-center ion-margin-bottom'>Jobs History</h2>
                    {customerOrders.length> 0?
                        // <Pending_Orders_List stateValue = {orderTransfer} Orders={customerOrders}/>
                        <Orders_List Orders={customerOrders}/>
                        :
                        !showLoading? 
                            <h3>No orders found</h3>
                            :
                            ''
                    }
                </div>
            </IonContent>
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={"Please wait..."}
            />
        </IonPage>
    );
};

export default Jobs_History;
