import { Preferences } from "@capacitor/preferences";
import { IonButton, IonContent, IonHeader, IonIcon, IonPage, useIonViewWillEnter } from "@ionic/react";
import { arrowBackOutline, call, mail, person, stopwatchOutline } from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router";
import Cookies from "universal-cookie";
import "../assets/scss/Meetup_Scan.scss";
import Order_Receiving from "../components/Order_Receiving";

const Meetup_Scan: React.FC = () =>{
    const cookies = new Cookies();
    const history: any = useHistory();

    const [scanStep, setScanStep] = useState<any>('overview');
    const [userDetails, setUserDetails] = useState<any>('');
    const [orderDetails, setOrderDetails] = useState<any>(null);
    const [meetupId, setMeetupId] = useState<any>('');
    const [meetupPoints, setMeetupPoints] = useState<any>([]);

    useIonViewWillEnter(async ()=>{
        let dtlc = cookies.get('dtlc');
        if(dtlc && dtlc.token){
            setUserDetails(dtlc);
        }
        const { value } = await Preferences.get({ key: 'api-data' });
        if(value){
            setOrderDetails(JSON.parse(value));
        }
    });

    const handleBack = () => {
        if(scanStep === 'overview'){
            history.goBack();
        }else if(scanStep === 'meetup-points'){
            setScanStep('overview');
        }
    }

    const handleSetScanStep = (step: any) => {
        setScanStep(step);
    }

    const handleViewMeetupPoints = (address: any) => {
        setMeetupPoints(address.order_meetup_points);
        setScanStep('meetup-points');
    }

    const proofView = (args: any) => {
        setMeetupId(args);
        if(args === 'back'){
            setScanStep('meetup-points');
        }
    };

    return(
 
        <IonPage>
            {scanStep !== 'add-proof'?
                <IonHeader className="header_top ion-no-border">
                    <div className="meetup-header">
                        <IonButton className="header_back_button" onClick={()=>handleBack()}>
                            <IonIcon icon={arrowBackOutline} size="large" />
                        </IonButton>
                    </div>
                </IonHeader>
                :
                ''
            }
            <IonContent className="linear_bg">
                <div className={scanStep !== 'add-proof'? 'booking-wrapper meetup-scan-wrapper' : ''}>
                {/* <Order_Receiving proofView={proofView} orderAddressId={meetupId}/> */}
                    {
                        scanStep === 'overview'?
                            <div className="wrapper-inner">   
                                <div className="booking-background-color ion-margin-bottom">
                                    <div className="pickup-wrapper ion-padding">
                                        <h5 className="ion-no-margin">Pickup Address</h5>
                                        <div className="addresses-wrapper">
                                            {orderDetails && orderDetails.pickup_address.map((item: any, index: any)=>{
                                                return(
                                                    <div key={index} className="addresses-wrapper">
                                                        {orderDetails.pickup_address.length > 1?
                                                            <div className="ion-margin-end">
                                                                <span className={'previous_input'}>
                                                                {index + 1}
                                                                </span>
                                                            </div>
                                                                : 
                                                            ''
                                                        }
                                                        <div>
                                                        {/* <h6 className="proof-btn" >Add proof</h6> */}
                                                        {/* {userDetails && userDetails.user_data.role_id === 3 && (item.order_proof === null || item.order_proof === undefined || !item.order_proof)?
                                                            ''
                                                            :
                                                            '' 
                                                        } */}
                                                        <h6>
                                                            {item.address}
                                                        </h6>
                                                            <div className="d-flex"><IonIcon className="addr-ico" icon={person} /><p><b>{item.contact.first_name}</b></p></div>
                                                            <div className="d-flex"><IonIcon className="addr-ico" icon={call} /><p>{item.contact.phone_no}</p></div>
                                                            <div className="d-flex"><IonIcon className="addr-ico" icon={mail} /><p>{item.contact.email}</p></div>
                                                            <IonButton fill="outline" onClick={()=> {
                                                                history.push({
                                                                    pathname: "view-meetup-points",
                                                                    state: {
                                                                      address: item,
                                                                      address_index: index
                                                                    }
                                                                });
                                                                // handleViewMeetupPoints(item)
                                                            }}>View meetup points</IonButton>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="booking-background-color ion-margin-bottom">
                                    <div className="pickup-wrapper ion-padding">
                                        <h5 className="ion-no-margin">Dropoff Address</h5>
                                        <div className="addresses-wrapper">
                                            {orderDetails && orderDetails.dropoff_address.map((item: any, index: any)=>{
                                                return(
                                                    <div key={index} className="addresses-wrapper">
                                                        {orderDetails.dropoff_address.length > 1?
                                                            <div className="ion-margin-end">
                                                                <span className={'previous_input'}>
                                                                {index + 1}
                                                                </span>
                                                            </div>
                                                                : 
                                                            ''
                                                        }
                                                        <div>
                                                        {/* <h6 className="proof-btn" >Add proof</h6> */}
                                                        {/* {userDetails && userDetails.user_data.role_id === 3 && (item.order_proof === null || item.order_proof === undefined || !item.order_proof)?
                                                            ''
                                                            :
                                                            '' 
                                                        } */}
                                                        <h6>
                                                            {item.address}
                                                        </h6>
                                                            <div className="d-flex"><IonIcon className="addr-ico" icon={person} /><p><b>{item.contact.first_name}</b></p></div>
                                                            <div className="d-flex"><IonIcon className="addr-ico" icon={call} /><p>{item.contact.phone_no}</p></div>
                                                            <div className="d-flex"><IonIcon className="addr-ico" icon={mail} /><p>{item.contact.email}</p></div>
                                                            <IonButton fill="outline" onClick={()=> {
                                                                history.push({
                                                                    pathname: "view-meetup-points",
                                                                    state: {
                                                                      address: item,
                                                                      address_index: index
                                                                    }
                                                                  });
                                                                // handleViewMeetupPoints(item)
                                                            }}>View meetup points</IonButton>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                        scanStep === 'meetup-points'?
                        <div className="meetup-cards-wrapper">
                            {meetupPoints.map((item: any, index: any)=>{
                                return(
                                    <div className="meetup-card" key={index} onClick={()=>{
                                        setMeetupId(item.id);
                                        handleSetScanStep('add-proof');
                                    }}>
                                        <div>
                                            <h4>{item.name}</h4>
                                            {/* <p>Doctor Asad</p> */}
                                        </div>
                                        <div className="d-flex">
                                            <IonIcon src={stopwatchOutline} size="large"/>
                                            <h6>{item.meetup_at}</h6>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        :
                        scanStep === 'add-proof'?
                            <Order_Receiving proofView={proofView} orderAddressId={meetupId}/>
                        :
                        ''
                    }
                </div>
            </IonContent>

    </IonPage>

    )
}
export default Meetup_Scan