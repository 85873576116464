import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonModal,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
  IonLabel,
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonIcon,
} from "@ionic/react";

import { BookingHeader } from "../components/BookingHeader";
import "../assets/scss/Delivery_Details.scss";
import { useState, useEffect, useRef } from "react";
import Pickup_Detailed_Location from "./Pickup_Detailed_Location";
import Pickup_Detailed_Contact from "./Pickup_Detailed_Contact";
import Dropoff_Detailed_Location from "./Dropoff_Detailed_Location";
import Dropoff_Detailed_Contact from "./Dropoff_Detailed_Contact";
import Cookies from "universal-cookie";
import { Preferences } from "@capacitor/preferences";
import Pickup_Meetup_Points from "./Pickup_Meetup_Points";
import { GoogleMap } from "@capacitor/google-maps";
import { arrowBackOutline } from "ionicons/icons";

const Delivery_Details: React.FC = () => {
  const cookies = new Cookies();
  const pickupMapRef = useRef<Array<HTMLElement>>([]);
  const dropoffMapRef = useRef<Array<HTMLElement>>([]);
  const mapRef = useRef<any>();

  // let newMap: Array<GoogleMap> = [];
  // let newMap: GoogleMap;
  let dropMap: Array<GoogleMap> = [];
  let markerId: any = [];

  const [steps, setSteps] = useState<any>("first");
  const [pickupAddresses, setPickupAddresses] = useState<any>([]);
  const [dropoffAddresses, setDropoffAddresses] = useState<any>([]);
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const [pickMapSt, setPickMapSt] = useState<any>([]);
  const [dropMapSt, setDropMapSt] = useState<any>([]);
  const [screenToView, setScreenToView] = useState('default');
  const [newMap, setNewMap] = useState<any>(null);

  let pickupGoogleAutocomplete: any = [];
  let dropoffGoogleAutocomplete: any = [];

  useEffect(() => {
  }, []);

  const googlePlacesApiInitilizerOnInput = async () => {
    const pickupAddressInput = document.getElementsByClassName(
      "google-auto-complete-places-pickup-inp"
    ) as any;

    for (let i = 0; i < pickupAddressInput.length; i++) {
      let autocomplete = new google.maps.places.Autocomplete(pickupAddressInput[i], {
        componentRestrictions: { country: "uk" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
      });

      autocomplete.addListener('place_changed', pickupFillIn);
      pickupGoogleAutocomplete.push(autocomplete);
    }

    function pickupFillIn(this: any) {
      let place = this.getPlace();
      handleAddressesDetails("pickup", place);
    }


    const dropoffAddressInput = document.getElementsByClassName(
      "google-auto-complete-places-dropoff-inp"
    ) as any;

    for (let i = 0; i < dropoffAddressInput.length; i++) {
      let autocomplete = new google.maps.places.Autocomplete(dropoffAddressInput[i], {
        componentRestrictions: { country: "uk" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
      });

      autocomplete.addListener('place_changed', dropoffFillIn);
      dropoffGoogleAutocomplete.push(autocomplete);
    }

    function dropoffFillIn(this: any) {
      var place = this.getPlace();
      handleAddressesDetails("dropoff", place);
    }
  }

  const handleAddressesDetails = async (type: any, googlePlaceData: any) => {
    let address = "";
    let postcode = "";

    for await (const component of googlePlaceData.address_components) {
      for await (const type of component.types) {
        if (type === "postal_code") {
          postcode = component.long_name;
        }
      }
      if (address !== '') {
        address += ', ' + component.long_name;
      } else {
        address += component.long_name;
      }
    }
    if (type === 'pickup') {
      let pickup_addresses = pickupAddresses;
      pickup_addresses[cookies.get('dtlc-address-index')].address = address;
      pickup_addresses[cookies.get('dtlc-address-index')].district = googlePlaceData.address_components[1].long_name;
      pickup_addresses[cookies.get('dtlc-address-index')].postcode = postcode;
      pickup_addresses[cookies.get('dtlc-address-index')].latitude = googlePlaceData.geometry.location.lat();
      pickup_addresses[cookies.get('dtlc-address-index')].longitude = googlePlaceData.geometry.location.lng();
      setPickupAddresses(handleSetState(pickup_addresses));
      // handleMapProcessing('pickup');
    } else if (type === 'dropoff') {
      let dropoff_addresses = dropoffAddresses;
      dropoff_addresses[cookies.get('dtlc-address-index')].address = address;
      dropoff_addresses[cookies.get('dtlc-address-index')].district = googlePlaceData.address_components[1].long_name;
      dropoff_addresses[cookies.get('dtlc-address-index')].postcode = postcode;
      dropoff_addresses[cookies.get('dtlc-address-index')].latitude = googlePlaceData.geometry.location.lat();
      dropoff_addresses[cookies.get('dtlc-address-index')].longitude = googlePlaceData.geometry.location.lng();
      setDropoffAddresses(handleSetState(dropoff_addresses));
      // handleMapProcessing('dropoff');
    }
  };

  useIonViewWillLeave(() => {
    setPickupAddresses([]);
    setDropoffAddresses([]);
  });

  useIonViewWillEnter(async () => {
    setSteps('first');
    const { value } = await Preferences.get({ key: 'dtlc-order-details' });
    if (value) {
      let order_details = JSON.parse(value);
      setOrderDetails(order_details);
      if (order_details.pickup_address && order_details.dropoff_address) {
        setPickupAddresses(handleSetState(order_details.pickup_address));
        setDropoffAddresses(handleSetState(order_details.dropoff_address));

        // handleMapProcessing('pickup', handleSetState(order_details.pickup_address));
        // handleMapProcessing('dropoff', handleSetState(order_details.dropoff_address));
      }
    }

    setTimeout(() => {
      googlePlacesApiInitilizerOnInput();
    }, 500);
  });

  // const handleMapProcessing = async (type: any, addressArr: any = "") => {
  //   if (type === 'pickup') {
  //     let pmap = [];
  //     if (addressArr !== "") {
  //       for (let item in addressArr) {
  //         // if(!newMapIdxArr.includes(parseInt(item))){
  //         pmap[parseInt(item)] = await GoogleMap.create({
  //           id: 'pickup-map' + parseInt(item), // Unique identifier for this map instance
  //           element: pickupMapRef.current[parseInt(item)], // reference to the capacitor-google-map element
  //           apiKey: 'AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw', // Your Google Maps API Key
  //           config: {
  //             center: {
  //               // The initial position to be rendered by the map
  //               lat: parseFloat(addressArr[parseInt(item)].latitude),
  //               lng: parseFloat(addressArr[parseInt(item)].longitude),
  //             },
  //             zoom: 15, // The initial zoom level to be rendered by the map
  //           },
  //         });

  //         await pmap[parseInt(item)].addMarker({
  //           coordinate: {
  //             lat: parseFloat(addressArr[parseInt(item)].latitude),
  //             lng: parseFloat(addressArr[parseInt(item)].longitude),
  //           },
  //           draggable: true
  //         });
  //         pmap[parseInt(item)].setOnMarkerDragEndListener(async (res: any) => {
  //           const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.latitude},${res.longitude}&key=AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw`);
  //           const jsonData = await response.json();
  //           let found: boolean = false;
  //           let route: any;
  //           let street_address: any;
  //           let post_code: any;
  //           jsonData.results.forEach((item: any) => {
  //             if (item.types.includes("street_address")) {
  //               street_address = item.formatted_address;
  //               post_code = item.address_components[item.address_components.length - 1].long_name;
  //               found = true;
  //             } else if (item.types.includes("route") && !found) {
  //               route = item.formatted_address;
  //               post_code = item.address_components[item.address_components.length - 1].long_name;
  //             }
  //           });


  //           let pickup_addresses = addressArr;
  //           pickup_addresses[parseInt(item)].postcode = post_code;
  //           pickup_addresses[parseInt(item)].address = found ? street_address : route;
  //           pickup_addresses[parseInt(item)].latitude = res.latitude;
  //           pickup_addresses[parseInt(item)].longitude = res.longitude;
  //           setPickupAddresses(handleSetState(pickup_addresses));
  //         });
  //         //   newMapIdxArr.push(parseInt(item));
  //         // }
  //         pmap.push(newMap[parseInt(item)]);
  //         setPickMapSt(pmap);
  //       }
  //     } else {
  //       for (let item in pickupAddresses) {
  //         // if(!newMapIdxArr.includes(parseInt(item))){
  //         pmap[parseInt(item)] = await GoogleMap.create({
  //           id: 'pickup-map' + parseInt(item), // Unique identifier for this map instance
  //           element: pickupMapRef.current[parseInt(item)], // reference to the capacitor-google-map element
  //           apiKey: 'AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw', // Your Google Maps API Key
  //           config: {
  //             center: {
  //               // The initial position to be rendered by the map
  //               lat: parseFloat(pickupAddresses[parseInt(item)].latitude),
  //               lng: parseFloat(pickupAddresses[parseInt(item)].longitude),
  //             },
  //             zoom: 15, // The initial zoom level to be rendered by the map
  //           },
  //         });
  //         await pmap[parseInt(item)].addMarker({
  //           coordinate: {
  //             lat: parseFloat(pickupAddresses[parseInt(item)].latitude),
  //             lng: parseFloat(pickupAddresses[parseInt(item)].longitude),
  //           },
  //           draggable: true
  //         });
  //         pmap[parseInt(item)].setOnMarkerDragEndListener(async (res: any) => {
  //           const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.latitude},${res.longitude}&key=AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw`);
  //           const jsonData = await response.json();
  //           let found: boolean = false;
  //           let route: any;
  //           let street_address: any;
  //           let post_code: any;
  //           jsonData.results.forEach((item: any) => {
  //             if (item.types.includes("street_address")) {
  //               street_address = item.formatted_address;
  //               post_code = item.address_components[item.address_components.length - 1].long_name;
  //               found = true;
  //             } else if (item.types.includes("route") && !found) {
  //               route = item.formatted_address;
  //               post_code = item.address_components[item.address_components.length - 1].long_name;
  //             }
  //           });


  //           let pickup_addresses = pickupAddresses;
  //           pickup_addresses[parseInt(item)].postcode = post_code;
  //           pickup_addresses[parseInt(item)].address = found ? street_address : route;
  //           pickup_addresses[parseInt(item)].latitude = res.latitude;
  //           pickup_addresses[parseInt(item)].longitude = res.longitude;
  //           setPickupAddresses(handleSetState(pickup_addresses));
  //         });
  //         //   newMapIdxArr.push(parseInt(item));
  //         // }

  //         pmap.push(newMap[parseInt(item)]);
  //         setPickMapSt(pmap);
  //       }
  //     }
  //   } else if (type === 'dropoff') {
  //     let dmap = [];
  //     if (addressArr !== "") {
  //       for (let item in addressArr) {
  //         dmap[parseInt(item)] = await GoogleMap.create({
  //           id: 'dropoff-map' + parseInt(item), // Unique identifier for this map instance
  //           element: dropoffMapRef.current[parseInt(item)], // reference to the capacitor-google-map element
  //           apiKey: 'AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw', // Your Google Maps API Key
  //           config: {
  //             center: {
  //               // The initial position to be rendered by the map
  //               lat: parseFloat(addressArr[parseInt(item)].latitude),
  //               lng: parseFloat(addressArr[parseInt(item)].longitude),
  //             },
  //             zoom: 15, // The initial zoom level to be rendered by the map
  //           },
  //         });
  //         await dmap[parseInt(item)].addMarker({
  //           coordinate: {
  //             lat: parseFloat(addressArr[parseInt(item)].latitude),
  //             lng: parseFloat(addressArr[parseInt(item)].longitude),
  //           },
  //           draggable: true
  //         });
  //         dmap[parseInt(item)].setOnMarkerDragEndListener(async (res: any) => {
  //           const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.latitude},${res.longitude}&key=AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw`);
  //           const jsonData = await response.json();
  //           let found: boolean = false;
  //           let route: any;
  //           let street_address: any;
  //           let post_code: any;
  //           jsonData.results.forEach((item: any) => {
  //             if (item.types.includes("street_address")) {
  //               street_address = item.formatted_address;
  //               post_code = item.address_components[item.address_components.length - 1].long_name;
  //               found = true;
  //             } else if (item.types.includes("route") && !found) {
  //               route = item.formatted_address;
  //               post_code = item.address_components[item.address_components.length - 1].long_name;
  //             }
  //           });


  //           let dropoff_addresses = addressArr;
  //           dropoff_addresses[parseInt(item)].postcode = post_code;
  //           dropoff_addresses[parseInt(item)].address = found ? street_address : route;
  //           dropoff_addresses[parseInt(item)].latitude = res.latitude;
  //           dropoff_addresses[parseInt(item)].longitude = res.longitude;
  //           setDropoffAddresses(handleSetState(dropoff_addresses));
  //         });

  //         dmap.push(dropMap[parseInt(item)]);
  //         setDropMapSt(dmap);
  //       }
  //     } else {
  //       for (let item in dropoffAddresses) {
  //         dmap[parseInt(item)] = await GoogleMap.create({
  //           id: 'dropoff-map' + parseInt(item), // Unique identifier for this map instance
  //           element: dropoffMapRef.current[parseInt(item)], // reference to the capacitor-google-map element
  //           apiKey: 'AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw', // Your Google Maps API Key
  //           config: {
  //             center: {
  //               // The initial position to be rendered by the map
  //               lat: parseFloat(dropoffAddresses[parseInt(item)].latitude),
  //               lng: parseFloat(dropoffAddresses[parseInt(item)].longitude),
  //             },
  //             zoom: 15, // The initial zoom level to be rendered by the map
  //           },
  //         });
  //         await dmap[parseInt(item)].addMarker({
  //           coordinate: {
  //             lat: parseFloat(dropoffAddresses[parseInt(item)].latitude),
  //             lng: parseFloat(dropoffAddresses[parseInt(item)].longitude),
  //           },
  //           draggable: true
  //         });
  //         dmap[parseInt(item)].setOnMarkerDragEndListener(async (res: any) => {
  //           const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.latitude},${res.longitude}&key=AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw`);
  //           const jsonData = await response.json();
  //           let found: boolean = false;
  //           let route: any;
  //           let street_address: any;
  //           let post_code: any;
  //           jsonData.results.forEach((item: any) => {
  //             if (item.types.includes("street_address")) {
  //               street_address = item.formatted_address;
  //               post_code = item.address_components[item.address_components.length - 1].long_name;
  //               found = true;
  //             } else if (item.types.includes("route") && !found) {
  //               route = item.formatted_address;
  //               post_code = item.address_components[item.address_components.length - 1].long_name;
  //             }
  //           });


  //           let dropoff_addresses = dropoffAddresses;
  //           dropoff_addresses[parseInt(item)].postcode = post_code;
  //           dropoff_addresses[parseInt(item)].address = found ? street_address : route;
  //           dropoff_addresses[parseInt(item)].latitude = res.latitude;
  //           dropoff_addresses[parseInt(item)].longitude = res.longitude;
  //           setDropoffAddresses(handleSetState(dropoff_addresses));
  //         });

  //         dmap.push(dropMap[parseInt(item)]);
  //         setDropMapSt(dmap);
  //       }
  //     }
  //   }
  // }

  const handleMapProcessing = async (type: any, addressArr: any = "") => {
    const newMap: any = [];
    if (type === "pickup") {
      if (addressArr != '') {
        for (let item in addressArr) {
          const newMapItem = await GoogleMap.create({
            id: "pickup-map" + parseInt(item), // Unique identifier for this map instance
            element: pickupMapRef.current[parseInt(item)], // reference to the capacitor-google-map element
            apiKey: "AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw", // Your Google Maps API Key

            config: {
              center: {
                // The initial position to be rendered by the map
                lat: parseFloat(addressArr[item].latitude),
                lng: parseFloat(addressArr[item].longitude),
              },
              gestureHandling: 'cooperative',
              zoom: 15, // The initial zoom level to be rendered by the map
            },
          });
          await newMapItem.addMarker({
            coordinate: {
              lat: parseFloat(addressArr[item].latitude),
              lng: parseFloat(addressArr[item].longitude),
            },
            draggable: true
          });
          newMap.push(newMapItem);
          setPickMapSt(newMap);
        }
      } else {
        for (let item in pickupAddresses) {
          // if(!newMapIdxArr.includes(parseInt(item))){
          const newMapItem = await GoogleMap.create({
            id: 'pickup-map' + parseInt(item), // Unique identifier for this map instance
            element: pickupMapRef.current[parseInt(item)], // reference to the capacitor-google-map element
            apiKey: 'AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw', // Your Google Maps API Key
            config: {
              center: {
                // The initial position to be rendered by the map
                lat: parseFloat(pickupAddresses[parseInt(item)].latitude),
                lng: parseFloat(pickupAddresses[parseInt(item)].longitude),
              },
              gestureHandling: 'cooperative',
              zoom: 15, // The initial zoom level to be rendered by the map
            },
          });
          await newMapItem.addMarker({
            coordinate: {
              lat: parseFloat(pickupAddresses[parseInt(item)].latitude),
              lng: parseFloat(pickupAddresses[parseInt(item)].longitude),
            },
            draggable: true
          });
          newMapItem.setOnMarkerDragEndListener(async (res: any) => {
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.latitude},${res.longitude}&key=AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw`);
            const jsonData = await response.json();
            let found: boolean = false;
            let route: any;
            let street_address: any;
            let post_code: any;
            jsonData.results.forEach((item: any) => {
              if (item.types.includes("street_address")) {
                street_address = item.formatted_address;
                post_code = item.address_components[item.address_components.length - 1].long_name;
                found = true;
              } else if (item.types.includes("route") && !found) {
                route = item.formatted_address;
                post_code = item.address_components[item.address_components.length - 1].long_name;
              }
            });


            let pickup_addresses = pickupAddresses;
            pickup_addresses[parseInt(item)].postcode = post_code;
            pickup_addresses[parseInt(item)].address = found ? street_address : route;
            pickup_addresses[parseInt(item)].latitude = res.latitude;
            pickup_addresses[parseInt(item)].longitude = res.longitude;
            setPickupAddresses(handleSetState(pickup_addresses));
          });
          newMap.push(newMapItem);
          setPickMapSt(newMap);
        }
      }
    } else if (type === "dropoff") {
      if (addressArr !== "") {
        for (let item in addressArr) {
          const newMapItem = await GoogleMap.create({
            id: "dropoff-map" + parseInt(item), // Unique identifier for this map instance
            element: dropoffMapRef.current[parseInt(item)], // reference to the capacitor-google-map element
            apiKey: "AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw", // Your Google Maps API Key
            config: {
              center: {
                // The initial position to be rendered by the map
                lat: parseFloat(addressArr[item].latitude),
                lng: parseFloat(addressArr[item].longitude),
              },
              gestureHandling: 'cooperative',
              zoom: 15, // The initial zoom level to be rendered by the map
            },
          });
          await newMapItem.addMarker({
            coordinate: {
              lat: parseFloat(addressArr[item].latitude),
              lng: parseFloat(addressArr[item].longitude),
            },
            draggable: true
          });
          newMap.push(newMapItem);
          setDropMapSt(newMap);
        }
      }
      else {
        for (let item in dropoffAddresses) {
          const newMapItem = await GoogleMap.create({
            id: 'dropoff-map' + parseInt(item), // Unique identifier for this map instance
            element: dropoffMapRef.current[parseInt(item)], // reference to the capacitor-google-map element
            apiKey: 'AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw', // Your Google Maps API Key
            config: {
              center: {
                // The initial position to be rendered by the map
                lat: parseFloat(dropoffAddresses[parseInt(item)].latitude),
                lng: parseFloat(dropoffAddresses[parseInt(item)].longitude),
              },
              gestureHandling: 'cooperative',
              zoom: 15, // The initial zoom level to be rendered by the map
            },
          });
          await newMapItem.addMarker({
            coordinate: {
              lat: parseFloat(dropoffAddresses[parseInt(item)].latitude),
              lng: parseFloat(dropoffAddresses[parseInt(item)].longitude),
            },
            draggable: true
          });
          newMapItem.setOnMarkerDragEndListener(async (res: any) => {
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.latitude},${res.longitude}&key=AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw`);
            const jsonData = await response.json();
            let found: boolean = false;
            let route: any;
            let street_address: any;
            let post_code: any;
            jsonData.results.forEach((item: any) => {
              if (item.types.includes("street_address")) {
                street_address = item.formatted_address;
                post_code = item.address_components[item.address_components.length - 1].long_name;
                found = true;
              } else if (item.types.includes("route") && !found) {
                route = item.formatted_address;
                post_code = item.address_components[item.address_components.length - 1].long_name;
              }
            });


            let dropoff_addresses = dropoffAddresses;
            dropoff_addresses[parseInt(item)].postcode = post_code;
            dropoff_addresses[parseInt(item)].address = found ? street_address : route;
            dropoff_addresses[parseInt(item)].latitude = res.latitude;
            dropoff_addresses[parseInt(item)].longitude = res.longitude;
            setDropoffAddresses(handleSetState(dropoff_addresses));
          });

          newMap.push(newMapItem);
          setDropMapSt(newMap);
        }
      }
    }
  };

  const handleSetState = (data: any) => {
    let local_data = data;
    local_data = JSON.stringify(local_data);
    local_data = JSON.parse(local_data);
    return local_data;
  }

  const handleAddNewAddress = (type: any) => {
    if (type === 'pickup') {
      let pickup_addresses = pickupAddresses;
      pickup_addresses.push({
        location_name: "",
        location_detail: "",
        notes: "",
        address: "",
        street: "",
        postcode: "",
        place_name: "",
        place_detail: "",
        latitude: 51.507621660098614,
        longitude: -0.1428042481715496,
        city: "",
        district: "",
        contact: {
          first_name: "",
          phone_no: "",
          email: "",
        },
      });
      setPickupAddresses(handleSetState(pickup_addresses));
    } else if (type === 'dropoff') {
      let dropoff_addresses = dropoffAddresses;
      dropoff_addresses.push({
        location_name: "",
        location_detail: "",
        notes: "",
        address: "",
        street: "",
        postcode: "",
        place_name: "",
        place_detail: "",
        latitude: 51.507621660098614,
        longitude: -0.1428042481715496,
        city: "",
        district: "",
        contact: {
          first_name: "",
          phone_no: "",
          email: "",
        },
      });
      setDropoffAddresses(handleSetState(dropoff_addresses));
    }
    setTimeout(() => {
      googlePlacesApiInitilizerOnInput();
      // handleMapProcessing(type);
    }, 500);
  }

  const handleRevomeAddress = (type: any, index: any) => {
    if (type === 'pickup') {
      let pickup_addresses = pickupAddresses;
      pickup_addresses.splice(index, 1);
      setPickupAddresses(handleSetState(pickup_addresses));
      // pickMapSt[index].destroy();
    } else if (type === 'dropoff') {
      let dropoff_addresses = dropoffAddresses;
      dropoff_addresses.splice(index, 1);
      setDropoffAddresses(handleSetState(dropoff_addresses));
      // dropMapSt[index].destroy();
    }
  }

  const passState = (arg: any) => {
    setSteps(arg);
  };

  const handleNext = async () => {
    let allowed = true;
    if (pickupAddresses.length <= 0) {
      setAlertMessage('Please add atleast one pickup address');
      setShowToast(true);
      return;
    }
    if (dropoffAddresses.length <= 0) {
      setAlertMessage('Please add atleast one dropoff address');
      setShowToast(true);
      return;
    }
    for await (let pickup_address of pickupAddresses) {
      if (pickup_address.address === '' || pickup_address.postcode === '' || pickup_address.latitude === '' || pickup_address.longitude === '') {
        handleSetToast('Some field is missing');
        allowed = false;
        break;
      }
    }
    for await (let dropoff_address of dropoffAddresses) {
      if (dropoff_address.address === '' || dropoff_address.postcode === '' || dropoff_address.latitude === '' || dropoff_address.longitude === '') {
        handleSetToast('Some field is missing');
        allowed = false;
        break;
      }
    }
    if (allowed) {
      let order_details = orderDetails;
      order_details.pickup_address = pickupAddresses;
      order_details.dropoff_address = dropoffAddresses;

      await Preferences.set({
        key: 'dtlc-order-details',
        value: JSON.stringify(order_details),
      });
      setSteps("meetup_points");
    }
  };

  const handleSetToast = (message: any) => {
    setAlertMessage(message);
    setShowToast(true);
    return;
  }

  const handleLabelClick = async (coordinates: any, index: any, type: any) => {
    setScreenToView('map');
    
    setTimeout(async ()=>{
      console.log(mapRef);
      console.log(newMap);
      // return;

      let simpleNewMap: GoogleMap;
      simpleNewMap = await GoogleMap.create({
        id: 'my-cool-map',
        element: mapRef.current,
        apiKey: 'AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw',
        config: {
          center: {
            lat: parseFloat(coordinates.lat),
            lng: parseFloat(coordinates.long)
          },
          zoom: 15
        }
      });
      simpleNewMap.addMarker({
        coordinate: {
          lat: parseFloat(coordinates.lat),
          lng: parseFloat(coordinates.long),
        },
        draggable: true
      });
      simpleNewMap.setOnMarkerDragEndListener(async (res: any) => {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.latitude},${res.longitude}&key=AIzaSyDcxAjA56FksC4qmA_P38fqAkPdPguQTgw`);
        const jsonData = await response.json();
        let found: boolean = false;
        let route: any;
        let street_address: any;
        let post_code: any;
        jsonData.results.forEach((item: any) => {
          if (item.types.includes("street_address")) {
            street_address = item.formatted_address;
            post_code = item.address_components[item.address_components.length - 1].long_name;
            found = true;
          } else if (item.types.includes("route") && !found) {
            route = item.formatted_address;
            post_code = item.address_components[item.address_components.length - 1].long_name;
          }
        });
      
        if(type === 'pickup'){
          let pickup_addresses = pickupAddresses;
          pickup_addresses[index].postcode = post_code;
          pickup_addresses[index].address = found ? street_address : route;
          pickup_addresses[index].latitude = res.latitude;
          pickup_addresses[index].longitude = res.longitude;
          setPickupAddresses(handleSetState(pickup_addresses));
        }else{
          let dropoff_addresses = dropoffAddresses;
          dropoff_addresses[index].postcode = post_code;
          dropoff_addresses[index].address = found ? street_address : route;
          dropoff_addresses[index].latitude = res.latitude;
          dropoff_addresses[index].longitude = res.longitude;
          setDropoffAddresses(handleSetState(dropoff_addresses));
        }
      });
      setNewMap(simpleNewMap);
    }, 500);
  }

  const handleMapViewBackButton = () => {
    newMap.destroy();
    setScreenToView('default');
    setTimeout(()=>{
      googlePlacesApiInitilizerOnInput();
    }, 500);
  }

  return (
    <IonPage>
      {steps === "first" ? (
        <>
          <IonContent overflow-scroll="true"
            fullscreen
            className={screenToView === 'default'? 'backdrop-effect position-relative' : 'backdrop-effect bg_transparent position-relative'}
          >
            {screenToView === 'default'?
              <>
                <BookingHeader from="" />
                <div>
              <div className="order_details plr-20">
                <div>
                  <h3 className="page_title scale-up-center ">
                    Enter details for your parcel’s journey
                  </h3>

                  {pickupAddresses.map((item: any, index: any) => {
                    return (
                      <div key={index} className="addr-bx mt_25">
                        <div className="w_100 d-flex align-items-center">
                          {pickupAddresses.length > 1 ?
                            <span className={Object.keys(item).length > 0 ? 'previous_input' : 'current_input'}>
                              {index + 1}
                            </span>
                            :
                            ''
                          }
                          <IonItem
                            className=" delivery_input scale-up-center"
                            lines="none"
                          >
                            <input
                              className="html_input google-auto-complete-places-pickup-inp"
                              type="text"
                              placeholder="Pickup address"
                              id={`google-auto-complete-places-${index}`}
                              value={item.address}
                              onChange={(e) => {
                                let pickup_addresses = pickupAddresses;
                                pickup_addresses[index].address = e.target.value;
                                setPickupAddresses(handleSetState(pickup_addresses));
                                cookies.set('dtlc-address-index', index);
                              }}
                            />
                          </IonItem>
                          <IonButton onClick={() => handleRevomeAddress('pickup', index)}>-</IonButton>
                        </div>
                        <IonInput style={{ margin_bottom: '12px' }} placeholder="Postcode" className="addr-detail-inp" value={item.postcode} onIonChange={(e) => {
                          let pickup_addresses = pickupAddresses;
                          pickup_addresses[index].postcode = e.detail.value;
                          setPickupAddresses(handleSetState(pickup_addresses));
                        }}></IonInput>
                        <IonLabel onClick={()=>handleLabelClick({lat: item.latitude, long: item.longitude}, index, 'pickup')}> {item.longitude === -0.1428042481715496 && item.latitude === 51.507621660098614 ? 'Select on map' : 'View on map'}</IonLabel>
                        {/* <capacitor-google-map ref={(el: any) => pickupMapRef.current[index] = el} style={{
                          display: 'inline-block',
                          width: '100%',
                        }}></capacitor-google-map> */}

                        <IonInput style={{ display: 'none' }} placeholder="District" className="addr-detail-inp" value={item.district} onIonChange={(e) => {
                          let pickup_addresses = pickupAddresses;
                          pickup_addresses[index].district = e.detail.value;
                          setPickupAddresses(handleSetState(pickup_addresses));
                        }}></IonInput>
                        <IonInput style={{ display: 'none' }} placeholder="Latitude" className="addr-detail-inp" value={item.latitude} onIonChange={(e) => {
                          let pickup_addresses = pickupAddresses;
                          pickup_addresses[index].latitude = e.detail.value;
                          setPickupAddresses(handleSetState(pickup_addresses));
                        }}></IonInput>
                        <IonInput style={{ display: 'none' }} placeholder="Longitude" className="addr-detail-inp" value={item.longitude} onIonChange={(e) => {
                          let pickup_addresses = pickupAddresses;
                          pickup_addresses[index].longitude = e.detail.value;
                          setPickupAddresses(handleSetState(pickup_addresses));
                        }}></IonInput>
                      </div>
                    )
                  })}
                  <div onClick={() => handleAddNewAddress('pickup')} className="align-items-center ion-margin-top mb_20">
                    <IonButton>+</IonButton>
                    <h4 className="ion-padding-start">Add new pickup address</h4>
                  </div>

                  {/* ////////////////////////////////////////////////////////////////////////////////// */}
                  {/* following is the dropoff address fields */}
                  {/* ////////////////////////////////////////////////////////////////////////////////// */}
                  {dropoffAddresses.map((item: any, index: any) => {
                    return (
                      <div key={index} className="addr-bx mt_25">
                        <div className="w_100 d-flex align-items-center">
                          {dropoffAddresses.length > 1 ?
                            <span className={Object.keys(item).length > 0 ? 'previous_input' : 'current_input'}>
                              {index + 1}
                            </span>
                            :
                            ''
                          }
                          <IonItem
                            className=" delivery_input scale-up-center"
                            lines="none"
                          >
                            <input
                              className="html_input google-auto-complete-places-dropoff-inp"
                              type="text"
                              placeholder="Dropoff address"
                              id={`google-auto-complete-places-${index}`}
                              value={item.address}
                              onChange={(e) => {
                                let dropoff_addresses = dropoffAddresses;
                                dropoff_addresses[index].address = e.target.value;
                                setDropoffAddresses(handleSetState(dropoff_addresses));
                                cookies.set('dtlc-address-index', index);
                              }}
                            />
                          </IonItem>
                          <IonButton onClick={() => handleRevomeAddress('dropoff', index)}>-</IonButton>
                        </div>
                        <IonInput placeholder="Postcode" className="addr-detail-inp" value={item.postcode} onIonChange={(e) => {
                          let dropoff_addresses = dropoffAddresses;
                          dropoff_addresses[index].postcode = e.detail.value;
                          setDropoffAddresses(handleSetState(dropoff_addresses));
                        }}></IonInput>
                        <IonLabel onClick={()=>handleLabelClick({lat: item.latitude, long: item.longitude}, index, 'dropoff')}> {item.longitude === -0.1428042481715496 && item.latitude === 51.507621660098614 ? 'Select on map' : 'View on map'}</IonLabel>
                        {/* <capacitor-google-map ref={(el: any) => dropoffMapRef.current[index] = el} style={{
                          display: 'inline-block',
                          width: '100%',
                        }}></capacitor-google-map> */}
                        <IonInput style={{ display: 'none' }} placeholder="District" className="addr-detail-inp" value={item.district} onIonChange={(e) => {
                          let dropoff_addresses = dropoffAddresses;
                          dropoff_addresses[index].district = e.detail.value;
                          setDropoffAddresses(handleSetState(dropoff_addresses));
                        }}></IonInput>
                        <IonInput style={{ display: 'none' }} placeholder="Latitude" className="addr-detail-inp" value={item.latitude} onIonChange={(e) => {
                          let dropoff_addresses = dropoffAddresses;
                          dropoff_addresses[index].latitude = e.detail.value;
                          setDropoffAddresses(handleSetState(dropoff_addresses));
                        }}></IonInput>
                        <IonInput style={{ display: 'none' }} placeholder="Longitude" className="addr-detail-inp" value={item.longitude} onIonChange={(e) => {
                          let dropoff_addresses = dropoffAddresses;
                          dropoff_addresses[index].longitude = e.detail.value;
                          setDropoffAddresses(handleSetState(dropoff_addresses));
                        }}></IonInput>
                      </div>
                    )
                  })}
                  <div onClick={() => handleAddNewAddress('dropoff')} className="align-items-center ion-margin-top mb_10">
                    <IonButton>+</IonButton>
                    <h4 className="ion-padding-start">Add new dropoff address</h4>
                  </div>

                  <IonButton expand="block"
                    className="scale-up-center ion-margin-top"
                    onClick={handleNext}
                  >
                    Next
                  </IonButton>
                </div>
              </div>
                </div>
              </>
              :
              <>
                <IonHeader className="header_top ion-no-border">
                    <div className="meetup-header">
                        <IonButton className="header_back_button" onClick={()=>{
                          handleMapViewBackButton();
                        }}>
                            <IonIcon icon={arrowBackOutline} size="large" />
                        </IonButton>
                    </div>
                </IonHeader>
                <div className="plr-20 margin-from-top">
                  <IonLabel>Drag the marker to change the location</IonLabel>
                  <capacitor-google-map ref={mapRef} style={{
                    display: 'inline-block',
                    width: '100%',
                    height: 400
                  }}></capacitor-google-map>
                </div>
              </>
            }
          </IonContent>
          <IonToast
            isOpen={showToast}
            position="bottom"
            onDidDismiss={() => setShowToast(false)}
            color="danger"
            message={alertMessage}
            duration={1500}
          />
        </>
      ) : steps === "meetup_points" ? (
        <Pickup_Meetup_Points
          passState={passState}
          OrderDetails={orderDetails}
        />
      ) : steps === "second" ? (
        <Pickup_Detailed_Location
          passState={passState}
          OrderDetails={orderDetails}
        />
      ) : steps === "third" ? (
        <Pickup_Detailed_Contact
          passState={passState}
          OrderDetails={orderDetails}
        />
      ) : steps === "forth" ? (
        <Dropoff_Detailed_Location
          passState={passState}
          OrderDetails={orderDetails}
        />
      ) : steps === "five" ? (
        <Dropoff_Detailed_Contact
          passState={passState}
          OrderDetails={orderDetails}
        />
      )
        : (
          ""
        )}
    </IonPage>
  );
};

export default Delivery_Details;