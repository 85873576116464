import { IonContent, IonLoading, IonIcon, IonImg, IonItem, IonLabel, IonList, IonPage, IonSearchbar, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react"
import { arrowBackOutline, chevronForwardOutline, trashBin, closeCircle } from "ionicons/icons"
import { useHistory } from "react-router"
import Menu from "../components/Menu";
import "../assets/scss/Bookings.scss";
import { useState } from "react";
import { postRequest } from "../ApiServices";
import Cookies from 'universal-cookie';
import { prepareOrderStructure } from "../functions";
import { Preferences } from '@capacitor/preferences';

const Bookings = () => {
    const cookies = new Cookies();
    const history = useHistory();

    const [showLoading, setShowLoading] = useState<boolean>(true);
    const [allOrders, setAllOrders] = useState<any>([]);
    const [searchedOrders, setSearchedOrders] = useState<any>([]);
    const [orderNumber, setOrderNumber] = useState<any>('');

    useIonViewWillEnter(()=>{
        setShowLoading(true);
        let dtlc = cookies.get('dtlc');
        if(dtlc && dtlc.token){
            postRequest('order/customer-pending-order', {}, dtlc.token.token).then(async (res: any)=>{
                let all_orders: any = [];
                for await(let order of res.data){
                    await prepareOrderStructure(order).then(async (result: any)=>{
                        all_orders.push(result);
                    }).catch((err: any)=>{
                        console.log(err);
                    });
                }
                setAllOrders(all_orders);
                setShowLoading(false);
            }).catch((err: any)=>{
                console.log(err);
            });
        }
    });

    useIonViewWillLeave(()=>{
        setAllOrders([]);
        setSearchedOrders([]);
    });

    const handleSearch = (event: any) => {
        setOrderNumber(event.detail.value);
        let searched_order = allOrders.filter((order: any) => order.order_number === parseInt(event.detail.value));
        setSearchedOrders(searched_order);
    }

    const handleOrderTap = async (index: any, type: any) => {
        let order;
        if(type === 'order'){
            order = allOrders[index];
        }else if(type === 'search-order') {
            order = searchedOrders[index];
        }
        await Preferences.set({
            key: 'api-data',
            value: JSON.stringify(order),
          });
          // cookies.set('dtlc-order-details', data.data);
          history.push('/view_order');
    }

  return (
    <IonPage>
        <IonContent>
            <div className="bookings-wrapper">
                <div className="menu-wrapper">
                    <IonIcon className="back-icon" icon={arrowBackOutline} size="large" onClick={()=>  history.goBack()} />
                    {/* <Menu/> */}
                </div>
                <div className="bookings-header">
                    <IonImg src={require('../assets/images/dtlc-cropped-white.png')} />
                    <IonSearchbar placeholder='Order Number' clearIcon={closeCircle} value={orderNumber} onIonChange={(event)=>{handleSearch(event)}} className="ion-padding panel-searchbar"></IonSearchbar>
                </div>
                <div className="order-list">
                    <h4>My Packages</h4>
                    <IonList>
                        {orderNumber === ''? 
                            allOrders.map((item: any, index: any)=>{
                                return(
                                    <div key={index} className="order-list-item">
                                        <IonItem lines="none" onClick={()=> handleOrderTap(index, 'order')} className="ion-margin-horizontal">
                                            <IonImg src={item.type === 'urgent'? require('../assets/images/running-white.png') : item.type === 'same day'? require('../assets/images/stopwatch-white.png') : item.type === 'schedule'? require('../assets/images/calendar-white.png') : ''} className="success-icon"/>
                                            <IonLabel>
                                                <p className="order-no">OrderNumber#</p>
                                                <h2 className="order-no-number">{item.order_number}</h2>
                                                <p className="order-status">{item.type}</p>
                                            </IonLabel>
                                            <IonIcon icon={chevronForwardOutline}></IonIcon>
                                        </IonItem>
                                    </div>
                                )
                            })
                        :
                            searchedOrders.map((item: any, index: any)=>{
                                return(
                                    <div key={index} className="order-list-item">
                                        <IonItem lines="none" onClick={()=> handleOrderTap(index, 'order')} className="ion-margin-horizontal">
                                            <IonImg src={item.type === 'urgent'? require('../assets/images/running-white.png') : item.type === 'same day'? require('../assets/images/stopwatch-white.png') : item.type === 'schedule'? require('../assets/images/calendar-white.png') : ''} className="success-icon"/>
                                            <IonLabel>
                                                <p className="order-no">OrderNumber#</p>
                                                <h2 className="order-no-number">{item.order_number}</h2>
                                                <p className="order-status">{item.type}</p>
                                            </IonLabel>
                                            <IonIcon icon={chevronForwardOutline}></IonIcon>
                                        </IonItem>
                                    </div>
                                )
                            })
                        }
                    </IonList>
                </div>
            </div>
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={"Please wait..."}
            />
        </IonContent>
</IonPage>
  )
}

export default Bookings
