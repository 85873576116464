import React, {useState} from 'react'

import { IonAvatar, IonContent, IonImg, IonPage } from "@ionic/react"
import { AppHeader } from "../components/AppHeader"
import "../assets/scss/Customer_Profile.scss"
import Orders_List from '../components/Orders_List'
const Customer_Profile = () => {
    const [customerInfo, setCustomerInfo] = useState(true);
  return (
    <IonPage>
        <IonContent>
            <AppHeader from=""/>
            <div className="customer-profile-wrapper">
                <div className='imge_profile'>
                    <IonAvatar>
                        <IonImg src={require("../assets/images/profile.jpg")} />
                        <div className='edit_pencile'>
                            <IonImg src={require('../assets/images/simple-pencile.png')} />
                        </div>
                    </IonAvatar>
                </div>
            <div className="wrapper-inner">
                <div>
                <div className="history-tabs">
                    <div className="justify-content-around">
                    <div className="ion-padding" onClick={()=>setCustomerInfo(true)}>Customer Info</div>
                    <div className="ion-padding" onClick={()=>setCustomerInfo(false)}>Order History</div>
                    </div>
                    
                </div>
                <div className={customerInfo ? "info-wrapper": "history-wrapper"}>
                {
                customerInfo ? <div className="customer-details">
                    <div className="ion-margin-bottom">
                        <h6>First Name:</h6>
                        <h4>Alim</h4>
                    </div>
                    <div  className="ion-margin-bottom">
                        <h6>Last Name:</h6>
                        <h4>Malik</h4>
                    </div>
                    <div  className="ion-margin-bottom">
                        <h6>Phone No:</h6>
                        <h4>+441122334455</h4>
                    </div>
                    <div  className="ion-margin-bottom">
                        <h6>Email:</h6>
                        <h4>customer.web.com</h4>
                    </div>
                </div>
                :
                <div className="history-details">
                    <Orders_List Orders={[]}/>
                </div>
            
                }
                </div>
                </div>
            </div>
            </div>
        </IonContent>
    </IonPage>
  )
}

export default Customer_Profile
