import {
    IonAvatar,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonItem,
    IonIcon,
    IonImg,
    IonPage,
    IonList,
    IonLabel,
    IonRow,
    IonToast,
    useIonViewWillEnter,
} from "@ionic/react";
import { notificationsOutline, locationOutline, settingsOutline, calculatorOutline, logOutOutline } from 'ionicons/icons';
import "../assets/scss/Driver_Sidebar.scss";
import { Link, useHistory, } from "react-router-dom";
import { useEffect, useState } from "react";
const Driver_Sidebar: React.FC = () => {
    const [isActive, setActive] = useState(false);

    return (
        <IonPage>
            <IonContent fullscreen className="linear_bg">

                <div className="driver-sidebar-profile ">
                    <div className="driver-profile">
                        <IonAvatar className="avatar-img">
                            <IonImg src={require('../assets/images/dummy-img.jpg')} />
                        </IonAvatar>
                        <div className="profile-content">
                            <h4>Hello Devin</h4>
                            <p>devin@gmail.com</p>
                        </div>
                    </div>

                    <IonList className='side-profile-list'>
                        <IonItem lines='none'>
                            <IonIcon icon={locationOutline} />
                            <IonLabel>Tracking</IonLabel>
                        </IonItem>
                        <IonItem lines='none'>
                            <IonIcon icon={locationOutline} />
                            <IonLabel>Dashboard</IonLabel>
                        </IonItem>
                        <IonItem lines='none'>
                            <IonIcon icon={locationOutline} />
                            <IonLabel>Shipping</IonLabel>
                        </IonItem>
                        <IonItem lines='none'>
                            <IonIcon icon={notificationsOutline} />
                            <IonLabel>Notification</IonLabel>
                        </IonItem>
                        <IonItem lines='none'>
                            <IonIcon icon={calculatorOutline} />
                            <IonLabel>Calculator</IonLabel>
                        </IonItem>

                        <IonItem lines='none'>
                            <IonIcon icon={settingsOutline} />
                            <IonLabel>Help & Support</IonLabel>
                        </IonItem>

                        <IonItem lines='none'>
                            <IonIcon icon={logOutOutline} />
                            <IonLabel>Logout</IonLabel>
                        </IonItem>

                    </IonList>

                </div>
            </IonContent>

        </IonPage>
    );
};

export default Driver_Sidebar;




