
import { IonAvatar, IonContent, IonIcon, IonLoading, IonImg, IonPage, useIonViewWillEnter } from "@ionic/react";
import { arrowBackOutline, arrowForwardOutline, eyeOutline } from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router";
import "../assets/scss/Customer_Queries.scss";
import { AppHeader } from "../components/AppHeader";
import { postRequest } from "../ApiServices";
import Cookies from 'universal-cookie';
import { Preferences } from "@capacitor/preferences";

const Customer_Queries: React.FC = () => {
    const history = useHistory();
    const cookies = new Cookies();

    const [customerProfile, setCustomerProfile] = useState(false);
    const [showLoading, setShowLoading] = useState<any>(true);
    const [customerQueries, setCustomerQueries] = useState<any>([]);
    const [selectedMessage, setSelectedMessage] = useState<any>('');

    useIonViewWillEnter(()=>{
        getMessages();
    });

    const getMessages = () => {
        let dtlc = cookies.get('dtlc');
        if(dtlc && dtlc.token){
            postRequest('dispatcher/get-contacts', {}, dtlc.token.token).then((res: any)=>{
                setShowLoading(false);
                setCustomerQueries(res.data);
            }).catch((err: any)=>{
                console.log(err);
            }); 
        }
    }

    const handleSelectedCustomerMessage = (index: any) => {
        console.log(customerQueries[index]);
        setSelectedMessage(customerQueries[index]);
        setCustomerProfile(true);
        let dtlc = cookies.get('dtlc');
        if(dtlc && dtlc.token){
            postRequest('dispatcher/read-contact', {
                id: customerQueries[index].id
            }, dtlc.token.token).then((res: any)=>{
                if(res.status){
                    let all_messages = customerQueries;
                    all_messages[index].is_read = 1;
                    setCustomerQueries(all_messages);
                }
            }).catch((err: any)=>{
                console.log(err);
            });
        }
    }

    const handleCustomerView = async () => {
        await Preferences.set({
            key: 'profile-data',
            value: JSON.stringify(selectedMessage.actor),
        });
      
        history.push("/customer_profile_view");
    }

  return (
    <IonPage>
        <IonContent className="linear_bg">
            {
            customerProfile?
            <>
                <div className="customer-query-wrapper">
                    <div className="back-btn" onClick={()=>{
                        getMessages();
                        setCustomerProfile(false);
                        }}><IonIcon icon={arrowBackOutline}/><span>Back</span></div>
                    <IonAvatar>
                        <IonImg src={selectedMessage.actor.image_url? selectedMessage.actor.image_url : require("../assets/images/profile.jpg")} />
                    </IonAvatar>
                    <div className="query-disc">
                        <div className="ion-margin-bottom">
                            <h5 onClick={()=>handleCustomerView()} className="view-customer-txt">View Customer</h5>
                        </div>
                        <div className="ion-margin-bottom">
                            <h6>Name:</h6>
                            <h4>{selectedMessage.name}</h4>
                        </div>
                        <div  className="ion-margin-bottom">
                            <h6>Email:</h6>
                            <h4>{selectedMessage.email}</h4>
                        </div>
                        <div  className="ion-margin-bottom">
                            <h6>Message:</h6>
                            <h4>{selectedMessage.message}</h4>
                        </div>
                    </div>
                </div>
            </>
            :
            <>
                <AppHeader from=""/>
            <div className="queries-wrapper">
                <h2>Customer Queries</h2>
                {customerQueries.length > 0?
                    customerQueries.map((item: any, index: any)=>{
                        console.log(item);
                        return(
                            <div key={index} className={item.is_read? "card queries-read" : "card queries-unread"} onClick={()=>handleSelectedCustomerMessage(index)}>
                                <IonAvatar>
                                <IonImg src={item.actor.image_url}/>
                                </IonAvatar>
                                <div>
                                    <h4>{item.actor.full_name}</h4>
                                    <p>{item.message}</p>
                                    <div><span>View</span><IonIcon icon={arrowForwardOutline}/></div>
                                </div>
                            </div>
                        )
                    })
                : 
                    <h3>No customer queries found</h3>
                }
            </div>
            </>
            }
        </IonContent>
        <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Please wait..."}
        />
    </IonPage>
  )
}

export default Customer_Queries
