import { useHistory } from "react-router-dom";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonButton,
  IonFooter,
  IonImg,
  useIonViewWillEnter,
} from "@ionic/react";
import "../assets/scss/Home.scss";
import Cookies from 'universal-cookie';

const Home: React.FC = () => {
  const history = useHistory();
  const cookies = new Cookies();

  useIonViewWillEnter(() =>{
    if(cookies.get('dtlc-tutorial-visit')){
      history.replace('/choices');
    }else{
      cookies.set("dtlc-tutorial-visit", true);
    }
  });
 
  return (
    <IonPage>
    
      <IonContent fullscreen>
      <div className="home-header">
      <div className="header-inner ion-no-border">
      </div>
      <div className="home-image">
    
      <IonImg src={require('../assets/images/dtlc-cropped.png')} />
   
      </div>
      </div>
        <div className="home-wrapper ion-padding-horizontal">
      
          <div className="tabs">
          <svg height="30" width="50">
  <circle cx="25" cy="18" r="4" stroke="black" strokeWidth="0" fill="#0B58AB" />
  <circle cx="40" cy="18" r="4" stroke="black" strokeWidth="0" fill="#30AF74" />
  Sorry, your browser does not support inline SVG.  
</svg> 
          </div>
        <h4>Welcome to</h4>
        <h2>DTLC Courier</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae
          possimus vitae praesentium doloremque amet quis facilis quia magnam
      
        </p>
        </div>
      
      </IonContent>
      <IonFooter collapse="fade" className="ion-no-border justify-content-end home-footer ion-padding">
  <IonButton className="btn-white btn-color" onClick={()=>{
    history.replace("/choices");
  }}>Skip</IonButton>
</IonFooter>
    </IonPage>
  );
};

export default Home;
