import { IonButton, IonButtons, IonContent, IonDatetime, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonPopover, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react"
import { chevronDownSharp, personCircle, trashBin } from "ionicons/icons"
import { AppHeader } from "../components/AppHeader"
import "../assets/scss/All_Orders.scss";
import Orders_List from "../components/Orders_List";
import All_Orders_List from "../components/AllOrdersList";
import { useState, useRef, useEffect } from "react";
import Cookies from "universal-cookie";
import { postRequest } from "../ApiServices";
const All_Orders: React.FC = () => {
  const cookies = new Cookies();
  const popover = useRef<HTMLIonPopoverElement>(null);
  const orderPopover = useRef<HTMLIonPopoverElement>(null);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [orderOpenPopover, setOpenOrderPopover] = useState(false);
  const [showLoading, setShowLoading] = useState<any>(true);
  const [customerPacakgeStatus, setCustomerPacakgeStatus] = useState<any>('Today');
  const [orderStatus, setOrderStatus] = useState<any>('All');
  const [customerOrders, setCustomerOrders] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [dateSelection, setDateSelection] = useState<any>('from');
  const [filterData, setFilterData] = useState<any>({
    from_date: '',
    to_date: '',
    order_status: 'ALL',
    type: 'today'
  });
  const [typeToSend, setTypeToSend] = useState<any>('');

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };
  const openOrderPopover = (e: any) => {
    orderPopover.current!.event = e;
    setOpenOrderPopover(true);
  };

  useIonViewWillEnter(() => {
    setShowLoading(true);
    let dtlc = cookies.get('dtlc');
    if (dtlc && dtlc.token) {
      postRequest("dispatcher/order-filter", filterData, dtlc.token.token)
        .then((res: any) => {
          setShowLoading(false);
          if (res.status) {
            setCustomerOrders({
              orders: res.data,
              type: 'today'
            });
            setTypeToSend('today');
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  });

  useIonViewWillLeave(() => {
    setCustomerOrders([]);
  });

  const handleDismissSelect = (e: any) => {
    if (e.target.value === 'custom-date') {
      setIsOpen(!isOpen);
    }
  }

  const handleFilterType = (packageStatus: any) => {
    setPopoverOpen(false);
    setCustomerPacakgeStatus(packageStatus);
    setFilterData({
      ...filterData,
      type: packageStatus
    });
    if (packageStatus !== 'custom-date') {
      let filter_data = filterData;
      filter_data.type = packageStatus;
      filter_data.from_date = '';
      filter_data.to_date = '';
      handleApiRequest(filter_data);
    }
  }

  const handleApiRequest = (data: any) => {
    setShowLoading(true);
    let dtlc = cookies.get('dtlc');
    if (dtlc && dtlc.token) {
      postRequest("dispatcher/order-filter", data, dtlc.token.token)
        .then((res: any) => {
          setShowLoading(false);
          if (res.status) {
            setDateSelection('from');
            setCustomerOrders({
              orders: res.data,
              type: data.type
            });
            setTypeToSend(data.type);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }

  const handleFilterStatus = (statusCheck: any) => {
    setOrderStatus(statusCheck)
    setOpenOrderPopover(false)
    let filter_data = filterData;
    filter_data.order_status = statusCheck;
    setFilterData({
      ...filterData,
      order_status: statusCheck
    });
    handleApiRequest(filter_data);
  }

  const handleCalenderBtnClick = () => {
    if (dateSelection === 'from') {
      setDateSelection('to');
    } else {
      setDateSelection('from');
      setIsOpen(false);
      let filter_data = filterData;
      handleApiRequest(filter_data);
    }
  }

  const getTodayDate = () => {
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${day}-${month}-${year}`;
    return currentDate;
  }

  return (
    <IonPage>
      <IonContent className="linear_bg">
        <AppHeader from="" />
        <div className='all-orders-wrapper'>
          <h2 className='ion-text-center ion-margin-bottom'>All Orders</h2>
          <IonList mode="md" className="ion-margin-bottom ion-no-padding ion-d-flex ion-justify-content-between ion-align-items-center">
            <div className="w-full">
              <div onClick={openPopover} className="select-list w-full ion-padding-15-12 ion-d-flex ion-justify-content-between ion-align-items-center">
                <span className="ion-text-capitalize ion-fs-14">{customerPacakgeStatus}</span>
                <IonIcon size='small' icon={chevronDownSharp} />
              </div>
              <IonPopover size="auto" ref={popover} isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
                <IonContent class="ion-padding-start ion-padding-end">
                  <IonItem lines='none'>
                    <IonLabel className="ion-no-margin" onClick={() => handleFilterType('today')}>Today</IonLabel>
                  </IonItem>
                  <IonItem lines='none'>
                    <IonLabel className="ion-no-margin" onClick={() => handleFilterType('weekly')}>Weekly</IonLabel>
                  </IonItem>
                  <IonItem lines='none'>
                    <IonLabel className="ion-no-margin" onClick={() => handleFilterType('monthly')}>Monthly Active</IonLabel>
                  </IonItem>
                  <IonItem lines='none'>
                    <IonLabel className="ion-no-margin" onClick={() => handleFilterType('custom-date')}>Custom Date</IonLabel>
                  </IonItem>
                </IonContent>
              </IonPopover>
            </div>
            <div className="ion-padding-start w-full">
              <div onClick={openOrderPopover} className="select-list w-full ion-padding-15-12 ion-d-flex ion-justify-content-between ion-align-items-center">
                <span className="ion-text-capitalize ion-fs-14">{orderStatus}</span>
                <IonIcon size='small' icon={chevronDownSharp} />
              </div>
              <IonPopover size="auto" ref={orderPopover} isOpen={orderOpenPopover} onDidDismiss={() => setOpenOrderPopover(false)}>
                <IonContent class="ion-padding-start ion-padding-end">
                  <IonItem lines='none'>
                    <IonLabel className="ion-no-margin" onClick={() => handleFilterStatus('ALL')}>All</IonLabel>
                  </IonItem>
                  <IonItem lines='none'>
                    <IonLabel className="ion-no-margin" onClick={() => handleFilterStatus('PENDING')}>Pending</IonLabel>
                  </IonItem>
                  <IonItem lines='none'>
                    <IonLabel className="ion-no-margin" onClick={() => handleFilterStatus('IN_PROGRESS')}>In Progress</IonLabel>
                  </IonItem>
                  <IonItem lines='none'>
                    <IonLabel className="ion-no-margin" onClick={() => handleFilterStatus('CANCELLED')}>Cancelled</IonLabel>
                  </IonItem>
                  <IonItem lines='none'>
                    <IonLabel className="ion-no-margin" onClick={() => handleFilterStatus('COMPLETED')}>Completed</IonLabel>
                  </IonItem>
                </IonContent>
              </IonPopover>
            </div>

          </IonList>
          <All_Orders_List Orders={customerOrders} type={typeToSend} />
          {/* {customerOrders.length > 0 ?
            // <Orders_List Orders={customerOrders} />
            <All_Orders_List Orders={customerOrders} type={filterData.type} />
            :
            !showLoading ?
              <h3>No orders founds</h3>
              :
              ''
          } */}
        </div>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />

      {/* modal code start */}
      <IonModal id="example-modal" isOpen={isOpen} onDidDismiss={() => { setIsOpen(false) }}>
        <div className="ion-padding">
          {
            dateSelection === 'from' ?
              <>
                <h2>From Date</h2>
                <IonDatetime presentation="date" onIonChange={(e) => {
                  let date_val: any = e.detail.value;
                  date_val = date_val.split('T');

                  setFilterData({
                    ...filterData,
                    from_date: date_val[0]
                  });
                }}></IonDatetime>
              </>
              :
              <>
                <h2>To Date</h2>
                <IonDatetime presentation="date" onIonChange={(e) => {
                  let date_val: any = e.detail.value;
                  date_val = date_val.split('T');

                  setFilterData({
                    ...filterData,
                    to_date: date_val[0]
                  });
                }} min={filterData.from_date === '' ? getTodayDate() : filterData.from_date}></IonDatetime>
              </>
          }
          <IonButtons slot="buttons">
            <IonButton color="primary" onClick={() => { handleCalenderBtnClick() }}>{dateSelection === 'from' ? 'Next' : 'Done'}</IonButton>
          </IonButtons>
        </div>
      </IonModal>

    </IonPage>
  )
}

export default All_Orders
