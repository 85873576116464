import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
  } from "@ionic/react";
  import { arrowForward, eyeOutline, lockClosedOutline, mailOutline } from "ionicons/icons";
  import React, { useState, useRef } from "react";
  import { Link, useHistory } from "react-router-dom";
  import "../../assets/scss/Desktop_Auth.scss";
import Change_Password_Component from "../../components/Change_Password_Component";
  import Navbar from "../../components/Navbar";
  
  const Desktop_ChangePassword: React.FC = () => {
    const [showPassword, setShowPassword] = useState<any>({
        current_psw: false,
        new_psw:false,
        confirm_psw: false,
       });
        const history = useHistory();
        const passwordHandler = (value: any)=>{
          setShowPassword({...showPassword, [value]: !showPassword[value]})
        }
        interface passwordDetail {
           current_password: string;
          new_password: string;
          password_confirmation: string;
        }
        const [passwordDetail, setpasswordDetail] = useState<any>({
          current_password: '',
          new_password: '',
          password_confirmation: '',
        });
        const itemRef = useRef<any>([]);
        const fieldsRef = useRef<any>([]);
        const itemHandler = async (type: string) => {
          for (let item in fieldsRef.current) {
          const {current_password, new_password, password_confirmation}= passwordDetail;
            if (item === 'currentPassword' && current_password === '') {
              fieldsRef.current[item].className = "sc-ion-label-ios-h sc-ion-label-ios-s ios md floating-label";
            }
            else if (item === 'newPassword' && new_password === '') {
              fieldsRef.current[item].className = "sc-ion-label-ios-h sc-ion-label-ios-s ios md floating-label";
            }
            else if (item === 'confirmPassword' && password_confirmation === '') {
              fieldsRef.current[item].className = "sc-ion-label-ios-h sc-ion-label-ios-s ios md floating-label";
            }
          }
      
          for (let item in itemRef.current) {
            if (item === "currentPassword") {
              itemRef.current[item].className = "";
            }
            else if (item === "newPassword") {
              itemRef.current[item].className = "";
            }
            else if (item === "confirmPassword") {
              itemRef.current[item].className = "";
            }
          }
      
          if (type === "currentPassword") {
            itemRef.current[type].className = "item-shadow";
            fieldsRef.current[type].className = "sc-ion-label-ios-h sc-ion-label-ios-s ios md top-label";
          }
          else if (type === "newPassword") {
            itemRef.current[type].className = "item-shadow";
            fieldsRef.current[type].className = "sc-ion-label-ios-h sc-ion-label-ios-s ios md top-label";
          }
          else if (type === "confirmPassword") {
            itemRef.current[type].className = "item-shadow";
            fieldsRef.current[type].className = "sc-ion-label-ios-h sc-ion-label-ios-s ios md top-label";
          }
        };
    return (
      <IonPage>
        <IonContent>
          <Navbar/>
          <IonGrid className="desktop-auth-grid">
            <IonRow className="justify-content-center align-items-center">
              <IonCol size="12" sizeLg="5">
                <div className="desktop-auth-wrapper">
                    <Change_Password_Component/>
                   {/* <IonRow className="justify-content-center">
                      <IonCol  size="12" sizeLg="6">
                      <div className="position-relative"><h4 className="ion-text-center"><span>OR</span></h4></div>
                    <IonButton fill="outline" onClick={(()=>history.push("/desktop_signup"))}>
                      sign up
                    </IonButton>
                      </IonCol>
                   </IonRow> */}
                </div>
              </IonCol>
              <IonCol size="12" sizeLg="5" className="ion-padding-top">
                <div>
                  <IonImg src={require("../../assets/images/change.png")} />
                </div>
              
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  };
  
  export default Desktop_ChangePassword;
  