import {
  IonCol,
  IonGrid,
  IonRow,
  IonImg,
  IonPage,
  IonContent,
  IonButtons,
  IonButton,
  IonIcon,
  IonLabel,
  IonItem,
  IonInput,
  IonTextarea,
  useIonViewWillEnter,
  IonToast,
} from "@ionic/react";
// import "../../assets/scss/Desktop_Dropoff.scss";
import "../../assets/scss/InputForms.scss";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Cookies from "universal-cookie";
import { validater } from '../../validate';
import { Preferences } from "@capacitor/preferences";
import { error } from "console";
const Desktop_Dropoff: React.FC = () => {
  const cookies = new Cookies();
  const history = useHistory();

  const [isShown, setIsShown] = useState(false);
  const [stepChild, setStepChild] = useState('five');
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [orderDetails, setOrderDetails] = useState<any>();

  useIonViewWillEnter(async () => {
    const { value } = await Preferences.get({ key: 'dtlc-order-details' });
    if (value) {
      let order_details = JSON.parse(value);
      setOrderDetails(order_details);
    }
  });

  const handleDropoffLocationDetails = async() => {
    let stop = false;
    let order_details = orderDetails;
    for (const index in order_details.dropoff_address){
      // order_details.pickup_address.forEach((address: any, index: any)=>{
      let result = validater({
        location_name: order_details.dropoff_address[index].location_name,
        location_detail: order_details.dropoff_address[index].location_detail,
      });
      if(!result.status){
        stop = true;
        setAlertMessage(`Dropoff address ${parseInt(index) + 1}: ${result.message}`);
        setShowToast(true);
        break;
      }
    }
    if(stop){
      return;
    }else{
      history.push('/dropoff-contact');
    }
  };

  const handleOrderDetailChange = async (value: any, index: any, type: any) => {
    let order_details = orderDetails;
    if(type === 'location-name'){
      order_details.dropoff_address[index].location_name = value;
      order_details.dropoff_address[index].place_name = value;
      setOrderDetails(order_details);
    }else if(type === 'location-detail'){
      order_details.dropoff_address[index].location_detail = value;
      setOrderDetails(order_details);
    }else if(type === 'location-notes'){
      order_details.dropoff_address[index].notes = value;
      setOrderDetails(order_details);
    }
    
    await Preferences.set({
      key: 'dtlc-order-details',
      value: JSON.stringify(order_details),
    });
    // cookies.set("dtlc-order-details", order_details);
  }

  const passState = () => {
    setIsShown(!isShown);
  };

  return (
    <IonPage>
      <IonContent>
        <div className="desktop-input desktop-wrapper">
          <IonGrid>
            <IonRow>
              <Sidebar passState={passState} />
              <IonCol
                size="12"
                sizeLg="9.5"
                className={isShown ? "rightbar rightbar-none" : "rightbar"}
              >
                <div className="inner-rightbar">
                  <div className=" input_rightbar_content">
                    <IonGrid>
                      <IonRow className="input_card">
                        <IonCol size="12" sizeMd="3" className="input-content">
                          <div className="ion-icon">
                            <IonImg
                              src={require("../../assets/images/dropOff.png")}
                            />
                            {/* <IonIcon className="Map-loction-icon" icon={locationOutline} /> */}
                          </div>
                          <IonIcon />
                        </IonCol>
                        <IonCol size="12" sizeMd="9" className="contact-form">
                          <div>
                            <div className="wrapper order_details">
                              <h3 className="mb_20 page_title scale-up-center ">Complete the drop-off location details</h3>
                              {orderDetails && orderDetails.dropoff_address.map((item: any, index: any)=>{
                                return(
                                  <div key={index} className="address-detail-bx">
                                    <div className="address-detail-outer">
                                      {orderDetails.pickup_address.length > 1? 
                                        <span className={'previous_input'}>
                                          {index + 1}
                                        </span>
                                          : 
                                        <IonImg className="loc-img" src={require('../../assets/images/location.png')} />
                                      }
                                      <h5 className="mb_0 loc-addr">{item.address}</h5>
                                    </div>
                                    <div className="mb_10 w_100">
                                      <IonItem
                                        className="detail_input delivery_input scale-up-center "
                                        lines="none"
                                      >
                                        <IonInput
                                          placeholder="Location name"
                                          type="text"
                                          value={item.location_name}
                                          onIonChange={(e) => handleOrderDetailChange(e.target.value, index, 'location-name')}
                                        />
                                      </IonItem>
                                    </div>
                                    <div className="mb_10 w_100">
                                      <IonItem
                                        className="detail_input delivery_input scale-up-center "
                                        lines="none"
                                      >
                                        <IonTextarea
                                          placeholder="Location details"
                                          value={item.location_detail}
                                          onIonChange={(e) => handleOrderDetailChange(e.target.value, index, 'location-detail')}
                                        />
                                      </IonItem>
                                    </div>
                                    <div className="mb_10 w_100">
                                      <IonItem
                                        className="detail_input delivery_input scale-up-center "
                                        lines="none"
                                      >
                                        <IonTextarea
                                          placeholder="Do we need to know anything extra?"
                                          value={item.notes}
                                          onIonChange={(e) => handleOrderDetailChange(e.target.value, index, 'location-notes')}
                                        />
                                      </IonItem>
                                    </div>
                                  </div>
                                );
                              })}
                              <IonButton expand="block" className="scale-up-center ion-margin-top" onClick={()=> handleDropoffLocationDetails()}>Next</IonButton>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
      <IonToast
        isOpen={showToast}
        position="bottom"
        onDidDismiss={() => setShowToast(false)}
        color={"danger"}
        message={alertMessage}
        duration={1500}
      />
    </IonPage>
  );
};
export default Desktop_Dropoff;
