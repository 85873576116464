import { IonButton, IonButtons, IonContent, IonDatetime, IonImg, IonInput, IonItem, IonPage, IonTextarea, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { BookingHeader } from "../components/BookingHeader";
import { useState } from "react";
import Cookies from 'universal-cookie';
import "../assets/scss/Delivery_Details.scss";

const Delivery_Summary: React.FC = () => {
  let timerr: any;
  let minutes = 1;
  let seconds = 5;
  const history = useHistory();
  const [over, setOver] = useState(false);
  const [time, setTime] = useState<any>({
    minutes: minutes,
    seconds: seconds
  });
  const cookies = new Cookies();

  useIonViewWillLeave(()=>{
    clearInterval(timerr);
  });

  useIonViewWillEnter(() => {
    clearInterval(timerr);
    
    if(cookies.get("dtlc-timer-start-time")){
      timerr = setInterval(() => tick(), 1000);
      
      let d:any = new Date();
      let current_time = d.getTime();

      let koib = cookies.get("dtlc-timer-start-time");
      let timer_start_time:any = new Date(Number(koib));
      timer_start_time = timer_start_time.getTime();
      let time_difference = current_time - timer_start_time;
      let sec = time_difference / 1000;
      let total_allowed_seconds = (minutes * 60) + seconds;

      if(sec > total_allowed_seconds){
        setOver(true);
        console.log("your time is up, you can't replace order now!");
        clearInterval(timerr);
        minutes = 0;
        seconds = 0;
        setTime({
          minutes: minutes,
          seconds: seconds
        });
      }else{
        total_allowed_seconds = total_allowed_seconds - sec;
        let mints = total_allowed_seconds / 60;
        let secons = mints - parseInt(mints.toString());
        secons = Math.ceil(secons * 60);
        mints = parseInt(mints.toString());
        minutes = mints;
        seconds = secons;
        setTime({
            minutes: mints,
            seconds: secons
        });

        // let mints = sec / 60;
        // //let seco = parseInt((minutes % 1).toFixed(2).substring(2));
        // const min = parseInt(mints.toString());
        // let secon = mints - min;
        // secon *= 60;
        // secon = Math.floor(secon);
        
        // if(seconds < secon){
        //   seconds = (seconds - secon) + 60;
        // }else{
        //   seconds = seconds - secon;
        // }
        // if(minutes == 1){
        //   minutes = 0;
        // }else{
        //   minutes = minutes - min;
        // }
        // setTime({
        //   minutes: minutes,
        //   seconds: seconds
        // });
      }
    
    }else{
      let d = new Date();
      cookies.set("dtlc-timer-start-time", d.getTime());
      timerr = setInterval(() => tick(), 1000);
    }
  });

  const tick = async () => {
    if (minutes == 0 && seconds === 0) {
      setOver(true);
      clearInterval(timerr);
    }
    else if (seconds !== 0) {
      seconds -= 1;
      setTime({
        minutes: minutes,
        seconds: seconds
      });
    } else if (seconds === 0) {
      seconds = 59;
      minutes -= 1;
      setTime({
        minutes: minutes,
        seconds: seconds
      });
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen className="linear_bg overflow">
        <BookingHeader from='deliver summary'/>
        <div className="wrapper order_details">
          <div className="position-relative">
            <div className="timer">
              {over ? <strong>"Time's up!"</strong> : <strong>{time.minutes.toString().padStart(2, "0")}:{time.seconds.toString().padStart(2, "0")}</strong>}
            </div>
            <IonImg src={require('../assets/images/appclock.png')} />
          </div>
          <h3 className="page_title scale-up-center ">Almost done!</h3>
          <p>Please proceed with the payment and a courier will shortly take care of your order.</p>
          <div className="box_style d-flex justify-content-center">
            <div className="summary_input scale-up-center ">
              <h5>up to 7:20 pm</h5>
              <p>Delivery time</p>
            </div>
            <div className="order_price scale-up-center ">
              <h5>£ 40.00</h5>
              <p>Order price</p>
            </div>
          </div>
          <IonButton className="scale-up-center" onClick={() => {history.push("/sender_contact_detail") }}>Next</IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Delivery_Summary;
