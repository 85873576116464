import { IonButton, IonButtons, IonCol, IonDatetime, IonGrid, IonIcon, IonImg, IonLoading, IonModal, IonRow, IonToast, useIonViewWillEnter } from '@ionic/react'
import axios from 'axios';
import { addOutline } from 'ionicons/icons'
import { useRef, useState } from 'react';
import Cookies from 'universal-cookie';
import { BASE_URL, postRequest } from '../ApiServices';
import "../assets/scss/Driver_Documents.scss";

const Driver_Documents_component = () => {
    const modal = useRef<HTMLIonModalElement>(null);
    const cookies = new Cookies();
    const date = new Date();

    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [checkList, setCheckList] = useState<any>([])
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [checklistIndex, setCheckListIndex] = useState<any>('');
    const [showToast, setShowToast] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<any>('');
    const [todayDate, setTodayDate] = useState<any>(date.getFullYear()+'-'+date.getMonth()+'-'+date.getDate()+'T00:00:00');

    const handleDateChange = (event: CustomEvent) => {
        setSelectedDate(event.detail.value!);
    };

    useIonViewWillEnter(() => {
        getDriverChecklist();
        let date = new Date();
        let month: any = date.getMonth() + 1;
        month = ('0' + month).slice(-2);
        let d: any = date.getDate();
        d = ('0' + d).slice(-2);

        setTodayDate(date.getFullYear()+'-'+month+'-'+d+'T00:00:00');
    });

    const getDriverChecklist = () => {
        setShowLoading(true);
        let dtlc = cookies.get('dtlc');
        if (dtlc && dtlc.token) {
            setShowLoading(true);
            postRequest('driver-checklists/get-checklist', {}, dtlc.token.token).then(async (data) => {
                if (data.status) {
                    setShowLoading(false);
                    setCheckList(data.data);
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    const handleFileUploadClick = (index: any) => {
        setCheckListIndex(index);
        if(checkList[index].is_expiry){
            setShowModal(true);
        }else{
            (document as any).getElementById("file-upload").click();
        }
    }

    const handleExpiryDate = () => {
        setShowModal(false);
        (document as any).getElementById("file-upload").click();
    }

    const handleFileUpload = (event: any) => {
        let dtlc = cookies.get('dtlc');
        if(dtlc && dtlc.token){
            setShowLoading(true);
            const file = event.target.files[0];
            let formData = new FormData();
            formData.append("checklist_id", checkList[checklistIndex].id);
            if(checkList[checklistIndex].is_expiry){
                formData.append("expiry_date", selectedDate.slice(0, 10));
            }
            formData.append("attachment", file);

            const config = {
                headers: {
                'content-type': 'multipart/form-data',
                "Access-Control-Allow-Origin": '*',
                Authorization: `Bearer ${dtlc.token.token}`
                }
            };
            axios
            .post(BASE_URL + 'driver-checklists/store', formData, config)
            .then(async (res: any) => {
                if(res.data.status){
                    getDriverChecklist();
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
        }
    }

    const handleReuploadFile = (index: any) => {
        handleFileUploadClick(index);
    }

    return (
        <div className="document-wrapper">
            <div className="driver-document-detail">
                <h2 className='document-title'>Driver Documents</h2>
                <div className="document-box">
                    <IonGrid>
                        <IonRow>
                            <input
                                type="file"
                                id="file-upload"
                                style={{ display: "none" }}
                                onChange={(e)=>handleFileUpload(e)}
                            />
                            {checkList.map((item: any, index: any) => {
                                    return (
                                        <IonCol size="6" key={index}>
                                            <div className="space-box">
                                                {item.driver_checklist && item.driver_checklist.latest_attachment?
                                                    <div>
                                                        <div className="category_boxes">
                                                            <IonButtons>
                                                                <div>
                                                                    <IonImg onClick={()=> handleReuploadFile(index)} src={require('../assets/images/cloud-computing.png')} />
                                                                </div>
                                                                {item.driver_checklist.latest_attachment.type === 'jpg' || item.driver_checklist.latest_attachment.type === 'jpeg'?
                                                                    <IonImg src={require('../assets/images/jpg.png')} />
                                                                    :
                                                                    item.driver_checklist.latest_attachment.type === 'png'?
                                                                        <IonImg src={require('../assets/images/png.png')} />
                                                                    : 
                                                                    item.driver_checklist.latest_attachment.type === 'gif'?
                                                                        <IonImg src={require('../assets/images/gif.png')} />
                                                                    :
                                                                    item.driver_checklist.latest_attachment.type === 'pdf'? 
                                                                    <IonImg src={require('../assets/images/pdf.png')} />
                                                                    :
                                                                    <IonImg src={require('../assets/images/file-icon.png')} />
                                                                }
                                                                <p>{item.driver_checklist.latest_attachment.name}</p>
                                                                <p className='date-set'>{item.updated_at.slice(0, 10)}</p>
                                                            </IonButtons>
                                                        </div>
                                                        <h6>{item.name}</h6>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className="empty_boxes">
                                                            <IonButtons>
                                                                <IonIcon className="back-icon" icon={addOutline} onClick={() => handleFileUploadClick(index)} />
                                                            </IonButtons>
                                                            
                                                        </div>
                                                        <h6>{item.name}</h6>
                                                    </div>
                                                }
                                            </div>
                                        </IonCol>
                                    )
                                })
                            }
                            <IonModal id="example-modal" onDidDismiss={() => {
                                setShowModal(false);
                            }} isOpen={showModal}>
                                <div className="ion-padding">
                                    <h3>Select Expiry Date</h3>
                                    <IonDatetime presentation="date" 
                                        min={todayDate}
                                        max="5023-12-31T23:59:59" value={selectedDate} onIonChange={handleDateChange}></IonDatetime>
                                    <IonButton onClick={() => handleExpiryDate()}>OK</IonButton>
                                </div>
                            </IonModal>
                        </IonRow>
                    </IonGrid>
                </div>
            </div>
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={"Please wait..."}
            />
            <IonToast
                isOpen={showToast}
                position="bottom"
                onDidDismiss={() => setShowToast(false)}
                color={"primary"}
                message={alertMessage}
                duration={2500}
            />
        </div>
    )
}

export default Driver_Documents_component
