import { IonButton, IonHeader, IonIcon, IonImg } from "@ionic/react";
import { arrowBackOutline, home, homeOutline, homeSharp } from "ionicons/icons";
import { useHistory } from "react-router";
import "../assets/scss/AppHeader.scss";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

interface general {
  from: string
}

export const BookingHeader: React.FC<general> = (props) => {
  const cookies = new Cookies();
  const history = useHistory();

  const handleHomeButtonClick = () => {
    let dtlc = cookies.get('dtlc');
    if(dtlc && dtlc.token){
      if(dtlc.user_data.role_id === 5){
        history.push('/dispatch_panel');
      }else if(dtlc.user_data.role_id === 3){
        history.push('/driver_panel');
      }else if(dtlc.user_data.role_id === 2){
        history.push('/customer_panel');
      }
    }
  }

  return (
    <IonHeader className="header_top ion-no-border">
      <div className="header_toolbar">
        <IonButton
          className="header_back_button"
          onClick={async () => {
            // if (props.goingBackFrom === "delivery types") {
            //     cookies.remove('dtlc-order-details');
            // }
            if(props.from === 'mobile'){
              history.push('/login');
            }else if(props.from === 'mobile-order-booking'){
              if(cookies.get('dtlc')){
                let dtlc = cookies.get('dtlc');
                if(dtlc.user_data.role_id === 2){
                  if (!Capacitor.isNative) {
                    await Preferences.remove({ key: 'dtlc-order-details'});
                  }
                  history.push('/customer_panel');
                }else if(dtlc.user_data.role_id === 3){
                  history.push('/driver_panel');
                }else if(dtlc.user_data.role_id === 5){
                  history.push('/dispatch_panel');
                }
              }else{
                if (!Capacitor.isNative) {
                  await Preferences.remove({ key: 'dtlc-order-details'});
                  history.replace('/login');
                }else{
                  history.push('/login');
                }
              }
            }else{
              // history.push('/delivery_details');
              history.goBack();
            }
          }}
        >
          <IonIcon icon={arrowBackOutline} size="large" />
        </IonButton>
        <span
          className="header_home_button"
          onClick={() => handleHomeButtonClick()}
        >
          <IonImg src={require("../assets/images/home-button.png")}/>
        </span>
      </div>
        
    </IonHeader>
  );
};
