const countryList = [
    {
        "name": "United Kingdom",
        "dialCode": "+44",
        "isoCode": "GB",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gb.svg"
    }
];

countryList.sort((a, b) => {
    var dialcode_a = a.dialCode.split('+');
    var dialcode_b = b.dialCode.split('+');
    a = parseInt(dialcode_a[1]);
    b = parseInt(dialcode_b[1]);

    if(a > b) {
        return 1;
    } else if(a < b) {
        return -1;
    } else {
        return 0;
    }
});


export default countryList;