import {
    IonPage,
    IonContent,
} from "@ionic/react";
import { AppHeader } from "../components/AppHeader";
import Driver_Setting_Component from "../components/Desktop_Setting_Component";


const Driver_Setting: React.FC = () => {

    return (
        <IonPage>
            <IonContent fullscreen className="linear_bg">
                <AppHeader from=''/>
                <Driver_Setting_Component/>
            </IonContent>
        </IonPage>
    );
};

export default Driver_Setting;




