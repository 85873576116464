import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Custom css */
import './theme/basic.scss';

import Book_Now from './pages/Book_Now';
import Booking_Confirmation from './pages/Booking_Confirmation';
import Booking_Type from './pages/Booking_Types';
import Choices from './pages/Choices';
import Order_Status from './pages/Order_Status';
import View_Order from './pages/View_Order';
import Delivery_Types from './pages/Delivery_Types';
import Delivery_Details from './pages/Delivery_Details';
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import Sender_Contact_Details from './pages/Sender_Contact_Details';
import Delivery_Summary from './pages/Delivery_Summary';
import SignUp from './pages/SignUp';
import User_Profile from './pages/User_Profile';
import ChangePassword from './pages/ChangePassword';
import About_App from './pages/About_App';
import Helps_FAQs from './pages/Helps_FAQs';
import Terms_Conditions from './pages/Terms_Conditions';
import User_Information from './pages/User_Information';
import Customer_Panel from './pages/Customer_Panel';
import Driver_Job from './pages/Driver_Job';
import Driver_Panel from './pages/Driver_Panel';
import Driver_Sidebar from './pages/Driver_Sidebar';
import Jobs_History from './pages/Jobs_History';
import Driver_Documents from './pages/Driver_Documents';
import Notifications from './pages/Notifications';
import NotificationDetails from './pages/NotificationDetails';
import Driver_Setting from './pages/Driver_Setting';
import Edit_Profile from './pages/Edit_Profile';
import Order_History from './pages/Order_History';
import Customer_Care from './components/Customer_Care';
import Bookings from './pages/Bookings';
import Dispatch from './pages/Dispatch';
import Manage_Driver from './pages/Manage_Driver';
import Driver_Profile from './pages/Driver_Profile';
import Driver_Profile_Edit from './pages/Driver_Profile_Edit';
import Order_Overview from './pages/Order_Overview';
import Inprocess_Orders from './pages/Inprocess_Orders';
import Privacy_Policy from './pages/Privacy_Policy';
import All_Orders from './pages/All_Orders';
import New_Orders from './pages/New_Orders';
import Order_Request from './pages/Order_Request';
import Manage_Customer from './pages/Manage_Customer';
import Customer_Queries from './pages/Customer_Queries';
import Customer_Profile from './pages/Customer_Profile';
import Customer_Profile_View from './pages/Customer_Profile_View';
import Driver_Rejection from './pages/Driver_Rejection';
import Order_Rejection_Details from './pages/Order_Rejection_Details';
import Driver_Location_Tracking from './pages/Driver_Location_Tracking';
import Delivered_Orders from './pages/Delivered_Orders';
import Order_Transfers from './pages/Order_Transfers';
import Order_Transfer_Reason from './pages/Order_Transfer_Reason';
import Meetup_Scan from './pages/Meetup_Scan';
import View_Meetup_Points from './pages/View_Meetup_Points';
import Meetup_points from './pages/Pickup_Meetup_Points';


setupIonicReact();
export let api_url = "http://127.0.0.1:8000/api/";

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/home">
          <Home />
        </Route>
        {/* <Route exact path="/meetup_points">
          <Meetup_points passState={()=>{}} OrderDetails={}/>
        </Route> */}
        <Route exact path="/signup">
          <SignUp />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/change_password">
          <ChangePassword />
        </Route>
        <Route exact path="/forgot">
          <Forgot />
        </Route>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/user_profile" >
          <User_Profile />
        </Route>
        <Route exact path="/user_information">
          <User_Information />
        </Route>
        <Route exact path="/about_app">
          <About_App />
        </Route>
        <Route exact path="/helps_faqs">
          <Helps_FAQs />
        </Route>
        <Route exact path="/terms_conditions">
          <Terms_Conditions />
        </Route>
        <Route exact path="/driver_tracking">
          <Driver_Location_Tracking />
        </Route>
        <Route exact path="/book_now">
          <Book_Now />
        </Route>
        <Route exact path="/booking_type">
          <Booking_Type />
        </Route>
        <Route exact path="/choices">
          <Choices />
        </Route>
        <Route exact path="/booking_confirmation">
          <Booking_Confirmation />
        </Route>
        <Route exact path="/order_status">
          <Order_Status />
        </Route>
        <Route exact path="/view_order">
          <View_Order />
        </Route>
        <Route exact path="/delivery_types">
          <Delivery_Types />
        </Route>
        <Route exact path="/delivery_details">
          <Delivery_Details />
        </Route>
        <Route exact path="/sender_contact_detail">
          <Sender_Contact_Details />
        </Route>
        <Route exact path="/delivery_summary">
          <Delivery_Summary />
        </Route>
        <Route exact path="/customer_panel">
          <Customer_Panel />
        </Route>
        <Route exact path="/driver_job">
          <Driver_Job />
        </Route>
        <Route exact path="/driver_panel">
          <Driver_Panel />
        </Route>
        <Route exact path="/driver_sidebar">
          <Driver_Sidebar />
        </Route>
        <Route exact path="/jobs_history">
          <Jobs_History />
        </Route>
        <Route exact path="/driver_documents">
          <Driver_Documents />
        </Route>
        <Route exact path="/notifications">
          <Notifications />
        </Route>
        <Route exact path="/notifications_details">
          <NotificationDetails/>
        </Route>
        <Route exact path="/driver_setting">
          <Driver_Setting/>
        </Route>
        <Route exact path="/edit_profile">
          <Edit_Profile/>
        </Route>
        <Route exact path="/order_history">
          <Order_History/>
        </Route>
        <Route exact path="/customer_care">
          <Customer_Care/>
        </Route>
        <Route exact path="/bookings">
          <Bookings/>
        </Route>
        <Route exact path="/driver_documents">
          <Driver_Documents />
        </Route>
        <Route exact path="/dispatch_panel">
          <Dispatch />
        </Route>
        <Route exact path="/manage_driver">
          <Manage_Driver />
        </Route>
        <Route exact path="/driver_profile_edit">
          <Driver_Profile_Edit />
        </Route>
        <Route exact path="/driver_profile">
          <Driver_Profile />
        </Route>
        <Route exact path="/order_overview">
          <Order_Overview />
        </Route>
        <Route exact path="/inprocess_orders">
          <Inprocess_Orders />
        </Route>
        <Route exact path="/privacy_policy">
          <Privacy_Policy />
        </Route>
        <Route exact path="/new_orders">
          <New_Orders />
        </Route>
        <Route exact path="/delivered_orders">
          <Delivered_Orders />
        </Route>
        <Route exact path="/order_requests">
          <Order_Request />
        </Route>
        <Route exact path="/all_orders">
          <All_Orders />
        </Route>
        <Route exact path="/manage_customer">
          <Manage_Customer />
        </Route>
        <Route exact path="/customer_queries">
          <Customer_Queries />
        </Route>
        <Route exact path="/customer_profile">
          <Customer_Profile />
        </Route>
        <Route exact path="/customer_profile_view">
          <Customer_Profile_View />
        </Route>
        <Route exact path="/driver_rejection">
          <Driver_Rejection />
        </Route>
        <Route exact path="/order_transfers">
          <Order_Transfers />
        </Route>
        <Route exact path="/order_transfer_reason">
          <Order_Transfer_Reason/>
        </Route>
        <Route exact path="/order_rejection_details">
          <Order_Rejection_Details />
        </Route>
        <Route exact path="/meetup_scan">
          <Meetup_Scan />
        </Route>
        <Route exact path="/view-meetup-points">
          <View_Meetup_Points />
        </Route>
        <Redirect to="/login" />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
